import React from "react";
import { useDispatch, useSelector } from "react-redux";

import InterventionConfirmModal from "./InterventionConfirmModal";
import ColoredButton from "../../../Common/Buttons/ColoredButton";

import { openModal } from "../../../../REDUX/actions/modal";

const InterventionLogic2 = ({ intervention, ticket }) => {
  const dispatch = useDispatch();
  const { interventionActionLoading } = useSelector(
    (state) => state.interventionsReducer
  );
  const {
    canAcceptInterventions,
    canRejectInterventions,
    canHoldForValidationInterventions,
    canRequestRevisionInterventions,
    canValidateInterventions,
  } = useSelector((state) => state.roleReducer);

  return (
    <div className={`w-100`}>
      {/* INTERVENTION CRéé */}
      {intervention.attributes.statut === 1 ? (
        <div className={`d-flex w-100 gap-3`}>
          <div className={`w50`}>
            {canAcceptInterventions ? (
              <ColoredButton
                color={"#0CCE91"}
                background="#0CCE9126"
                icon="check-square"
                square
                loading={interventionActionLoading}
                disabled={interventionActionLoading}
                textButton="Accepter"
                callback={() => {
                  dispatch(
                    openModal({
                      type: "small",
                      content: (
                        <InterventionConfirmModal
                          intervention={intervention}
                          ticket={ticket}
                          title={"Ajouter des notes"}
                          statut={2}
                          text={"Intervention acceptée"}
                          color={"#0CCE91"}
                          background="#0CCE9126"
                          icon="check-square"
                        />
                      ),
                    })
                  );
                }}
              />
            ) : null}
          </div>

          <div className={`w50`}>
            {canRejectInterventions ? (
              <ColoredButton
                color={"#FF2C2C"}
                background="#FF2C2C26"
                icon="x-square"
                textButton="Refuser"
                loading={interventionActionLoading}
                disabled={interventionActionLoading}
                square
                callback={() => {
                  dispatch(
                    openModal({
                      type: "small",

                      content: (
                        <InterventionConfirmModal
                          intervention={intervention}
                          ticket={ticket}
                          title={"Motif du refus"}
                          statut={5}
                          text={"Intervention refusée"}
                          color={"#FF2C2C"}
                          background="#FF2C2C26"
                          icon="x-square"
                        />
                      ),
                    })
                  );
                }}
              />
            ) : null}
          </div>
        </div>
      ) : null}
      {/* INTERVENTION Accepté */}
      {intervention.attributes.statut === 2 ||
      intervention.attributes.statut === 6
        ? canHoldForValidationInterventions && (
            <div className="">
              <ColoredButton
                color={`${
                  intervention.attributes.statut === 2 ? "purple" : "orange"
                }`}
                textButton={`Passer à "Attente de validation"`}
                outlined
                loading={interventionActionLoading}
                disabled={interventionActionLoading}
                callback={() => {
                  dispatch(
                    openModal({
                      type: "small",
                      content: (
                        <InterventionConfirmModal
                          intervention={intervention}
                          addDuration
                          ticket={ticket}
                          title={"Ajouter des notes"}
                          statut={3}
                          text={"Intervention en attente de validation"}
                          color={`white`}
                          background={`purple`}
                        />
                      ),
                    })
                  );
                }}
              />
            </div>
          )
        : null}
      {/* INTERVENTION en attente de validation */}
      {intervention.attributes.statut === 3 ? (
        <div className="d-flex w-100 gap-3">
          <div className={`w50`}>
            {canValidateInterventions ? (
              <ColoredButton
                color={"#0CCE91"}
                background="#0CCE9126"
                icon="check-square"
                square
                loading={interventionActionLoading}
                disabled={interventionActionLoading}
                textButton="Valider & finaliser"
                callback={() => {
                  dispatch(
                    openModal({
                      type: "small",
                      content: (
                        <InterventionConfirmModal
                          intervention={intervention}
                          ticket={ticket}
                          title={"Ajouter des notes"}
                          statut={4}
                          text={"Intervention finalisée"}
                          color={"#0CCE91"}
                          background="#0CCE9126"
                          icon="check-square"
                        />
                      ),
                    })
                  );
                }}
              />
            ) : null}
          </div>

          <div className="w50">
            {canRequestRevisionInterventions ? (
              <ColoredButton
                color={"#FF2C2C"}
                background="#FF2C2C26"
                icon="x-square"
                square
                loading={interventionActionLoading}
                disabled={interventionActionLoading}
                textButton="Révision"
                callback={() => {
                  dispatch(
                    openModal({
                      type: "small",
                      content: (
                        <InterventionConfirmModal
                          intervention={intervention}
                          ticket={ticket}
                          title="Motif de demande de revision"
                          statut={6}
                          text={"Intervention demandée en revision"}
                          color="#FF2C2C"
                          background="#FF2C2C26"
                          icon="x-square"
                        />
                      ),
                    })
                  );
                }}
              />
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default InterventionLogic2;
