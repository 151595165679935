import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./FunctionSideBar.css";
import { closeSideBar } from "../../../REDUX/actions/sideBar";
import { Offcanvas } from "react-bootstrap";
import { CloseIcon } from "../../Common/LightDarkSvg";
import LightDarkText from "../../Common/LightDarkText";
import LightDarkContainer from "../../Common/LightDarkContainer";

const FunctionSideBar = () => {
  const dispatch = useDispatch();
  const { open, content, sideBartype, sideBarTitle } = useSelector(
    (state) => state.sideBarReducer
  );
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <Offcanvas
      show={open}
      onHide={() => {
        dispatch(closeSideBar());
      }}
      scroll
      backdrop
      style={{ background: "transparent", border: "none", height: "100vh" }}
      placement="end"
      className={`${sideBartype} p-3 notScrollable`}
    >
      <div className="radius10 bg-white">
        <Offcanvas.Header
          className={` d-flex justify-content-start gap-3 p-0 px-4 py-3 m-0 radius10-top ${
            mode === "light" ? "bg-white" : "bg-dark-mode"
          }`}
        >
          <CloseIcon
            dimension={30}
            clickCallback={() => {
              dispatch(closeSideBar());
            }}
          />
          {sideBarTitle && (
            <LightDarkText
              className="text-start f24 bold "
              color={mode === "light" ? "text-dark-blue" : "text-light-text"}
            >
              {sideBarTitle}
            </LightDarkText>
          )}
        </Offcanvas.Header>
      </div>
      <LightDarkContainer
        className="h100"
        colorLight="white radius10-bottom notScrollable"
      >
        {content}
      </LightDarkContainer>
    </Offcanvas>
  );
};

export default FunctionSideBar;
