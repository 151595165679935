export const BreadCrumb = (path) => {
  const pathArray = path.split("/");
  if (path.includes("oneDrive")) {
    return "Dossier OneDrive";
  }
  if (path.includes("gDrive")) {
    return "Dossier Google Drive";
  }
  if (path.includes("sharepointsite")) {
    return "Site SharePoint";
  }
  if (path.includes("external-sync")) {
    return "Synchronisation en cours";
  }
  if (path.includes("configrateur-3d")) {
    return "Configurateur 3D";
  }
  if (pathArray[2]) {
    return pathArray[2];
  } else {
    return "Dashboard";
  }
};
