import React, { useState } from "react";
import { useDrop } from "react-dnd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../REDUX/actions/modal";
import {
  moveFile,
  deleteFolder,
  downloadMultipleAgilixFolders,
} from "../../REDUX/actions/cloud";
import { openConfirmationModal } from "../../REDUX/actions/confirmationModal";
import useWindowWidth from "../../Hooks/useWindowWidth";
import {
  CloudFolder,
  DeleteIcon,
  Download,
  EditIcon,
  ShareIcon,
} from "../Common/LightDarkSvg";
import LightDarkText from "../Common/LightDarkText";
import RenameCloudItem from "./RenameCloudItem";
import ShareFolder from "./ShareFolder";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import TooltipActions from "../Common/Tooltip/TooltipActions";
import ElementName from "./CloudPartials/ElementName";
import FileSharedSection from "./CloudPartials/FileSharedSection";
import ShareInTicket from "./ShareModals/ShareInTicket";
import SocietyAvatar from "../Societies/NewSociety/SocietyAvatar";
import TicketIcon from "../Common/MenuIcons/TicketIcon";
import ShareTicketNumber from "./ShareModals/ShareTicketNumber";

const FolderCard = ({ dossier, shared, isMine }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();

  const [showTooltip, setshowTooltip] = useState(false);
  const { mode } = useSelector((state) => state.genearlReducer);

  const { organisationUrl } = useSelector(
    (state) => state.organisationsReducer
  );

  const [loadingDownload, setLoadingDownload] = useState(false);
  const [{ isActive }, drop] = useDrop(() => ({
    accept: "Card",
    drop: (item) => {
      dispatch(moveFile(item.id, dossier.id));
    },
    collect: (monitor) => ({
      isActive: monitor.canDrop() && monitor.isOver(),
    }),
  }));

  const handleDeleteFolder = (folderId, message2, message, btnMessage) => {
    dispatch(
      openConfirmationModal({
        callBack: () => dispatch(deleteFolder([folderId])),
        icon: "archive",
        message: message || "Voulez vous supprimer le dossier",
        message2: message2,
        confirmButtonText: btnMessage || "Supprimer le dossier",
      })
    );
  };
  const tooltipData = [
    {
      id: 1,
      text: "Renommer",
      icon: <EditIcon className="me-2" size={24} />,
      action: () => {
        setshowTooltip(false);
        dispatch(
          openModal({
            type: "small",
            content: (
              <RenameCloudItem
                id={dossier.id}
                name={dossier?.attributes?.name}
              />
            ),
          })
        );
      },
      condition: isMine,
    },
    {
      id: 2,
      text: "Partager",
      icon: <ShareIcon className="me-2" size={24} />,
      action: () => {
        setshowTooltip(false);
        dispatch(
          openModal({
            type: "small",
            content: (
              <ShareFolder
                folder={dossier}
                folderSharedUsers={dossier?.attributes?.sharedUsers}
                parentFolder={dossier?.attributes?.parentFolder?.data}
              />
            ),
          })
        );
      },
      condition: isMine,
    },
    {
      id: 3,
      text: "Télécharger",
      icon: <Download className="me-2" size={24} />,
      action: () => {
        setshowTooltip(false);
        dispatch(
          downloadMultipleAgilixFolders([
            { id: dossier.id, name: dossier.attributes.name },
          ])
        );
      },
      condition: true,
    },
    {
      id: 4,
      text: "Supprimer",
      icon: <DeleteIcon className="me-2" size={24} />,
      action: () => {
        setshowTooltip(false);
        handleDeleteFolder(
          dossier.id,
          `Supprimer le dossier ${dossier?.attributes?.name}?`,
          "êtes vous sûr de vouloir",
          "Supprimer le dossier"
        );
      },
      condition: isMine,
    },
    {
      id: 5,
      text: "Partager dans un ticket",
      icon: <TicketIcon className="me-2" size={24} />,
      action: () => {
        setshowTooltip(false);
        dispatch(
          openModal({
            type: "small",
            content: <ShareInTicket folder={dossier} />,
          })
        );
      },
      condition: isMine,
    },
  ];

  return (
    <div
      ref={drop}
      className="folderCard d-flex flex-column justify-content-between p-3 radius05 t-02"
      style={{
        transform: `${isActive ? "scale(1.1)" : ""}`,
        backgroundColor: `${
          isActive && mode === "light"
            ? "#e0e7ff"
            : !isActive && mode === "dark"
            ? "#181f3a"
            : isActive && mode === "dark"
            ? "#354074"
            : "white"
        }`,
      }}
    >
      {loadingDownload ? (
        <div className="alignC mt-5">
          <Spinner size="md" variant="light-text" />
        </div>
      ) : (
        <>
          <div className="d-flex align-items-start position-relative justify-content-between">
            <ShareTicketNumber
              dossier={dossier}
              isMine={isMine}
              sharesNumber={dossier.attributes.cloud_ticket_shares.data.length}
              callBack={() =>
                isMine &&
                dispatch(
                  openModal({
                    type: "small",
                    content: <ShareInTicket folder={dossier} />,
                  })
                )
              }
            />
            <div
              className="pointer"
              onClick={() =>
                navigate(`${organisationUrl}/dossier/${dossier.id}`)
              }
            >
              {dossier.attributes.avatar ? (
                <SocietyAvatar
                  width={screenWidth < 1200 ? 30 : 40}
                  height={screenWidth < 1200 ? 30 : 40}
                  imageUrl={
                    dossier.attributes.avatar?.data?.attributes?.image?.data
                      ?.attributes?.url
                  }
                />
              ) : (
                <CloudFolder size={screenWidth < 1200 ? 30 : 40} />
              )}
            </div>
            <div className="d-flex justify-self-end">
              {shared ? (
                <FileSharedSection file={dossier} isMine={isMine} />
              ) : null}

              <div
                className="pointer position-relative ms-2"
                style={{ width: "fit-content" }}
                onClick={() => setshowTooltip(!showTooltip)}
              >
                <TooltipActions
                  setshowTooltip={setshowTooltip}
                  showTooltip={showTooltip}
                  tooltipData={tooltipData}
                />
              </div>
            </div>
          </div>

          <div className="d-flex mt-3 mb-2 justify-content-between">
            <div
              className="text-start pointer  w-100"
              onClick={() =>
                navigate(`${organisationUrl}/dossier/${dossier.id}`)
              }
            >
              <ElementName
                name={dossier.attributes.name}
                callBack={() =>
                  navigate(`${organisationUrl}/dossier/${dossier.id}`)
                }
              />
              {dossier.attributes?.shared_to_societes?.data.length === 1 ? (
                <LightDarkText
                  className="f12 bold mt-1"
                  color="text-light-text"
                >
                  {
                    dossier.attributes?.shared_to_societes?.data[0]?.attributes
                      ?.name
                  }
                </LightDarkText>
              ) : null}
              <div className="d-flex gap-2">
                <LightDarkText
                  className="f10 bold mt-1"
                  color="text-light-text"
                >
                  {dossier.attributes?.fichiers?.count || 0}
                  {dossier.attributes?.fichiers?.count === 1
                    ? " fichier "
                    : " fichiers "}{" "}
                  |
                </LightDarkText>
                <LightDarkText
                  className="f10 bold mt-1"
                  color="text-light-text"
                >
                  {dossier.attributes?.subFolders?.count || 0}
                  {dossier.attributes?.subFolders?.count === 1
                    ? " dossier "
                    : " dossiers "}
                </LightDarkText>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FolderCard;
