import QueryString from "qs";
import Api from "../../Api/Api";
import { getTicketItem, refetchTicket } from "./tickets";
import { handleError } from "./toastMessage";
import { Pagination } from "react-bootstrap";

export const openPdfCommentModal = (data) => async (dispatch) => {
  console.log(data);
  dispatch({
    type: "OPEN_PDF_COMMENT_MODAL",
    payload: data,
  });
};
export const closePdfCommentModal = () => async (dispatch) => {
  dispatch({
    type: "CLOSE_PDF_COMMENT_MODAL",
  });
};

export const createNewFileVersion =
  (versionData) => async (dispatch, getState) => {
    const orgId = getState().organisationsReducer.selectedOrganisation.id;
    const ticket = getState().ticketsReducer.ticket;
    console.log(ticket);
    try {
      await Api.post(`api/createNewFileVersion?organisation=${orgId}`, {
        data: versionData,
      });
      dispatch(getTicketItem(ticket.id, {}, false));
    } catch (error) {
      console.log(error);
    }

    //   dispatch({
    //     type: "SAVE_FILE_COMMENTS",
    //     payload: data,
    //   });
  };

export const getFileVersions = (fileId) => async (dispatch, getState) => {
  if (!fileId) return;
  dispatch({ type: "FETCH_FILE_VERSIONS_LOADING", payload: true });
  const query = QueryString.stringify({
    filters: {
      fichier: {
        id: { $eq: fileId },
      },
    },
    populate: {
      comments: {
        fields: ["*"],
        populate: {
          user: {
            fields: ["id", "firstName", "lastName", "genre", "connected"],
            populate: {
              avatar: {
                fields: ["id"],
                populate: {
                  image: { fields: ["url"] },
                },
              },
            },
          },
        },
      },
      user: {
        fields: ["id", "firstName", "lastName", "genre", "connected"],
        populate: {
          avatar: {
            fields: ["id"],
            populate: {
              image: { fields: ["url"] },
            },
          },
        },
      },
    },
    Pagination: { pageSize: 100 },
  });
  try {
    const response = await Api.get(`api/fichier-versions?${query}`);

    dispatch({ type: "FETCH_FILE_VERSIONS", payload: response.data.data });

    return response.data.data;
  } catch (error) {
    dispatch(handleError());
  }
};

export const handleSelectVersion = (version) => async (dispatch, getState) => {
  dispatch({
    type: "SELECT_VERSION",
    payload: version,
  });
};
