import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import LightDarkText from "./LightDarkText";

import useWindowWidth from "../../Hooks/useWindowWidth";

import { handleFirstLastName } from "../../Utils/utils";

import { closeModal, openModal } from "../../REDUX/actions/modal";
import { CloseIcon } from "./LightDarkSvg";
import UserSmallCard from "../Clients/UserSmallCard";
import UserThumbnail from "./UserThumbnail";
import SocietyAvatar from "../Societies/NewSociety/SocietyAvatar";

const SocietyGroupThumbnail = ({ items, modalTitle, showNumber = 3 }) => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);
  // const [search, setSearch] = useState("");
  return (
    <div
      onClick={(e) => (
        e.stopPropagation(),
        dispatch(
          openModal({
            type: "",
            content: (
              <div
                className={`${
                  mode === "light" ? "bg-white" : "bg-dark-mode"
                } p-4 radius05 d-flex flex-column justify-content-between notScrollable`}
                style={{
                  width: `${
                    screenWidth > 600 ? "600px" : "calc(100vw - 50px)"
                  }`,
                }}
              >
                <div className="d-flex justify-content-between mb-3">
                  <LightDarkText
                    className="custom-title-font-size bold w-100"
                    color={mode === "light" ? "text-dark-blue" : "text-white"}
                  >
                    {modalTitle}
                  </LightDarkText>

                  <div
                    className="d-flex justify-content-end"
                    onClick={() => dispatch(closeModal())}
                  >
                    <CloseIcon />
                  </div>
                </div>
                <div
                  className="hideScrollbar d-flex flex-column gap-4"
                  style={{ maxHeight: "500px", overflowY: "scroll" }}
                >
                  {items?.map((el) => (
                    <div key={el.id} className="alignH flex-1 gap-3">
                      <SocietyAvatar
                        imageUrl={
                          el?.attributes?.avatar?.data?.attributes?.image?.data
                            ?.attributes?.url
                        }
                      />
                      <div className="flex-1 d-flex flex-column">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip style={{ zIndex: 1050 }}>
                              <LightDarkText className="custom-ticket-font-size py-1 text-white">
                                {el.attributes.name}
                              </LightDarkText>
                            </Tooltip>
                          }
                        >
                          <div>
                            <LightDarkText
                              className="custom-font-size bolder text-start"
                              style={{
                                textWrap: "wrap",
                              }}
                            >
                              {el.attributes.name}
                            </LightDarkText>
                          </div>
                        </OverlayTrigger>

                        <a
                          href={el.attributes.siteWeb}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LightDarkText
                            className="f12 bold text-start pointer text-decoration-underline text-blue"
                            color={"blue"}
                            style={{
                              textWrap: "wrap",
                            }}
                          >
                            {el.attributes.siteWeb}
                          </LightDarkText>{" "}
                        </a>

                        <a href={`mailto:${el.attributes.email}`}>
                          {" "}
                          <LightDarkText
                            className="f12 bold text-start pointer"
                            style={{
                              textWrap: "wrap",
                            }}
                          >
                            {el.attributes.email}
                          </LightDarkText>
                        </a>
                        <a href={`tel:${el.attributes.phoneNumber}`}>
                          <LightDarkText
                            className="f12 bold text-start pointer text-blue"
                            style={{
                              textWrap: "wrap",
                            }}
                          >
                            {el.attributes.phoneNumber}
                          </LightDarkText>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ),
          })
        )
      )}
    >
      {items?.length ? (
        <div className={`d-flex align-items-center`}>
          <div className="d-flex" style={{ marginLeft: "15px" }}>
            {items
              ?.slice(0, showNumber)
              ?.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i)
              .map((society, index) => (
                <OverlayTrigger
                  key={index}
                  placement="bottom"
                  overlay={
                    <Tooltip style={{ zIndex: 10000 }}>
                      <LightDarkText className="custom-ticket-font-size py-1 text-white">
                        {society.attributes.name}
                      </LightDarkText>
                    </Tooltip>
                  }
                >
                  <div
                    className="avatars__item pointer"
                    style={{
                      zIndex: items?.slice(0, showNumber).length - index,
                    }}
                  >
                    <UserThumbnail
                      key={index}
                      img={`user${society.attributes.genre || "3"}`}
                      size={32}
                      apiImg={
                        society?.attributes?.avatar?.data?.attributes?.image
                          ?.data?.attributes?.url
                      }
                    />
                  </div>
                </OverlayTrigger>
              ))}
          </div>
          {items?.length > showNumber && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip className="mt-1" style={{ zIndex: 10000 }}>
                  <LightDarkText className="custom-ticket-font-size py-1 text-white">
                    Consulter tous les membres
                  </LightDarkText>
                </Tooltip>
              }
            >
              <div>
                <LightDarkText
                  className={`ms-2 text-start bold pointer clickable-text t-02 ${
                    screenWidth > 1200 ? "f12" : "f12"
                  }`}
                  color={mode === "light" ? "text-blue-grey" : "text-white"}
                >
                  {items?.length > showNumber
                    ? `+${items?.length - showNumber} Autres`
                    : `${items?.length} Autres`}
                </LightDarkText>
              </div>
            </OverlayTrigger>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default SocietyGroupThumbnail;
