import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProspectsStats } from "../../REDUX/actions/stats";
import Loading from "../Common/Loading";
import {
  transformDataToMultiLines,
  transformticketsToStatsPie,
} from "../Charts/chartsUtils";
import MultipleLineChart from "../Charts/MultipleLineChart";
import PieChart from "../Charts/PieChart";
import {
  CommandesStatSvg,
  DevisStatSvg,
  ProspectsStatSvg,
} from "../Common/StatisticsSvgs";
import StatBox from "./StatBox";

const StatisticsProspects = () => {
  const dispatch = useDispatch();

  const { prospectStats, prospectStatsLoading } = useSelector(
    (state) => state.statsReducer
  );

  const generalProspectsStats = [
    {
      icon: <ProspectsStatSvg fill2="#3365ff" />,
      count: prospectStats.totalProspects,
      title: "Prospects totals",
    },

    {
      icon: <ProspectsStatSvg fill2="#00c486" />,
      count: prospectStats.activeProspects,
      title: "Prospects actifs",
    },

    {
      icon: <ProspectsStatSvg fill2="#ff5d9e" />,
      count: prospectStats.archivedProspects,
      title: "Prospects archivés",
    },
  ];
  const generalDevisStats = [
    {
      icon: <DevisStatSvg fill1="#3365ff" />,
      count: prospectStats.totalDevis,
      title: "Devis totals",
    },

    {
      icon: <DevisStatSvg fill1="#00c486" />,
      count: prospectStats.activeDevis,
      title: "Devis actifs",
    },

    {
      icon: <DevisStatSvg fill1="#ff5d9e" />,
      count: prospectStats.archivedDevis,
      title: "Devis archivés",
    },
  ];
  const generalCommandesStats = [
    {
      icon: <CommandesStatSvg fill1="#3365ff" />,
      count: prospectStats.totalCommandes,
      title: "Commandes totales",
    },

    {
      icon: <CommandesStatSvg fill1="#00c486" />,
      count: prospectStats.activeCommandes,
      title: "Commandes actives",
    },

    {
      icon: <CommandesStatSvg fill1="#ff5d9e" />,
      count: prospectStats.archivedCommandes,
      title: "Commandes archivées",
    },
  ];
  useEffect(() => {
    dispatch(getProspectsStats());
  }, []);

  const prospectsPerOrigin = transformticketsToStatsPie(
    prospectStats?.prospectByOrigin
  );
  const devisPerOrigin = transformticketsToStatsPie(
    prospectStats?.devisByOrigin
  );
  const commandesPerOrigin = transformticketsToStatsPie(
    prospectStats?.commandesByOrigin
  );
  const commandeStatus1 = transformDataToMultiLines(
    prospectStats?.commandeStatus1,
    "#FF721B",
    "Archivées"
  );
  const commandeStatus2 = transformDataToMultiLines(
    prospectStats?.commandeStatus2,
    "#3365FF",
    "Validées"
  );
  const commandeStatus3 = transformDataToMultiLines(
    prospectStats?.commandeStatus3,
    "#FFBC20",
    "En attente"
  );

  const devisStatus1 = transformDataToMultiLines(
    prospectStats?.devisStatus1,
    "#FF721B",
    "Archivées"
  );
  const devisStatus2 = transformDataToMultiLines(
    prospectStats?.devisStatus2,
    "#3365FF",
    "attente d'affectation revendeur"
  );
  const devisStatus3 = transformDataToMultiLines(
    prospectStats?.devisStatus3,
    "#0CCE91",
    "attente validation client"
  );

  const prospectStatus1 = transformDataToMultiLines(
    prospectStats?.prospectStatus1,
    "#3365FF",
    "attente validation admin"
  );
  const prospectStatus2 = transformDataToMultiLines(
    prospectStats?.prospectStatus2,
    "#0CCE91",
    "attente validation commercial"
  );
  const prospectStatus3 = transformDataToMultiLines(
    prospectStats?.prospectStatus3,
    "#FFBC20",
    "attente validation revendeur"
  );
  const prospectStatus4 = transformDataToMultiLines(
    prospectStats?.prospectStatus4,
    "#FF721B",
    "Archivées"
  );
  return prospectStatsLoading ? (
    <Loading showLoader />
  ) : (
    <div className="w-100 scrollable d-flex flex-column gap-4">
      <div className="grid3">
        {generalProspectsStats.map((el, i) => (
          <StatBox
            key={i}
            unit={el.unit}
            Icon={el.icon}
            count={el.count}
            title={el.title}
          />
        ))}
      </div>

      <div className={`statisticsLine alignH gap-3`}>
        <div className="w70 h100">
          <MultipleLineChart
            title="Prospets les 12 derniers mois"
            dataSets={[
              prospectStatus1,
              prospectStatus2,
              prospectStatus3,
              prospectStatus4,
            ]}
            customToolTip={{
              callbacks: {
                label: function (context) {
                  const value = context.raw;
                  return ` ${value} prospects ${
                    context.dataset.label?.toLowerCase() || ""
                  }`;
                },
              },
            }}
          />
        </div>
        <div className="w30">
          <PieChart
            title={"Répartition des prospects par origine"}
            infos={prospectsPerOrigin}
            tootltipLabel="prospects"
            cutout="0%"
            showInside
          />
        </div>
      </div>
      <div className="grid3">
        {generalDevisStats.map((el, i) => (
          <StatBox
            key={i}
            unit={el.unit}
            Icon={el.icon}
            count={el.count}
            title={el.title}
          />
        ))}
      </div>

      <div className={`statisticsLine alignH gap-3`}>
        <div className="w30">
          <PieChart
            title={"Répartition des devis par origine"}
            infos={devisPerOrigin}
            tootltipLabel="devis"
            cutout="0%"
            showInside
          />
        </div>
        <div className="w70 h100">
          <MultipleLineChart
            title="Devis les 12 derniers mois"
            dataSets={[devisStatus2, devisStatus3, devisStatus1]}
            customToolTip={{
              callbacks: {
                label: function (context) {
                  const value = context.raw;
                  return ` ${value} devis ${
                    context.dataset.label?.toLowerCase() || ""
                  }`;
                },
              },
            }}
          />
        </div>
      </div>
      <div className="grid3">
        {generalCommandesStats.map((el, i) => (
          <StatBox
            key={i}
            unit={el.unit}
            Icon={el.icon}
            count={el.count}
            title={el.title}
          />
        ))}
      </div>

      <div className={`statisticsLine alignH gap-3`}>
        <div className="w70 h100">
          <MultipleLineChart
            title="Commandes les 12 derniers mois"
            dataSets={[commandeStatus2, commandeStatus3, commandeStatus1]}
            customToolTip={{
              callbacks: {
                label: function (context) {
                  const value = context.raw;
                  return ` ${value} commandes ${
                    context.dataset.label?.toLowerCase() || ""
                  }`;
                },
              },
            }}
          />
        </div>
        <div className="w30">
          <PieChart
            title={"Répartition des commandes par origine"}
            infos={commandesPerOrigin}
            tootltipLabel="commandes"
            cutout="0%"
            showInside
          />
        </div>
      </div>
    </div>
  );
};

export default StatisticsProspects;
