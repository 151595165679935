import QueryString from "qs";
import Api from "../../../Api/Api";

export const checkExternalFolderShare =
  (folderId, type) => async (dispatch, getState) => {
    try {
      const organisation = getState().organisationsReducer.selectedOrganisation;
      if (!organisation.id) {
        return;
      }

      const query = QueryString.stringify({
        filters: {
          organisation: { id: { $eq: organisation.id } },
          type: { $eq: type },
          item_id: { $eq: folderId },
        },
        populate: {
          ticket: {
            id: { $eq: folderId },
          },
        },
      });
      const response = await Api.get(`api/cloud-ticket-shares?${query}`);

      dispatch({
        type: "FETCH_EXTERNAL_FOLDER_TICKET_SHARES",
        payload: response.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

export const checkExternalServiceShares =
  (type) => async (dispatch, getState) => {
    try {
      const organisation = getState().organisationsReducer.selectedOrganisation;
      const user = getState().userReducer.user;

      if (!organisation.id) {
        return;
      }

      const query = QueryString.stringify({
        fields: ["item_id"],
        filters: {
          organisation: { id: { $eq: organisation.id } },
          type: { $eq: type },
          user: {
            id: { $eq: user.id },
          },
        },
      });
      const response = await Api.get(`api/cloud-ticket-shares?${query}`);

      dispatch({
        type: "FETCH_EXTERNAL_SERVICE_SHARES",
        payload: response.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
