import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../REDUX/actions/modal";
import {
  getCloud,
  shareFile,
  shareFoldersInTickets,
} from "../../REDUX/actions/cloud";
import LightDarkContainer from "../Common/LightDarkContainer";
import LightDarkText from "../Common/LightDarkText";
import ColoredButton from "../Common/Buttons/ColoredButton";
import LighDarkMultiChoice from "../Common/LighDarkMultiChoice";
import { CloseIcon } from "../Common/LightDarkSvg";
import { refetchTicket } from "../../REDUX/actions/tickets";
import { getMessages, refetchMessage } from "../../REDUX/actions/messages";

const CloudFolderSelector = () => {
  const dispatch = useDispatch();

  const { ticket } = useSelector((state) => state.ticketsReducer);
  const [foldersInput, setFoldersInput] = useState({ folders: [] });
  const { dossiers, cloudLoading, refetchCloud } = useSelector(
    (state) => state.cloudReducer
  );

  const handleSubmit = () => {
    dispatch(
      shareFoldersInTickets(
        foldersInput.folders.map((s) => s.value),
        [ticket.id],
        []
      )
    ).then(() => {
      dispatch(refetchTicket(ticket.id));
      dispatch(getMessages(ticket.id, 0, false));
      dispatch(closeModal());
    });
  };

  useEffect(() => {
    if (refetchCloud) {
      dispatch(getCloud());
    }
  }, [refetchCloud]);

  const handleShowSociety = (societies) => {
    if (societies.length === 0) {
      return "Pas de société";
    } else if (societies.length === 1) {
      return societies[0].attributes.name;
    } else {
      return `${societies[0].attributes.name}, ${
        societies[1].attributes.name
      } et ${societies.length - 2} autres`;
    }
  };

  return (
    <LightDarkContainer
      className="p-4 radius05 directionC"
      colorLight="white"
      colorDark="dark-mode"
    >
      <div
        className="d-flex justify-content-end mb-2"
        onClick={() => dispatch(closeModal())}
      >
        <CloseIcon />
      </div>
      <LightDarkText className="text-center custom-title-font-size bold mb-4">
        Partager un dossier
      </LightDarkText>
      <div className="text-start">
        <div onSubmit={(e) => e.preventDefault()}>
          <LighDarkMultiChoice
            label="Choisir un dossier"
            data={dossiers
              // Remove already shared folders
              .filter(
                (el) =>
                  !ticket.attributes.cloud_ticket_shares.data
                    .map((s) => s.attributes.folder?.data?.id)
                    .includes(el.id)
              )
              .map((el) => {
                return {
                  value: el.id,
                  label: `#${el.id} - ${
                    el.attributes.name
                  } - ${handleShowSociety(
                    el.attributes.shared_to_societes.data
                  )}`,
                };
              })}
            totalInputs={foldersInput}
            setValue={setFoldersInput}
            champs="folders"
            multiple={true}
            placeholder="Sélectionner un dossier"
          />
        </div>
      </div>

      <div className="col-6 align-self-center mb-3 mt-5">
        <ColoredButton
          loading={cloudLoading}
          textButton="Enregistrer les modifications"
          disabled={!foldersInput.folders?.length > 0}
          color="light-blue"
          callback={handleSubmit}
        />
      </div>
    </LightDarkContainer>
  );
};

export default CloudFolderSelector;
