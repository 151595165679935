import React from "react";
import { useSelector } from "react-redux";

import { CloudFolder } from "../Common/LightDarkSvg";
import ElementName from "./CloudPartials/ElementName";
import gDriveLogo from "../../Images/apiIcons/gDrive.svg";
import oneDriveLogo from "../../Images/apiIcons/oneDrive.svg";
import FolderCardInMessageIcon from "./FolderCardInMessageIcon";
const FolderCardInMessageExternal = ({ share }) => {
  const { organisationUrl } = useSelector(
    (state) => state.organisationsReducer
  );
  const handleNavigate = () => {
    window.open(
      `${organisationUrl}/${share.attributes.type}/${share.attributes.item_id}`,
      "_blank"
    );
  };

  return (
    <div
      className="folderCard d-flex flex-column justify-content-between p-3 radius05 t-02 border"
      style={{
        backgroundColor: `white`,
      }}
    >
      <div
        className="d-flex align-items-start justify-content-between "
        role="button"
      >
        <FolderCardInMessageIcon
          handleNavigate={handleNavigate}
          share={share}
        />
      </div>

      <div className="d-flex mt-3 mb-2 justify-content-between">
        <div className="text-start pointer  w-100" onClick={handleNavigate}>
          <ElementName
            name={share.attributes.item_name}
            callBack={handleNavigate}
          />
        </div>
      </div>
    </div>
  );
};

export default FolderCardInMessageExternal;
