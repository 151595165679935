import React from "react";
import { useSelector } from "react-redux";

import AttachementUploader from "../../Common/AttachementUploader";
import CustomRange from "../../Common/CustomRange";

import "./InMessageInput.css";

const InMessageInput = ({
  setValue,
  placeholder = "Votre raison",
  addDuration = false,
  isInterventionRevision = false,
  duration,
  setduration,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  return (
    <div className="">
      {addDuration && (
        <CustomRange
          label={"Durée totale"}
          totalInputs={duration}
          setValue={setduration}
          champs="duration"
          editbundle={true}
        />
      )}

      {/* <input
        type="text"
        className={`${
          mode === "light"
            ? "inMessageLightInputInput"
            : "inMessageDarkInputInput"
        } radius05 px-3 mb-4 mt-2 custom-ticket-font-size w-100`}
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
      /> */}

      <AttachementUploader
        label={"Pièces jointes"}
        required={!isInterventionRevision}
      />
    </div>
  );
};

export default InMessageInput;
