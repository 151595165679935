import { CHANGE_FILTER_COMMANDE } from "../ActionsType/commandeTypes";
import { CHANGE_FILTER_DEVIS } from "../ActionsType/devisTypes";
import {
  CHANGE_FILTER_DATE,
  CHANGE_FILTER_PROSPECT,
} from "../ActionsType/prospectTypes";

export const handleSelectedSociety = (ids) => async (dispatch) => {
  dispatch({ type: "CHANGE_FILTER_SOCIETY", payload: ids });
};

export const handleSelectedTicket = (ids) => async (dispatch) => {
  dispatch({ type: "CHANGE_FILTER_TICKET", payload: ids });
};
export const handleSelectedOperators = (ids) => async (dispatch) => {
  dispatch({ type: "CHANGE_FILTER_OPERATORS", payload: ids });
};
export const handleSelectedIntervention = (ids) => async (dispatch) => {
  dispatch({ type: "CHANGE_FILTER_INTERVENTION", payload: ids });
};

export const handleSelectedInterventionsPrestation =
  (ids) => async (dispatch) => {
    dispatch({ type: "CHANGE_FILTER_INTERVENTIONS_PRESTATIONS", payload: ids });
  };

export const handleChecks = (obj) => async (dispatch) => {
  dispatch({ type: "CHANGE_CHECKS", payload: obj });
};

export const toggleUnreadTickets = () => async (dispatch) => {
  dispatch({ type: "TOGGLE_UNREAD_TICKETS" });
};
export const toggleProspectAndTickets = () => async (dispatch) => {
  dispatch({ type: "TOGGLE_PROSPECT_AND_TICKETS" });
};

export const handleSearch = (obj) => async (dispatch) => {
  dispatch({ type: "CHANGE_SEARCH", payload: obj });
};

export const resetTicketFilters =
  (resetAll = true) =>
  (dispatch) => {
    dispatch({ type: "CHANGE_FILTER_SOCIETY", payload: undefined });
    dispatch({ type: "CHANGE_FILTER_TICKET", payload: undefined });
    dispatch({ type: "CHANGE_SEARCH", payload: "" });
    resetAll &&
      dispatch({ type: "CHANGE_CHECKS", payload: { ticketsArchived: false } });
  };

export const resetInterventionFilters = () => async (dispatch) => {
  dispatch({ type: "CHANGE_FILTER_SOCIETY", payload: undefined });
  dispatch({ type: "CHANGE_FILTER_INTERVENTION", payload: undefined });
};

export const resetSocieteFilters = () => async (dispatch) => {
  dispatch({ type: "CHANGE_CHECKS", payload: { archivedSociety: false } });
};

export const resetClientFilters = () => async (dispatch) => {
  dispatch({ type: "CHANGE_FILTER_SOCIETY", payload: undefined });
  dispatch({ type: "CHANGE_CHECKS", payload: { clientArchived: false } });
};

export const resetOperatorFilters = () => async (dispatch) => {
  dispatch({ type: "CHANGE_FILTER_SOCIETY", payload: undefined });
  dispatch({ type: "CHANGE_CHECKS", payload: { operateursArchived: false } });
};

export const handleSelectedProspect = (ids) => async (dispatch) => {
  dispatch({ type: CHANGE_FILTER_PROSPECT, payload: ids });
};

export const handleSelectedDate = (ids) => async (dispatch) => {
  dispatch({ type: CHANGE_FILTER_DATE, payload: ids });
};

export const handleSelectedDevis = (ids) => async (dispatch) => {
  dispatch({ type: CHANGE_FILTER_DEVIS, payload: ids });
};
export const handleSelectedCommande = (ids) => async (dispatch) => {
  dispatch({ type: CHANGE_FILTER_COMMANDE, payload: ids });
};

export const resetProspectFilters = () => async (dispatch) => {
  dispatch({ type: CHANGE_FILTER_PROSPECT, payload: [] });
  dispatch({ type: "CHANGE_CHECKS", payload: { prospectArchived: false } });
};
export const resetDevisFilters = () => async (dispatch) => {
  dispatch({ type: CHANGE_FILTER_DEVIS, payload: [] });
  dispatch({ type: "CHANGE_CHECKS", payload: { devisArchived: false } });
};

export const resetCommandeFilters = () => async (dispatch) => {
  dispatch({ type: CHANGE_FILTER_COMMANDE, payload: [] });
  dispatch({ type: "CHANGE_CHECKS", payload: { commandeArchived: false } });
};
