import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getOneDriveToken } from "../../REDUX/actions/externalServices";
import Loading from "../../Components/Common/Loading";
import { getGoogleDriveToken } from "../../REDUX/actions/externalServices/gdrive";

const ExternalSyncView = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const codeParam = searchParams.get("code");
    const serviceParam = searchParams.get("service");
    const scopeParams = searchParams.get("scope");

    if (serviceParam === "onedrive" && codeParam) {
      dispatch(getOneDriveToken(codeParam, serviceParam));
    }
    if (
      (serviceParam === "gdrive" ||
        scopeParams.includes("https://www.googleapis.com/auth/drive")) &&
      codeParam
    ) {
      dispatch(getGoogleDriveToken(codeParam, serviceParam));
    }
  }, [location.search]);

  return (
    <div>
      <Loading showLoader />
    </div>
  );
};

export default ExternalSyncView;
