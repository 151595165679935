import React from "react";
import "./StatusButton.css";

const StatusButton = ({
  text,
  classname,
  type,
  background,
  ticketButton,
  onClick,
  height,
}) => {
  return (
    <button
      className={` ${
        ticketButton ? "w85" : "w-100"
      } alignC buttonStatus custom-ticket-font-size bold t-02 text-${type} radius05 ${classname}`}
      style={{ backgroundColor: `${background}`, height: `${height}` }}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default StatusButton;
