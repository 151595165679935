import React, { useEffect, useState } from "react";
import file from "../../../Images/apiIcons/file.svg";
import LightDarkText from "../../Common/LightDarkText";
import { useSelector } from "react-redux";
import { Check, ExternalLoaderSvg, Fail } from "../../Common/LightDarkSvg";

const ExternalLoaderItem = ({ element }) => {
  const { copiedItems, errorsItems } = useSelector(
    (state) => state.externalServicesReducer
  );
  const [isSuccess, setIsSuccess] = useState(
    copiedItems.some((file) => file.file.id === element.id)
  );
  const [isFail, setIsFail] = useState(
    errorsItems.some((file) => file.file.id === element.id)
  );

  useEffect(() => {
    setIsSuccess(copiedItems.some((file) => file.file.id === element.id));
    setIsFail(errorsItems.some((file) => file.file.id === element.id));
  }, [copiedItems, errorsItems, element]);

  return (
    <LightDarkText
      className={
        "externalLoadingItem radius08 p-2 f14 d-flex alignH w-100 justify-content-between"
      }
    >
      <img src={file} alt="check" className="me-2" />
      <div className="text-ellipsis flex-1 w90 text-start bold">
        {element.name}{" "}
      </div>
      {!isSuccess && !isFail ? <ExternalLoaderSvg /> : null}
      {isFail ? <Fail /> : null}
      {isSuccess ? <Check /> : null}
    </LightDarkText>
  );
};

export default ExternalLoaderItem;
