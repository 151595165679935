import React from "react";

const MainSociety = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#FF721B" />
      <path
        d="M15.3333 18.6667H8.66663C8.39329 18.6667 8.16663 18.44 8.16663 18.1667C8.16663 17.8934 8.39329 17.6667 8.66663 17.6667H15.3333C15.6066 17.6667 15.8333 17.8934 15.8333 18.1667C15.8333 18.44 15.6066 18.6667 15.3333 18.6667Z"
        fill="white"
      />
      <path
        d="M17.5667 7.67999L14.9 9.58665C14.5467 9.83999 14.04 9.68665 13.8867 9.27999L12.6267 5.91999C12.4134 5.33999 11.5934 5.33999 11.38 5.91999L10.1134 9.27332C9.96002 9.68665 9.46002 9.83999 9.10669 9.57999L6.44002 7.67332C5.90669 7.29999 5.20002 7.82665 5.42002 8.44665L8.19336 16.2133C8.28669 16.48 8.54002 16.6533 8.82002 16.6533H15.1734C15.4534 16.6533 15.7067 16.4733 15.8 16.2133L18.5734 8.44665C18.8 7.82665 18.0934 7.29999 17.5667 7.67999ZM13.6667 13.8333H10.3334C10.06 13.8333 9.83336 13.6067 9.83336 13.3333C9.83336 13.06 10.06 12.8333 10.3334 12.8333H13.6667C13.94 12.8333 14.1667 13.06 14.1667 13.3333C14.1667 13.6067 13.94 13.8333 13.6667 13.8333Z"
        fill="white"
      />
    </svg>
  );
};

export default MainSociety;
