import React from "react";
import { ChevronRight, CloudFolder } from "../Common/LightDarkSvg";
import LightDarkText from "../Common/LightDarkText";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CloudBreadCrumbItem = ({ el, elementRoute, siteId }) => {
  const navigate = useNavigate();
  const { organisationUrl } = useSelector(
    (state) => state.organisationsReducer
    //TODO
  );
  return (
    <div className="alignH gap-1 t-02 clickable-text">
      {" "}
      <div
        className="alignH gap-1 t-02 clickable-text"
        onClick={() =>
          navigate(
            `${organisationUrl}/${elementRoute}/${el.id}?${
              siteId ? "site=" + siteId : ""
            }`
          )
        }
      >
        <CloudFolder size={24} />
        <LightDarkText className="text-start f14 pointer">
          {el.name}
        </LightDarkText>
      </div>
      <ChevronRight darkColor="#354074" />
    </div>
  );
};

export default CloudBreadCrumbItem;
