import React, { useState, useRef, useEffect } from "react";
import { Document, Page } from "react-pdf";
import Modal from "react-modal";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./PdfCommentModal.css";
import CommentLayer from "./CommentLayer";
import AddCommentIcon from "../../Common/PdfIcons/addCommentIcon";
import CloseModalButton from "../../Common/PdfIcons/closeModalButton";
import ExitCommentMode from "../../Common/PdfIcons/exitCommentMode";
import ShowCommentsIcon from "../../Common/PdfIcons/showCommentsIcon";
import HideCommentsIcon from "../../Common/PdfIcons/hideCommentIcon";
import { useDispatch, useSelector } from "react-redux";
import { closePdfCommentModal } from "../../../REDUX/actions/pdfComments";
import PdfVersionList from "./PdfVersionList";
import { InternalCheck } from "../../Common/LightDarkSvg";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const PdfCommentModal = ({
  handleCommentCallback = () => {},
  handleDeleteCommentCallback = () => {},
  handleOnCloseModalCallback = () => {},
  handleOnCommentUpdateCallback = () => {},
}) => {
  const dispatch = useDispatch();
  const { isOpen, isFullScreen, fileUrl, fileId, selectedComments } =
    useSelector((state) => state.pdfCommentsReducer);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isCommenting, setIsCommenting] = useState(false);
  const [showComments, setShowComments] = useState(true);
  const [isImage, setIsImage] = useState(false);
  const [comments, setComments] = useState([]);

  const onValidateModal = () => {
    handleOnCloseModalCallback(comments);
    dispatch(closePdfCommentModal());
  };
  const onCloseModal = () => {
    dispatch(closePdfCommentModal());
  };

  useEffect(() => {
    setComments(
      selectedComments.flatMap((el) => {
        return { id: el.id, ...el.attributes };
      })
    );
  }, [selectedComments]);

  useEffect(() => {
    // Check if the file is an image by looking at the file extension or MIME type
    if (fileUrl) {
      const isImageFile =
        /\.(jpg|jpeg|png|gif|webp)$/i.test(fileUrl) ||
        fileUrl?.startsWith("image/");
      setIsImage(isImageFile);
    }
  }, [fileUrl]);

  // Update local comments when handleCommentCallback is called
  const handleLocalCommentCallback = (newComment, url) => {
    if (fileUrl === url) {
      setComments((prev) => [...prev, newComment]);
    }
    handleCommentCallback(newComment, url);
  };

  // Update local comments when handleDeleteCommentCallback is called
  const handleLocalDeleteCommentCallback = (comment, url) => {
    if (fileUrl === url) {
      setComments((prev) =>
        prev.filter((c) => c.documentId !== comment.documentId)
      );
    }
    handleDeleteCommentCallback(comment, url);
  };

  // Update local comments when handleOnCommentUpdateCallback is called
  const handleLocalCommentUpdateCallback = (updatedComment, url) => {
    if (fileUrl === url) {
      setComments((prev) =>
        prev.map((c) =>
          c.documentId === updatedComment.documentId
            ? { ...c, ...updatedComment }
            : c
        )
      );
    }
    handleOnCommentUpdateCallback(updatedComment, url);
  };

  const toggleCommenting = () => {
    setIsCommenting((prev) => !prev);
  };

  const renderContent = () => {
    if (isImage) {
      return (
        <div
          className="image-container"
          style={{ maxWidth: "800px", margin: "0 auto" }}
        >
          <img
            src={fileUrl}
            alt="Uploaded content"
            style={{
              width: "100%",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </div>
      );
    }

    return (
      <Document
        file={fileUrl}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      >
        <Page pageNumber={pageNumber} width={800} />
      </Document>
    );
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          dispatch(closePdfCommentModal(false));
        }}
        shouldCloseOnEsc={!isCommenting}
        className={`${isFullScreen ? "pdf-modal-fullscreen" : "pdf-modal"}`}
        overlayClassName="pdf-overlay"
        ariaHideApp={false}
      >
        <div className="modal-header gap-3">
          {/* <button
            className="hide-comments-button"
            title={showComments ? "Hide Comments" : "Show Comments"}
            onClick={() => setShowComments(!showComments)}
          >
            {showComments ? <HideCommentsIcon /> : <ShowCommentsIcon />}
          </button> */}
          <button
            className="comment-button"
            title={isCommenting ? "Exit Commenting Mode" : "Add Comment"}
            onClick={toggleCommenting}
          >
            {isCommenting ? <ExitCommentMode /> : <AddCommentIcon />}
          </button>
          <button
            className="close-button"
            title="Sauvgarder"
            onClick={() => {
              onValidateModal();
            }}
          >
            <InternalCheck width="18" height="15" />
          </button>
          <button
            className="close-button"
            title="Close"
            onClick={() => {
              onCloseModal();
            }}
          >
            <CloseModalButton />
          </button>
        </div>

        <div className="page-container d-flex justify-content-between align-items-stretch">
          {/* Add VersionsCard to the left side */}
          <PdfVersionList fileId={fileId} fileUrl={fileUrl} />
          <div
            className={`pdf-div ${isCommenting ? "commenting-mode" : ""}`}
            style={{ position: "relative" }}
          >
            {renderContent()}
            {showComments && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              >
                <CommentLayer
                  fileId={fileId}
                  pageNumber={pageNumber}
                  comments={comments}
                  handleCommentCallback={handleLocalCommentCallback}
                  handleDeleteCommentCallback={handleLocalDeleteCommentCallback}
                  isCommenting={isCommenting}
                  setIsCommenting={setIsCommenting}
                  handleOnCommentUpdateCallback={
                    handleLocalCommentUpdateCallback
                  }
                  fileUrl={fileUrl}
                />
              </div>
            )}
          </div>
        </div>

        {!isImage && (
          <div className="page-controls">
            <button
              onClick={() => setPageNumber((p) => Math.max(1, p - 1))}
              disabled={pageNumber === 1}
            >
              Précédent
            </button>
            <span>
              Page {pageNumber} of {numPages || "--"}
            </span>
            <button
              onClick={() => setPageNumber((p) => Math.min(numPages, p + 1))}
              disabled={pageNumber === numPages}
            >
              Suivant
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default PdfCommentModal;
