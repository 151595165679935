import { useSelector } from "react-redux";

import LightDarkText from "../../LightDarkText";

import { creationDate } from "../../../Tickets/ticketUtils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ElementDate = ({ element, notRead = false }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip>
          <LightDarkText className="custom-ticket-font-size  py-1 text-white text-ellipsis">
            {creationDate(element)}
          </LightDarkText>
        </Tooltip>
      }
    >
      <div className="text-ellipsis me-2">
        <LightDarkText
          className={`custom-ticket-font-size pe-2 bold text-start text-ellipsis ${
            notRead && "xbold"
          }`}
          color={mode === "light" ? "text-blue-grey" : "text-light-text"}
        >
          {creationDate(element)}
        </LightDarkText>
      </div>
    </OverlayTrigger>
    // <div className="text-ellipsis">
    //   <LightDarkText
    //     className={`custom-ticket-font-size pe-2 bold text-start text-ellipsis ${
    //       notRead && "xbold"
    //     }`}
    //     color={mode === "light" ? "text-blue-grey" : "text-light-text"}
    //   >
    //     {creationDate(element)}
    //   </LightDarkText>
    // </div>
  );
};

export default ElementDate;
