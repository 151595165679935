const initialState = {
  stats: undefined,
  loading: false,
  error: undefined,
  operatorStats: undefined,
  historyStats: null,
  historyStatsLoading: null,
  interviewStatsLoading: false,
  interviewStats: [],
  prospectStatsLoading: false,
  prospectStats: [],
};
const statsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "STATS_LOADING":
      return { ...state, loading: true };
    case "RESET_ORGANISATION":
      return initialState;
    case "STATS_SUCCESS":
      return { ...state, loading: false, stats: payload };
    case "STATS_FAIL":
      return { ...state, loading: false, error: payload };
    case "OPERATOR_STATS_SUCCESS":
      return { ...state, loading: false, operatorStats: payload };
    case "HISTORY_STATS_LOADING":
      return { ...state, historyStatsLoading: true };
    case "HISTORY_STATS_SUCCESS":
      return { ...state, historyStatsLoading: false, historyStats: payload };
    case "INTERVIEW_STATS_LOADING":
      return { ...state, interviewStatsLoading: true };
    case "INTERVIEW_STATS_SUCCESS":
      return {
        ...state,
        interviewStatsLoading: false,
        interviewStats: payload,
      };
    case "PROSPECT_STATS_LOADING":
      return { ...state, prospectStatsLoading: true };
    case "PROSPECT_STATS_SUCCESS":
      return {
        ...state,
        prospectStatsLoading: false,
        prospectStats: payload,
      };
    default:
      return state;
  }
};

export default statsReducer;
