import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ChevronRight, CloudFolder } from "../Common/LightDarkSvg";
import LightDarkText from "../Common/LightDarkText";
import CloudBreadCrumbItem from "./CloudBreadCrumbItem";

const CloudBreadCrumb = ({
  path,
  name,
  elementRoute,
  siteId = null,
  setActiveTab,
  activeTab,
  rootName = "Mes dossiers",
  rootIcon,
}) => {
  const navigate = useNavigate();
  const [breadCrumbData, setbreadCrumbData] = useState([]);

  useEffect(() => {
    const handlebreadCrumb = () => {
      const pathParts = path.split("\\");
      let result = [];
      for (let i = 1; i < pathParts.length; i += 2) {
        const id = pathParts[i];
        const name = pathParts[i + 1];
        result.push({ id, name });
      }
      setbreadCrumbData(result);
    };
    if (path) {
      handlebreadCrumb();
    }
  }, [path]);

  const { documentsRoute } = useSelector((state) => state.organisationsReducer);

  return (
    <div className="alignH gap-2 mt-3 flex-wrap">
      <LightDarkText
        className="text-start f14 bold pointer t-02 clickable-text"
        onclickCallback={() => {
          navigate(documentsRoute), setActiveTab(activeTab);
        }}
      >
        {rootIcon ? <img src={rootIcon} /> : rootName}
      </LightDarkText>
      <ChevronRight darkColor="#354074" />
      {breadCrumbData.map((el) => (
        <CloudBreadCrumbItem
          key={el.id}
          el={el}
          elementRoute={elementRoute}
          siteId={siteId}
          navigate={navigate}
        />
      ))}
      <div className="alignH gap-1 t-02">
        <CloudFolder size={24} />
        <LightDarkText className="alignH xbold f16">{name}</LightDarkText>
      </div>
    </div>
  );
};

export default CloudBreadCrumb;
