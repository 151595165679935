import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import LightDarkInput from "../../Common/LightDarkInput";
import LightDarkTextEditor from "../../Common/LightDarkTextEditor";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import FormRequiredMessage from "../../Common/Forms/FormRequiredMessage";
import ExistingAndSelectedFiles from "../../Common/Files/ExistingAndSelectedFiles";

import { closeModal } from "../../../REDUX/actions/modal";
import {
  createFaq,
  createFaqCategory,
  updateFaq,
} from "../../../REDUX/actions/faq";
import AttachementUploader from "../../Common/AttachementUploader";
import LighDarkMultiChoice from "../../Common/LighDarkMultiChoice";
import FormHeader from "../../Common/Forms/FormHeader";
import { clearTempUpload } from "../../../REDUX/actions/files";
import MessageSelectedFiles from "../../Tickets/TicketMessages/TicketChatContainer/MessageInputContainer/MessageSelectedFiles";

const FaqForm = ({ categories, faq }) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);

  const [existingFiles, setExistingFiles] = useState([]);

  const [faqInputs, setFaqInputs] = useState(
    faq
      ? {
          question: faq.attributes.title,
          answer: faq.attributes.content,
          category: [
            {
              value: faq.attributes.faq_category?.data.id,
              label: faq.attributes.faq_category?.data?.attributes?.name,
            },
          ],
        }
      : {
          question: "",
          answer: "",
          category: [],
        }
  );

  useEffect(() => {
    if (faq) {
      if (faq.attributes.fichiers && faq.attributes.fichiers.data) {
        setExistingFiles(faq.attributes.fichiers.data);
      }
    }
  }, [faq]);

  const handleSubmitFaq = async () => {
    const faqData = {
      title: faqInputs.question,
      content: faqInputs.answer,
      faq_category: faqInputs.category[0].value,
    };

    const existingFileIds = existingFiles.map((file) => file.id);

    try {
      if (faq) {
        dispatch(updateFaq(faq.id, faqData, existingFileIds));
      } else {
        dispatch(createFaq(faqData));
      }

      dispatch(closeModal());
      dispatch(clearTempUpload());
    } catch (error) {
      console.error("Error submitting FAQ:", error);
    }
  };
  const isFormValid =
    faqInputs.question && faqInputs.answer && faqInputs.category.length > 0;

  return (
    <div
      className={`${mode === "light" ? "bg-white" : "bg-dark-mode"} 
      px-4 py-3 radius05 h100 directionC justify-content-between`}
      style={{
        width: "700px",
        paddingInline: "10px",
      }}
      onClick={(e) => e.stopPropagation()}
      onSubmit={(e) => e.preventDefault()}
    >
      <FormHeader formTitle={faq ? "Modifier l'élément" : "Créer un élément"} />
      <div>
        <div className={`mb-4`}>
          <LighDarkMultiChoice
            label={"Catégorie"}
            data={categories}
            totalInputs={faqInputs}
            setValue={setFaqInputs}
            multiple={false}
            champs="category"
            createNew={true}
            createNewFieldCallback={createFaqCategory}
            required
          />
        </div>
        <div className="mb-4">
          <LightDarkInput
            label={"Question"}
            totalInputs={faqInputs}
            setValue={setFaqInputs}
            champs="question"
            required
          />
        </div>
        <div className="mb-4">
          <LightDarkTextEditor
            label={"Réponse"}
            totalInputs={faqInputs}
            value={faqInputs}
            setValue={setFaqInputs}
            champs="answer"
            required
          />
        </div>
      </div>
      <div className="h70 mb-4">
        <AttachementUploader
          flex1
          label={"Pièces jointes ( Photos / vidéos )"}
        />
      </div>
      {faq ? (
        <ExistingAndSelectedFiles
          existingFiles={existingFiles}
          setExistingFiles={setExistingFiles}
        />
      ) : (
        <MessageSelectedFiles />
      )}

      <div className="d-flex justify-content-between align-items-center mt-4">
        <FormRequiredMessage />
        <div className="d-flex gap-3">
          <ColoredButton
            textButton={"Annuler"}
            color="dark-grey"
            callback={() => (
              dispatch(closeModal()), dispatch(clearTempUpload())
            )}
          />
          <ColoredButton
            textButton={faq ? "Modifier" : "Créer"}
            color="light-blue"
            callback={handleSubmitFaq}
            disabled={!isFormValid}
          />
        </div>
      </div>
    </div>
  );
};

export default FaqForm;
