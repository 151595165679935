import {
  UPDATE_INTERVENTION,
  CREATE_INTERVENTION,
  CREATE_ACTION,
} from "../Graphql/Mutations/interventions";
import { GET_INTERVENTION_ITEM } from "../Graphql/Queries/Intervention";
import client from "./Client";
import { uploadFiles } from "./files";
import { closeSideBarCard } from "./general";
import { closeModal } from "./modal";
import { sendMessage } from "./tickets";
import store from "../store/store";
import { handleError, handleInfo } from "./toastMessage";
import { UPDATE_MESSAGE } from "../Graphql/Mutations/Tickets";
import Api from "../../Api/Api";
const color = "light-blue";

export const createIntervention =
  (newIntervention, attachementFiles, mentions) => async (dispatch) => {
    const attachedFiles = await dispatch(
      uploadFiles(
        { ticket: newIntervention.ticket, cloud: newIntervention.cloud },
        attachementFiles.files
      )
    );
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }

    try {
      const { data } = await client.mutate({
        mutation: CREATE_INTERVENTION,
        variables: {
          title: newIntervention.title,
          description: newIntervention.description,
          duration: newIntervention.duration,
          statut: newIntervention.statut,
          hors_forfait: newIntervention.hors_forfait,
          // responsibles: newIntervention.responsibles,
          dateDebut: newIntervention.dateDebut,
          societe: newIntervention.societe,
          ticket: newIntervention.ticket,
          fichiers: attachedFiles,
          creator: newIntervention.creator,
          organisation: orgId,
        },
      });

      const flatMentions = mentions?.map(
        (mention) => mention?.insert?.mention?.id
      );
      await dispatch(
        sendMessage(
          {
            content: newIntervention.description,
            ticket: newIntervention.ticket,
            sender: newIntervention.creator, //change when me
            links: newIntervention.links,
            type: 1,
            confidentiel: attachementFiles.isConfidentiel,
            relatedIntervention: data.createIntervention.data.id,
            fichiers: attachedFiles,
            is_internal: false,
          },
          attachementFiles,
          attachedFiles,
          true,
          flatMentions
        )
      );

      dispatch(refetchIntervention(data?.createIntervention?.data?.id, true));
      dispatch({ type: "CLEAR_UPLOAD" });
      dispatch(closeModal());

      dispatch(
        handleInfo(
          `Intervention #${data?.createIntervention?.data?.id ?? ""} créée`,
          1,
          color
        )
      );
    } catch (error) {
      dispatch(handleError("Erreur création de l'intervention", 1));
      throw dispatch(closeModal());
    }
  };

export const createAction = (action) => async () => {
  await client.mutate({
    mutation: CREATE_ACTION,
    variables: {
      title: action.title,
      motif: action.motif,
      intervention: action.intervention,
      ticket: action.ticket,
      event: action.event,
      by: action.by,
      statut: action.actionType,
    },
  });
};

export const updateInterventionDuration = (id, duration) => async () => {
  try {
    await Api.put(`/api/interventions/${id}`, {
      data: { duration: duration },
    });
  } catch (error) {}
};

export const actionIntervention =
  (actionType, messageData, actionLogData) => async (dispatch) => {
    dispatch({ type: "INTERVENTION_ACTION_LOADING", payload: true });
    try {
      await client.mutate({
        mutation: UPDATE_INTERVENTION,
        variables: {
          statut: actionType,
          id: messageData.messageData.relatedIntervention,
        },
      });

      await dispatch(createAction({ ...actionLogData, actionType }));
      await dispatch(
        sendMessage(
          messageData.messageData,
          messageData.attachedFiles,
          null,
          false,
          messageData.messageData.mentions
        )
      );

      dispatch(
        refetchIntervention(messageData.messageData.relatedIntervention)
      );

      dispatch(handleInfo(actionLogData.title, 1, color));
      dispatch(closeSideBarCard());
      dispatch({ type: "INTERVENTION_ACTION_LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "INTERVENTION_ACTION_LOADING", payload: false });
      if (error.message === "Forfait epuisé") {
        dispatch(
          handleError(
            "Votre forfait est epuisé. Contactez un administrateur pour l'augmenter.",
            1
          )
        );
      } else {
        dispatch(handleError("Erreur ", 1));
      }

      dispatch(closeModal());
    }
  };

export const updateInterventionDetails =
  (intervention, attachementFiles) => async (dispatch) => {
    const user = store.getState().userReducer.user;
    const attachedFiles = await dispatch(
      uploadFiles(
        { ticket: intervention.ticket, cloud: intervention.cloud },
        attachementFiles.files
      )
    );
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_INTERVENTION,
        variables: {
          id: intervention.id,
          title: intervention.title,
          hors_forfait: intervention.hors_forfait,
          duration: intervention.duration,
          description: intervention.description,
          fichiers:
            intervention.fichiers.data.length && attachedFiles
              ? [
                  ...intervention.fichiers.data.map((el) => el.id),
                  ...attachedFiles,
                ]
              : attachedFiles,
        },
      });
      if (intervention?.relatedMessages?.data[0]) {
        await client.mutate({
          mutation: UPDATE_MESSAGE,
          variables: {
            links: intervention.links,
            id: intervention?.relatedMessages?.data[0]?.id,
            fichiers:
              intervention.fichiers.data.length && attachedFiles
                ? [
                    ...intervention.fichiers.data.map((el) => el.id),
                    ...attachedFiles,
                  ]
                : attachedFiles,
          },
        });
      }
      dispatch(
        createAction({
          title: "Intervention mise à jour",
          intervention: intervention.id,
          ticket: intervention.ticket,
          by: user.id,
          statut: 0,
        })
      );

      dispatch(refetchIntervention(intervention.id));
      dispatch(
        handleInfo(
          `L'intervention #${
            data?.updateIntervention?.data?.id ?? ""
          } mise à jour`,
          1,
          color
        )
      );
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
      throw dispatch(closeModal());
    }
  };

export const deleteIntervention = (id) => async (dispatch) => {
  try {
    const { data } = await Api.post(`/api/handleDeleteIntervention/${id}`);
    dispatch({
      type: "DELETE_INTERVENTION",
      payload: data.id,
    });
    dispatch(handleInfo(`L'intervention #${data.id} supprimée`, 1, color));
  } catch (error) {
    dispatch(handleError("Erreur de la suppression de l'intervention", 1));
  }
};

export const getAllInterventions = (withLoading) => async (dispatch) => {
  const interventions = store.getState().interventionsReducer.interventions;
  if (!interventions.length || withLoading) {
    dispatch({ type: "INTERVENTIONS_LOADING" });
  }

  const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
  const interventionsArchived =
    store.getState().filtersReducer.interventionsArchived;
  if (!orgId) {
    return;
  }
  try {
    const { data } = await Api.get(
      `/api/fetchConditionalInterventions?organisation=${orgId}&archived=${interventionsArchived}`
    );
    dispatch({
      type: "FETCH_ALL_INTERVENTIONS",
      payload: data.interventions.data,
    });
  } catch (error) {
    dispatch(handleError("Erreur réseau", 1));
  }
};

export const getInterventionById = (intervention) => async (dispatch) => {
  const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
  if (!orgId) {
    return;
  }
  try {
    const { data } = await client.query({
      query: GET_INTERVENTION_ITEM,
      variables: {
        id: intervention.id,
        orgId,
      },
    });
    dispatch({
      type: "SHOW_INTERVENTION_SIDEBAR",
      payload: data.intervention.data,
    });
  } catch (error) {
    dispatch(handleError("Erreur réseau", 1));
  }
};

export const refetchIntervention =
  (id, isNew = false) =>
  async (dispatch, getState) => {
    try {
      const orgId = getState().organisationsReducer.selectedOrganisation.id;
      if (!orgId) {
        return;
      }

      const { data } = await Api.get(
        `/api/refetchIntervention/${id}?organisation=${orgId}`
      );

      if (isNew) {
        dispatch({
          type: "FETCH_NEW_INTERVENTION",
          payload: data?.intervention?.data,
        });
      } else {
        dispatch({
          type: "REFETCH_INTERVENTION",
          payload: data?.intervention?.data,
        });
      }
    } catch (error) {}
  };

export const createNewInterventionType = (label) => async (dispatch) => {
  const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
  if (!orgId) {
    return;
  }
  try {
    const { data } = await Api.post(`/api/interventions-types`, {
      data: {
        organisation: orgId,
        name: label,
        order: 1,
      },
    });

    dispatch({
      type: "CREATE_NEW_INTERVENTION_TYPE",
      payload: { label: data.data.attributes.name, value: data.data.id },
    });

    return { label: data.data.attributes.name, value: data.data.id };
  } catch (error) {
    dispatch({ type: "NEW_SELECT_FIELD_LOADING", payload: false });
  }
};
