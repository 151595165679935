import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFileVersions } from "../../../REDUX/actions/pdfComments";

import PdfVersionCard from "./PdfVersionCard";
import LightDarkText from "../../Common/LightDarkText";
const PdfVersionList = ({ fileId }) => {
  const dispatch = useDispatch();
  const { fileVersions } = useSelector((state) => state.pdfCommentsReducer);
  console.log(fileVersions);
  return (
    <div className="w25 notScrollable h100 bgDarkenHover pointer position-sticky top-0 h100 scrollable">
      <LightDarkText className={"bold mb-3"}>
        Historique des annotations
      </LightDarkText>
      <div className="">
        {fileVersions.length === 0 ? (
          <div className="text-center text-muted"></div>
        ) : (
          <div className="d-flex flex-column gap-2 ">
            {fileVersions.map((version) => (
              <PdfVersionCard
                key={version.id}
                version={version}
                fileId={fileId}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PdfVersionList;
