import React, { useEffect, useState } from "react";
import LightDarkContainer from "../../Common/LightDarkContainer";
import LightDarkText from "../../Common/LightDarkText";
import oneDriveLogo from "../../../Images/apiIcons/oneDrive.svg";
import sharePointLogo from "../../../Images/apiIcons/sharePoint.svg";
import {
  CopyAgilix,
  DeleteIcon,
  Download,
  EditIcon,
  EyeIcon,
  InternalCheck,
} from "../../Common/LightDarkSvg";
import { useDispatch, useSelector } from "react-redux";
import TooltipActions from "../../Common/Tooltip/TooltipActions";
import {
  copyFolderInAgilix,
  deleteOneDriveFolder,
  downloadMultipleOneDriveFolders,
  renameOneDriveFolder,
} from "../../../REDUX/actions/externalServices";
import { openModal } from "../../../REDUX/actions/modal";
import RenameModal from "../CloudPartials/RenameModal";
import { openConfirmationModal } from "../../../REDUX/actions/confirmationModal";
import { useNavigate } from "react-router-dom";
import ElementName from "../CloudPartials/ElementName";

const OneDriveFoderCard = ({
  folder,
  selected,
  setSelected,
  siteId = null,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { organisationUrl } = useSelector(
    (state) => state.organisationsReducer
  );
  const [showTooltip, setshowTooltip] = useState(false);
  const [isSelected, setisSelected] = useState(false);
  const handleSelect = () => {
    if (isSelected) {
      setisSelected(!isSelected);
      setSelected(selected.filter((el) => el.id !== folder.id));
    } else {
      setisSelected(!isSelected);

      setSelected([...selected, { id: folder.id, name: folder.name }]);
    }
  };
  useEffect(() => {
    setisSelected(selected.filter((el) => el.id === folder.id).length);
  }, [selected]);
  const tooltipData = [
    {
      id: 1,
      text: "Voir",
      icon: <EyeIcon className="me-2" size={24} />,
      action: () => {
        navigate(
          `${organisationUrl}/onedrivefolder/${folder.id}?site=${siteId || ""}`
        );
        setshowTooltip(false);
      },
      condition: true,
    },
    {
      id: 4,
      text: "Télécharger",
      icon: <Download className="me-2" size={24} />,
      action: () => {
        dispatch(
          downloadMultipleOneDriveFolders(
            [{ id: folder.id, name: folder.name }],
            siteId
          )
        );
        setshowTooltip(false);
      },
      condition: true,
    },
    {
      id: 2,
      text: "Renommer",
      icon: <EditIcon className="me-2" size={24} />,
      action: () => {
        dispatch(
          openModal({
            type: "small",
            content: (
              <RenameModal
                id={folder.id}
                name={folder.name}
                callBack={renameOneDriveFolder}
              />
            ),
          })
        );
        setshowTooltip(false);
      },
      condition: !siteId,
    },
    {
      id: 3,
      text: "Copier dans Agilix",
      icon: <CopyAgilix className="me-2" size={24} />,
      action: () => {
        dispatch(copyFolderInAgilix([{ id: folder.id, name: folder.name }]));
        setshowTooltip(false);
      },
      condition: true,
    },

    {
      id: 5,
      text: "Supprimer de OneDrive",
      icon: <DeleteIcon className="me-2" size={24} />,
      action: () => {
        dispatch(
          openConfirmationModal({
            callBack: () => dispatch(deleteOneDriveFolder(folder.id)),
            icon: "archive",
            message: "Voulez vous supprimer le dossier",
            message2: "Le dossier sera supprimé définitivement de OneDrive.",
            confirmButtonText: "Supprimer le dossier",
          })
        );
        setshowTooltip(false);
      },
      condition: !siteId,
    },
  ];
  return (
    <LightDarkContainer
      colorDark="dark"
      colorLight="white"
      className={`folderCard pointer cloudFolderCardContainer position-relative d-flex flex-column justify-content-between  radius05 t-02 position-relative ${
        isSelected ? "ContainerSelected" : ""
      }`}
    >
      <div className="blueFileFilter radius05"></div>
      <div className={`shadowSelect t-02 `}></div>
      <div
        onClick={handleSelect}
        className={`SelectBoxCloud pointer radius05 t-02 alignC `}
      >
        <InternalCheck />
      </div>

      <div className="position-relative d-flex justify-content-between align-items-start m-3">
        <img
          src={siteId ? sharePointLogo : oneDriveLogo}
          className="pointer"
          alt="oneDrive"
          width={40}
          onClick={() =>
            navigate(
              `${organisationUrl}/onedrivefolder/${folder.id}?site=${
                siteId || ""
              }`
            )
          }
        />
      </div>
      <div className="d-flex justify-content-between m-3">
        <div className="d-flex flex-column align-items-start w90">
          <ElementName
            name={folder.name}
            callBack={() =>
              navigate(
                `${organisationUrl}/onedrivefolder/${folder.id}?site=${
                  siteId || ""
                }`
              )
            }
          />
          <LightDarkText className={"f10"} color={"text-light-text"}>
            {folder.folder.childCount} elements
          </LightDarkText>
        </div>
        <TooltipActions
          setshowTooltip={setshowTooltip}
          showTooltip={showTooltip}
          tooltipData={tooltipData}
          // left={"180px"}
        />
      </div>
    </LightDarkContainer>
  );
};

export default OneDriveFoderCard;
