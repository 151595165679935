import React, { useState } from "react";
import UserThumbnail from "../../Common/UserThumbnail";
import { handleFirstLastName } from "../../../Utils/utils";

import LightDarkText from "../../Common/LightDarkText";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  handleSelectVersion,
  openPdfCommentModal,
} from "../../../REDUX/actions/pdfComments";

const PdfVersionCard = ({ version, fileId, fileUrl }) => {
  const dispatch = useDispatch();
  const { selectedFileVersions } = useSelector(
    (state) => state.pdfCommentsReducer
  );
  const { mode } = useSelector((state) => state.genearlReducer);
  const [commentsNumber] = useState(
    version.attributes?.comments.data.attributes?.count ||
      version.attributes?.comments.data.length
  );

  const isSelected = selectedFileVersions.some(
    (selectedVersion) => selectedVersion.id === version.id
  );

  return (
    <div
      onClick={() =>
        dispatch(handleSelectVersion(version, version.attributes.comments.data))
      }
      key={version.id}
      className={`text-start border-bottom p-2 radius10 opacityHover ${
        isSelected && "lightListCardFocus"
      }`}
    >
      <LightDarkText className="d-flex align-items-center gap-2 f12">
        <UserThumbnail
          userId={version.attributes?.user.data?.id}
          connected={version.attributes?.user.data?.attributes?.connected}
          lastConnected={
            version.attributes?.user.data?.attributes?.lastConnected
          }
          apiImg={
            version.attributes?.user.data?.attributes?.avatar?.data?.attributes
              ?.image?.data?.attributes?.url
          }
          img={`user${
            version.attributes?.user.data?.attributes?.data?.attributes
              ?.genre || "3"
          }`}
          size={40}
        />
        <div className="d-flex flex-column f12 bold">
          <div className="alignH justify-content-between mt-2">
            <LightDarkText className="text-start bold f12 pointer">
              {handleFirstLastName(version.attributes?.user?.data)}
            </LightDarkText>
          </div>
          {moment(version.attributes?.createdAt).format("DD/MM/YYYY HH:mm")}

          <span className="text-light-text f10">
            <span className="bold">
              {commentsNumber} commentaire
              {commentsNumber === 1 ? "" : "s"}
            </span>
          </span>
        </div>
      </LightDarkText>
    </div>
  );
};

export default PdfVersionCard;
