import React, { useEffect, useState } from "react";
import LightDarkText from "../../Common/LightDarkText";
import SelectCloudBox from "../SelectCloudBox";
import { Spinner } from "react-bootstrap";
import CloudFileCard from "../OneDrive/CloudFileCard";

const ExternalFileList = ({
  title,
  loading,
  allFiles,
  type,
  logo,
  search = "",
}) => {
  const [filteredFiles, setFilteredFiles] = useState(allFiles);
  useEffect(() => {
    setFilteredFiles(
      allFiles.filter((el) =>
        el.name?.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, allFiles]);

  const [selected, setSelected] = useState([]);

  const [urlField, setUrlField] = useState("");
  useEffect(() => {
    setUrlField(type === "oneDrive" ? "@microsoft.graph.downloadUrl" : "url");
  }, [type]);

  return (
    <div className="position-relative text-dark-blue h100">
      <LightDarkText className="custom-title-font-size  mb-3 bold text-start alignH gap-2">
        <div
          className="d-flex gap-3 alignC"
          style={{ width: "20px", height: "30px" }}
        >
          {loading ? (
            <Spinner size="sm" />
          ) : (
            <img src={logo} alt={"oneDrive"} />
          )}
        </div>
        {title}
      </LightDarkText>
      <SelectCloudBox
        isFolder={false}
        key={2}
        selected={selected}
        setSelected={setSelected}
        allItems={filteredFiles.map((el) => {
          return {
            name: el.name,
            id: el.id,
            mimeType: el.mimeType,
            url: el[urlField],
          };
        })}
        type={type}
      />
      <div className="d-flex flex-wrap gap-4 ps-1 pt-4">
        {filteredFiles.map((file, index) => (
          <CloudFileCard
            name={file.name}
            id={file.id}
            mimeType={file.mimeType}
            size={file.size}
            creationDate={file.createdAt}
            url={file[urlField]}
            thumbnailUrl={file.url}
            downloadUrl={file.url}
            key={file.id}
            index={index}
            file={file}
            setSelected={setSelected}
            selected={selected}
            type={type}
          />
        ))}
      </div>
    </div>
  );
};

export default ExternalFileList;
