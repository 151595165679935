import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSideBarCard, openSideBarCard } from "../../REDUX/actions/general";
import {
  CheckFillIcon,
  DeleteIcon,
  EditIcon,
  ExportIcon,
  EyeIcon,
  NoteIcon,
} from "../Common/LightDarkSvg";
import ProspectItem from "../Prospect/ProspectItem";
import CommandeSideBar from "./CommandeSideBar";
import NoteForm from "../Prospect/NoteForm";
import { openModal } from "../../REDUX/actions/modal";
import { setCommande } from "../../REDUX/actions/commande";
import { useNavigate } from "react-router-dom";
import { updateStatus } from "../../REDUX/actions/prospect";
import { exportXlsx, handleUpdateStatus } from "../../Utils/utils";
import moment from "moment";
import TooltipActions from "../Common/Tooltip/TooltipActions";

const CommandeColumn = ({
  dataItem,
  selectedItem,
  setSelectedItem,
  filteredTableListData,
  selectedCommande,
  setSelectedCommande,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mode, openSideBar } = useSelector((state) => state.genearlReducer);
  const { commandesRoute } = useSelector((state) => state.organisationsReducer);
  const { isSupervisor } = useSelector((state) => state.roleReducer);

  const { commandes } = useSelector((state) => state.commandeReducer);
  const [showTooltip, setshowTooltip] = useState(false);
  const tooltipData = [
    {
      id: 1,
      text: "Voir",
      icon: <EyeIcon className="me-2" />,
      action: () => {
        setSelectedItem(dataItem?.id);
        dispatch(setCommande(dataItem));
        dispatch(openSideBarCard(<CommandeSideBar commande={dataItem?.id} />));
        setshowTooltip(false);
      },
      condition: true,
    },
    {
      id: 2,
      text: "Note interne",
      icon: <NoteIcon className="me-2" />,
      action: () => {
        dispatch(
          openModal({
            type: "small",
            content: (
              <NoteForm
                id={dataItem.id}
                fullName={`${dataItem?.firstname || ""} ${
                  dataItem?.lastname || ""
                }`}
                type={"commande"}
                oldNote={dataItem?.note}
              />
            ),
          })
        );
        setshowTooltip(false);
        dispatch(closeSideBarCard());
      },
      condition: true,
    },
    {
      id: 3,
      text: "Modifier",
      icon: <EditIcon className="me-2" />,

      action: () => navigate(`${commandesRoute}/${dataItem?.id}`),
      condition: !["KO", "VALID"].includes(dataItem.status),
    },
    {
      id: 4,
      icon: <CheckFillIcon className="me-2" />,
      text: "Confirmer",
      action: () => {
        dispatch(
          updateStatus(dataItem?.id, {
            status: handleUpdateStatus(dataItem?.status)?.newStatus,
          })
        );
        setshowTooltip(false);
      },
      condition: !["KO", "VALID"].includes(dataItem.status) && isSupervisor,
    },
    {
      id: 5,
      text: !dataItem?.archived ? "Archiver" : "Désarchiver",
      icon: <DeleteIcon className="me-2" />,

      action: () =>
        dispatch(
          openModal({
            type: "small",
            content: (
              <NoteForm
                item={dataItem}
                id={dataItem.id}
                fullName={`${dataItem?.firstname || ""} ${
                  dataItem?.lastname || ""
                }`}
                oldNote={dataItem?.note}
                type={"commande"}
                isArchive={true}
              />
            ),
          })
        ),
      condition: true,
    },
    {
      id: 6,
      text: "Exporter",
      icon: <ExportIcon className="me-2" />,

      action: () => {
        const pros = commandes.find((el) => el.id === dataItem.id);
        const time = moment(Date.now()).format("DD/MM/YYYY hh:mm");
        exportXlsx(pros, `Bon-de-commande${time}`);
        setshowTooltip(false);
      },
      condition: isSupervisor,
    },
  ];

  return (
    <div
      className={`d-flex pointer  py-2 pe-3  ${
        mode === "light" ? "bg-white" : "bg-dark-grey-bg"
      }
      ${
        dataItem?.id === selectedItem &&
        (mode === "light" ? "lightListCardFocus" : "darkListCardFocus")
      }
      `}
      style={{
        marginLeft: "1px",
        marginRight: "1px",
        borderTop:
          mode === "light"
            ? "2px solid rgb(248,249,255)"
            : "2px solid rgb(37, 44, 69)",
      }}
    >
      <div className="w5 d-flex align-items-center justify-content-center ">
        <input
          type="checkbox"
          id={dataItem.id}
          checked={selectedCommande.includes(dataItem.id)}
          className=""
          onChange={(e) => {
            const commandeId = +e.target.id;
            const isSelected = selectedCommande.includes(commandeId);

            setSelectedCommande((prevSelected) =>
              isSelected
                ? prevSelected.filter((el) => el !== commandeId)
                : [...prevSelected, commandeId]
            );
          }}
        />
      </div>
      <div className="w-100 d-flex">
        {filteredTableListData.map(({ id, accessor, widthSideBar, width }) => {
          const columnCol = openSideBar ? widthSideBar : width;

          return (
            <div className={`alignH w${columnCol} p-0 `} key={id}>
              <div className=" w-100 ">
                <ProspectItem
                  dataItem={dataItem}
                  accessor={accessor}
                  table={true}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="d-flex  align-items-center justify-content-end w5">
        <TooltipActions
          setshowTooltip={setshowTooltip}
          showTooltip={showTooltip}
          tooltipData={tooltipData}
        />
      </div>
    </div>
  );
};

export default CommandeColumn;
