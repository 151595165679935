import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import {
  CollapseIcon,
  ExpandIcon,
  HandleHistoryIcon,
} from "../../Common/LightDarkSvg";
import SideChatCard from "./TicketCard";
import EmptyList from "../../Common/EmptyList";
import LightDarkText from "../../Common/LightDarkText";
import SideChatTicketPlaceHolder from "../../PlaceHolders/SideChatTicketPlaceHolder";

import useWindowWidth from "../../../Hooks/useWindowWidth";

import { changeExpandedChatList } from "../../../REDUX/actions/tickets";

const TicketsList = ({ resetReplying }) => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);

  const { tickets, filteredTickets, expandedChatList, ticketsLoading, ticket } =
    useSelector((state) => state.ticketsReducer);
  const { showUnreadTickets } = useSelector((state) => state.filtersReducer);

  const [unreadTickets, setUnreadTickets] = useState([]);

  useEffect(() => {
    if (showUnreadTickets) {
      setUnreadTickets(
        filteredTickets.filter((paramTicket) => {
          return (
            paramTicket?.attributes?.notifications?.data?.attributes?.count >
              0 || paramTicket.id === ticket?.id
          );
        })
      );
    } else {
      setUnreadTickets(filteredTickets);
    }
  }, [showUnreadTickets, filteredTickets]);

  return (
    <div
      className={`h100 ${
        expandedChatList ? "col-3" : "col-1"
      } d-flex justify-content-start t-02`}
    >
      <div className="w-100 p-0 d-flex flex-column h100 overflow-hidden">
        <div
          className={`mb-3 d-flex ${
            mode === "light" ? "bg-light-mode" : "bg-dark-mode"
          } ${
            expandedChatList ? "justify-content-end" : "justify-content-center"
          }`}
        >
          <div
            className={`d-flex ${
              !expandedChatList ? "flex-column" : " align-items-center gap-4"
            }`}
          >
            {screenWidth > 992 && (
              <>
                {/* <div
                  className={`d-flex align-items-center gap-2 ${
                    !expandedChatList && " order-1 mt-3 mb-1"
                  }`}
                >
                  <HandleHistoryIcon
                    notRead={showUnreadTickets}
                    unreadTicketToggle
                    clickCallback={() =>
                      setShowUnreadTickets(!showUnreadTickets)
                    }
                  />
                  <LightDarkText
                    className={"bold custom-ticket-font-size"}
                    color={mode === "light" ? "text-blue-grey" : "text-white"}
                  >
                    Non lu
                  </LightDarkText>
                </div> */}
                <OverlayTrigger
                  placement="bottom"
                  trigger={["hover", "click"]}
                  delay={{ hide: 250 }}
                  overlay={
                    <Tooltip style={{ zIndex: 400 }}>
                      <LightDarkText className="custom-ticket-font-size py-1 text-white">
                        {expandedChatList ? "Réduire" : "Étendre"}
                      </LightDarkText>
                    </Tooltip>
                  }
                >
                  <div>
                    {expandedChatList ? (
                      <CollapseIcon
                        size={screenWidth < 1200 ? 22 : 24}
                        darkColor={"white"}
                        callBack={() => dispatch(changeExpandedChatList(false))}
                      />
                    ) : (
                      <ExpandIcon
                        size={screenWidth < 1200 ? 22 : 24}
                        darkColor={"white"}
                        callBack={() => dispatch(changeExpandedChatList(true))}
                      />
                    )}
                  </div>
                </OverlayTrigger>
              </>
            )}
          </div>
        </div>
        <div
          className={`${
            screenWidth < 992 ? " d-flex" : !unreadTickets.length ? "" : ""
          } y-mandatory h-100`}
        >
          {ticketsLoading || !tickets?.length ? (
            <>
              <SideChatTicketPlaceHolder expandedChatList={expandedChatList} />
              <SideChatTicketPlaceHolder expandedChatList={expandedChatList} />
            </>
          ) : !unreadTickets.length ? (
            <EmptyList text={"Vous n'avez pas de tickets"} color="light-blue" />
          ) : (
            unreadTickets.map((ticket, index) => (
              <SideChatCard
                ticket={ticket}
                key={index}
                resetReplying={resetReplying}
              />
            ))
          )}
        </div>
      </div>
      <div
        className="d-flex align-items-center ms-4"
        style={{
          borderLeft: `2px solid ${mode === "light" ? "white" : "#252C45"}`,
        }}
      />
    </div>
  );
};

export default TicketsList;
