import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./custom.scss";
import Routes from "./Routes/Routes";
import { BrowserRouter } from "react-router-dom";
import Layout from "./Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getInitialData } from "./REDUX/actions/sharedData";
import Auth from "./Components/Layout/Auth/Auth";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { changeDisplayMode } from "./REDUX/actions/general";
import Pprod from "./Components/WorkInProgress/Pprod";
import {
  ANDROID_APP_STORE_URL,
  ENVIRONNEMENT,
  IOS_APP_STORE_URL,
} from "./Api/endPoint";
import WindowWatcher from "./Components/Layout/WindowWatcher";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import handleMobileLink from "./Utils/handleMobileLink";
import MobileView from "./Components/WorkInProgress/MobileView";

function App() {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { user } = useSelector((state) => state.userReducer);

  useEffect(() => {
    const checkDevice = async () => {
      if (isMobile) {
        const mobileLink = await handleMobileLink(window);
        console.log(mobileLink);

        if (isAndroid) {
          window.location.href = mobileLink;

          setTimeout(() => {
            window.location.href = ANDROID_APP_STORE_URL;
          }, 2000);
        } else if (isIOS) {
          window.location.href = mobileLink;

          setTimeout(() => {
            window.location.href = IOS_APP_STORE_URL;
          }, 2000);
        }
      }
    };
    checkDevice();
  }, [isMobile]);

  useEffect(() => {
    const mode = localStorage.getItem("mode");
    dispatch(changeDisplayMode(mode));
  }, []);
  useEffect(() => {
    if (user) {
      dispatch(getInitialData());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return isMobile ? (
    <MobileView />
  ) : (
    <div className={`App ${mode}-App zoom`}>
      {/* <WorkInProgress /> */}
      <BrowserRouter>
        <Auth>
          {ENVIRONNEMENT === "production" ? null : (
            <Pprod espace={ENVIRONNEMENT} />
          )}{" "}
          <Layout>
            <DndProvider backend={HTML5Backend}>
              <WindowWatcher />
              <Routes />
            </DndProvider>
          </Layout>
        </Auth>
      </BrowserRouter>
    </div>
  );
}

export default App;
