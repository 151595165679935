import moment from "moment";
import Api from "../../../Api/Api";
import { handleError, handleInfo } from "../toastMessage";
import { getCloud } from "../cloud";
import { saveAs } from "file-saver";

export const loginToGoogleDrive = () => async () => {
  try {
    const response = await Api.get(`api/external-service/loginToGoogleDrive`);
    window.location.href = response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getGoogleDriveToken = (code) => async (dispatch, getState) => {
  const { documentsRoute } = getState().organisationsReducer;
  try {
    const organisation = getState().organisationsReducer.selectedOrganisation;

    if (!organisation.id) {
      return;
    }
    await Api.get(
      `api/external-service/getGoogleDriveToken?authorizationCode=${code}&service=gdrive&organisation=${organisation.id}`
    );
    window.location.href = documentsRoute;
  } catch (error) {
    window.location.href = documentsRoute;

    dispatch(
      handleError(
        "Problème lors de la synchronisation OneDrive, veuillez réessayer plus tard.",
        "SHOW_ERROR"
      )
    );
  }
};

export const fetchGoogleDrive =
  (withLoading = true) =>
  async (dispatch, getState) => {
    try {
      if (withLoading) {
        dispatch({ type: "GOOGLE_DRIVE_LOADING" });
      }

      const organisation = getState().organisationsReducer.selectedOrganisation;

      if (!organisation.id) {
        return;
      }
      const { data } = await Api.get(
        `api/external-service/fetchGoogleDriveFiles?organisation=${organisation.id}`
      );
      dispatch({ type: "FETCH_GOOGLE_DRIVE", payload: data.googleDriveData });
    } catch (error) {
      // dispatch({ type: "SYNCH_ERROR", payload: "googleDriveSynched" });

      if (error.response.data.error.message === "InvalidAuthenticationToken") {
        // dispatch(handledesynchronize(concernedService.id, "oneDriveSynched"));
        dispatch(
          handleError(
            "Service oneDrive déconnecté. Veuillez synchroniser de nouveau"
          )
        );
      } else {
        dispatch(handleError());
      }
    }
  };

export const getGoogleDriveFolder =
  (folderId, withLoading = true) =>
  async (dispatch, getState) => {
    const { documentsRoute } = getState().organisationsReducer;
    try {
      if (withLoading) {
        dispatch({ type: "GOOGLE_DRIVE_LOADING" });
      }

      const organisation = getState().organisationsReducer.selectedOrganisation;

      if (!organisation.id) {
        return;
      }
      const { data } = await Api.get(
        `api/external-service/getGoogleDriveFolder?organisation=${organisation.id}&folderGoogleDriveId=${folderId}`
      );
      dispatch({
        type: "FETCH_GOOGLE_DRIVE_FOLDER",
        payload: data,
      });
    } catch (error) {
      // window.location.href = documentsRoute;
      dispatch(handleError());
      // window.location.href = false;
    }
  };

export const downloadMultipleGoogleDriveFolders =
  (foldersArray) => async (dispatch, getState) => {
    try {
      dispatch({
        type: "SHOW_EXTERNAL_LOADER",
        payload: {
          filesArray: foldersArray,
          title: "Téléchargement de vos éléments...",
        },
      });
      const organisation = getState().organisationsReducer.selectedOrganisation;
      if (!organisation.id) {
        return;
      }
      const response = await Api.post(
        `api/external-service/downloadMultipleGoogleDriveFolders?organisation=${organisation.id}`,
        {
          data: {
            foldersArray,
          },
        },
        { responseType: "arraybuffer" }
      );
      const blob = new Blob([response.data], { type: "application/zip" });

      if (foldersArray.length === 1) {
        saveAs(blob, `Agilix-${foldersArray[0].name}.zip`);
      } else {
        saveAs(blob, `Agilix-${moment().format("DD-MM-YYYY")}.zip`);
      }

      setTimeout(() => {
        dispatch({ type: "HIDE_EXTERNAL_LOADER" });
      }, 5000);
    } catch (error) {
      console.log(error);
      dispatch(handleError());
      setTimeout(() => {
        dispatch({ type: "HIDE_EXTERNAL_LOADER" });
      }, 5000);
    }
  };

export const copyGoogleFoldersInAgilix =
  (foldersArray) => async (dispatch, getState) => {
    dispatch({
      type: "SHOW_EXTERNAL_LOADER",
      payload: {
        filesArray: foldersArray,
        title: "Importation de vos éléments...",
      },
    });

    try {
      const organisation = getState().organisationsReducer.selectedOrganisation;
      if (!organisation.id) {
        return;
      }
      await Api.post(
        `api/external-service/copyGoogleFoldersInAgilix?organisation=${organisation.id}`,
        {
          data: foldersArray,
        }
      );
      dispatch(getCloud(false));
      dispatch(
        handleInfo("Dossier copié dans agilix", "SHOW_INFO", "oneDriveBlue")
      );
      setTimeout(() => {
        dispatch({ type: "HIDE_EXTERNAL_LOADER" });
      }, 5000);
    } catch (error) {
      console.log(error);
      dispatch(handleError());
      setTimeout(() => {
        dispatch({ type: "HIDE_EXTERNAL_LOADER" });
      }, 5000);
    }
  };
