import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LightDarkText from "../Common/LightDarkText";
import ColoredButton from "../Common/Buttons/ColoredButton";
import TextAreaInput from "../Simulateur/TextAreaInput";

import { archiveProspect, updateProspect } from "../../REDUX/actions/prospect";

const NoteForm = ({ id, fullName, type, isArchive, oldNote, item }) => {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);

  const [note, setNote] = useState({ note: oldNote });

  const handleSubmit = () => {
    isArchive
      ? dispatch(archiveProspect(id, !item.archived, note.note, type))
      : dispatch(updateProspect(id, note, undefined, type));
  };
  return (
    <div className="p-2 my-3 d-flex flex-column align-items-center">
      {isArchive ? (
        <LightDarkText className={"pointer fitContent f18 bold text-start"}>
          {item.archived ? "Désarchiver" : "Archiver"} {type} #{id} {fullName}
        </LightDarkText>
      ) : (
        <>
          <LightDarkText className={"pointer fitContent f18 bold text-start"}>
            Note interne {type} de {fullName}
          </LightDarkText>
          <LightDarkText
            className={"pointer fitContent f12 bold text-start mt-2"}
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            Ces informations ne seront visibles uniquement par un admin
          </LightDarkText>
        </>
      )}
      <div className="ms-2 w85 mt-3">
        <TextAreaInput
          label={isArchive && "Raison d'annulation"}
          bgwhite={false}
          placeholder="Note interne"
          // link={item?.attributes?.link}
          champs={"note"}
          totalInput={note}
          setValue={setNote}
        />
      </div>

      <div className="w40 mt-3">
        <ColoredButton
          textButton={isArchive ? "Archiver " : "Valider"}
          color={isArchive ? "red" : "green"}
          callback={handleSubmit}
          disabled={!isArchive && !note}
        />
      </div>
    </div>
  );
};

export default NoteForm;
