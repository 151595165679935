import moment from "moment";
import Api from "../../Api/Api";
import { handleRoleFilters } from "../../Utils/handleFilters";
import { saveAs } from "file-saver";
import {
  CREATE_CLOUD,
  DELETE_FICHIER,
  DELETE_UPLOAD,
  MOVE_FILE,
  RENAME_FOLDER,
} from "../Graphql/Mutations/cloud";
import { GET_FILE } from "../Graphql/Queries/cloud";
import store from "../store/store";
import client from "./Client";
import { getInterventionById } from "./interventions";
import { closeModal } from "./modal";
import { getTicketItem } from "./tickets";
import { handleError, handleInfo } from "./toastMessage";
import { handleRefetchCloud } from "./files";
import { checkExternalServiceShares } from "./externalServices/cloudCommuns";
const color = "light-blue";

export const getCloud =
  (withLoading = true) =>
  async (dispatch, getState) => {
    const orgId = getState().organisationsReducer.selectedOrganisation.id;
    const user = getState().userReducer.user;
    if (!orgId) {
      return;
    }
    if (withLoading) {
      dispatch({ type: "LOADING_CLOUD" });
    }
    const userOrg = await user.attributes?.user_org_permissions?.data?.filter(
      (userOrgElt) => +userOrgElt?.attributes?.organisation?.data?.id === +orgId
    )[0];

    try {
      if (userOrg?.attributes?.cloud?.data?.id) {
        const { data } = await Api.get(
          `/api/fetchCloud?organisation=${orgId}&cloudId=${userOrg?.attributes?.cloud?.data?.id}&userId=${user.id}`
        );

        dispatch({ type: "FETCH_CLOUD", payload: data.cloud });
      } else {
        if (!userOrg) {
          return;
        }

        const createdCloud = await client.mutate({
          mutation: CREATE_CLOUD,
          variables: {
            userOrg: userOrg.id,
            orgId: orgId,
          },
        });

        const { data } = await Api.get(
          `/api/fetchCloud?organisation=${orgId}&cloudId=${createdCloud.data.createCloud.data.id}&userId=${user.id}`
        );

        dispatch({ type: "FETCH_CLOUD", payload: data.cloud.data });
      }
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
    }
  };

export const getCloudFiles =
  (withLoading = true) =>
  async (dispatch, getState) => {
    const orgId = getState().organisationsReducer.selectedOrganisation.id;
    const user = getState().userReducer.user;
    if (!orgId) {
      return;
    }
    if (withLoading) {
      dispatch({ type: "LOADING_CLOUD" });
    }
    const userOrg = await user.attributes?.user_org_permissions?.data?.filter(
      (userOrgElt) => +userOrgElt?.attributes?.organisation?.data?.id === +orgId
    )[0];

    try {
      const { data } = await Api.get(
        `/api/fetchCloudFiles?organisation=${orgId}&cloudId=${userOrg?.attributes?.cloud?.data?.id}&userId=${user.id}`
      );

      dispatch({ type: "FETCH_CLOUD_FILES", payload: data.cloud });
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
    }
  };

export const fetchSharedFolders =
  (withLoading = true) =>
  async (dispatch, getState) => {
    const orgId = getState().organisationsReducer.selectedOrganisation.id;

    if (!orgId) {
      return;
    }
    if (withLoading) {
      dispatch({ type: "LOADING_CLOUD" });
    }

    try {
      const { data } = await Api.get(
        `/api/fetchSharedFolders?organisation=${orgId}`
      );

      dispatch({
        type: "FETCH_SHARED_FOLDERS",
        payload: data?.sharedFolders,
      });
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
    }
  };

export const fetchSharedFiles =
  (withLoading = true) =>
  async (dispatch, getState) => {
    const orgId = getState().organisationsReducer.selectedOrganisation.id;

    if (!orgId) {
      return;
    }
    if (withLoading) {
      dispatch({ type: "LOADING_CLOUD" });
    }

    try {
      const { data } = await Api.get(
        `/api/fetchSharedFiles?organisation=${orgId}`
      );

      dispatch({
        type: "FETCH_SHARED_FILES",
        payload: data?.sharedFiles.data,
      });
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
    }
  };

export const deleteFile = (fichier, inFolder) => async (dispatch) => {
  try {
    await client.mutate({
      mutation: DELETE_UPLOAD,
      variables: {
        id: fichier.attributes.file.data.id,
      },
    });
    const { data } = await client.mutate({
      mutation: DELETE_FICHIER,
      variables: {
        id: fichier.id,
      },
    });
    dispatch(handleInfo("Fichier supprimé", 1, color));
    if (fichier.attributes.intervention?.data) {
      dispatch(getInterventionById(fichier.attributes.intervention?.data));
    }
    if (fichier.attributes.ticket?.data) {
      dispatch(getTicketItem(fichier.attributes.ticket?.data?.id));
    }
    if (inFolder) {
      dispatch({
        type: "DELETE_FICHIER_INFOLDER",
        payload: data.deleteFichier.data,
      });
    } else {
      dispatch(handleRefetchCloud("agilix", true));
      // dispatch({ type: "DELETE_FICHIER", payload: data.deleteFichier.data });
    }
  } catch (error) {
    // dispatch(handleError("Erreur réseau", 1));
  }
};

export const addFolder =
  ({ name, sharedUsers, parentFolderId = null, subFolder = false }) =>
  async (dispatch) => {
    try {
      const orgId =
        store.getState().organisationsReducer.selectedOrganisation.id;
      if (!orgId) {
        return;
      }
      await Api.post(`api/handleNewFolder?organisation=${orgId}`, {
        data: {
          name,
          sharedUsers,
          parentFolderId,
          subFolder,
        },
      });

      dispatch(handleRefetchCloud());

      dispatch(handleInfo("Dossier créé", 1, color));
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
    }
  };

export const renameFolder = (newName, folderId) => async (dispatch) => {
  try {
    await client.mutate({
      mutation: RENAME_FOLDER,
      variables: {
        id: folderId,
        name: newName,
      },
    });

    dispatch(handleRefetchCloud());
    dispatch(closeModal());
  } catch (error) {
    // dispatch(handleError("Erreur réseau", 1));
  }
};

export const moveFile = (fichierId, dossierId) => async (dispatch) => {
  try {
    await client.mutate({
      mutation: MOVE_FILE,
      variables: {
        fichierId: fichierId,
        dossierId: dossierId,
      },
    });

    dispatch(handleRefetchCloud());
  } catch (error) {
    // dispatch(handleError("Erreur réseau", 1));
  }
};

export const getFolderItem =
  (id, withLoading = true) =>
  async (dispatch) => {
    if (!id) return;
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }
    if (withLoading) {
      dispatch({ type: "LOADING_CLOUD" });
    }

    try {
      const folder = await Api.get(
        `/api/folders/fetchFolderItem?folderId=${id}&organisation=${orgId}`
      );

      dispatch({ type: "FETCH_FOLDER_ITEM", payload: folder.data.folder });
    } catch (error) {
      if (error?.response?.data?.error?.status === 403) {
        dispatch(
          handleError(
            "vous n'avez pas accès à ces informations. Veuillez contacter votre administrateur.",
            1
          )
        );
      } else {
        dispatch(handleError("Erreur réseau", 1));
      }
    }
  };

// Delete Folder
export const deleteFolder = (folderIds) => async (dispatch, getState) => {
  try {
    const orgId = getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }
    await Api.post(`/api/folders/handleDeleteFolder?organisation=${orgId}`, {
      data: folderIds,
    });

    dispatch(handleRefetchCloud());
    dispatch(handleInfo("Dossier supprimé", 1, color));
  } catch (error) {
    dispatch(handleError("Erreur suppression dossier", 1));
  }
};

// share folder with others
export const shareFolder =
  (id, sharedUsers, shareToRoles, sharedSocieties) =>
  async (dispatch, getState) => {
    try {
      const orgId = getState().organisationsReducer.selectedOrganisation.id;
      if (!orgId) {
        return;
      }
      await Api.post(`/api/handleShareFolder?organisation=${orgId}`, {
        data: {
          id,
          sharedUsers: sharedUsers.map((s) => s.value),
          shareToRoles: shareToRoles.map((s) => s.value),
          sharedSocieties: sharedSocieties.map((s) => s.value),
        },
      });

      dispatch(handleRefetchCloud());
      dispatch(
        handleInfo(
          sharedUsers?.length > 0
            ? "Le partage de dossier a été modifié"
            : "Le partage de dossier a été modifié",
          1,
          color
        )
      );
    } catch (error) {
      dispatch(handleError("Error partage dossier", 1));
    }
  };

export const shareFoldersInTickets =
  (foldersId, ticketsIds, sharesToDelete = []) =>
  async (dispatch, getState) => {
    const orgId = getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }

    try {
      await Api.post(
        `/api/folders/shareFoldersInTickets?organisation=${orgId}`,
        {
          data: {
            foldersId,
            tickets: ticketsIds,
            sharesToDelete,
          },
        }
      );
      dispatch(handleInfo("Dossier partagé avec succès", 1, color));
      dispatch(handleRefetchCloud());
    } catch (error) {
      dispatch(handleInfo("Error partage dossier", 1));
    }
  };

export const shareExternalFoldersInTickets =
  (folders, ticketsIds, sharesToDelete = [], type) =>
  async (dispatch, getState) => {
    const orgId = getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }

    try {
      await Api.post(
        `/api/folders/shareExternalFoldersInTickets?organisation=${orgId}`,
        {
          data: {
            folders,
            tickets: ticketsIds,
            sharesToDelete,
            type,
            is_internal: false,
          },
        }
      );

      dispatch(handleInfo("Dossier partagé avec succès", 1, color));
      dispatch(checkExternalServiceShares(type));
      dispatch(handleRefetchCloud(type, false));
    } catch (error) {
      dispatch(handleInfo("Error partage dossier", 1));
    }
  };

export const getAttachement =
  (user, page, pageSize = 30, search, sort) =>
  async (dispatch) => {
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }
    if (page === 1) {
      dispatch({ type: "RESET_ATTACHMENTS" });
    }
    dispatch({
      type: "FETCH_ATTACHMENTS_LOADING",
    });
    try {
      const { data } = await Api.get(
        `/api/fetchConditionalTicketAttachements?organisation=${orgId}&search=${search}&page=${page}&pageSize=${pageSize}&sort=${sort}`
      );

      dispatch({
        type: "FETCH_ATTACHMENTS",
        payload: data,
      });
    } catch (error) {
      dispatch(handleInfo("Error réseau", 1));
    }
  };

// share files with others
export const shareFile =
  (id, sharedUsers, shareToRoles, sharedSocieties, folderId) =>
  async (dispatch, getState) => {
    try {
      const orgId = getState().organisationsReducer.selectedOrganisation.id;
      if (!orgId) {
        return;
      }
      await Api.post(`/api/handleShareFile?organisation=${orgId}`, {
        data: {
          id,
          sharedUsers: sharedUsers.map((s) => s.value),
          shareToRoles: shareToRoles.map((s) => s.value),
          sharedSocieties: sharedSocieties.map((s) => s.value),
        },
      });

      dispatch(handleRefetchCloud("agilix", true));
      dispatch(
        handleInfo(
          sharedUsers?.length > 0
            ? "Le partage de fichier a été modifié"
            : "Le partage de fichier a été modifié",
          1,
          color
        )
      );
    } catch (error) {
      dispatch(handleError("Error partage fichier", 1));
    }
  };

export const getNewFolderFile =
  ({ id }) =>
  async (dispatch) => {
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }
    try {
      const { data } = await client.query({
        query: GET_FILE,
        variables: {
          id,
          orgId: orgId,
        },
      });
      dispatch({
        type: "FETCH_NEW_FOLDER_FILE",
        payload: data?.fichier?.data,
      });
    } catch {}
  };

export const downloadMultipleAgilixFolders =
  (foldersArray) => async (dispatch, getState) => {
    dispatch({
      type: "SHOW_EXTERNAL_LOADER",
      payload: {
        filesArray: foldersArray,
        title: "Téléchargement de vos éléments...",
      },
    });
    const orgId = getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }
    try {
      const response = await Api.post(
        `api/folders/downloadMultipleAgilixFolders?organisation=${orgId}`,
        {
          data: {
            foldersArray,
          },
        },
        { responseType: "arraybuffer" }
      );
      const blob = new Blob([response.data], { type: "application/zip" });
      if (foldersArray.length === 1) {
        saveAs(blob, `Agilix-${foldersArray[0].name}.zip`);
      } else {
        saveAs(blob, `Agilix-${moment().format("DD-MM-YYYY")}.zip`);
      }

      setTimeout(() => {
        dispatch({ type: "HIDE_EXTERNAL_LOADER" });
      }, 5000);
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
      dispatch({ type: "HIDE_EXTERNAL_LOADER" });
    }
  };
