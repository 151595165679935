import moment from "moment";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Thumbnail from "../Common/Thumbnail";
import LightDarkText from "../Common/LightDarkText";
import LightDarkContainer from "../Common/LightDarkContainer";
import TooltipActions from "../Common/Tooltip/TooltipActions";
import {
  DeleteIcon,
  EditIcon,
  EyeIcon,
  StarIcon,
} from "../Common/LightDarkSvg";
import SocietyForm from "./SocietyForm/SocietyForm";

import { getValueByAccessor } from "../../Utils/utils";
import { handleDuration } from "../Tickets/ticketUtils";

import useWindowWidth from "../../Hooks/useWindowWidth";

import { openSideBarCard } from "../../REDUX/actions/general";
import { closeModal } from "../../REDUX/actions/modal";
import { openConfirmationModal } from "../../REDUX/actions/confirmationModal";
import { UpdateSociete } from "../../REDUX/actions/societe";
import { openSideBar } from "../../REDUX/actions/sideBar";

const SocietyRow = ({
  society,
  tableList,
  selectedItem,
  setSelectedItem,
  clickOnSocietyCard = () => {},
  index,
}) => {
  const location = useLocation();

  const navigate = useNavigate();

  const screenWidth = useWindowWidth();

  const dispatch = useDispatch();

  const { societeRoute } = useSelector((state) => state.organisationsReducer);
  const { mode } = useSelector((state) => state.genearlReducer);
  const { canCreateSociety } = useSelector((state) => state.roleReducer);

  const [showTooltip, setshowTooltip] = useState(false);

  const currentPlan = society?.attributes?.plans?.data?.find((plan) => {
    return (
      moment(plan?.attributes?.date).format("YYYY-MM-DD") ===
      moment().format("YYYY-MM-01")
    );
  });
  const consumption_0 = currentPlan?.attributes?.consumption_0 || 0;
  const monthlyPlan = currentPlan?.attributes?.monthlyPlan || 0;
  const monthlyQuota = currentPlan?.attributes?.monthlyQuota || 0;

  const showSocietyDetails = () => {
    if (location.pathname.includes(societeRoute)) {
      setSelectedItem(society);
      dispatch(openSideBarCard());
    } else {
      clickOnSocietyCard(society);
    }
  };

  const tooltipData = [
    {
      id: 1,
      text: "Voir les détails",
      icon: <EyeIcon className="me-2" />,
      action: () => {
        if (location.pathname === "/") {
          navigate(societeRoute);
        }
        setshowTooltip(false);
        setSelectedItem(society);
        dispatch(openSideBarCard());
      },
      condition: location.pathname.includes(societeRoute),
    },
    {
      id: 2,
      text: "Éditer",
      icon: <EditIcon className="me-2" />,
      action: () => {
        dispatch(
          openSideBar({
            content: <SocietyForm societyId={society.id} />,
            title: `Éditer la société ${society.attributes.name}`,
            type: "w40",
            isToggle: "isToggle value",
          })
        );
      },
      condition: canCreateSociety,
    },
    {
      id: 3,
      text: society.attributes.archived ? "Désarchiver" : "Archiver",
      icon: <DeleteIcon className="me-2" />,
      action: () => {
        dispatch(
          openConfirmationModal({
            callBack: () => {
              dispatch(
                UpdateSociete({
                  id: society.id,
                  archived: society.attributes.archived ? false : true,
                })
              );
              dispatch(closeModal());
            },
            message: `Voulez vous vraiment ${
              society.attributes.archived ? "désarchiver" : "archiver"
            } la société?`,
            message2: `#${society.id} ${society.attributes.name}`,
            confirmButtonText: society.attributes.archived
              ? "Désarchiver la société"
              : "Archiver la société",
          })
        );
        setshowTooltip(false);
      },
      condition: canCreateSociety,
    },
  ];
  return (
    <LightDarkContainer
      key={index}
      className={`d-flex w-100 py-2 px-3 pointer ${
        society?.id === selectedItem?.id &&
        (mode === "light" ? "lightListCardFocus" : "darkListCardFocus")
      }`}
      colorLight="white"
      colorDark="dark-grey-bg"
      hover={true}
      cardContainer={true}
    >
      <div className="w-100 d-flex" style={{ overflow: "hidden" }}>
        {tableList.map(({ id, accessor, widthSideBar, width }) => {
          const columnCol = openSideBar ? widthSideBar : width;
          return (
            <div
              className={`alignH w${columnCol} p-0`}
              key={id}
              onClick={() => {
                showSocietyDetails();
              }}
            >
              {accessor === "adresse" ? (
                (society.attributes.adress ||
                  society.attributes.city ||
                  society.attributes.zipCode) && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        <LightDarkText className="custom-ticket-font-size py-1 text-white">
                          {`${society.attributes.adress}, `}
                          {society.attributes.zipCode &&
                            `${society.attributes.zipCode}, `}
                          {society.attributes.city &&
                            `${society.attributes.city}`}
                        </LightDarkText>
                      </Tooltip>
                    }
                  >
                    <div className="text-ellipsis pe-3">
                      <LightDarkText
                        color={
                          mode === "light"
                            ? "text-blue-grey"
                            : "text-light-text"
                        }
                        className={
                          "custom-ticket-font-size bold text-start text-ellipsis"
                        }
                      >
                        {society.attributes.adress &&
                          `${society.attributes.adress}, `}
                        {society.attributes.zipCode &&
                          `${society.attributes.zipCode}, `}
                        {society.attributes.city &&
                          `${society.attributes.city}`}
                      </LightDarkText>
                    </div>
                  </OverlayTrigger>
                )
              ) : accessor.includes("createdAt") ? (
                <div>
                  <LightDarkText
                    className={
                      "custom-ticket-font-size bold text-start text-ellipsis"
                    }
                    color={
                      mode === "light" ? "text-blue-grey" : "text-light-text"
                    }
                  >
                    {moment(society.attributes?.createdAt).format("DD.MM.YYYY")}
                  </LightDarkText>
                </div>
              ) : accessor === "forfait" ? (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      <LightDarkText className="custom-ticket-font-size py-1 text-white">
                        {handleDuration(consumption_0 + monthlyQuota)}/
                        {handleDuration(monthlyPlan)}
                      </LightDarkText>
                    </Tooltip>
                  }
                >
                  <div className={`w80 text-start alignH p-0`}>
                    <LightDarkText
                      className={"custom-ticket-font-size text-start w-100"}
                      color={
                        mode === "light" ? "text-blue-grey" : "text-light-text"
                      }
                    >
                      <div
                        className={`progress radius05 ${
                          mode === "light"
                            ? "bg-light-text"
                            : "bg-light-message"
                        } `}
                        style={{
                          height: "7px",
                          width: `${location.pathname === "/" ? "70%" : "80%"}`,
                          border: "transparent",
                        }}
                      >
                        <div
                          className="progress-bar bg-green"
                          role="progressbar"
                          style={{
                            width: `${(monthlyQuota / monthlyPlan) * 100}%`,
                            display: `${
                              !monthlyQuota || !monthlyPlan ? "none" : ""
                            }`,
                          }}
                          aria-valuenow={Number(
                            (monthlyQuota / monthlyPlan) * 100
                          )}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                        <div
                          className="progress-bar bg-light-blue"
                          role="progressbar"
                          style={{
                            width: `${(consumption_0 / monthlyPlan) * 100}%`,
                            display: `${
                              !consumption_0 || !monthlyPlan ? "none" : ""
                            }`,
                          }}
                          aria-valuenow={Number(
                            (consumption_0 / monthlyPlan) * 100
                          )}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </LightDarkText>
                  </div>
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      <LightDarkText className="custom-ticket-font-size py-1 text-white">
                        {getValueByAccessor(society, accessor)}
                      </LightDarkText>
                    </Tooltip>
                  }
                >
                  <div className={`d-flex align-items-center w95 gap-2`}>
                    {accessor.includes("name") && (
                      <>
                        <Thumbnail
                          size={screenWidth < 1200 ? 30 : 40}
                          apiImg={
                            society?.attributes?.avatar?.data?.attributes?.image
                              ?.data?.attributes?.url
                          }
                          img={"society"}
                          rounded
                        />
                        {society?.attributes?.society_type?.data?.attributes
                          ?.name === "Ambassadeur" && (
                          <div className="w7">
                            <StarIcon />
                          </div>
                        )}
                      </>
                    )}
                    <LightDarkText
                      className={`custom-ticket-font-size text-start text-ellipsis ${
                        accessor.includes("name") ? "bolder" : "bold"
                      }`}
                      color={
                        accessor.includes("name") && mode === "light"
                          ? "text-dark-blue"
                          : accessor.includes("name") && mode !== "light"
                          ? "text-white"
                          : mode === "light"
                          ? "text-blue-grey"
                          : "text-light-text"
                      }
                    >
                      {accessor.includes("id") ? "#" : null}
                      {getValueByAccessor(society, accessor)}
                    </LightDarkText>
                  </div>
                </OverlayTrigger>
              )}
            </div>
          );
        })}
      </div>
      <div className="d-flex align-items-center justify-content-end">
        <TooltipActions
          setshowTooltip={setshowTooltip}
          showTooltip={showTooltip}
          tooltipData={tooltipData}
        />
      </div>
    </LightDarkContainer>
  );
};

export default SocietyRow;
