import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CopyAgilix,
  Download,
  EyeIcon,
  InternalCheck,
} from "../../Common/LightDarkSvg";
import { useDispatch, useSelector } from "react-redux";
import LightDarkContainer from "../../Common/LightDarkContainer";
import ElementName from "../CloudPartials/ElementName";
import LightDarkText from "../../Common/LightDarkText";
import TooltipActions from "../../Common/Tooltip/TooltipActions";
import {
  copyFolderInAgilix,
  downloadMultipleOneDriveFolders,
} from "../../../REDUX/actions/externalServices";
import {
  copyGoogleFoldersInAgilix,
  downloadMultipleGoogleDriveFolders,
} from "../../../REDUX/actions/externalServices/gdrive";
import TicketIcon from "../../Common/MenuIcons/TicketIcon";
import { openModal } from "../../../REDUX/actions/modal";
import ShareExternalInTicket from "./ShareExternalInTicket";
import ShareTicketNumber from "../ShareModals/ShareTicketNumber";

const ExternalFolderCard = ({
  folderName,
  externalServiceLogo,
  externalFolderId,
  childCount,
  selected,
  setSelected,
  type,
  externalServiceShares,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { organisationUrl } = useSelector(
    (state) => state.organisationsReducer
  );
  const [isSelected, setIsSelected] = useState(false);
  const [showTooltip, setshowTooltip] = useState(false);
  const handleNavigate = () => {
    navigate(`${organisationUrl}/${type}/${externalFolderId}`);
  };

  const handleShare = () => {
    dispatch(
      openModal({
        type: "small",
        content: (
          <ShareExternalInTicket
            folder={{ id: externalFolderId, name: folderName }}
            type={type}
          />
        ),
      })
    );
  };

  const tooltipData = [
    {
      id: "voir",
      text: "Voir",
      icon: <EyeIcon className={"me-2"} size={24} />,
      action: () => {
        handleNavigate();
      },
      condition: true,
    },
    {
      id: "download",
      text: "Télécharger",
      icon: <Download className="me-2" size={24} />,
      action: () => {
        if (type === "oneDrive") {
          dispatch(
            downloadMultipleOneDriveFolders(
              [{ id: externalFolderId, name: folderName }],
              null
            )
          );
        }
        if (type === "gDrive") {
          dispatch(
            downloadMultipleGoogleDriveFolders([
              { id: externalFolderId, name: folderName },
            ])
          );
        }
        setshowTooltip(false);
      },
      condition: true,
    },
    {
      id: 4,
      action: () => {
        if (type === "oneDrive") {
          dispatch(
            copyFolderInAgilix([{ id: externalFolderId, name: folderName }])
          );
        }
        if (type === "gDrive") {
          dispatch(
            copyGoogleFoldersInAgilix([
              { id: externalFolderId, name: folderName },
            ])
          );
        }
        setshowTooltip(false);
      },
      text: "Copier dans Agilix", //folders ,
      icon: <CopyAgilix className="me-2" />,
      condition: true,
    },

    {
      id: 5,
      text: "Partager dans un ticket",
      icon: <TicketIcon className="me-2" width={22} height={22} />,
      action: () => {
        setshowTooltip(false);
        handleShare();
      },
      condition: true,
    },
  ];

  const handleSelect = () => {
    if (isSelected) {
      setIsSelected(!isSelected);
      setSelected(selected.filter((el) => el.id !== externalFolderId));
    } else {
      setIsSelected(!isSelected);

      setSelected([...selected, { id: externalFolderId, name: folderName }]);
    }
  };

  useEffect(() => {
    setIsSelected(selected.filter((el) => el.id === externalFolderId).length);
  }, [selected]);
  return (
    <LightDarkContainer
      colorDark="dark"
      colorLight="white"
      className={`folderCard pointer cloudFolderCardContainer position-relative d-flex flex-column justify-content-between  radius05 t-02 position-relative ${
        isSelected ? "ContainerSelected" : ""
      }`}
    >
      <ShareTicketNumber
        dossier={{ id: externalFolderId, name: folderName }}
        isMine={true}
        sharesNumber={
          externalServiceShares?.filter(
            (el) => el.attributes.item_id === externalFolderId
          ).length
        }
        callBack={() => handleShare()}
        top={-13}
        right={null}
        left={-13}
      />
      <div className="blueFileFilter radius05"></div>
      <div className={`shadowSelect t-02 `}></div>
      <div
        onClick={handleSelect}
        className={`SelectBoxCloud pointer radius05 t-02 alignC `}
      >
        <InternalCheck />
      </div>

      <div className="position-relative d-flex justify-content-between align-items-start m-3">
        <img
          src={externalServiceLogo}
          className="pointer"
          alt={folderName}
          width={40}
          onClick={handleNavigate}
        />
      </div>
      <div className="d-flex justify-content-between m-3">
        <div className="d-flex flex-column align-items-start w90">
          <ElementName name={folderName} callBack={handleNavigate} />
          <LightDarkText className={"f10"} color={"text-light-text"}>
            {childCount} éléments
          </LightDarkText>
        </div>
        <TooltipActions
          setshowTooltip={setshowTooltip}
          showTooltip={showTooltip}
          tooltipData={tooltipData}
          isVertical
          // left={"180px"}
        />
      </div>
    </LightDarkContainer>
  );
};

export default ExternalFolderCard;
