import moment from "moment";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormHeader from "../../Common/Forms/FormHeader";
import InterventionInfosform from "./InterventionInfosform";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import FormRequiredMessage from "../../Common/Forms/FormRequiredMessage";

import {
  checkRequiredInterventionInputs,
  prepareInterventionFields,
} from "../interventionUtils";

import {
  createIntervention,
  updateInterventionDetails,
} from "../../../REDUX/actions/interventions";
import { closeModal } from "../../../REDUX/actions/modal";
import { getCurrentPlan } from "../../../REDUX/actions/plan";
import { clearTempUpload } from "../../../REDUX/actions/files";

const InterventionForm = ({
  societeId = null,
  responsibleOperators = [],
  ticketItemIntervention,
  relatedTicket,
  isNew,
  intervention,
}) => {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { societes } = useSelector((state) => state.sharedDataReducer);
  const { currentPlan } = useSelector((state) => state.planReducer);

  const { temporarySelected, isConfidentiel } = useSelector(
    (state) => state.filesReducer
  );
  const { tickets } = useSelector((state) => state.ticketsReducer);
  const { user } = useSelector((state) => state.userReducer);

  const { interventionsTypes } = useSelector(
    (state) => state.interventionsReducer
  );

  const [totalRemainingForfait, setTotalRemainingForfait] = useState(0);
  const [societerelatedTickets, setSocieterelatedTickets] = useState([]);
  const [mentions, setMentions] = useState([]);

  const [interventionInputs, setinterventionInputs] = useState(
    isNew
      ? {
          title: "",
          multiChoiceTitle: [],
          description: "",
          hors_forfait: false,
          duration: 0,
          statut: 1,
          responsibles: responsibleOperators,
          dateDebut: new Date(),
          societe: societeId
            ? societes.filter((el) => +el.value === +societeId)
            : [],
          ticket:
            ticketItemIntervention && relatedTicket
              ? [
                  {
                    value: relatedTicket.id,
                    label: `#${relatedTicket.id}-${relatedTicket.attributes.name}`,
                  },
                ]
              : tickets.length === 1
              ? [
                  {
                    value: tickets[0].id,
                    label: `#${tickets[0].id}-${tickets[0].attributes.name}`,
                  },
                ]
              : [],
          creator: user.id,
          cloud:
            user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
              ?.data?.id,
        }
      : {
          ...intervention.attributes,
          multiChoiceTitle: interventionsTypes.filter(
            (el) => el.label === intervention.attributes.title
          ),
          cloud:
            user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
              ?.data?.id,
        }
  );

  useEffect(() => {
    setTotalRemainingForfait(
      interventionInputs.societe[0]?.totalRemainingForfait || 0
    );
  }, [interventionInputs.societe, societes]);

  useEffect(() => {
    const ticketsOnMenu = tickets.filter(
      (ticket) =>
        ticket.attributes.societe.data?.id ===
        interventionInputs.societe[0]?.value
    );

    const localSocieterelatedTickets = [];
    for (let ticket of ticketsOnMenu) {
      if (ticket?.attributes?.statut !== 2) {
        localSocieterelatedTickets.push({
          value: ticket.id,
          label: `#${ticket.id}-${ticket.attributes.name}`,
          responsibles: ticket?.attributes?.responsibleOperators?.data?.map(
            (el) => el?.id
          ),
        });
      }
    }
    setSocieterelatedTickets(localSocieterelatedTickets);
  }, [interventionInputs.societe]);

  useEffect(() => {
    if (interventionsTypes.length) {
      setinterventionInputs((prev) => {
        return {
          ...prev,
          title: prev.multiChoiceTitle[0]?.label || "",
        };
      });
    }
  }, [interventionInputs.multiChoiceTitle, interventionsTypes]);

  const handleSubmitIntervention = () => {
    if (isNew) {
      dispatch(
        createIntervention(
          prepareInterventionFields({
            ...interventionInputs,
            ticket:
              societerelatedTickets.length === 1
                ? societerelatedTickets
                : interventionInputs["ticket"],
            responsibles: relatedTicket
              ? responsibleOperators
              : societerelatedTickets.length === 1
              ? societerelatedTickets[0]?.responsibles
              : interventionInputs["ticket"][0]?.responsibles,
          }),
          {
            files: temporarySelected,
            isConfidentiel: isConfidentiel,
          },
          mentions
        )
      );
      dispatch(clearTempUpload());
      dispatch(closeModal());
    } else {
      dispatch(
        updateInterventionDetails(
          prepareInterventionFields({
            ...interventionInputs,
            responsibles: intervention?.attributes?.responsibles?.data?.map(
              (item) => item.id
            ),
            ticket: [
              {
                label: intervention?.attributes?.ticket?.data?.attributes?.name,
                value: intervention?.attributes?.ticket?.data?.id,
              },
            ],
            id: intervention.id,
          }),
          { files: temporarySelected },
          currentPlan,
          intervention?.attributes?.duration
        )
      );
      dispatch(clearTempUpload());
      dispatch(closeModal());
    }
  };

  useEffect(() => {
    dispatch(
      getCurrentPlan(
        interventionInputs?.societe[0]?.value ??
          societeId ??
          intervention?.attributes?.societe?.data?.id,

        moment().format("YYYY-MM-01")
      )
    );
  }, [
    dispatch,
    intervention?.attributes?.societe?.data?.id,
    interventionInputs?.societe,
    societeId,
  ]);

  return (
    <div
      className={`${mode === "light" ? "bg-white" : "bg-dark-mode"} 
      px-4 py-3 radius05 h100 directionC justify-content-between hideScrollbar`}
      style={{
        width: "700px",
        paddingInline: "10px",
      }}
      onSubmit={(e) => e.preventDefault()}
    >
      <FormHeader
        formTitle={isNew ? "Créer une intervention" : "Modifier l'intervention"}
      />
      <InterventionInfosform
        isNew={isNew}
        societeId={societeId}
        interventionInputs={interventionInputs}
        setinterventionInputs={setinterventionInputs}
        societerelatedTickets={societerelatedTickets}
        totalRemainingForfait={totalRemainingForfait}
        setMentions={setMentions}
      />
      <FormRequiredMessage />
      <div className="align-self-center col-4">
        <ColoredButton
          disabled={checkRequiredInterventionInputs(
            interventionInputs,
            societerelatedTickets.length
          )}
          textButton={
            isNew ? "Créer l'intervention" : "Enregistrer les modifications"
          }
          color="light-blue"
          callback={() => handleSubmitIntervention()}
        />
      </div>
    </div>
  );
};

export default InterventionForm;
