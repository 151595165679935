export const handlPermissionsList = (organisationsReducer, role, isNew) => {
  return [
    {
      name: "Gestion des tickets",
      permissions: [
        organisationsReducer.ticketsAccess
          ? {
              label: "Créer un ticket",
              name: "canCreateTickets",
              roles: "all",
              type: "ticket",
            }
          : null,
        organisationsReducer.ticketsAccess
          ? {
              label: "Modifier un ticket",
              name: "canEditTickets",
              roles: [2, 3, 4, 5],
              type: "ticket",
            }
          : null,
        organisationsReducer.ticketsAccess
          ? {
              label: "Modifier les participants",
              name: "canManageParticipants",
              roles: [2, 3, 4, 5],
              type: "ticket",
            }
          : null,
        organisationsReducer.ticketsAccess
          ? {
              label: "Archiver un ticket",
              name: "canArchiveTickets",
              roles: "all",
              type: "ticket",
            }
          : null,
        organisationsReducer.ticketsAccess
          ? {
              label: "Envoyer des messages",
              name: "canSendMessages",
              roles: "all",
              type: "ticket",
            }
          : null,

        organisationsReducer.ticketsAccess
          ? {
              label: "Résoudre un ticket",
              name: "canResolveTickets",
              roles: "all",
              type: "ticket",
            }
          : null,
        organisationsReducer.ticketsAccess
          ? {
              label: "Attribution automatique aux tickets",
              name: "ticketAuto",
              roles: [2, 4],
              type: "ticket",
            }
          : null,
        organisationsReducer.selectedOrganisation.attributes.singleOperator
          ? {
              label: "Suppression automatique des tickets",
              name: "deletableFromTicket",
              roles: [2],
              type: "ticket",
            }
          : null,
      ]
        .filter((item) => item?.roles === "all" || item?.roles?.includes(role))
        .filter((item) => item),
    },
    {
      name: "Gestion des interventions",
      permissions: [
        organisationsReducer.interventionsAccess
          ? {
              label: "Créer des interventions",
              name: "canCreateInterventions",
              roles: "all",
              type: "ticket",
            }
          : null,
        organisationsReducer.interventionsAccess
          ? {
              label: "Supprimer/Modifier des interventions",
              name: "canDeleteUpdateInterventions",
              roles: [2],
              type: "ticket",
            }
          : null,
        organisationsReducer.hasInterventionTypes
          ? {
              label: "Ajouter des types d'interventions",
              name: "canAddInterventionsType",
              roles: [2],
              type: "ticket",
            }
          : null,
        organisationsReducer.interventionsAccess
          ? {
              label: "Accepter des interventions",
              name: "canAcceptInterventions",
              roles: "all",
              type: "Intervention",
            }
          : null,
        organisationsReducer.interventionsAccess
          ? {
              label: "Refuser des interventions",
              name: "canRejectInterventions",
              roles: "all",
              type: "Intervention",
            }
          : null,
        organisationsReducer.interventionsAccess
          ? {
              label: "Valider des interventions",
              name: "canHoldForValidationInterventions",
              roles: "all",
              type: "Intervention",
            }
          : null,

        organisationsReducer.interventionsAccess
          ? {
              label: "Réviser des interventions",
              name: "canRequestRevisionInterventions",
              roles: "all",
              type: "Intervention",
            }
          : null,
        organisationsReducer.interventionsAccess
          ? {
              label: "Finaliser des interventions",
              name: "canValidateInterventions",
              roles: "all",
            }
          : null,
      ]
        .filter((item) => item?.roles === "all" || item?.roles?.includes(role))
        .filter((item) => item),
    },
    {
      name: "Gestion des factures",
      permissions: [
        organisationsReducer.facturesAccess
          ? {
              label: "Création de factures",
              name: "canCreateInvoices",
              roles: [1],
              type: "Factures",
            }
          : null,

        organisationsReducer.facturesAccess
          ? {
              label: "Accés aux factures",
              name: "canSeeInvoices",
              roles: [1, 3, 4, 5],
            }
          : null,
      ]
        .filter((item) => item?.roles === "all" || item?.roles?.includes(role))
        .filter((item) => item),
    },
    {
      name: "Gestion des prospets",
      permissions: [
        organisationsReducer.prospetsAccess
          ? {
              label: "Création des prospets",
              name: "canCreateProspet",
              roles: "all",
            }
          : null,
        organisationsReducer.devisAccess
          ? {
              label: "Création des devis",
              name: "canCreateDevis",
              roles: "all",
            }
          : null,
        organisationsReducer.devisAccess
          ? {
              label: "Création des bons de commande",
              name: "canCreateCommande",
              roles: "all",
            }
          : null,
      ]
        .filter((item) => item?.roles === "all" || item?.roles?.includes(role))
        .filter((item) => item),
    },
    {
      name: "Gestion des diffusions",
      permissions: [
        organisationsReducer.diffusionAccess
          ? {
              label: "Création de diffusion",
              name: "canCreateDiffusion",
              roles: [1],
            }
          : null,
      ]
        .filter((item) => item?.roles === "all" || item?.roles?.includes(role))
        .filter((item) => item),
    },
    {
      name: "Gestion des sociétés",
      permissions: [
        organisationsReducer.societeAccess
          ? {
              label: "Créer des sociétés",
              name: "canCreateSociety",
              roles: [1, 2],
            }
          : null,
      ]
        .filter((item) => item?.roles === "all" || item?.roles?.includes(role))
        .filter((item) => item),
    },
    {
      name: "Gestion des utilisateurs",
      permissions: [
        {
          label: "Accés aux opérateurs",
          name: "canSeeOperators",
          roles: [1],
        },
        {
          label: "Créer des opérateurs",
          name: "canCreateOperators",
          roles: [1],
        },

        {
          label: "Accéder aux collaborateurs",
          name: "canSeeCollabs",
          roles: [1, 4, 5],
        },
        {
          label: "Créer des collaborateurs",
          name: "canCreateCollabs",
          roles: [1],
        },
        {
          label: "Voir qui est connecté",
          name: "canSeeWhoOnline",
          roles: [1, 2, 3, 4, 5],
        },
        isNew
          ? {
              label: "Afficher l'historique des tickets",
              name: "showHistory",
              roles: "all",
            }
          : {},
      ]
        .filter((item) => item?.roles === "all" || item?.roles?.includes(role))
        .filter((item) => item),
    },
    {
      name: "Gestion des administrateurs",
      permissions: [
        {
          label: "Modifier les permissions des superviseurs",
          name: "canModifySupervisorPermissions",
          roles: [1],
        },
        {
          label: "Modifier les permissions des clients",
          name: "canModifyAdminPermissions",
          roles: [1],
        },
      ]
        .filter((item) => item?.roles === "all" || item?.roles?.includes(role))
        .filter((item) => item),
    },
  ].filter((el) => el.permissions.length);
};
