import React, { useEffect } from "react";

import NotificationCard from "./NotificationCard";
import LightDarkText from "../../Common/LightDarkText";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotifications,
  handleAllNotifications,
} from "../../../REDUX/actions/notifications";
import NotificationsPlaceHolder from "./NotificationsPlaceHolder";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Eye from "../../Common/MenuIcons/Eye";
import EyeSlash from "../../Common/MenuIcons/EyeSlash";
import EmptyList from "../../Common/EmptyList";

const NotificationList = ({ type }) => {
  const dispatch = useDispatch();
  const { notifications, notificationsloading } = useSelector(
    (state) => state.notificationsReducer
  );
  useEffect(() => {
    dispatch(fetchNotifications(type));
  }, [type]);

  return (
    <div className="position-relative h100">
      {notificationsloading ? (
        <NotificationsPlaceHolder />
      ) : notifications?.length > 0 ? (
        <div className="position-relative">
          {notifications.map((el, index) => (
            <NotificationCard
              type={type}
              key={index}
              index={index}
              notification={{ id: el?.id, ...el?.attributes }}
            />
          ))}
        </div>
      ) : (
        <LightDarkText className="f14 h100 alignC text-center">
          <EmptyList type={"notifications"} />
        </LightDarkText>
      )}
      <div className="position-fixed" style={{ bottom: "20px", right: "20px" }}>
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip style={{ zIndex: 1050 }} className="me-1">
              <LightDarkText className="custom-ticket-font-size py-1 text-white">
                {notifications?.some((el) => !el?.attributes.read)
                  ? "Tout marquer en lu"
                  : "Tout marquer en non lu"}
              </LightDarkText>
            </Tooltip>
          }
        >
          <div
            className="p-2 bg-light-blue radius05 pointer scaleHover11 t02 m-2"
            role="button"
            onClick={() =>
              notifications?.some((el) => !el?.attributes.read)
                ? dispatch(handleAllNotifications(true, type))
                : dispatch(handleAllNotifications(false, type))
            }
          >
            {notifications?.some((el) => !el?.attributes.read) ? (
              <Eye fill="white" />
            ) : (
              <EyeSlash fill="white" />
            )}
          </div>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default NotificationList;
