import React, { useEffect, useState } from "react";
import LightDarkText from "../../Common/LightDarkText";
import SelectCloudBox from "../SelectCloudBox";
import { Spinner } from "react-bootstrap";
import ExternalFolderCard from "./ExternalFolderCard";
import { useDispatch, useSelector } from "react-redux";
import { checkExternalServiceShares } from "../../../REDUX/actions/externalServices/cloudCommuns";

const ExternalFolderList = ({
  loading = false,
  title,
  type,
  folderList,
  search = "",
  logo,
}) => {
  const dispatch = useDispatch();
  const { externalServiceShares } = useSelector(
    (state) => state.externalServicesReducer
  );
  const [selected, setSelected] = useState([]);
  const [filteredFolders, setFilteredFolders] = useState(folderList);

  useEffect(() => {
    dispatch(checkExternalServiceShares(type));
  }, [type]);

  useEffect(() => {
    setFilteredFolders(
      folderList.filter((el) =>
        el.name?.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, folderList]);

  return (
    <div className="position-relative mt-5">
      <LightDarkText className="custom-title-font-size  bold text-start alignH gap-2">
        <div
          className="d-flex gap-3 alignC"
          style={{ width: "20px", height: "30px" }}
        >
          {loading ? (
            <Spinner size="sm" />
          ) : (
            <img src={logo} alt={"oneDrive"} />
          )}
        </div>
        {title}
      </LightDarkText>

      <SelectCloudBox
        isFolder={true}
        key={7}
        selected={selected}
        setSelected={setSelected}
        allItems={filteredFolders.map((el) => {
          return {
            name: el.name,
            id: el.id,
          };
        })}
        type={type}
      />
      <div className="d-flex flex-wrap gap-4 ps-1 pt-4">
        {filteredFolders.map((folder) => (
          <ExternalFolderCard
            key={folder.id}
            folderName={folder.name}
            externalServiceLogo={logo}
            externalFolderId={folder.id}
            childCount={folder.childCount}
            selected={selected}
            setSelected={setSelected}
            type={type}
            externalServiceShares={externalServiceShares}
          />
        ))}
      </div>
    </div>
  );
};

export default ExternalFolderList;
