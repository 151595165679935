import { useSelector } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import LightDarkText from "../../../Common/LightDarkText";
import CalendarCard from "../../CalendarsList/CalendarCard";
import moment from "moment";
import SearchDropDownElement from "./SearchDropDownElement";
import { sortFunction } from "../../../../Utils/utils";

const SearchDropDownList = ({
  events,
  calendars,
  setSearch,
  eventClick = () => {},
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  const upcomingEvents = sortFunction(
    events.filter((el) => moment().diff(moment(el.attributes.startDate)) < 0),
    "startDate",
    true
  );
  const passedEvents = sortFunction(
    events.filter((el) => moment().diff(moment(el.attributes.startDate)) > 0),
    "startDate",
    false
  );

  const localSplittedEvents = [
    { localEvents: upcomingEvents, name: "Événements à venir :" },
    { localEvents: passedEvents, name: "Événements passés :" },
  ];

  return (
    <OutsideClickHandler onOutsideClick={() => setSearch("")}>
      <div className="position-relative">
        <div
          className={`radius05 hideScrollbar position-absolute col-10 ${
            mode === "light" ? "bg-white" : "bg-dark-grey-bg"
          }`}
          style={{
            zIndex: 15,
            border: `${
              mode === "light"
                ? "1px solid #A9BCD3"
                : "1px solid rgb(37, 44, 69)"
            }`,
            height: `${
              events?.length >= 3
                ? "275px"
                : events?.length < 3
                ? "fit-content"
                : "100px"
            }`,
          }}
        >
          <div
            className={`p-3 d-flex align-items-center justify-content-start gap-3 ${
              mode === "light" ? "bg-light-mode" : "bg-dark-grey-bg"
            } `}
            style={{
              height: "80px",
              borderBottom: `${
                mode === "light"
                  ? "1px solid #A9BCD3"
                  : "1px solid rgb(37, 44, 69)"
              }`,
            }}
          >
            <LightDarkText
              className="f12 bold text-start"
              style={{ minWidth: "max-content" }}
              color={mode === "light" ? "text-light-text" : "text-white"}
            >
              Agendas :
            </LightDarkText>
            <div
              className="alignH gap-3 w-100"
              style={{
                overflowX: "scroll",
              }}
            >
              {calendars?.length ? (
                calendars?.map((calendar, index) => (
                  <CalendarCard
                    calendar={calendar}
                    key={index}
                    index={index}
                    searchList
                    setSearch={setSearch}
                  />
                ))
              ) : (
                <LightDarkText
                  className="f14 h100 alignC text-center mx-auto"
                  color={mode === "light" ? "text-blue-grey" : "text-white"}
                >
                  Vous n'avez pas de calendriers correspondants
                </LightDarkText>
              )}
            </div>
          </div>
          {localSplittedEvents.map((el, index) => (
            <div
              style={{
                borderBottom: `${
                  mode === "light"
                    ? "1px solid #A9BCD3"
                    : "1px solid rgb(37, 44, 69)"
                }`,
              }}
              key={index}
              className={`p-3 d-flex ${
                el.localEvents?.length && "flex-column"
              } gap-3`}
            >
              <LightDarkText
                className="f12 bold text-start"
                color={mode === "light" ? "text-light-text" : "text-white"}
              >
                {el.name}
              </LightDarkText>
              {el.localEvents?.length ? (
                el.localEvents?.map((event) => (
                  <SearchDropDownElement
                    key={event.id}
                    event={event}
                    eventClick={eventClick}
                  />
                ))
              ) : (
                <LightDarkText
                  className="f14 h100 alignC text-center mx-auto"
                  color={mode === "light" ? "text-blue-grey" : "text-white"}
                >
                  Vous n'avez pas d'événements correspondants
                </LightDarkText>
              )}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default SearchDropDownList;
