import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import LightDarkText from "../LightDarkText";
import "./CustomListTooltip.css";

const CustomListTooltip = ({ children, tooltipContent }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [dropPosition, setDropPosition] = useState("");

  useEffect(() => {
    const element = document.querySelector(".custom-list-tooltip");
    if (element) {
      const rect = element.getBoundingClientRect();
      const isLeftHalf = rect.left < window.innerWidth / 2;
      const isTopHalf = rect.top < window.innerHeight / 2;

      if (isLeftHalf) {
        setDropPosition(isTopHalf ? "dropLeftTop" : "dropLeftBottom");
      } else {
        setDropPosition(isTopHalf ? "dropRightTop" : "dropRightBottom");
      }
    }
  }, [showTooltip]);

  return (
    <div
      className="custom-list-tooltip-container position-relative"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && (
        <OutsideClickHandler
          onOutsideClick={() => {
            setShowTooltip(false);
          }}
        >
          <div
            className={`custom-list-tooltip position-absolute ${dropPosition}`}
            onClick={(e) => e.stopPropagation()}
          >
            {tooltipContent.map((item, index) => (
              <div
                key={index}
                className="d-flex align-items-center gap-3 tooltip-item"
              >
                {item.icon && <span className="">{item.icon}</span>}
                <LightDarkText className="f12 bold lh-22 tooltip-text">
                  {item.text}
                </LightDarkText>
              </div>
            ))}
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default CustomListTooltip;
