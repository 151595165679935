import { gql } from "@apollo/client";

export const CREATE_CLOUD = gql`
  mutation ($userOrg: ID!, $orgId: ID!) {
    createCloud(data: { user_org_permission: $userOrg, organisation: $orgId }) {
      data {
        id
      }
    }
  }
`;

export const CREATE_FILE = gql`
  mutation (
    $name: String
    $ticket: ID
    $cloud: ID
    $file: ID
    $intervention: ID
    $folder: ID
    $confidentiel: Boolean
    $sharedUsers: [ID]
  ) {
    createFichier(
      data: {
        name: $name
        ticket: $ticket
        cloud: $cloud
        file: $file
        intervention: $intervention
        folder: $folder
        confidentiel: $confidentiel
        sharedUsers: $sharedUsers
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const RENAME_FOLDER = gql`
  mutation ($id: ID!, $name: String) {
    updateFolder(id: $id, data: { name: $name }) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const RENAME_FILE = gql`
  mutation ($id: ID!, $name: String) {
    updateFichier(id: $id, data: { name: $name }) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const MOVE_FILE = gql`
  mutation ($fichierId: ID!, $dossierId: ID!) {
    updateFichier(id: $fichierId, data: { folder: $dossierId }) {
      data {
        id
      }
    }
  }
`;

export const DELETE_UPLOAD = gql`
  mutation ($id: ID!) {
    deleteUploadFile(id: $id) {
      data {
        id
      }
    }
  }
`;

export const DELETE_FICHIER = gql`
  mutation ($id: ID!) {
    deleteFichier(id: $id) {
      data {
        id
      }
    }
  }
`;
