import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowWidth from "../../Hooks/useWindowWidth";
import ListHeader from "../../Components/Common/ListHeader";
import SearchInput from "../../Components/Common/SearchInput";
import ColoredButton from "../../Components/Common/Buttons/ColoredButton";
import TableList from "../../Components/Common/TableComponents/TableList";
import SideBar from "../../Components/Common/TableComponents/SideBar";

import { useEffect } from "react";
import { donwloadDiffusionFile } from "../../Utils/utils";
import CommandeColumn from "../../Components/Commande/CommandeColumn";

import { useNavigate } from "react-router-dom";
import { closeSideBarCard } from "../../REDUX/actions/general";
import CommandeSideBar from "../../Components/Commande/CommandeSideBar";
import { tableCommandeListData } from "../../Utils/tableStaticData";
import { GetAllCommandes } from "../../REDUX/actions/commande";
import useOnScreen from "../../Hooks/useScreen";
import _ from "lodash";
import { exporterTous } from "../../REDUX/actions/prospect";
import moment from "moment";
import { openModal } from "../../REDUX/actions/modal";
import ExportModal from "../../Components/Prospect/ExportModal";

import SelectBoxProspect from "../../Components/Prospect/SelectBoxProspect";

const CommandeView = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const screenWidth = useWindowWidth();
  const [selectedItem, setSelectedItem] = useState();
  const [page, setPage] = useState(1);
  const [selectedCommande, setSelectedCommande] = useState([]);
  const [searchCommande, setSearchCommande] = useState("");
  const { canCreateCommande } = useSelector((state) => state.roleReducer);
  const { commandesRoute } = useSelector((state) => state.organisationsReducer);

  const { selectedProspect, commandeArchived, selectedDate } = useSelector(
    (state) => state.filtersReducer
  );
  const { measureRef, isIntersecting, observer } = useOnScreen();

  const { loading, transformedArrays } = useSelector(
    (state) => state.commandeReducer
  );
  const { openSideBar } = useSelector((state) => state.genearlReducer);

  const filteredTableListData = openSideBar
    ? tableCommandeListData.filter((item) => item.alwaysVisible)
    : tableCommandeListData;

  const [sortProspect, setsortProspect] = useState({
    champs: "id",
    asc: false,
  });
  const debounceSearch = _.debounce((value) => {
    setSearchCommande(value);
  }, 500);
  useEffect(() => {
    dispatch(
      GetAllCommandes(
        page,
        selectedProspect,
        sortProspect,
        searchCommande,
        selectedDate
      )
    );
  }, [
    selectedProspect,
    page,
    commandeArchived,
    sortProspect.champs,
    sortProspect.asc,
    searchCommande,
    selectedDate,
  ]);
  useEffect(() => {
    setPage(1);
  }, [
    selectedProspect,
    sortProspect.champs,
    sortProspect.asc,
    searchCommande,
    commandeArchived,
  ]);
  useEffect(() => {
    if (isIntersecting) {
      setPage((page) => page + 1);
    }
  }, [isIntersecting, dispatch, observer]);

  return (
    <div className="container-fluid">
      <div className="d-flex flex-row align-items-center justify-content-between h50px mb-2">
        <div className={`col h100`}>
          <ListHeader
            showProspectFilter={true}
            showSocityFilter={false}
            type="commande"
            archived={commandeArchived}
          >
            <SearchInput
              placeholder={"Recherche"}
              onChange={(e) => {
                dispatch(closeSideBarCard());

                debounceSearch(e.target.value);
              }}
            />
          </ListHeader>
        </div>
        {canCreateCommande ? (
          <div className="col-1 ms-1 fitContent h100">
            <ColoredButton
              height="100"
              textButton={
                selectedCommande.length === 0
                  ? "Exporter tout"
                  : selectedCommande.length === 1
                  ? "Exporter le fichier"
                  : "Exporter les fichiers"
              }
              color="light-blue"
              loading={false}
              callback={() => {
                dispatch(
                  openModal({
                    type: "small",
                    content: (
                      <ExportModal
                        handleSubmit={(type) =>
                          dispatch(
                            exporterTous(
                              type,
                              commandeArchived
                                ? ["KO"]
                                : ["TO_VALIDATE", "VALID"],
                              selectedCommande,
                              commandeArchived,
                              (value) => {
                                const time = moment(Date.now()).format(
                                  "DD-MM-YYYY-hh-mm"
                                );
                                donwloadDiffusionFile(
                                  value,
                                  `Commande-${time}`
                                );
                              }
                            )
                          )
                        }
                      />
                    ),
                  })
                );
              }}
            />
          </div>
        ) : null}

        {canCreateCommande ? (
          <div className="col-2 ms-1 h100">
            <ColoredButton
              height="100"
              textButton={screenWidth > 600 && "Ajouter un bon de commande"}
              icon={screenWidth < 600 && "plus"}
              color="light-blue"
              callback={() => {
                navigate(`${commandesRoute}/new`);
              }}
            />
          </div>
        ) : null}
      </div>

      <div className="d-flex justify-content-between mt-1 gap-3 position-relative">
        <SelectBoxProspect
          setSelected={setSelectedCommande}
          selected={selectedCommande}
          allItems={transformedArrays}
          archived={commandeArchived}
          type={"commandes"}
        />
        <TableList
          headerItems={filteredTableListData}
          setSort={setsortProspect}
          sort={sortProspect}
          hasSelect
          type={"commande"}
          loading={loading}
        >
          {transformedArrays?.map((dataItem, index) => (
            <CommandeColumn
              key={dataItem?.id}
              dataItem={dataItem}
              filteredTableListData={filteredTableListData}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              selectedCommande={selectedCommande}
              setSelectedCommande={setSelectedCommande}
              index={index}
            />
          ))}
        </TableList>

        {openSideBar && (
          <SideBar setSelectedItem={setSelectedItem}>
            <CommandeSideBar commande={selectedItem} />
          </SideBar>
        )}
      </div>
    </div>
  );
};

export default CommandeView;
