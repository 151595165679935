import React from "react";
import { useSelector } from "react-redux";
import LightDarkContainer from "../../../../../../Common/LightDarkContainer";
import Thumbnail from "../../../../../../Common/Thumbnail";
import LightDarkText from "../../../../../../Common/LightDarkText";
import useWindowWidth from "../../../../../../../Hooks/useWindowWidth";
import {
  formatDate,
  handleFirstLastName,
} from "../../../../../../../Utils/utils";
import { showConditionalMessage } from "../../../../messageUtils";

const MessageRespondContent = ({ responseMessageId }) => {
  const screenWidth = useWindowWidth();
  const { user } = useSelector((state) => state.userReducer);

  const { expandedChatList } = useSelector((state) => state.ticketsReducer);
  const { messages } = useSelector((state) => state.messagesReducer);
  const handleScroll = (id) => {
    document.querySelector(`#messageId-${id}`).scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const messageContent = messages.find((el) => el?.id === responseMessageId);
  return messageContent ? (
    <div className="w100 mb-3">
      <LightDarkContainer
        callBack={() => handleScroll(messageContent?.id)}
        className={`radius20 t-02 px-4 py-2 pointer`}
        colorLight="white"
        colorDark="dark-grey-bg"
      >
        <div className={`row w-100 justify-content-between m-0 gap-1`}>
          <div className={` d-flex gap-2 p-0 col-9`}>
            <div>
              <Thumbnail
                rounded
                apiImg={
                  messageContent?.attributes?.sender?.data?.attributes?.avatar
                    ?.data?.attributes?.image?.data?.attributes?.url
                }
                img="user"
                size={screenWidth < 1200 ? 20 : 30}
              />
            </div>
            <div className=" directionC overflow-hidden ">
              <div className="col-12">
                <LightDarkText
                  className={`custom-ticket-font-size bolder text-start text-ellipsis`}
                >
                  {handleFirstLastName(
                    messageContent?.attributes?.sender?.data
                  )}
                </LightDarkText>
              </div>
              <LightDarkText
                className={`custom-ticket-font-size text-start}`}
                color="text-light-text"
              >
                {formatDate(messageContent?.attributes?.createdAt)}
              </LightDarkText>
            </div>
          </div>
        </div>
        {expandedChatList && (
          <div className=" directionC overflow-hidden align-items-start">
            <div className="col-12 pt-2">
              <LightDarkText
                className={`text-start custom-ticket-font-size textCrop`}
                insertHtml={
                  showConditionalMessage(
                    messageContent?.attributes,
                    user,
                    +messageContent?.attributes?.sender?.data?.id === +user.id
                  ).message
                }
              ></LightDarkText>
            </div>
          </div>
        )}
      </LightDarkContainer>
    </div>
  ) : null;
};

export default MessageRespondContent;
