import React from "react";
import { useSelector } from "react-redux";
import LightDarkText from "../../Common/LightDarkText";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LightDarkContainer from "../../Common/LightDarkContainer";
import { formatDate, handleFirstLastName } from "../../../Utils/utils";
import FolderCardInMessageIcon from "../../Cloud/FolderCardInMessageIcon";

const CloudAttachedFolders = ({}) => {
  const { ticket } = useSelector((state) => state.ticketsReducer);
  const { mode } = useSelector((state) => state.genearlReducer);
  const { organisationUrl } = useSelector(
    (state) => state.organisationsReducer
  );

  const handleScroll = (id) => {
    document.querySelector(`#messageId-${id}`)?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  return ticket.attributes.cloud_ticket_shares.data.map((share) => {
    const handleNavigate = () =>
      share.attributes.type === "gDrive" || share.attributes.type === "OneDrive"
        ? window.open(
            `${organisationUrl}/${share.attributes.type}/${share.attributes.item_id}`,
            "_blank"
          )
        : window.open(
            `${organisationUrl}/dossier/${share.attributes.item_id}`,
            "_blank"
          );

    return (
      <div key={share.id}>
        <LightDarkContainer
          colorLight="light-mode"
          colorDark="dark-mode"
          className="mt-3 p-3 radius05  scaledAttachement border"
        >
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip style={{ zIndex: 10000 }}>
                <LightDarkText
                  className={"custom-ticket-font-size bold pointer text-white"}
                >
                  {share?.attributes?.folder?.data?.attributes?.name ||
                    share.attributes.item_name}
                </LightDarkText>
              </Tooltip>
            }
          >
            <div className="text-ellipsis p-0 m-0" onClick={handleNavigate}>
              {/* file?.attributes?.file?.data?.attributes?.mime?.includes */}

              <LightDarkText
                className="text-start custom-ticket-font-size bold pointer"
                color={mode === "light" ? "text-blue-grey" : "text-light-text"}
                onclickCallback={() => {}}
              >
                <div className="xbold alignH gap-2">
                  <FolderCardInMessageIcon
                    handleNavigate={handleNavigate}
                    share={share}
                    size={25}
                    avatar={
                      share?.attributes?.folder?.data?.attributes?.avatar?.data
                        ?.attributes?.image?.data?.attributes?.url
                    }
                  />
                  {/* <CloudFolder size={25} /> */}
                  <span className="">
                    {share?.attributes?.folder?.data?.attributes?.name ||
                      share.attributes.item_name}
                  </span>
                </div>
              </LightDarkText>
            </div>
          </OverlayTrigger>
          <div className="alignH justify-content-between mt-1">
            <LightDarkText
              color={mode === "light" ? "text-light-text" : "text-white"}
              className="text-start bold f10 pointer"
              onclickCallback={() => {
                handleScroll(share?.attributes?.message?.data?.id);
              }}
            >
              {handleFirstLastName(
                share?.attributes?.message?.data?.attributes?.sender?.data
              )}
              , {formatDate(share?.attributes?.createdAt)}
            </LightDarkText>
            <LightDarkText
              className="text-end xbold f10 pointer textBlueHover text-nowrap"
              onclickCallback={() => {
                handleScroll(share?.attributes?.message?.data?.id);
              }}
            >
              voir le message
            </LightDarkText>
          </div>
        </LightDarkContainer>
      </div>
    );
  });
};

export default CloudAttachedFolders;
