import React, { useEffect, useState } from "react";
import LightDarkContainer from "../../Common/LightDarkContainer";
import useWindowWidth from "../../../Hooks/useWindowWidth";
import { useSelector } from "react-redux";
import RightParticipants from "./RightParticipants";
import RightPanelMenu from "./RightPanelMenu";
import RightAttachedFiles from "./RightAttachedFiles";
import RightInterventions from "./RightInterventions";
import RightFavorites from "./RightFavorites";
import RightLinks from "./RightLinks";
import RightEvents from "./RightEvents";
import RightInterviews from "./RightInterviews";
import "./TicketRightSection.css";

import RightInfo from "./RightInfo";
import TicketRightSectionHeader from "./TicketRightSectionHeader";

const TicketRightSection = () => {
  const [isToggle, setIsToggle] = useState("Info");
  const { ticket } = useSelector((state) => state.ticketsReducer);
  const { user } = useSelector((state) => state.userReducer);
  const { showPrivateMessages, messagesLoading } = useSelector(
    (state) => state.messagesReducer
  );
  const { mode } = useSelector((state) => state.genearlReducer);

  useEffect(() => {
    setIsToggle("Info");
  }, [showPrivateMessages]);

  return (
    <LightDarkContainer
      className={`radius05  p-3 d-flex flex-column notScrollable w-100 h100  ${
        showPrivateMessages
          ? mode === "light"
            ? "privateMessageContainerLight"
            : "privateMessageContainerDark"
          : ""
      }`}
      colorLight="white"
      colorDark="dark-grey-bg"
    >
      <TicketRightSectionHeader />

      {messagesLoading || !ticket.attributes ? null : (
        <RightPanelMenu setIsToggle={setIsToggle} isToggle={isToggle} />
      )}

      {isToggle === "Info" && <RightInfo />}
      {isToggle === "Participants" && <RightParticipants ticket={ticket} />}
      {isToggle === "Fichiers" && (
        <RightAttachedFiles ticket={ticket} user={user} />
      )}
      {isToggle === "Interventions" && <RightInterventions ticket={ticket} />}
      {isToggle === "Links" && <RightLinks ticket={ticket} />}
      {isToggle === "Interviews" && <RightInterviews ticket={ticket} />}
      {isToggle === "Events" && <RightEvents ticket={ticket} />}
      {isToggle === "Favorites" && <RightFavorites ticket={ticket} />}
    </LightDarkContainer>
  );
};

export default TicketRightSection;
