import moment from "moment";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LightDarkContainer from "../../../../../../Common/LightDarkContainer";
import LightDarkText from "../../../../../../Common/LightDarkText";
import { DeleteIcon, EditIcon } from "../../../../../../Common/LightDarkSvg";
import AttachementFiles from "../../../../../../Common/AttachementFiles";

import { openModal } from "../../../../../../../REDUX/actions/modal";
import { openConfirmationModal } from "../../../../../../../REDUX/actions/confirmationModal";

import { endPoint } from "../../../../../../../Api/endPoint";
import {
  prepareMentionOverlay,
  showMentionOverlay,
} from "../../../../messageUtils";

import defaultUserImg from "../../../../../../../Images/temp/user.png";
import MessageCardFooter from "../MessageCardFooter";
import {
  deleteMessage,
  getTicketItem,
} from "../../../../../../../REDUX/actions/tickets";
import { deleteFile } from "../../../../../../../REDUX/actions/cloud";
import { useNavigate } from "react-router-dom";
import UpdateMessage from "../../../../UpdateMessage";
import TooltipActions from "../../../../../../Common/Tooltip/TooltipActions";
import InfoQuestion from "../../../../../../Common/InfoQuestion";

const InternMessageContent = ({
  ticketId,
  internMessage,
  internMessageContentToShow,
  internMeSending,
  setInternMeSending,
  showInternOriginal,
  setShowInternOriginal,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { ticket } = useSelector((state) => state.ticketsReducer);

  const [userData, setUserData] = useState();
  const [mentionTag, setMentionTag] = useState();

  const { languages } = useSelector((state) => state.sharedDataReducer);
  const [showTooltip, setshowTooltip] = useState(false);

  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );

  useEffect(() => {
    const messageContent = document?.querySelector(
      `.message-content-${internMessage?.id}`
    );
    prepareMentionOverlay(
      messageContent,
      setMentionTag,
      setUserData,
      internMessage
    );
  }, [internMessage]);

  useEffect(() => {
    if ((mentionTag, userData)) {
      showMentionOverlay(mentionTag, userData, defaultUserImg);
    }
  }, [mentionTag, userData, internMessage]);

  const addClassToElement = (html, elementTag, className) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const element = doc.querySelector(elementTag);
    if (element) {
      element.classList.add(className.class1, className.class2);
    }
    return doc.documentElement.outerHTML;
  };

  const modifiedHtmlContent = addClassToElement(
    internMessageContentToShow.message || "",
    internMessageContentToShow.message?.substring(1, 3).startsWith("h")
      ? internMessageContentToShow.message?.substring(1, 3)
      : "p",
    { class1: "message-text", class2: "custom-ticket-font-size" }
  );

  const handleDeleteMessage = (
    messageId,
    message2,
    message,
    btnMessage,
    files,
    is_internal,
    parent_id
  ) => {
    dispatch(
      openConfirmationModal({
        callBack: async () => {
          dispatch(deleteMessage(messageId, is_internal, parent_id));
          for (let i = 0; i < files?.length; i++) {
            dispatch(deleteFile(files[i])).then(() => {
              i === files?.length - 1 &&
                dispatch(getTicketItem(ticketId, navigate));
            });
          }
        },
        icon: "archive",
        message: message || "Voulez vous supprimer la note",
        message2: message2,
        confirmButtonText: btnMessage || "Supprimer le note",
      })
    );
  };

  var a = moment(new Date());
  var b = moment(internMessage?.attributes?.createdAt);
  const diff = a.diff(b, "hours");

  const tooltipData = [
    {
      id: 1,
      text: "Modifier",
      icon: <EditIcon className="me-2" disabled={diff >= 24} />,
      action: () => {
        setshowTooltip(false);
        dispatch(
          openModal({
            type: "small",
            content: <UpdateMessage message={internMessage} />,
          })
        );
      },
      toolTipText:
        "Il s'est écoulé plus de 24 heures depuis que vous avez créé votre message, par souci de conformité, il n est désormais plus possible de le modifier ou de le supprimer.",
      disabled: diff >= 24,
      condition: internMeSending && !ticket.attributes.archived,
    },
    {
      id: 2,
      text: "Supprimer",
      icon: <DeleteIcon className="me-2" disabled={diff >= 24} />,
      action: () => {
        setshowTooltip(false);
        handleDeleteMessage(
          internMessage.id,
          `#${internMessage.id} ?`,
          "Êtes-vous sur de supprimer cette note",
          "Je confirme la suppression",
          internMessage?.attributes?.fichiers?.data,
          internMessage?.attributes?.is_internal,
          internMessage?.attributes?.internal_response_to?.data?.id
        );
      },
      toolTipText:
        "Il s'est écoulé plus de 24 heures depuis que vous avez créé votre message, par souci de conformité, il n est désormais plus possible de le modifier ou de le supprimer.",
      disabled: diff >= 24,
      condition: internMeSending && !ticket.attributes.archived,
    },
  ];

  return (
    <div className="w-100 position-relative">
      {languages.length && !internMeSending ? (
        internMessageContentToShow.translated ? (
          <div className="d-flex gap-2 justify-content-end ">
            <LightDarkText
              className="f10 pointer mb-1"
              color={mode === "light" ? "text-blue-grey" : "text-light-text"}
              onclickCallback={() => setShowInternOriginal(!showInternOriginal)}
            >
              {showInternOriginal
                ? "Afficher la traduction"
                : "Afficher l'original"}
            </LightDarkText>
          </div>
        ) : (
          <div className="d-flex gap-2 justify-content-end ">
            <LightDarkText
              className="f10 mb-1"
              color={mode === "light" ? "text-blue-grey" : "text-light-text"}
            >
              Traduction indisponible
            </LightDarkText>
          </div>
        )
      ) : null}

      <div>
        <div className="d-flex gap-3">
          <div className="messageLeft">
            <LightDarkContainer
              colorLight={internMeSending ? "lighter-red" : "lighter-red"}
              colorDark={internMeSending ? "light-red" : "light-red"}
              className={
                "px-3 py-2 messageCardMessage internMessageCard position-relative radius05"
              }
            >
              {/* <div className="alignH justify-content-end me-1"> */}
              {!internMeSending ? (
                <InfoQuestion
                  position="absolute"
                  top={8}
                  right={8}
                  small
                  htmlMessage={`Cette réponse privée est visible uniquement par les superviseurs
              et opérateurs de ${selectedOrganisation?.attributes?.name}`}
                />
              ) : null}
              {/* </div> */}
              {internMeSending && !ticket.attributes.archived && (
                <div className="position-absolute text-end tootlip-ticket">
                  <TooltipActions
                    isVertical
                    setshowTooltip={setshowTooltip}
                    showTooltip={showTooltip}
                    tooltipData={tooltipData}
                  />
                </div>
              )}
              <div
                className={`d-flex justify-content-between ${
                  internMessage?.attributes?.fichiers?.data?.length &&
                  internMessageContentToShow.message &&
                  "mb-3"
                }`}
              >
                <LightDarkText
                  className={`bold text-start break-word d-flex w-100 justify-content-between`}
                  color={mode === "light" ? "text-red" : "text-white"}
                >
                  <span
                    className={`message-content-${internMessage?.id} text-start f12 p-nomb`}
                    dangerouslySetInnerHTML={{
                      __html: modifiedHtmlContent,
                    }}
                    style={{
                      "--username": `${userData?.attributes?.firstName || ""} ${
                        userData?.attributes?.last || ""
                      }`,
                      "--avatar": `${
                        endPoint +
                        userData?.attributes?.avatar?.data?.attributes?.image
                          ?.data?.attributes?.url
                      }`,
                    }}
                  ></span>
                </LightDarkText>
              </div>
              <AttachementFiles message={internMessage} />
            </LightDarkContainer>
          </div>
        </div>
        <div style={{ height: "10px" }}>
          <MessageCardFooter
            showFullDate
            note={true}
            message={internMessage}
            meSending={internMeSending}
            setMeSending={setInternMeSending}
          />
        </div>
      </div>
    </div>
  );
};

export default InternMessageContent;
