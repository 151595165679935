import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LightDarkText from "../../Common/LightDarkText";

const ElementName = ({ name, callBack = () => {} }) => {
  return (
    <OverlayTrigger
      role="button"
      placement="bottom"
      overlay={<Tooltip>{name}</Tooltip>}
    >
      <div
        role="button"
        onClick={callBack}
        className={"me-2 custom-font-size bold text-start  text-ellipsis w-100"}
      >
        <LightDarkText className={"underline-hover"}>{name}</LightDarkText>
      </div>
    </OverlayTrigger>
  );
};

export default ElementName;
