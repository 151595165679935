import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { initCurrentUser } from "../../../REDUX/actions/user";

const Auth = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initCurrentUser());
  }, []);

  return <>{children}</>;
};

export default Auth;
