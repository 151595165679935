import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { pdfjs } from "react-pdf";
import { donwloadFile, fileUrl } from "../../Utils/utils";
import LightDarkText from "./LightDarkText";
import { openModal } from "../../REDUX/actions/modal";
import PreviewModal from "./PreviewModal";
import { imageEndPoint } from "../../Api/endPoint";
import { extractPreviewData } from "../Cloud/cloudUtils";
import PdfCard from "../Cloud/PdfCard";
import ConditionalFileIcon from "../Cloud/ConditionalFileIcon";

const AttachementFiles = ({ message }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);

  return message.attributes?.fichiers?.data.length ? (
    <div className="w-100 mt-2">
      <div className="d-flex inMessageAttachContainer flex-wrap gap-3">
        {message.attributes?.fichiers?.data.map((file, index) => (
          <div
            key={index}
            className="pointer attachFileInMessagewithText"
            onClick={() =>
              file?.attributes?.file?.data?.attributes?.mime?.includes("pdf") ||
              file?.attributes?.file?.data?.attributes?.mime?.includes("image")
                ? dispatch(
                    openModal({
                      type: "pdfFile",
                      content: (
                        <PreviewModal fileData={extractPreviewData(file)} />
                      ),
                    })
                  )
                : donwloadFile(file)
            }
          >
            {file?.attributes?.file?.data?.attributes?.mime?.includes(
              "image"
            ) ? (
              <div
                className="attachFileInMessage alignC radius05"
                style={{ overflow: "hidden" }}
              >
                <img
                  src={
                    imageEndPoint +
                    file?.attributes?.file?.data?.attributes?.url
                  }
                  alt=""
                  loading="lazy"
                  className="w-100 h-100 ob-cover"
                />
              </div>
            ) : file?.attributes?.file?.data?.attributes?.mime?.includes(
                "pdf"
              ) ? (
              <div
                className="attachFileInMessage t-02 radius05"
                style={{ overflow: "hidden" }}
              >
                <PdfCard
                  url={fileUrl(file?.attributes?.file?.data?.attributes?.url)}
                />
              </div>
            ) : (
              <ConditionalFileIcon
                mime={file?.attributes?.file?.data?.attributes?.mime}
              />
            )}

            <LightDarkText
              color={mode === "light" ? "text-dark-blue" : "text-light-text"}
              className={"f10 mt-1 bold text-start text-decoration-underline"}
            >
              {file?.attributes?.file?.data?.attributes?.name}
            </LightDarkText>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};
export default AttachementFiles;
