import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ColoredButton from "../../Common/Buttons/ColoredButton";
import LighDarkMultiChoice from "../../Common/LighDarkMultiChoice";
import LightDarkText from "../../Common/LightDarkText";
import { CloseIcon } from "../../Common/LightDarkSvg";
import LightDarkInput from "../../Common/LightDarkInput";
import Uploader from "../../Common/Uploader";

import { handleFirstLastName } from "../../../Utils/utils";

import useWindowWidth from "../../../Hooks/useWindowWidth";

import { closeModal } from "../../../REDUX/actions/modal";
import {
  createCalendar,
  updateCalendar,
} from "../../../REDUX/actions/calendars";

const CalendarForm = ({ isNew, shareCalendar, calendar }) => {
  const dispatch = useDispatch();

  const screenWidth = useWindowWidth();

  const { user } = useSelector((state) => state.userReducer);
  const { canShareTo, societes } = useSelector(
    (state) => state.sharedDataReducer
  );
  const { mode } = useSelector((state) => state.genearlReducer);

  const [avatar, setAvatar] = useState();
  const [agendaInputs, setAgendaInputs] = useState(
    isNew
      ? {
          name: "",
          societe: [],
          sharedUsers: [],
        }
      : {
          ...calendar?.attributes,
          name: calendar?.attributes?.name,
          societe: calendar?.attributes?.societe?.data
            ? [
                {
                  label: calendar?.attributes?.societe?.data?.attributes?.name,
                  value: calendar?.attributes?.societe?.data?.id,
                  avatar:
                    calendar?.attributes?.societe?.data?.attributes?.avatar
                      ?.data?.attributes?.image?.data?.attributes?.url,
                  societyRelatedUsers: [],
                },
              ]
            : [],
          sharedUsers: calendar?.attributes?.sharedUsers?.data
            ? calendar?.attributes?.sharedUsers?.data?.map((el) => ({
                label: handleFirstLastName(el),
                value: el.id,
              }))
            : [],
        }
  );
  const currentImage = agendaInputs.societe[0]?.value
    ? agendaInputs?.societe[0]?.avatar
    : calendar?.attributes?.societe?.data
    ? calendar?.attributes?.societe?.data?.attributes?.avatar?.data?.attributes
        ?.image?.data?.attributes?.url
    : calendar?.attributes?.avatar?.data?.attributes?.image?.data?.attributes
        ?.url;

  useEffect(() => {
    const selectedSociety = societes?.filter(
      (el) => +el?.value === +agendaInputs.societe[0]?.value
    );
    if (selectedSociety?.length === 1) {
      const societyUsers = selectedSociety[0]?.societyRelatedUsers
        ?.filter((el) => el.value !== user.id)
        ?.map((el) => el.value);

      setAgendaInputs({
        ...agendaInputs,
        name:
          isNew && agendaInputs?.societe[0]?.value
            ? agendaInputs?.societe[0]?.label
            : calendar?.attributes?.name
            ? calendar?.attributes?.name
            : "",
        sharedUsers: canShareTo.filter((el) => societyUsers.includes(el.value)),
      });
    }
  }, [agendaInputs?.societe]);

  const handleSubmit = () => {
    if (isNew) {
      dispatch(
        createCalendar(
          {
            ...agendaInputs,
            societe: agendaInputs.societe[0]?.value
              ? agendaInputs.societe[0]?.value
              : null,
            sharedUsers: agendaInputs.sharedUsers.map((user) => user?.value),
          },
          avatar
        )
      );
    } else {
      dispatch(
        updateCalendar({
          calendar: {
            ...agendaInputs,
            id: calendar.id,
            societe: agendaInputs.societe[0]?.value
              ? agendaInputs.societe[0]?.value
              : null,
            sharedUsers: agendaInputs.sharedUsers.map((user) => user?.value),
          },
          avatar,
          fetchNewUpdatedEvent: false,
        })
      );
    }
    dispatch(closeModal());
  };

  return (
    <div
      className={`${mode === "light" ? "bg-white" : "bg-dark-mode"}
        p-4 radius05 d-flex flex-column justify-content-between`}
      style={{
        width: `${screenWidth > 600 ? "600px" : "calc(100vw - 50px)"}`,
        // minHeight: "max-content",
        height: `${shareCalendar ? "475px" : "540px"}`,
      }}
      onSubmit={(e) => e.preventDefault()}
    >
      <div>
        <div className="d-flex justify-content-between">
          <LightDarkText className="custom-title-font-size bold w-100">
            {isNew
              ? "Créer un agenda"
              : shareCalendar
              ? "Partager l'agenda"
              : "Modifier l'agenda"}
          </LightDarkText>
          <div
            className="d-flex justify-content-end mb-2"
            onClick={() => dispatch(closeModal())}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="row my-4">
          {!shareCalendar && (
            <div className="col-4">
              <Uploader
                setState={setAvatar}
                state={avatar}
                id="image"
                name="image"
                placeholder="Ajouter l'icone de l'agenda"
                squareUploader
                defaultImage={currentImage}
              />
            </div>
          )}
          <div className={`${!shareCalendar ? "col-8" : "col-12"}`}>
            {!shareCalendar && (
              <LightDarkInput
                label="Nom de l'agenda"
                placeholder="Nom de l'agenda"
                type="text"
                totalInputs={agendaInputs}
                setValue={setAgendaInputs}
                champs="name"
                required
                calendarForm
              />
            )}

            <div className="my-4">
              <LighDarkMultiChoice
                label="Partager l'agenda avec une société"
                placeholder="Ajouter une société"
                data={societes}
                totalInputs={agendaInputs}
                setValue={setAgendaInputs}
                multiple={false}
                champs="societe"
                calendarForm
              />
            </div>
          </div>

          <div className="col-12">
            <LighDarkMultiChoice
              label="Ajouter des utilisateurs"
              data={canShareTo}
              totalInputs={agendaInputs}
              setValue={setAgendaInputs}
              champs="sharedUsers"
              placeholder="Sélectionner des utilisateurs"
            />
          </div>
        </div>
      </div>
      {/*  */}
      <div>
        {!shareCalendar && (
          <div className={`row`}>
            <LightDarkText
              className={"text-start bold custom-ticket-font-size mb-2"}
            >
              <span className="text-red">*</span>Champs obligatoires
            </LightDarkText>
          </div>
        )}
        <div className="w-100 row mt-3 justify-content-center">
          <div className={isNew ? "col-5" : "col-6"}>
            <ColoredButton
              disabled={!agendaInputs?.name}
              textButton={
                isNew ? "Créer l'agenda" : "Enregistrer les modifications"
              }
              color="light-blue"
              callback={() => handleSubmit()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarForm;
