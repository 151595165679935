import React from "react";
import { CloudFolder } from "../Common/LightDarkSvg";
import gDriveLogo from "../../Images/apiIcons/gDrive.svg";
import oneDriveLogo from "../../Images/apiIcons/oneDrive.svg";
import SocietyAvatar from "../Societies/NewSociety/SocietyAvatar";

const FolderCardInMessageIcon = ({
  handleNavigate,
  share,
  size = 40,
  avatar,
}) => {
  return (
    <div
      className="d-flex align-items-start justify-content-between "
      role="button"
    >
      <div className="pointer" onClick={handleNavigate}>
        {share.attributes.type === "gDrive" && (
          <img className="" width={size} height={size} src={gDriveLogo} />
        )}
        {share.attributes.type === "oneDrive" && (
          <img className="" width={size} height={size} src={oneDriveLogo} />
        )}
        {share.attributes.type !== "oneDrive" &&
          share.attributes.type !== "gDrive" &&
          (avatar ? (
            <SocietyAvatar width={size} height={size} imageUrl={avatar} />
          ) : (
            <CloudFolder width={size} size={size} />
          ))}
      </div>
    </div>
  );
};

export default FolderCardInMessageIcon;
