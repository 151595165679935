import { getFileSize } from "../../Utils/utils";

const initialState = {
  fichiers: [],
  dossiers: [],
  sharedFolders: [],
  sharedFiles: [],
  ticketsAttchment: [],
  totalTicketsAttchment: 0,
  cloudLoading: false,
  foldersSelect: [],
  folderItem: null,
  sharingFolder: [],
  sharingFiles: [],
  refetchCloud: true,
  ticketsAttchmentLoading: false,
};

const cloudReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case "LOADING_CLOUD":
      return { ...state, cloudLoading: payload || true };
    case "DELETE_FICHIER":
      return {
        ...state,
        cloudLoading: false,
        fichiers: state.fichiers.filter((el) => el.id !== payload.id),
      };
    case "DELETE_FICHIER_INFOLDER":
      const newFiles = state.folderItem.attributes.fichiers.data.filter(
        (el) => el.id !== payload.id
      );

      return {
        ...state,
        cloudLoading: false,
        folderItem: {
          ...state.folderItem,
          attributes: {
            ...state.folderItem.attributes,
            fichiers: {
              ...state.folderItem.attributes.fichier,
              data: newFiles,
            },
          },
        },
      };
    case "FETCH_FOLDER_ITEM":
      let files = payload.attributes?.fichiers?.data?.map((fichier) => {
        let getSize = async (f) => {
          return await getFileSize(f);
        };
        return {
          ...fichier,
          attributes: {
            ...fichier.attributes,

            fileName: fichier?.attributes?.file?.data?.attributes?.name,
            fileSize: getSize(fichier),
          },
        };
      });
      return {
        ...state,
        cloudLoading: false,
        folderItem: {
          ...payload,
          attributes: {
            ...payload.attributes,
            fichiers: { ...payload.attributes?.fichiers, data: files },
          },
        },
      };

    case "FETCH_CLOUD":
      const foldersSelect = payload.attributes.folders.data.map((folder) => {
        return { value: folder.id, label: folder.attributes.name };
      });

      return {
        ...state,
        refetchCloud: false,
        cloudLoading: false,
        dossiers: payload.attributes.folders.data,
        foldersSelect: foldersSelect,
      };
    case "FETCH_CLOUD_FILES":
      return {
        ...state,
        refetchCloud: false,
        cloudLoading: false,
        fichiers: payload.attributes.fichiers.data,
      };
    case "RESET_ATTACHMENTS":
      return {
        ...state,
        ticketsAttchmentLoading: false,
        totalTicketsAttchment: 0,
        ticketsAttchment: [],
      };
    case "RESET_ORGANISATION":
      return initialState;
    case "FETCH_ATTACHMENTS_LOADING":
      return { ...state, ticketsAttchmentLoading: true };
    case "FETCH_ATTACHMENTS":
      const ticketsAttchment = payload.data.map((fichier) => ({
        ...fichier,
        attributes: {
          ...fichier.attributes,
          ticketId: fichier.attributes.ticket?.data?.id,
          ticketName: fichier.attributes.ticket?.data?.attributes?.name,
          fileName: fichier.attributes.file?.data?.attributes?.name,
          fileSize: 0,
        },
      }));

      return {
        ...state,
        ticketsAttchment: [
          ...(state?.ticketsAttchment || []),
          ...ticketsAttchment,
        ],
        ticketsLoading: false,
        ticketsAttchmentLoading: false,
        totalTicketsAttchment: payload?.meta?.pagination?.total || 0,
      };

    case "FETCH_SHARED_FOLDERS":
      return { ...state, sharedFolders: payload.data, cloudLoading: false };
    case "FETCH_SHARED_FILES":
      return { ...state, sharedFiles: payload, cloudLoading: false };

    case "FETCH_NEW_FOLDER_FILE": {
      let getSize = async (f) => {
        return await getFileSize(f);
      };
      const newFile = {
        ...payload,
        attributes: {
          ...payload.attributes,

          fileName: payload?.attributes?.file?.data?.attributes?.name,
          fileSize: getSize(payload),
        },
      };
      const updatedFolder = state?.folderItem;
      updatedFolder.attributes.fichiers.data = [
        ...updatedFolder.attributes?.fichiers?.data,
        newFile,
      ];

      return { ...state, folderItem: updatedFolder };
    }
    default:
      return state;
  }
};

export default cloudReducer;
