import React from "react";
import LightDarkContainer from "../../Common/LightDarkContainer";
import LightDarkText from "../../Common/LightDarkText";
import { CloudFolder, DeleteIcon } from "../../Common/LightDarkSvg";
import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ShareInTicketItem = ({ share, setSharesToDelete }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const { ticketRoute } = useSelector((state) => state.organisationsReducer);

  return (
    <div>
      <LightDarkContainer
        colorLight="light-mode"
        colorDark="dark-mode"
        className=" p-3 radius05 alignH justify-content-between   border position-relative"
      >
        <div className="text-ellipsis p-0 m-0">
          <LightDarkText
            className="text-start custom-ticket-font-size bold pointer"
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            <div className="xbold alignH gap-2">
              <CloudFolder size={25} />{" "}
              <span
                className=""
                onClick={() =>
                  window.open(
                    `${ticketRoute}/${share.attributes.ticket.data?.id}`
                  )
                }
              >
                #{share.attributes.ticket?.data?.id}{" "}
                {share?.attributes?.ticket?.data?.attributes?.name}
              </span>
            </div>
          </LightDarkText>
        </div>
        <div
          className="stopShareFolderTicket"
          onClick={() => {
            setSharesToDelete((prev) => [...prev, share.id]);
          }}
        >
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip style={{ zIndex: 10000 }}>
                <LightDarkText className="custom-ticket-font-size py-1 text-white text-start">
                  Arrêter le partage
                </LightDarkText>
              </Tooltip>
            }
          >
            <div>
              <DeleteIcon size={25} className={"scaledAttachement"} />
            </div>
          </OverlayTrigger>
        </div>
      </LightDarkContainer>
    </div>
  );
};

export default ShareInTicketItem;
