import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Document, Page } from "react-pdf";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { deleteFile } from "../../REDUX/actions/cloud";
import { openConfirmationModal } from "../../REDUX/actions/confirmationModal";
import { openModal } from "../../REDUX/actions/modal";
import {
  fileUrl,
  donwloadFile,
  fileCompleteUrl,
  formatDate,
  fileExtention,
  handleFirstLastName,
} from "../../Utils/utils";
import {
  DeleteIcon,
  Download,
  EditIcon,
  EyeIcon,
  HorzontalLines,
  ShareIcon,
  SharedFolder,
  Tickets,
} from "../Common/LightDarkSvg";
import LightDarkText from "../Common/LightDarkText";

import PreviewModal from "../Common/PreviewModal";
import { imageEndPoint } from "../../Api/endPoint";
import RenameCloudItem from "./RenameCloudItem";
import ShareFile from "./ShareFile";
import SettingTooltip from "../Common/Tooltip/SettingTooltip";
import TooltipActions from "../Common/Tooltip/TooltipActions";
import { extractPreviewData } from "./cloudUtils";
import PdfCard from "./PdfCard";
import ConditionalFileIcon from "./ConditionalFileIcon";

const AttachementCardCard = ({ fichier, inFolder, shared, measureRef }) => {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { user } = useSelector((state) => state.userReducer);
  const { isSupervisor } = useSelector((state) => state.roleReducer);
  const { ticketRoute } = useSelector((state) => state.organisationsReducer);
  const [showTooltip, setshowTooltip] = useState(false);
  const [tooltipCoordinates, setTooltipCoordinates] = useState({ x: 0, y: 0 });

  const toolTipRef = useRef();

  const handleDeleteFile = () => {
    dispatch(
      openConfirmationModal({
        callBack: () => dispatch(deleteFile(fichier, inFolder)),
        icon: "archive",
        message: "Voulez vous vraiment Supprimer le fichier",
        message2: `#${fichier.id} ${fichier?.attributes?.file?.data?.attributes?.name}`,
        confirmButtonText: "Supprimer le fichier",
      })
    );
  };

  const handleResize = () => {
    setTooltipCoordinates({
      x: toolTipRef?.current?.getBoundingClientRect().x + 12,
      y: toolTipRef?.current?.getBoundingClientRect().y + 16,
    });
  };

  const openPreview = () => {
    dispatch(
      openModal({
        type: "pdfFile",
        content: <PreviewModal fileData={extractPreviewData(fichier)} />,
      })
    );
  };

  const closeTooltip = () => {
    setshowTooltip(false);
  };
  useEffect(() => {
    window?.addEventListener("resize", handleResize);
    document
      ?.getElementById("fileBody")
      ?.addEventListener("scroll", closeTooltip);
    return () => {
      window?.removeEventListener("resize", handleResize);
      document
        ?.getElementById("fileBody")
        ?.removeEventListener("scroll", closeTooltip);
    };
  }, []);

  const tooltipData = [
    {
      id: 1,
      text: "Voir",
      icon: <EyeIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        openPreview();
      },
      condition:
        fichier?.attributes?.file?.data?.attributes?.mime?.includes("image") ||
        fichier?.attributes?.file?.data?.attributes?.mime?.includes("pdf"),
    },

    {
      id: 3,
      text: "Télécharger",
      icon: <Download className="me-2" size={16} />,
      action: () => {
        setshowTooltip(false);
        donwloadFile(fichier);
      },
      condition: true,
    },
    {
      id: 4,
      text: "Voir le ticket",
      icon: <Tickets className="me-2" size={16} darkColor="#354074" />,
      action: () => {
        setshowTooltip(false);
        window.open(`${ticketRoute}/${fichier.attributes.ticket.data.id}`);
      },
      condition: true,
    },
    // {
    //   id: 5,
    //   text: "Renommer",
    //   icon: <EditIcon className="me-2" />,
    //   action: () => {
    //     setshowTooltip(false);
    //     dispatch(
    //       openModal({
    //         type: "small",
    //         content: (
    //           <RenameCloudItem
    //             id={fichier.id}
    //             name={
    //               fichier?.attributes?.name
    //                 ? fichier?.attributes?.name
    //                 : fichier?.attributes.file.data?.attributes?.name?.replace(
    //                     `.${fileExtention(fichier)}`,
    //                     ""
    //                   )
    //             }
    //             isFile={true}
    //             folderId={fichier?.attributes?.folder?.data?.id}
    //           />
    //         ),
    //       })
    //     );
    //   },
    //   condition:
    //     user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud?.data
    //       ?.id === fichier?.attributes?.cloud?.data?.id ||
    //     user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud?.data
    //       ?.id ===
    //       fichier?.attributes?.folder?.data?.attributes?.cloud?.data?.id ||
    //     isSupervisor,
    // },
    // {
    //   id: 6,
    //   text: "Supprimer",
    //   icon: <DeleteIcon className="me-2" />,
    //   action: () => {
    //     setshowTooltip(false);
    //     handleDeleteFile();
    //   },
    //   condition:
    //     user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud?.data
    //       ?.id === fichier?.attributes?.cloud?.data?.id ||
    //     user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud?.data
    //       ?.id ===
    //       fichier?.attributes?.folder?.data?.attributes?.cloud?.data?.id ||
    //     isSupervisor,
    // },
  ];

  return (
    <div
      className="pointer pb-5 col-2"
      ref={measureRef}
      onClick={() => {
        fichier?.attributes?.file?.data?.attributes?.mime?.includes("image") ||
        fichier?.attributes?.file?.data?.attributes?.mime?.includes("pdf")
          ? openPreview()
          : donwloadFile(fichier);
      }}
    >
      {fichier?.attributes?.file?.data?.attributes?.mime?.includes("image") ? (
        <div
          className={`cloudFileCard t-02 alignC radius05 ${
            mode === "light" ? "bg-white" : "bg-dark-grey-bg"
          }`}
          style={{ overflow: "hidden" }}
        >
          <img
            src={
              imageEndPoint + fichier?.attributes?.file?.data?.attributes?.url
            }
            alt=""
            loading="lazy"
            className="w-100 h-100 ob-cover"
          />
        </div>
      ) : fichier?.attributes?.file?.data?.attributes?.mime?.includes("pdf") ? (
        <div
          className="cloudFileCard t-02 radius05"
          style={{ overflow: "hidden" }}
          ref={measureRef}
        >
          <PdfCard
            url={fileUrl(fichier?.attributes?.file?.data?.attributes?.url)}
          />
        </div>
      ) : (
        <ConditionalFileIcon
          mime={fichier?.attributes?.file?.data?.attributes?.mime}
        />
      )}
      <div className="d-flex mt-3 align-items-start justify-content-between">
        <div style={{ maxWidth: "70%" }}>
          <LightDarkText
            className={
              "break-word custom-font-size bold text-start text-ellipsis"
            }
          >
            {fichier?.attributes?.name
              ? `${fichier?.attributes?.name}.${fileExtention(fichier)}`
              : fichier?.attributes.file.data?.attributes.name}
          </LightDarkText>
          <LightDarkText
            className="f10 text-start mt-1"
            color="text-light-text"
          >
            {formatDate(fichier?.attributes?.file?.data?.attributes?.createdAt)}
          </LightDarkText>
        </div>
        <div
          className="d-flex align-items-center pointer position-relative ms-3"
          onClick={(e) => {
            setTooltipCoordinates({
              x: toolTipRef?.current?.getBoundingClientRect().x + 10,
              y: toolTipRef?.current?.getBoundingClientRect().y + 16,
            });
            e.stopPropagation();
            setshowTooltip(!showTooltip);
          }}
          ref={toolTipRef}
        >
          <TooltipActions
            setshowTooltip={setshowTooltip}
            showTooltip={showTooltip}
            tooltipData={tooltipData}
            isHorizontal
          />
          {shared ? (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id={`tooltip-${fichier?.id}`}>
                  {user?.attributes?.user_org_permissions?.data[0]?.attributes
                    ?.cloud?.data?.id === fichier?.attributes?.cloud?.data?.id
                    ? `Partagé avec ${fichier?.attributes?.sharedUsers?.data?.map(
                        (el) => ` ${handleFirstLastName(el)}`
                      )} `
                    : `Partagé par 
                  ${handleFirstLastName(
                    fichier?.attributes?.cloud?.data?.attributes?.user?.data
                  )}`}
                </Tooltip>
              }
            >
              <button className="pe-0 bg-transparent border-0 outline-none">
                <SharedFolder color="#354074" />
              </button>
            </OverlayTrigger>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AttachementCardCard;
