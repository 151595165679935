import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { closeModal } from "../../../REDUX/actions/modal";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import NotificationManagement from "../../Layout/Notification/NotificationManagement";
import UserInfoForm from "./UserInfoForm";
import UserSettingsForm from "./UserSettingsForm";
import UserFormHeader from "./UserFormHeader";
import UserOpStats from "./UserOpStats";
import {
  getUserForm,
  handleInviteUser,
  handleNewUser,
  handleUpdateuser,
} from "../../../REDUX/actions/user";
import UserPermissionForm from "./UserPermissionForm";
import {
  checkButtonDisabledUser,
  generateTextButtonUser,
  notificationsFields,
  permissionFields,
  userFields,
} from "./userFormUtils";
import ServicesList from "../../MarketPlace/ServicesList";
import { getDevices } from "../../../REDUX/actions/sharedData";
import LightDarkText from "../../Common/LightDarkText";
import SocietyFormPlaceHolder from "../../PlaceHolders/Society/SocietyFormPlaceHolder";
import { closeSideBar } from "../../../REDUX/actions/sideBar";

const UserForm = ({ isNew, userId, isOp, afterSubmitCallback, societyId }) => {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);

  const { userAdditionalFields } = useSelector(
    (state) => state.organisationsReducer
  );

  const { societes } = useSelector((state) => state.sharedDataReducer);

  const { userFormLoading, userForm, user } = useSelector(
    (state) => state.userReducer
  );

  const [isToggle, setIsToggle] = useState("Coordonnées");
  const [settingValues, setSettingValues] = useState({});
  const [permissionValues, setpermissionValues] = useState({});
  const [emailInfo, setemailInfo] = useState({
    notValid: false,
    alreadyUsed: false,
    userFound: null,
    inMyOrg: null,
  });
  const [avatar, setAvatar] = useState();
  const [clientInputs, setclientInputs] = useState(null);

  const [meEditing, setMeEditing] = useState(false);

  useEffect(() => {
    if (+user.id === +userId) {
      setMeEditing(true);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      dispatch(getUserForm(userId));
    }
  }, [userId]);

  const currentImage =
    userForm?.attributes?.avatar?.data?.attributes?.image?.data?.attributes
      ?.url;

  const handlePreviousTab = () => {
    switch (isToggle) {
      case "personnalisation":
        setIsToggle("Coordonnées");
        break;
      case "Notifications":
        setIsToggle("personnalisation");
        break;
      case "Permissions":
        setIsToggle("Notifications");
        break;
      default:
        break;
    }
  };

  const handleSubmitClient = async () => {
    if (isToggle !== "Permissions" && !meEditing) {
      switch (isToggle) {
        case "Coordonnées":
          setIsToggle("personnalisation");
          break;
        case "personnalisation":
          setIsToggle("Notifications");
          break;
        case "Notifications":
          setIsToggle("Permissions");
          break;
        default:
          break;
      }
    } else {
      if (emailInfo.alreadyUsed) {
        dispatch(
          handleInviteUser(
            {
              id: emailInfo?.userFound.id,
              isOp,
              clientInputs,
              notificationsSetting: settingValues,
              permissionValues: permissionValues,
            },
            afterSubmitCallback
          )
        );
      } else if (isNew) {
        dispatch(
          handleNewUser(
            {
              isOp,
              clientInputs,
              avatar,
              notificationsSetting: settingValues,
              permissionValues: permissionValues,
            },
            afterSubmitCallback
          )
        );
      } else {
        dispatch(
          handleUpdateuser(
            {
              isOp,
              clientInputs,
              avatar,
              notificationsSetting: settingValues,
              permissionValues: permissionValues,
              id: userForm?.id,
              userOrgId: userForm.attributes.user_org_permissions.data[0].id,
              notifSettingId:
                userForm.attributes.user_org_permissions.data[0].attributes
                  .notification_setting.data.id,
            },
            afterSubmitCallback,
            userForm
          )
        );
      }
      dispatch(closeSideBar());
    }
  };

  useEffect(() => {
    if (userForm || isNew) {
      setclientInputs(userFields(isNew, userForm, isOp));
      setSettingValues(notificationsFields(userForm));
    }
  }, [dispatch, userForm]);
  useEffect(() => {
    if ((clientInputs && userForm) || isNew) {
      setpermissionValues(
        permissionFields(
          clientInputs?.role[0]?.value,
          userForm,
          userForm?.attributes?.user_org_permissions?.data[0]?.attributes
            ?.role !== clientInputs?.role[0]?.value
        )
      );
    }
  }, [dispatch, clientInputs?.role, userForm]);

  useEffect(() => {
    if (isNew && societyId && clientInputs?.role) {
      if (clientInputs?.role[0]?.value === 2) {
        setclientInputs({
          ...clientInputs,
          SocietiesUnderResponsability: societes.filter(
            (el) => +el.value === +societyId
          ),
          workIn: [],
        });
      } else if (clientInputs?.role[0]?.value > 2) {
        setclientInputs({
          ...clientInputs,
          workIn: societes.filter((el) => +el.value === +societyId),
          SocietiesUnderResponsability: [],
        });
      } else {
        setclientInputs({
          ...clientInputs,
          workIn: [],
          SocietiesUnderResponsability: [],
        });
      }
    }
  }, [clientInputs?.role[0]?.value, societyId]);

  useEffect(() => {
    dispatch(getDevices(clientInputs?.prestations));
  }, [clientInputs?.prestations, dispatch, userForm]);

  return userFormLoading || !clientInputs ? (
    <SocietyFormPlaceHolder />
  ) : (
    <div
      className={`${
        mode === "light" ? "bg-white" : "bg-dark-mode"
      } radius05 scrollable h100 d-flex flex-column justify-content-between`}
    >
      <UserFormHeader
        isNew={isNew}
        client={userForm}
        isToggle={isToggle}
        setIsToggle={setIsToggle}
        clientInputs={clientInputs}
        emailInfo={emailInfo}
      />
      <div className="px-4 flex-1 scrollable py-4">
        {isToggle === "Coordonnées" && (
          <UserInfoForm
            setAvatar={setAvatar}
            avatar={avatar}
            currentImage={currentImage}
            clientInputs={clientInputs}
            setclientInputs={setclientInputs}
            isNew={isNew}
            setemailInfo={setemailInfo}
            emailInfo={emailInfo}
            client={userForm}
            isOp={isOp}
          />
        )}
        {isToggle === "personnalisation" && (
          <UserSettingsForm
            client={userForm}
            clientInputs={clientInputs}
            setclientInputs={setclientInputs}
            invitation={emailInfo.alreadyUsed}
            isOp={isOp}
          />
        )}

        {isToggle === "Statistiques" && <UserOpStats operator={userForm} />}
        {isToggle === "Notifications" && (
          <NotificationManagement
            role={
              +userForm?.attributes?.user_org_permissions[0]?.role ||
              clientInputs?.role[0]?.value
            }
            values={settingValues}
            setValues={setSettingValues}
          />
        )}
        {isToggle === "Permissions" && (
          <UserPermissionForm
            role={
              +userForm?.attributes?.user_org_permissions[0]?.role ||
              +clientInputs?.role[0]?.value
            }
            values={permissionValues}
            setValues={setpermissionValues}
            isNew={isNew}
          />
        )}
        {isToggle === "externalServices" && <ServicesList />}
      </div>

      <div className="alignH justify-content-between py-3 px-4">
        <LightDarkText className="text-start bold custom-ticket-font-size ">
          <span className="text-red">*</span>Champs obligatoires
        </LightDarkText>
        <div className="d-flex gap-2 align-items-center">
          {isToggle !== "Coordonnées" && !meEditing && (
            <ColoredButton
              textButton="Précédent"
              color="dark-grey"
              callback={handlePreviousTab}
            />
          )}
          {!isNew && emailInfo.alreadyUsed ? null : (
            <ColoredButton
              disabled={checkButtonDisabledUser(
                clientInputs,
                isNew,
                emailInfo,
                userAdditionalFields,
                isToggle
              )}
              textButton={generateTextButtonUser(
                meEditing,
                emailInfo,
                isNew,
                isOp,
                isToggle
              )}
              color="light-blue"
              callback={() => handleSubmitClient()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserForm;
