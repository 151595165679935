import _ from "lodash";

import React from "react";

import Loading from "../../Common/Loading";

import UserSideBarDetails from "./UserSideBarDetails";
import UserSideBarActions from "./UserSideBarActions";
import { useSelector } from "react-redux";
import LogHistoryList from "./LogHistoryList";

const UserSideBar = ({ operatorSideBar = false, isToggle }) => {
  const { selectedUser } = useSelector((state) => state.clientsReducer);

  return !_.isEmpty(selectedUser) ? (
    <div className="d-flex flex-column justify-content-between h100 hideScrollbar">
      {isToggle === "details" && (
        <div className="d-flex flex-column justify-content-between h100">
          <UserSideBarDetails operatorSideBar={operatorSideBar} />
          <UserSideBarActions isOp={operatorSideBar} />
        </div>
      )}
      {isToggle === "loginLogs" && (
        <div className="d-flex flex-column justify-content-between h100">
          <LogHistoryList selectedUser={selectedUser} />
        </div>
      )}
    </div>
  ) : (
    <Loading />
  );
};

export default UserSideBar;
