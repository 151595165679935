import { googleDrivePreviewUrl } from "../../Components/Cloud/cloudUtils";

const initialState = {
  oneDriveLoading: false,
  oneDriveSynched: false,
  oneDriveFiles: [],
  oneDriveInitialFetch: false,
  sharePointSiteItem: {},
  sharePointSites: [],
  sharePointSiteFolders: [],
  sharePointSiteFiles: [],
  synchedServices: [],
  oneDriveFolders: [],
  externalServices: [],
  showExternalLoader: false,
  externalItems: [],
  copiedItems: [],
  errorsItems: [],
  externalLoaderTitle: "Importation de vos éléments...",
  oneDriveChildFiles: [],
  oneDriveChildFolders: [],
  fileInfo: {},
  externalTicketFolderShares: [],
  externalServiceShares: [],

  googleDriveLoading: false,
  googleDriveSynched: false,
  googleDriveFiles: [],
  googleDriveInitialFetch: false,
  googleDriveFolders: [],
  googleDriveChildFiles: [],
  googleDriveChildFolders: [],
};

const externalServicesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    //INITIAL DATA
    case "FETCH_INITIAL_DATA":
      if (payload.userRole.data.attributes.external_services) {
        return {
          ...state,
          oneDriveSynched:
            payload.userRole.data.attributes.external_services.some(
              (element) => element.type.tag === "oneDrive"
            ),
          googleDriveSynched:
            payload.userRole.data.attributes.external_services.some(
              (element) => element.type.tag === "gDrive"
            ),
          externalServices: payload.userRole.data.attributes.external_services,
        };
      } else {
        return { ...state };
      }
    // ONE_DRIVE
    case "ONE_DRIVE_LOADING":
      return { ...state, oneDriveLoading: true };
    case "FETCH_ONE_DRIVE":
      let oneDriveFolders = [];
      let oneDriveFiles = [];
      payload.value.forEach((el) => {
        if (el.folder) {
          oneDriveFolders.push({
            id: el.id,
            name: el.name,
            childCount: el.folder.childCount,
            createdAt: el.createdDateTime,
            size: el.size,
          });
        } else {
          oneDriveFiles.push({
            id: el.id,
            name: el.name,
            size: el.size,
            url: el["@microsoft.graph.downloadUrl"],
            mimeType: el.file.mimeType,
            createdAt: el.createdDateTime,
          });
        }
      });

      return {
        ...state,
        oneDriveInitialFetch: true,
        oneDriveLoading: false,
        oneDriveFiles: oneDriveFiles,
        oneDriveFolders: oneDriveFolders,
      };

    // GOOGLE_DRIVE
    case "GOOGLE_DRIVE_LOADING":
      return { ...state, googleDriveLoading: true };
    case "FETCH_GOOGLE_DRIVE":
      let googleCloudFolders = [];
      let googleCloudFiles = [];

      payload.files.forEach((el) => {
        if (el.mimeType === "application/vnd.google-apps.folder") {
          googleCloudFolders.push({
            id: el.id,
            name: el.name,
            childCount: null,
          });
        } else {
          googleCloudFiles.push({
            id: el.id,
            name: el.name,
            mimeType: el.mimeType,
            url: el.webContentLink || el.webViewLink,
            createdAt: el.createdTime,
            size: el.size,
          });
        }
      });
      return {
        ...state,
        googleDriveInitialFetch: true,
        googleDriveLoading: false,
        googleDriveFiles: googleCloudFiles,
        googleDriveFolders: googleCloudFolders,
      };
    case "FETCH_GOOGLE_DRIVE_FOLDER":
      let googleCloudChildFolders = [];
      let googleCloudChildFiles = [];

      payload.files.forEach((el) => {
        if (el.mimeType === "application/vnd.google-apps.folder") {
          googleCloudChildFolders.push({
            id: el.id,
            name: el.name,
            childCount: null,
          });
        } else {
          googleCloudChildFiles.push({
            id: el.id,
            name: el.name,
            mimeType: el.mimeType,
            url: el.webContentLink || el.webViewLink,
            createdAt: el.createdTime,
            size: el.size,
          });
        }
      });

      return {
        ...state,
        googleDriveLoading: false,
        googleDriveChildFiles: googleCloudChildFiles,
        googleDriveChildFolders: googleCloudChildFolders,
        fileInfo: payload.fileInfo,
      };
    // SHARE_POINT
    case "FETCH_SHAREPOINT":
      return {
        ...state,
        oneDriveLoading: false,
        sharePointSites: payload.value,
      };
    case "NO_SHAREPOINT":
      return {
        ...state,

        sharePointSites: [],
      };

    case "FETCH_EXTERNAL_FOLDER_TICKET_SHARES":
      return {
        ...state,
        externalTicketFolderShares: payload,
      };
    case "FETCH_EXTERNAL_SERVICE_SHARES":
      return {
        ...state,
        externalServiceShares: payload,
      };

    case "FETCH_ONE_DRIVE_FOLDER":
      return {
        ...state,
        oneDriveLoading: false,
        oneDriveChildFiles: payload.value.filter((el) => el.file),
        oneDriveChildFolders: payload.value.filter((el) => el.folder),
        fileInfo: payload.fileInfo,
      };
    case "FETCH_SHAREPOINT_SITE":
      return {
        ...state,
        oneDriveLoading: false,
        sharePointSiteFiles: payload.filesInfo.filter((el) => el.file),
        sharePointSiteFolders: payload.filesInfo.filter((el) => el.folder),
        sharePointSiteItem: payload.siteInfo,
      };

    //LOADERS AND COPY
    case "SHOW_EXTERNAL_LOADER":
      return {
        ...state,
        showExternalLoader: true,
        externalItems: payload.filesArray,
        externalLoaderTitle: payload.title,
        copiedItems: [],
        errorsItems: [],
      };

    case "HIDE_EXTERNAL_LOADER":
      return {
        ...state,
        showExternalLoader: false,
        externalItems: [],
        copiedItems: [],
      };

    case "ITEM_COPIED":
      return { ...state, copiedItems: [...state.copiedItems, payload] };
    case "ITEM_COPY_ERROR":
      return { ...state, errorsItems: [...state.errorsItems, payload] };
    //ERRORS
    case "SYNCH_ERROR":
      return {
        ...state,
        [payload]: false,
        oneDriveLoading: false,
        googleDriveLoading: false,
      };

    default:
      return state;
  }
};

export default externalServicesReducer;
