import { gql } from "@apollo/client";
import { userAttributes } from "../general/userFields";

export const UPDATE_TICKET = gql`
  mutation (
    $id: ID!
    $name: String
    $archived: Boolean
    $statut: Int
    $description: String
    $priority: Int
    $prestation: ID
    $type: ID
    $device: ID
    $visibleBy: [ID]
    $departements: [ID]
    $societe: ID
    $societes_copies: [ID]
    $additionalFields: JSON
  ) {
    updateTicket(
      id: $id
      data: {
        name: $name
        archived: $archived
        statut: $statut
        description: $description
        priority: $priority
        societes_copies: $societes_copies
        prestation: $prestation
        type: $type
        device: $device
        visibleBy: $visibleBy
        departements: $departements
        societe: $societe
        additionalFields: $additionalFields
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const ADD_TICKET_OPERATORS = gql`
  mutation ($id: ID!, $visibleBy: [ID]) {
    updateTicket(id: $id, data: { visibleBy: $visibleBy }) {
      data {
        id
      }
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation (
    $content: String
    $ticket: ID
    $interview: ID
    $event: ID
    $sender: ID
    $confidentiel: Boolean
    $firstMessage: Boolean
    $type: Int
    $relatedIntervention: ID
    $fichiers: [ID]
    $mentions: [ID]
    $message_links: [ID]
    $response_to: ID
    $internal_response_to: ID
    $is_internal: Boolean
    $is_response: Boolean
  ) {
    createMessage(
      data: {
        content: $content
        firstMessage: $firstMessage
        ticket: $ticket
        interview: $interview
        event: $event
        sender: $sender
        confidentiel: $confidentiel
        type: $type
        relatedIntervention: $relatedIntervention
        fichiers: $fichiers
        mentions: $mentions
        message_links: $message_links
        response_to : $response_to
        is_internal : $is_internal
        is_response : $is_response
        internal_response_to : $internal_response_to
      }
    ) {
      data {
        id
        attributes {
          fichiers {
            data {
              id
              attributes {
              message {
                  data {
                    id
                    attributes {
                      is_internal
                    }
                  }
                }
                file {
                  data {
                    id
                    attributes {
                      url
                      name
                      mime
                    }
                  }
                }
                cloud {
                  data {
                    id
                  }
                }
              }
            }
          }
          oldId
          type
          content
          createdAt
          is_internal
          firstMessage
          oldDate
          confidentiel
          relatedIntervention {
            data {
              id
              attributes {
                createdAt
                updatedAt
                duration
                dateDebut
                description
                statut
                title
                acceptDate
                refusDate
                revisionDate
                validateDate
                societe {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                actions {
                  data {
                    id
                    attributes {
                      createdAt
                      title
                      motif
                      by {
                        data {
                          id
                          attributes {
                            ${userAttributes}
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          sender {
            data {
              id
              attributes {
                ${userAttributes}
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                poste {
                  data {
                    attributes {
                      name
                    }
                  }
                }
                role {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          relatedIntervention {
            data {
              id
              attributes {
                title
              }
            }
          }
          message_links {
            data {
              id
            }
          }
          mentions {
            data {
              id
              attributes {
                ${userAttributes}
                poste {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                role {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_MESSAGE = gql`
  mutation ($id: ID!) {
    deleteMessage(id: $id) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_MESSAGE = gql`
  mutation ($id: ID!, $content: String, $fichiers: [ID], $mentions: [ID], $updated: Boolean, $links: [ID]) {
    updateMessage(
      id: $id
      data: { content: $content, fichiers: $fichiers, mentions: $mentions, updated: $updated, message_links: $links }
    ) {
      data {
        id
        attributes {
          fichiers {
            data {
              id
              attributes {
                file {
                  data {
                    id
                    attributes {
                      url
                      name
                      mime
                    }
                  }
                }
                cloud {
                  data {
                    id
                  }
                }
              }
            }
          }
          oldId
          type
          content
          createdAt
          firstMessage
          oldDate
          confidentiel
          message_links {
            data {
              id
            }
          }
          relatedIntervention {
            data {
              id
              attributes {
                createdAt
                updatedAt
                duration
                dateDebut
                description
                statut
                title
                acceptDate
                refusDate
                revisionDate
                validateDate
                societe {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                actions {
                  data {
                    id
                    attributes {
                      createdAt
                      title
                      motif
                      by {
                        data {
                          id
                          attributes {
                            ${userAttributes}
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          sender {
            data {
              id
              attributes {
                ${userAttributes}
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                poste {
                  data {
                    attributes {
                      name
                    }
                  }
                }
                role {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          relatedIntervention {
            data {
              id
              attributes {
                title
              }
            }
          }
          mentions {
            data {
              id
              attributes {
                ${userAttributes}
                poste {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                role {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
