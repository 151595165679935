import React from "react";
import TicketInfoBlock from "./TicketInfoBlock";
import { ticketInfosData } from "../ticketUtils";
import useWindowWidth from "../../../Hooks/useWindowWidth";
import { useSelector } from "react-redux";

const RightInfo = () => {
  const screenWidth = useWindowWidth();
  const { ticketHandleLogic } = useSelector(
    (state) => state.organisationsReducer
  );
  const { ticket } = useSelector((state) => state.ticketsReducer);
  const { messagesLoading, ticketsLoading } = useSelector(
    (state) => state.messagesReducer
  );

  return ticketsLoading || !ticket.attributes ? null : (
    <div className="directionC justify-content-between notScrollable h100">
      <div className="hideScrollbar h100">
        <TicketInfoBlock
          ticketInfosData={ticketInfosData(
            ticket,
            ticketHandleLogic,
            screenWidth
          )}
          ticket={ticket}
          isLoading={messagesLoading}
        />
      </div>
    </div>
  );
};

export default RightInfo;
