import React from "react";
import { useDispatch, useSelector } from "react-redux";

import InterventionConfirmModal from "./InterventionConfirmModal";
import ColoredButton from "../../../Common/Buttons/ColoredButton";

import { openModal } from "../../../../REDUX/actions/modal";
import { actionIntervention } from "../../../../REDUX/actions/interventions";

const InterventionLogic1 = ({ intervention, ticket }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const { temporarySelectedIntervention } = useSelector(
    (state) => state.filesReducer
  );
  const { interventionActionLoading } = useSelector(
    (state) => state.interventionsReducer
  );
  const {
    canAcceptInterventions,
    canRejectInterventions,
    canHoldForValidationInterventions,
    canRequestRevisionInterventions,
    canValidateInterventions,
  } = useSelector((state) => state.roleReducer);

  const handleInterventionActions = (action, title) => {
    let messageData = {
      ticket: ticket.id,
      relatedIntervention: intervention.id,
      sender: user.id,
      type: action,
      is_internal: false,
    };

    let actionLogData = {
      title: title,
      intervention: intervention.id,
      by: user.id,
    };

    dispatch(
      actionIntervention(
        action,
        {
          messageData: messageData,
          attachedFiles: { files: temporarySelectedIntervention },
        },
        actionLogData
      )
    );
  };
  return (
    <div className={`w-100`}>
      {/* INTERVENTION CRéé */}
      {intervention.attributes.statut === 1 ? (
        <div className={`d-flex w-100 gap-3`}>
          <div className={`w50`}>
            {canAcceptInterventions ? (
              <ColoredButton
                color={"#0CCE91"}
                background="#0CCE9126"
                icon="check-square"
                square
                textButton="Accepter"
                loading={interventionActionLoading}
                disabled={interventionActionLoading}
                callback={() =>
                  handleInterventionActions(2, "Intervention acceptée")
                }
              />
            ) : null}
          </div>

          <div className={`w50`}>
            {canRejectInterventions ? (
              <ColoredButton
                color={"#FF2C2C"}
                background="#FF2C2C26"
                icon="x-square"
                loading={interventionActionLoading}
                disabled={interventionActionLoading}
                textButton="Refuser"
                square
                callback={() => {
                  dispatch(
                    openModal({
                      type: "small",
                      content: (
                        <InterventionConfirmModal
                          intervention={intervention}
                          ticket={ticket}
                          title={"Motif du refus"}
                          statut={5}
                          text={"Intervention refusée"}
                          color={"#FF2C2C"}
                          background="#FF2C2C26"
                          icon="x-square"
                        />
                      ),
                    })
                  );
                }}
              />
            ) : null}
          </div>
        </div>
      ) : null}
      {/* INTERVENTION Accepté */}
      {intervention.attributes.statut === 2 ||
      intervention.attributes.statut === 6
        ? canHoldForValidationInterventions && (
            <div className="">
              <ColoredButton
                color={`${
                  intervention.attributes.statut === 2 ? "purple" : "orange"
                }`}
                textButton={`Passer à "Attente de validation"`}
                outlined
                loading={interventionActionLoading}
                disabled={interventionActionLoading}
                callback={() => {
                  handleInterventionActions(
                    3,
                    "Intervention en attente de validation"
                  );
                }}
              />
            </div>
          )
        : null}
      {/* INTERVENTION en attente de validation */}
      {intervention.attributes.statut === 3 ? (
        <div className="d-flex w-100 gap-3">
          <div className={`w50`}>
            {canValidateInterventions ? (
              <ColoredButton
                color={"#0CCE91"}
                background="#0CCE9126"
                icon="check-square"
                square
                loading={interventionActionLoading}
                disabled={interventionActionLoading}
                textButton="Valider & finaliser"
                callback={() =>
                  handleInterventionActions(4, "Intervention finalisée")
                }
              />
            ) : null}
          </div>

          <div className="w50">
            {canRequestRevisionInterventions ? (
              <ColoredButton
                color={"#FF2C2C"}
                background="#FF2C2C26"
                icon="x-square"
                loading={interventionActionLoading}
                disabled={interventionActionLoading}
                square
                textButton="Révision"
                callback={() => {
                  dispatch(
                    openModal({
                      type: "small",
                      content: (
                        <InterventionConfirmModal
                          intervention={intervention}
                          ticket={ticket}
                          title="Motif de demande de revision"
                          statut={6}
                          text={"Intervention demandée en revision"}
                          color="#FF2C2C"
                          background="#FF2C2C26"
                          icon="x-square"
                        />
                      ),
                    })
                  );
                }}
              />
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default InterventionLogic1;
