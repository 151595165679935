import React, { useEffect } from "react";
import PdfCommentModal from "./PdfCommentModal";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewFileVersion,
  getFileVersions,
} from "../../../REDUX/actions/pdfComments";
import Loading from "../../Common/Loading";

const PdfCommentContainer = () => {
  const dispatch = useDispatch();
  //   handleCommentCallback = () => {},
  //   handleDeleteCommentCallback = () => {},
  //   handleOnCloseModalCallback = () => {},
  //   handleOnCommentUpdateCallback = () => {},
  const handleOnClose = (e) => {
    // return console.log(e);
    dispatch(createNewFileVersion(e.filter((c) => c.isNew)));
  };
  const { fileLoading, fileId } = useSelector(
    (state) => state.pdfCommentsReducer
  );
  useEffect(() => {
    dispatch(getFileVersions(fileId));
  }, [fileId]);
  return fileLoading ? (
    <Loading />
  ) : (
    <PdfCommentModal handleOnCloseModalCallback={handleOnClose} />
  );
};

export default PdfCommentContainer;
