import React, { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import ListHeader from "../../Components/Common/ListHeader";
import SearchInput from "../../Components/Common/SearchInput";
import OneDriveFodlerList from "../../Components/Cloud/OneDrive/OneDriveFodlerList";
import OneDriveFileList from "../../Components/Cloud/OneDrive/OneDriveFileList";
import { getOneDriveFolder } from "../../REDUX/actions/externalServices";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Components/Common/Loading";
import CloudBreadCrumb from "../../Components/Cloud/CloudBreadCrumb";
import ExternalFolderList from "../../Components/Cloud/GoogleDrive/ExternalFolderList";
import ExternalFolderCard from "../../Components/Cloud/GoogleDrive/ExternalFolderCard";
import { getGoogleDriveFolder } from "../../REDUX/actions/externalServices/gdrive";
import gDriveLogo from "../../Images/apiIcons/gDrive.svg";
import ExternalFileList from "../../Components/Cloud/GoogleDrive/ExternalFileList";

const GoogleDriveFolderView = ({ activeTab, setActiveTab }) => {
  const params = useParams();
  const dispatch = useDispatch();

  const [searchFiles, setsearchFiles] = useState("");

  const {
    googleDriveChildFiles,
    googleDriveChildFolders,
    googleDriveLoading,
    fileInfo,
  } = useSelector((state) => state.externalServicesReducer);

  useEffect(() => {
    dispatch(getGoogleDriveFolder(params.id, true, null));
  }, [params.id]);

  return googleDriveLoading ? (
    <Loading showLoader />
  ) : (
    <div className="container-fluid notScrollable h100 d-flex flex-column">
      <div className="d-flex flex-row justify-content-between align-items-center h50px mb-2">
        <div className="col-12 h100">
          <ListHeader showSocityFilter={false} Archives={true}>
            <SearchInput onChange={(e) => setsearchFiles(e.target.value)} />
          </ListHeader>
        </div>
      </div>

      <CloudBreadCrumb
        name={fileInfo.name}
        path={fileInfo.path}
        elementRoute="gDrive"
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        rootName="Google Drive"
        rootIcon={gDriveLogo}
      />
      <div className="d-flex flex-column gap-5 scrollable ps-1 pb-5">
        <ExternalFolderList
          search={searchFiles}
          title="Dossiers Google Drive"
          type="gDrive"
          logo={gDriveLogo}
          loading={googleDriveLoading}
          folderList={googleDriveChildFolders}
        />

        <ExternalFileList
          allFiles={googleDriveChildFiles}
          search={searchFiles}
          type="gDrive"
          title="Fichiers Google Drive"
          logo={gDriveLogo}
          loading={googleDriveLoading}
        />
      </div>
    </div>
  );
};

export default GoogleDriveFolderView;
