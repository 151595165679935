import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LightDarkText from "../../Common/LightDarkText";
import EyeSlash from "../../Common/MenuIcons/EyeSlash";

const AllSharesButton = ({ callBack }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip style={{ zIndex: 10000 }}>
          <LightDarkText className="custom-ticket-font-size py-1 text-white text-start">
            Arrêter tous les partages
          </LightDarkText>
        </Tooltip>
      }
    >
      <div onClick={callBack} className="pointer">
        <EyeSlash fill="#ff5757" />
      </div>
    </OverlayTrigger>
  );
};

export default AllSharesButton;
