import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowWidth from "../../Hooks/useWindowWidth";
import ListHeader from "../../Components/Common/ListHeader";
import SearchInput from "../../Components/Common/SearchInput";
import ColoredButton from "../../Components/Common/Buttons/ColoredButton";
import TableList from "../../Components/Common/TableComponents/TableList";
import SideBar from "../../Components/Common/TableComponents/SideBar";

import { useEffect } from "react";
import { donwloadDiffusionFile } from "../../Utils/utils";
import DevisColumn from "../../Components/Devis/DevisColumn";
import { useNavigate } from "react-router-dom";
import { closeSideBarCard } from "../../REDUX/actions/general";
import DevisSideBar from "../../Components/Devis/DevisSideBar";
import { tableListData } from "../../Utils/tableStaticData";
import Loading from "../../Components/Common/Loading";
import useOnScreen from "../../Hooks/useScreen";
import { GetAllDevis } from "../../REDUX/actions/devis";
import _ from "lodash";
import { exporterTous } from "../../REDUX/actions/prospect";
import moment from "moment";
import { openModal } from "../../REDUX/actions/modal";
import ExportModal from "../../Components/Prospect/ExportModal";
import MultipleArchiveButton from "../../Components/Commande/MultipleArchiveButton";
import SelectBoxProspect from "../../Components/Prospect/SelectBoxProspect";

const DevisView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const screenWidth = useWindowWidth();
  const [selectedItem, setSelectedItem] = useState();
  const [page, setPage] = useState(1);
  const [selectedDevis, setSelectedDevis] = useState([]);
  const [searchDevis, setSearchDevis] = useState("");
  const { devisRoute } = useSelector((state) => state.organisationsReducer);
  const { openSideBar } = useSelector((state) => state.genearlReducer);
  const { canCreateDevis, isSupervisor } = useSelector(
    (state) => state.roleReducer
  );
  const { loadingExport } = useSelector((state) => state.prospectReducer);
  const { transformedArrays, loading } = useSelector(
    (state) => state.devisReducer
  );
  const { selectedProspect, devisArchived, selectedDate } = useSelector(
    (state) => state.filtersReducer
  );
  const { measureRef, isIntersecting, observer } = useOnScreen();
  const filteredTableListData = openSideBar
    ? tableListData.filter((item) => item.alwaysVisible)
    : tableListData;
  const [sortProspect, setsortProspect] = useState({
    champs: "id",
    asc: false,
  });

  const debounceSearch = _.debounce((value) => {
    setSearchDevis(value);
  }, 500);

  useEffect(() => {
    dispatch(
      GetAllDevis(
        page,
        selectedProspect,
        sortProspect,
        searchDevis,
        selectedDate
      )
    );
  }, [
    page,
    devisArchived,
    selectedProspect,
    sortProspect.champs,
    sortProspect.asc,
    searchDevis,
    selectedDate,
  ]);
  useEffect(() => {
    setPage(1);
  }, [
    page,
    selectedProspect,
    sortProspect.champs,
    sortProspect.asc,
    searchDevis,
    devisArchived,
  ]);
  useEffect(() => {
    if (isIntersecting) {
      setPage((page) => page + 1);
    }
  }, [isIntersecting, dispatch, observer]);

  return (
    <div className="container-fluid">
      <div className="d-flex flex-row align-items-center justify-content-between h50px mb-2">
        <div className={`col h100`}>
          <ListHeader
            showProspectFilter={true}
            showSocityFilter={false}
            type="devis"
          >
            <SearchInput
              placeholder={"Recherche"}
              onChange={(e) => {
                dispatch(closeSideBarCard());

                debounceSearch(e.target.value);
              }}
            />
          </ListHeader>
        </div>
        {canCreateDevis ? (
          <div className="col-1 ms-1 fitContent h100">
            <ColoredButton
              height="100"
              textButton={
                selectedDevis.length === 0
                  ? "Exporter tout"
                  : selectedDevis.length === 1
                  ? "Exporter le fichier"
                  : "Exporter les fichiers"
              }
              color="light-blue"
              loading={loadingExport}
              callback={() => {
                dispatch(
                  openModal({
                    type: "small",
                    content: (
                      <ExportModal
                        handleSubmit={(type) =>
                          dispatch(
                            exporterTous(
                              type,
                              devisArchived
                                ? ["KO_NEW"]
                                : ["NEW", "NEW_SELLER"],
                              selectedDevis,
                              devisArchived,
                              (value) => {
                                const time = moment(Date.now()).format(
                                  "DD-MM-YYYY-hh-mm"
                                );
                                donwloadDiffusionFile(value, `devis-${time}`);
                              }
                            )
                          )
                        }
                      />
                    ),
                  })
                );

                dispatch(
                  exporterTous(
                    devisArchived ? ["KO_NEW"] : ["NEW", "NEW_SELLER"],
                    selectedDevis,
                    devisArchived,
                    (value) => {
                      const time = moment(Date.now()).format(
                        "DD-MM-YYYY-hh-mm"
                      );
                      donwloadDiffusionFile(value, `devis-${time}`);
                    }
                  )
                );
              }}
            />
          </div>
        ) : null}{" "}
        {canCreateDevis ? (
          <div className="col-2 ms-1 h100">
            <ColoredButton
              height="100"
              textButton={screenWidth > 600 && "Ajouter une demande"}
              icon={screenWidth < 600 && "plus"}
              color="light-blue"
              callback={() => {
                navigate(`${devisRoute}/new`);
              }}
            />
          </div>
        ) : null}
      </div>
      <div className="d-flex justify-content-between gap-3 p-0 position-relative">
        <SelectBoxProspect
          setSelected={setSelectedDevis}
          selected={selectedDevis}
          allItems={transformedArrays}
          archived={devisArchived}
          type={"devis"}
        />
        <TableList
          headerItems={filteredTableListData}
          setSort={setsortProspect}
          sort={sortProspect}
          type={"devis"}
          loading={false}
          hasSelect
        >
          {transformedArrays?.map((dataItem, index) => (
            <DevisColumn
              dataItem={dataItem}
              key={dataItem?.id}
              filteredTableListData={filteredTableListData}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              selectedDevis={selectedDevis}
              setSelectedDevis={setSelectedDevis}
              index={index}
            />
          ))}
          <div ref={measureRef} className="opacity-0">
            lazy pointer
          </div>
          {loading && (
            <div className=" position-relative ">
              <Loading />
            </div>
          )}
        </TableList>

        {openSideBar && (
          <SideBar setSelectedItem={setSelectedItem}>
            <DevisSideBar devisId={selectedItem} />
          </SideBar>
        )}
      </div>
    </div>
  );
};

export default DevisView;
