import React, { useState } from "react";
import LighDarkMultiChoice from "../../Common/LighDarkMultiChoice";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../REDUX/actions/modal";
import { CloseIcon } from "../../Common/LightDarkSvg";
import LightDarkContainer from "../../Common/LightDarkContainer";
import LightDarkText from "../../Common/LightDarkText";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import { selectFormat } from "../../../Utils/utils";
import { shareFoldersInTickets } from "../../../REDUX/actions/cloud";
import ShareInTicketItem from "./ShareInTicketItem";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import EyeSlash from "../../Common/MenuIcons/EyeSlash";
import AllSharesButton from "./AllSharesButton";

const ShareInTicket = ({ folder }) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);

  const { tickets } = useSelector((state) => state.ticketsReducer);

  const [sharesToDelete, setSharesToDelete] = useState([]);

  const [sharedInTickets, setSharedInTickets] = useState({
    tickets: [],
  });
  const [localLoader, setLocalLoader] = useState(false);

  const handleSubmit = () => {
    setLocalLoader(true);
    dispatch(
      shareFoldersInTickets(
        [folder.id],
        sharedInTickets.tickets.map((s) => s.value),
        sharesToDelete
      )
    )
      .then(() => dispatch(closeModal()))
      .finally(() => setLocalLoader(false));
  };
  return (
    <LightDarkContainer
      className="p-4 radius05 directionC"
      colorLight="white"
      colorDark="dark-mode"
    >
      <div
        className="d-flex justify-content-end mb-2"
        onClick={() => dispatch(closeModal())}
      >
        <CloseIcon />
      </div>
      <LightDarkText className="text-center custom-title-font-size bold mb-4">
        Partager dans un ticket
      </LightDarkText>
      <div onSubmit={(e) => e.preventDefault()}>
        <LighDarkMultiChoice
          label={`Sélectionner un ticket`}
          placeholder="Sélectionner un ticket"
          data={selectFormat(tickets).filter(
            (el) =>
              !folder.attributes.cloud_ticket_shares.data
                .map((s) => +s.attributes.ticket_id)
                .includes(el.value)
          )}
          totalInputs={sharedInTickets}
          setValue={setSharedInTickets}
          champs="tickets"
          multiple={true}
        />
      </div>

      {folder.attributes.cloud_ticket_shares.data.length > 0 && (
        <div className="mt-5">
          <div className="alignH justify-content-between mb-1 px-1">
            <LightDarkText
              className={"text-start bold custom-ticket-font-size"}
              color={mode === "light" ? "text-blue-grey" : "text-white"}
            >
              Dossier partagé dans (
              {folder.attributes.cloud_ticket_shares.data.length}) ticket(s)
            </LightDarkText>
            <AllSharesButton
              callBack={() =>
                setSharesToDelete(
                  folder.attributes.cloud_ticket_shares.data.map((s) => s.id)
                )
              }
            />
          </div>
          <div
            style={{ maxHeight: "400px" }}
            className="overflow-y-scroll d-flex flex-column gap-2"
          >
            {folder.attributes.cloud_ticket_shares.data
              .filter((s) => !sharesToDelete.includes(s.id))
              .map((share) => (
                <ShareInTicketItem
                  key={share.id}
                  share={share}
                  setSharesToDelete={setSharesToDelete}
                />
              ))}
          </div>
        </div>
      )}

      <div className="col-6 align-self-center mb-3 mt-5">
        <ColoredButton
          textButton="Enregistrer les modifications"
          disabled={
            (!sharedInTickets.tickets?.length && !sharesToDelete.length) ||
            localLoader
          }
          color="light-blue"
          callback={handleSubmit}
          loading={localLoader}
        />
      </div>
    </LightDarkContainer>
  );
};

export default ShareInTicket;
