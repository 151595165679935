import React, { useState, useRef, useEffect } from "react";
import "./CommentLayer.css";
import DeleteCommentIcon from "../../Common/PdfIcons/deleteCommentIcon";
import UserThumbnail from "../../Common/UserThumbnail";
import { useSelector } from "react-redux";
const CommentLayer = ({
  comments,
  isCommenting,
  setIsCommenting,
  pageNumber,
  fileUrl,
  fileId,
  handleCommentCallback,
  handleDeleteCommentCallback,
  handleOnCommentUpdateCallback,
}) => {
  const { user } = useSelector((state) => state.userReducer);

  const [newComment, setNewComment] = useState("");
  const [activeComment, setActiveComment] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);
  const [fetchedComments, setFetchedComments] = useState([]);
  const [newLocalComments, setNewLocalComments] = useState([]);
  const [draggedComment, setDraggedComment] = useState(null);
  const modalRef = useRef(null);
  const layerRef = useRef(null);
  const lastValidPosition = useRef(null);

  useEffect(() => {
    if (comments && Array.isArray(comments)) {
      const formattedComments = comments
        .filter(
          (comment) =>
            comment &&
            comment.id &&
            comment.Page_Number === pageNumber &&
            !comment.isNew
        )
        .map((comment) => ({
          id: comment.id,
          documentId: comment.id,
          // Format coordinates consistently as percentages with % symbol
          x: `${comment.x || comment.X_Coordinate}`,
          y: `${comment.y || comment.Y_Coordinate}`,
          text: comment.commentText || comment.text,
          commentor: comment.commentor,
          pageNumber: comment.Page_Number || pageNumber,
          user: {
            userId: comment?.user?.data?.id,
            connected: comment?.user?.data?.attributes?.connected,
            lastConnected: comment?.user?.data?.attributes?.lastConnected,
            apiImg:
              comment?.user?.data?.attributes?.avatar?.data?.attributes?.image
                ?.data?.attributes?.url,

            img: `${
              comment?.user?.data?.attributes?.data?.attributes?.genre || "3"
            }`,
          },
        }));
      setFetchedComments(formattedComments);
    } else {
      setFetchedComments([]);
    }
  }, [comments, pageNumber]);

  const handleClick = (event) => {
    if (!isCommenting) return;

    const rect = event.currentTarget.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const clickY = event.clientY - rect.top;

    // Calculate available space on the right and bottom
    const availableSpaceRight = rect.width - clickX;
    const availableSpaceBottom = rect.height - clickY;
    const commentWidth = 240; // Width of the comment input box from CSS
    const commentHeight = 100; // Minimum height (60px) + padding + some extra space for content

    // Decide whether to place the comment on the left or right
    let x;
    if (availableSpaceRight < commentWidth) {
      // Not enough space on the right, place it on the left
      x = ((clickX - commentWidth) / rect.width) * 100;
      // Ensure x doesn't go negative
      x = Math.max(0, x);
    } else {
      // Enough space on the right
      x = (clickX / rect.width) * 100;
    }

    // Decide whether to place the comment above or below
    let y;
    if (availableSpaceBottom < commentHeight) {
      // Not enough space at the bottom, place it above
      y = ((clickY - commentHeight) / rect.height) * 100;
      // Ensure y doesn't go negative
      y = Math.max(0, y);
    } else {
      // Enough space at the bottom
      y = (clickY / rect.height) * 100;
    }

    setNewComment("");
    setActiveComment({ x: `${x}%`, y: `${y}%`, text: "", user: user });
  };

  const calculateValidPosition = (e, rect) => {
    if (!e.clientX && !e.clientY) {
      return null;
    }

    let x = ((e.clientX - rect.left) / rect.width) * 100;
    let y = ((e.clientY - rect.top) / rect.height) * 100;

    // Check if the coordinates are within bounds and valid numbers
    if (isNaN(x) || isNaN(y) || !isFinite(x) || !isFinite(y)) {
      return null;
    }

    // Clamp values between 0 and 100
    x = Math.max(0, Math.min(100, x));
    y = Math.max(0, Math.min(100, y));

    return { x, y };
  };

  const handleDragStart = (e, comment) => {
    e.stopPropagation();
    setDraggedComment(comment);

    // Initialize last valid position with current position
    lastValidPosition.current = {
      x: parseFloat(comment.x),
      y: parseFloat(comment.y),
    };

    // Hide the ghost image
    const img = new Image();
    img.src =
      "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";
    e.dataTransfer.setDragImage(img, 0, 0);
  };

  const handleDrag = (e) => {
    if (!draggedComment || !layerRef.current) return;

    e.preventDefault();
    e.stopPropagation();

    const rect = layerRef.current.getBoundingClientRect();
    const newPosition = calculateValidPosition(e, rect);

    if (newPosition) {
      lastValidPosition.current = newPosition;
      setNewLocalComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === draggedComment.id
            ? { ...comment, x: `${newPosition.x}`, y: `${newPosition.y}` }
            : comment
        )
      );
    }
  };

  const handleDragEnd = (e) => {
    if (!draggedComment || !layerRef.current) return;
    e.preventDefault();

    const rect = layerRef.current.getBoundingClientRect();
    const finalPosition =
      calculateValidPosition(e, rect) || lastValidPosition.current;

    if (finalPosition) {
      // Update local state
      setNewLocalComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === draggedComment.id
            ? {
                ...comment,
                x: `${finalPosition.x}%`,
                y: `${finalPosition.y}%`,
              }
            : comment
        )
      );

      // Prepare updated comment for callback
      const updatedComment = {
        ...draggedComment,
        x: finalPosition.x,
        y: finalPosition.y,
      };

      // Notify parent component
      handleOnCommentUpdateCallback(updatedComment, fileUrl);
    }

    setDraggedComment(null);
    lastValidPosition.current = null;
  };

  const handleCommentSubmit = (event) => {
    if (event.key === "Enter" && newComment.trim() !== "") {
      const xCoordinate = parseFloat(activeComment.x);
      const yCoordinate = parseFloat(activeComment.y);

      // Create a local unique ID for the new comment (will be replaced by actual ID after API response)
      const tempId = `temp-${Date.now()}`;

      // Create new comment object with consistent field naming
      const newCommentObj = {
        id: tempId,
        documentId: tempId,
        x: xCoordinate,
        fileId: fileId,
        y: yCoordinate,
        text: newComment,
        isNew: true,
        pageNumber: pageNumber,
        Page_Number: pageNumber,
        user: {
          userId: user?.id,
          connected: user?.attributes?.connected,
          lastConnected: user?.attributes?.lastConnected,
          apiImg:
            user?.attributes?.avatar?.data?.attributes?.image?.data?.attributes
              ?.url,

          img: `${user?.attributes?.genre || "3"}`,
        },
      };

      // Add to local comments immediately for instant feedback
      setNewLocalComments((prev) => [
        ...prev,
        { ...newCommentObj, x: `${xCoordinate}`, y: `${yCoordinate}` }, // Format for display
      ]);

      // Send to parent component through callback
      handleCommentCallback(newCommentObj, fileUrl);

      // Reset states after the comment is successfully placed
      setNewComment("");
      setActiveComment(null);
      setIsCommenting(false);
    } else if (event.key === "Escape") {
      event.stopPropagation();
      setNewComment("");
      setActiveComment(null);
      setIsCommenting(false);
    }
  };

  const handleDeleteComment = (comment) => {
    // Remove from local state immediately
    setNewLocalComments(newLocalComments.filter((c) => c.id !== comment.id));
    setSelectedComment(null);

    // Call parent callback with the comment and fileUrl
    if (comment.documentId) {
      handleDeleteCommentCallback(comment, fileUrl);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // For active comment: if the click target is not the input element.
      if (activeComment && !event.target.classList.contains("comment-input")) {
        setActiveComment(null);
        setNewComment("");
      }
      // For selected comment modal: if the click is outside the modal
      if (
        selectedComment &&
        modalRef.current &&
        !modalRef.current.contains(event.target)
      ) {
        setSelectedComment(null);
      }
    };

    // Only add the event listener if one of the conditions is active.
    if (activeComment || selectedComment) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeComment, selectedComment]);

  return (
    <div
      ref={layerRef}
      onClick={handleClick}
      className="comment-layer"
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        pointerEvents: "auto",
      }}
      onDragOver={(e) => e.preventDefault()}
    >
      {selectedComment && (
        <div
          ref={modalRef}
          className="comment-modal"
          style={{
            top: `${selectedComment.y}%`,
            left: `${selectedComment.x}%`,
          }}
        >
          <div className="comment-modal-header">
            <p>{selectedComment.text}</p>
            <button
              className="delete-comment-button"
              onClick={() => handleDeleteComment(selectedComment)}
              aria-label="Delete comment"
            >
              <DeleteCommentIcon />
            </button>
          </div>
        </div>
      )}

      {activeComment && (
        <textarea
          className="comment-input"
          style={{ top: activeComment.y, left: activeComment.x }}
          value={newComment}
          placeholder="Add your comment..."
          onChange={(e) => setNewComment(e.target.value)}
          onKeyDown={handleCommentSubmit}
          autoFocus
        />
      )}

      {[...fetchedComments, ...newLocalComments]
        .filter((comment) => comment.pageNumber === pageNumber)
        .map((comment) => (
          <div
            key={comment.id}
            className="comment-indicator"
            style={{
              top: `${comment.y}%`,
              left: `${comment.x}%`,
              cursor: "grab",
              position: "absolute",
              pointerEvents: "auto",
              userSelect: "none",
            }}
            onClick={(e) => {
              if (!draggedComment) {
                setSelectedComment(comment);
              }
            }}
            draggable
            onDragStart={(e) => handleDragStart(e, comment)}
            onDrag={handleDrag}
            onDragEnd={handleDragEnd}
          >
            <div className="comment-indicator-user">
              <UserThumbnail
                border={comment?.isNew ? "lightBlue" : ""}
                historyThumbnail={comment?.isNew ? true : ""}
                userId={comment?.user?.userId}
                connected={comment?.user?.connected}
                lastConnected={comment?.user?.lastConnected}
                apiImg={comment?.user?.apiImg}
                img={`user${comment?.user?.img || "3"}`}
                size={40}
              />
            </div>
          </div>
        ))}
    </div>
  );
};
export default CommentLayer;
