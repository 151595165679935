import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LightDarkText from "../../Common/LightDarkText";
import { imageLoader } from "../../../Utils/utils";
import { useSelector } from "react-redux";

const TicketDevice = ({ ticket, notRead }) => {
  const logoUrl =
    ticket?.attributes?.device?.data?.attributes?.logo?.data?.attributes?.icon
      ?.data?.attributes?.url || "/uploads/message_question_dca05c9aa7.svg";
  return (
    <div className="alignH custom-ticket-font-size  bold text-start text-ellipsis">
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id={`tooltip-${ticket?.id}`}>
            <LightDarkText className="custom-ticket-font-size  py-1 text-white text-ellipsis">
              {ticket?.attributes?.stringDevice?.split("(")[0] ||
                "Non mentionné"}
            </LightDarkText>
          </Tooltip>
        }
      >
        {logoUrl ? <img src={imageLoader(logoUrl)} /> : <div></div>}
      </OverlayTrigger>
    </div>
  );
};

export default TicketDevice;
