import React, { useEffect, useState } from "react";
import LightDarkText from "../../../../../../Common/LightDarkText";
import { useSelector } from "react-redux";
import { endPoint } from "../../../../../../../Api/endPoint";
import defaultUserImg from "../../../../../../../Images/temp/user.png";

import {
  prepareMentionOverlay,
  showMentionOverlay,
} from "../../../../messageUtils";

const MessageInnerHtmlText = ({ htmlContent, message }) => {
  const { showPrivateMessages } = useSelector((state) => state.messagesReducer);
  const { mode } = useSelector((state) => state.genearlReducer);

  const [userData, setUserData] = useState();
  const [mentionTag, setMentionTag] = useState();

  useEffect(() => {
    const messageContent = document?.querySelector(
      `.message-content-${message?.id}`
    );
    prepareMentionOverlay(messageContent, setMentionTag, setUserData, message);
  }, [message]);
  useEffect(() => {
    if (mentionTag && userData) {
      showMentionOverlay(mentionTag, userData, defaultUserImg);
    }
  }, [mentionTag, userData, message]);

  return (
    <LightDarkText
      className={`bold text-start break-word internalMessageImages`}
      color={
        mode === "light"
          ? "text-blue-grey"
          : showPrivateMessages
          ? "text-blue-grey"
          : "text-white"
      }
    >
      <span
        className={`message-content-${message?.id} text-start custom-ticket-font-size p-nomb`}
        dangerouslySetInnerHTML={{
          __html: htmlContent,
        }}
        style={{
          "--username": `${userData?.attributes?.firstName || ""} ${
            userData?.attributes?.last || ""
          }`,
          "--avatar": `${
            endPoint +
            userData?.attributes?.avatar?.data?.attributes?.image?.data
              ?.attributes?.url
          }`,
        }}
      ></span>
    </LightDarkText>
  );
};

export default MessageInnerHtmlText;
