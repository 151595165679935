import _ from "lodash";
import { clientRoles, genres, operatorRoles } from "./roles";
import { validMail } from "../../../Utils/utils";
import { userModel } from "../../../Models/user";

export const userFields = (isNew, client, isOp) => {
  let roles = isOp ? operatorRoles : clientRoles;
  const userOrg = client?.attributes?.user_org_permissions?.data[0]?.attributes;

  return isNew
    ? userModel
    : client
    ? {
        password: "",
        password2: "",
        lastName: client?.attributes.lastName,
        firstName: client?.attributes.firstName,
        email: client?.attributes.email,
        adress: client?.attributes.adress,
        city: client?.attributes.city,
        zipCode: client?.attributes.zipCode,
        birthDate: client?.attributes.birthDate,
        phoneNumber: client?.attributes.phoneNumber,

        workIn: userOrg?.workIn?.data?.map((society) => {
          return { value: society?.id, label: society?.attributes?.name };
        }),
        poste: userOrg?.poste?.data
          ? [
              {
                value: userOrg?.poste?.data.id,
                label: userOrg?.poste?.data?.attributes?.name,
              },
            ]
          : [],
        preferredLanguage: userOrg?.preferredLanguage?.data
          ? [
              {
                value: userOrg?.preferredLanguage?.data.id,
                label: `${userOrg?.preferredLanguage?.data?.attributes?.name} (${userOrg?.preferredLanguage?.data?.attributes?.code})`,
              },
            ]
          : [],
        departements: userOrg?.departements?.data.map((department) => {
          return {
            value: department?.id,
            label: department?.attributes?.name,
          };
        }),
        role: [roles.filter((el) => el.value === userOrg?.role)[0]],
        genre: client.attributes.genre
          ? [genres.filter((el) => el.value === client.attributes.genre)[0]]
          : [genres[2]],
        prestations: userOrg.prestations?.data.map((prestation) => {
          return {
            value: +prestation.id,
            label: prestation.attributes.name,
          };
        }),
        devices: userOrg.devices?.data.map((device) => {
          return {
            value: +device.id,
            label: device.attributes.name,
            prestation: device?.attributes?.prestation?.data?.id,
          };
        }),
        SocietiesUnderResponsability: userOrg.SocietiesUnderResponsability
          ? userOrg.SocietiesUnderResponsability?.data.map((society) => {
              return { value: +society.id, label: society.attributes.name };
            })
          : userOrg.workIn?.data.map((society) => {
              return { value: society.id, label: society.attributes.name };
            }),
        ...(client.attributes.additionalData?.data || []).reduce(
          (acc, item) => {
            acc[item.attributes.name] = item.attributes.value;
            return acc;
          },
          {}
        ),
      }
    : null;
};

export const notificationsFields = (client) => {
  if (client) {
    const notificationsSetting =
      client?.attributes?.user_org_permissions?.data[0]?.attributes
        ?.notification_setting?.data?.attributes;
    return _.omit(notificationsSetting, ["__typename"]);
  }
  return {
    ticketCreation: true,
    ticketResolved: true,
    ticketArchive: true,
    message: true,
    intervention: true,
    cloudShare: true,
    fileImport: true,
    collaboratorAdded: true,
    profileMention: true,
    packageExceeded: true,
    prospectActions: true,
    devisActions: true,
    commandeActions: true,
    newProspect: true,
    newDevis: true,
    newCommande: true,
  };
};

export const permissionFields = (role, user, roleChanged = false) => {
  if (user && !roleChanged) {
    const permissions = _.omit(
      user?.attributes?.user_org_permissions?.data[0]?.attributes,
      [
        "role",
        "name",
        "__typename",
        "notification_setting",
        "departements",
        "devices",
        "poste",
        "prestations",
        "workIn",
        "SocietiesUnderResponsability",
        "organisation",
        "cloud",
        "preferredLanguage",
        "user",
        "external_services",
        "createdAt",
        "updatedAt",
      ]
    );

    return permissions;
  } else {
    switch (role) {
      case 1:
        return {
          //Tickets
          canCreateTickets: true,
          canEditTickets: true,
          canManageParticipants: true,
          deletableFromTicket: true,
          canArchiveTickets: true,
          canSendMessages: true,
          canResolveTickets: true,
          ticketAuto: true,
          //Interventions
          canCreateInterventions: true,
          canDeleteUpdateInterventions: true,
          canAddInterventionsType: true,
          canAcceptInterventions: true,
          canRejectInterventions: true,
          canHoldForValidationInterventions: true,
          canRequestRevisionInterventions: true,
          canValidateInterventions: true,
          //Factures
          canCreateInvoices: true,
          canSeeInvoices: true, //canCreateDevis
          //prospects
          canCreateProspet: true,
          canCreateDevis: true,
          canCreateCommande: true,
          //diffusion
          canCreateDiffusion: true,
          //societes
          canCreateSociety: true,
          //users
          canSeeOperators: true,
          canCreateOperators: true,
          canSeeCollabs: true,
          canCreateCollabs: true,
          canSeeWhoOnline: true,
          //history
          showHistory: true,
          //permissions
          canModifySupervisorPermissions: false,
          canModifyAdminPermissions: true,
        };
      case 2:
        return {
          //
          canCreateTickets: true,
          canEditTickets: false,
          canManageParticipants: false,
          deletableFromTicket: true,
          canArchiveTickets: true,
          canSendMessages: true,
          canResolveTickets: true,
          ticketAuto: true,
          //
          canCreateInterventions: true,
          canDeleteUpdateInterventions: false,
          canAddInterventionsType: false,
          canAcceptInterventions: false,
          canRejectInterventions: false,
          canHoldForValidationInterventions: true,
          canRequestRevisionInterventions: false,
          canValidateInterventions: false,
          //
          canCreateInvoices: false,
          canSeeInvoices: false,
          //
          canCreateProspet: true,
          canCreateDevis: true,
          canCreateCommande: true,
          //
          canCreateDiffusion: false,
          //
          canCreateSociety: false,
          //
          canSeeOperators: true,
          canCreateOperators: false,
          canSeeCollabs: false,
          canCreateCollabs: false,
          canSeeWhoOnline: true,
          //
          showHistory: true,
          //permissions
          canModifySupervisorPermissions: false,
          canModifyAdminPermissions: false,
        };
      case 3:
        return {
          //
          canCreateTickets: true,
          canEditTickets: true,
          canManageParticipants: true,
          deletableFromTicket: true,
          canArchiveTickets: true,
          canSendMessages: true,
          canResolveTickets: true,
          ticketAuto: true,
          //
          canCreateInterventions: false,
          canDeleteUpdateInterventions: false,
          canAddInterventionsType: false,
          canAcceptInterventions: true,
          canRejectInterventions: true,
          canHoldForValidationInterventions: false,
          canRequestRevisionInterventions: true,
          canValidateInterventions: true,
          //
          canCreateInvoices: false,
          canSeeInvoices: true,
          //
          canCreateProspet: true,
          canCreateDevis: true,
          canCreateCommande: true,
          //
          canCreateDiffusion: false,
          //
          canCreateSociety: false,
          //
          canSeeOperators: false,
          canCreateOperators: false,
          canSeeCollabs: true,
          canCreateCollabs: false,
          canSeeWhoOnline: true,
          //
          showHistory: true,
          //permissions
          canModifySupervisorPermissions: false,
          canModifyAdminPermissions: true,
        };
      case 4:
        return {
          //
          canCreateTickets: true,
          canEditTickets: false,
          canManageParticipants: false,
          deletableFromTicket: true,
          canArchiveTickets: false,
          canSendMessages: true,
          canResolveTickets: true,
          ticketAuto: true,
          //
          canCreateInterventions: false,
          canDeleteUpdateInterventions: false,
          canAddInterventionsType: false,
          canAcceptInterventions: true,
          canRejectInterventions: true,
          canHoldForValidationInterventions: false,
          canRequestRevisionInterventions: true,
          canValidateInterventions: true,
          //
          canCreateInvoices: false,
          canSeeInvoices: false,
          //
          canCreateProspet: true,
          canCreateDevis: true,
          canCreateCommande: true,
          //
          canCreateDiffusion: false,
          //
          canCreateSociety: false,
          //
          canSeeOperators: false,
          canCreateOperators: false,
          canSeeCollabs: true,
          canCreateCollabs: false,
          canSeeWhoOnline: true,
          //
          showHistory: true,
          //permissions
          canModifySupervisorPermissions: false,
          canModifyAdminPermissions: false,
        };
      case 5:
        return {
          //
          canCreateTickets: false,
          canEditTickets: false,
          canManageParticipants: false,
          deletableFromTicket: true,
          canArchiveTickets: false,
          canSendMessages: false,
          canResolveTickets: false,
          //
          canCreateInterventions: false,
          canDeleteUpdateInterventions: false,
          canAddInterventionsType: false,
          canAcceptInterventions: false,
          canRejectInterventions: false,
          canHoldForValidationInterventions: false,
          canRequestRevisionInterventions: false,
          canValidateInterventions: false,
          //
          canCreateInvoices: false,
          canSeeInvoices: false,
          //
          canCreateProspet: false,
          canCreateDevis: false,
          canCreateCommande: false,
          //
          canCreateDiffusion: false,
          //
          canCreateSociety: false,
          //
          canSeeOperators: false,
          canCreateOperators: false,
          canSeeCollabs: false,
          canCreateCollabs: false,
          canSeeWhoOnline: true,
          //
          showHistory: false,
          //permissions
          canModifySupervisorPermissions: false,
          canModifyAdminPermissions: false,
        };

      default:
        return {
          //
          canCreateTickets: false,
          canEditTickets: false,
          canArchiveTickets: false,
          canSendMessages: false,
          canResolveTickets: false,
          //
          canCreateInterventions: false,
          canAcceptInterventions: false,
          canRejectInterventions: false,
          canHoldForValidationInterventions: false,
          canRequestRevisionInterventions: false,
          canValidateInterventions: false,
          //
          canCreateInvoices: false,
          canSeeInvoices: false,
          //
          canCreateProspet: false,
          canCreateDevis: false,
          canCreateCommande: false,
          //
          canCreateDiffusion: false,
          //
          canCreateSociety: false,
          //
          canSeeOperators: false,
          canCreateOperators: false,
          canSeeCollabs: false,
          canCreateCollabs: false,
          canSeeWhoOnline: true,
          //
          showHistory: false,
          //permissions
          canModifySupervisorPermissions: false,
          canModifyAdminPermissions: false,
        };
    }
  }
};

export const UserFormTabs = (
  isNew,
  emailInfo,
  user,
  client,
  inputRole,
  canModifySupervisorPermissions,
  canCreateOperators,
  canModifyAdminPermissions,
  canCreateCollabs,
  clientInputs
) => {
  const userId = user?.id?.toString();
  const clientId = client?.id?.toString();

  return [
    {
      title: "Coordonnées",
      toggleValue: "Coordonnées",
      isVisible: true,
      hideTab: false,
    },
    {
      title: "Personnalisation",
      toggleValue: "personnalisation",
      isVisible:
        clientInputs?.role?.length &&
        (clientInputs.role[0]?.value !== 1 ||
          (isNew && !emailInfo.alreadyUsed) ||
          userId === clientId),
      hideTab: emailInfo.inMyOrg || (emailInfo.alreadyUsed && !isNew),
    },
    {
      title: "Notifications",
      toggleValue: "Notifications",
      // isVisible: isNew || userId === clientId || userRole === 1,
      isVisible:
        (inputRole === 1 && canModifySupervisorPermissions) ||
        (inputRole === 2 && canCreateOperators) ||
        (inputRole === 3 && canModifyAdminPermissions) ||
        ((inputRole === 4 || inputRole === 5) && canCreateCollabs) ||
        userId === clientId,
      hideTab: emailInfo.inMyOrg || (emailInfo.alreadyUsed && !isNew),
    },
    {
      title: "Permissions",
      toggleValue: "Permissions",
      isVisible:
        ((inputRole === 1 && canModifySupervisorPermissions) ||
          (inputRole === 2 && canCreateOperators) ||
          (inputRole === 3 && canModifyAdminPermissions) ||
          ((inputRole === 4 || inputRole === 5) && canCreateCollabs)) &&
        userId !== clientId,
      hideTab: emailInfo.inMyOrg || (emailInfo.alreadyUsed && !isNew),
    },
    {
      title: "Statistiques",
      toggleValue: "Statistiques",
      isVisible: client?.attributes?.roleId === 2,
    },
    {
      title: "Services connectés",
      toggleValue: "externalServices",
      isVisible: userId === clientId,
    },
  ];
};

export const checkButtonDisabledUser = (
  clientInputs,
  isNew,
  emailInfo,
  userAdditionalFields,
  isToggle
) => {
  const anyEmptyRequiredField = userAdditionalFields.some(
    //Check if there is an empty additional field : Demande Eleance
    (field) =>
      field.attributes.required &&
      (!clientInputs[field.attributes.name] ||
        clientInputs[field.attributes.name].trim() === "")
  );
  if (isToggle === "Coordonnées") {
    return (
      anyEmptyRequiredField ||
      (!validMail(clientInputs.email) || emailInfo.inMyOrg
        ? true
        : emailInfo.alreadyUsed
        ? !clientInputs?.role?.length || !clientInputs?.poste?.length
        : !clientInputs.role.length ||
          !clientInputs.firstName ||
          !clientInputs.lastName ||
          !clientInputs?.poste?.length)
    );
  } else if (isToggle === "personnalisation") {
    return (
      anyEmptyRequiredField ||
      (!validMail(clientInputs.email) || emailInfo.inMyOrg
        ? true
        : emailInfo.alreadyUsed
        ? !clientInputs?.role?.length || !clientInputs?.poste?.length
        : isNew
        ? !clientInputs.role.length ||
          !clientInputs.firstName ||
          !clientInputs.lastName ||
          clientInputs?.password?.length < 6 ||
          clientInputs.password2 !== clientInputs.password ||
          !clientInputs?.poste?.length
        : clientInputs.password2 !== clientInputs.password)
    );
  } else {
    return false;
  }
};
export const generateTextButtonUser = (
  meEditing,
  emailInfo,
  isNew,
  isOp,
  isToggle
) => {
  if (isToggle !== "Permissions" && !meEditing) {
    return "Suivant";
  } else {
    return emailInfo.alreadyUsed
      ? "Ajouter à votre espace"
      : isNew
      ? isOp
        ? "Ajouter l'opérateur"
        : "Ajouter le client"
      : "Mettre à jour les informations";
  }
};
