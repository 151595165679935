import {
  CHANGE_FILTER_DATE,
  CHANGE_FILTER_PROSPECT,
} from "../ActionsType/prospectTypes";

const initialState = {
  selectedSociety: undefined,
  selectedStatus: undefined,
  selectedInterventionStatus: undefined,
  selectedProspect: [],
  selectedInterventionPrestations: [],
  selectedDate: "",
  clientArchived: false,
  operateursArchived: false,
  prospectArchived: false,
  devisArchived: false,
  commandeArchived: false,
  cancelledInterventions: undefined,
  finalizedInterventions: undefined,
  archivedSociety: false,
  ticketsArchived: false,
  resolvedTickets: undefined,
  cancelledTickets: undefined,
  showUnreadTickets: false,
  search: "",
  selectedOperators: [],
  showProspectTickets: false,
  interventionsArchived: false,
};

const filtersReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case "CHANGE_FILTER_SOCIETY":
      return { ...state, selectedSociety: payload };
    case "CHANGE_SEARCH":
      return { ...state, search: payload };
    case "CHANGE_FILTER_TICKET":
      return { ...state, selectedStatus: payload };
    case "CHANGE_FILTER_OPERATORS":
      return { ...state, selectedOperators: payload };
    case "CHANGE_FILTER_INTERVENTION":
      return { ...state, selectedInterventionStatus: payload };
    case "CHANGE_FILTER_INTERVENTIONS_PRESTATIONS":
      return { ...state, selectedInterventionPrestations: payload };
    case CHANGE_FILTER_PROSPECT:
      return { ...state, selectedProspect: payload };
    case CHANGE_FILTER_DATE:
      return { ...state, selectedDate: payload };
    case "CHANGE_CHECKS":
      return { ...state, ...payload };
    case "TOGGLE_UNREAD_TICKETS":
      return { ...state, showUnreadTickets: !state.showUnreadTickets };
    case "TOGGLE_PROSPECT_AND_TICKETS":
      return { ...state, showProspectTickets: !state.showProspectTickets };
    case "RESET_ORGANISATION":
      return initialState;
    case "FETCH_INITIAL_DATA":
      return {
        ...initialState,
        showProspectTickets: payload.organisation.data.attributes.withProspects,
      };
    default:
      return state;
  }
};

export default filtersReducer;
