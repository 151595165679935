import React from "react";

import StatusButton from "../../Common/Buttons/StatusButton";
import InfoLabel from "../TicketCommonComponents/InfoLabel";
import LightDarkPlaceHolder from "../../PlaceHolders/LightDarkPlaceHolder";

import useWindowWidth from "../../../Hooks/useWindowWidth";
import LightDarkText from "../../Common/LightDarkText";
import { useSelector } from "react-redux";
import SocietyGroupThumbnail from "../../Common/SocietyGroupThumbnail";

const TicketInfoBlock = ({ ticketInfosData, isLoading = true, ticket }) => {
  const screenWidth = useWindowWidth();
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div className="d-flex mt-3 scrollable justify-content-between gap-3">
      <div className="w50 d-flex flex-column gap-3">
        {!isLoading && ticket.attributes?.societes_copies?.data?.length > 0 && (
          <div>
            <LightDarkText
              className="f12 text-start bold wContent"
              color={mode === "light" ? "text-blue-grey" : "text-white"}
            >
              Sociétés en copie (
              {ticket.attributes?.societes_copies?.data?.length})
            </LightDarkText>
            <div className="alignH flex-nowrap mt-1 px-1">
              <SocietyGroupThumbnail
                items={ticket.attributes?.societes_copies?.data}
                modalTitle="Sociétés en copie"
                showNumber={3}
              />
            </div>
          </div>
        )}
        {ticketInfosData?.generalInfos?.map((info, index) =>
          isLoading ? (
            <LightDarkPlaceHolder key={index} customClass="py-1 mb-2" />
          ) : (
            info?.isVisible && (
              <InfoLabel
                key={index}
                label={info?.label}
                value={info?.value}
                isLink={info?.isLink}
                icon={info?.icon}
              />
            )
          )
        )}
      </div>
      <div className="w50 d-flex flex-column gap-3">
        {ticketInfosData?.stateInfos?.map((info, index) =>
          info?.isVisible && isLoading ? (
            <LightDarkPlaceHolder key={index} customClass="h40" />
          ) : (
            info?.isVisible && (
              <StatusButton
                key={index}
                height={screenWidth < 1200 ? "30px" : "40px"}
                type={info?.type}
                text={info?.text}
                background={info?.background}
              />
            )
          )
        )}
      </div>
    </div>
  );
};

export default TicketInfoBlock;
