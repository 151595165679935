import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHistoryLogStats } from "../../REDUX/actions/stats";
import MultipleBarChart from "../Charts/MultipleBarChart";
import {
  transformDataToMultiLines,
  transformLogsToStatsMultiBars,
} from "../Charts/chartsUtils";
import MultipleLineChart from "../Charts/MultipleLineChart";
import {
  convertMilliseconds,
  convertMillisecondsToHours,
} from "../../Utils/utils";
import Loading from "../Common/Loading";

const StatisticsLog = ({ duration }) => {
  const dispatch = useDispatch();
  const { historyStats, historyStatsLoading } = useSelector(
    (state) => state.statsReducer
  );

  useEffect(() => {
    dispatch(getHistoryLogStats(duration.value));
  }, [duration]);

  const arrayLogs = [
    historyStats?.logStats?.supervisorLogs,
    historyStats?.logStats?.operatorsLogs,
    historyStats?.logStats?.adminLogs,
    historyStats?.logStats?.collaboratorLogs,
    historyStats?.logStats?.invitedLogs,
  ];

  const arrayLabel = [
    "Superviseurs",
    "Opérateurs",
    "Administrateurs",
    "Collaborateurs",
    "Invités",
  ];
  const usersPerMonth = transformDataToMultiLines(
    historyStats?.usersStats?.usersByMonth,
    "#354074",
    "",
    24
  );
  const interventionsDataNumber = transformLogsToStatsMultiBars(
    arrayLogs,
    arrayLabel,
    duration.value,
    "count"
  );
  const interventionsDatavolume = transformLogsToStatsMultiBars(
    arrayLogs,
    arrayLabel,
    duration.value,
    "volume"
  );

  return historyStatsLoading ? (
    <Loading showLoader />
  ) : (
    <div className="w-100 scrollable d-flex flex-column gap-4">
      <MultipleBarChart
        dataSets={interventionsDataNumber}
        title="Nombre de connexions totales"
        labelDuration={duration.value}
        customToolTip={{
          callbacks: {
            label: function (context) {
              const value = context.raw;
              return `${value} connexions ${
                context.dataset.label?.toLowerCase() || ""
              }`;
            },
          },
        }}
        showY
      />
      <MultipleBarChart
        dataSets={interventionsDatavolume}
        title="Volume de connexion total"
        labelDuration={duration.value}
        customToolTip={{
          callbacks: {
            label: function (context) {
              const value = context.raw;
              return `${convertMilliseconds(value)} ${
                context.dataset.label?.toLowerCase() || ""
              }`;
            },
          },
        }}
        showY
        customYCallback={(value) => {
          return `${convertMillisecondsToHours(value)} heures`;
        }}
      />
      <MultipleLineChart
        dataSets={[{ ...usersPerMonth, data: usersPerMonth?.data?.reverse() }]}
        showLabel={false}
        title="Evolution des utilisateurs"
        months={24}
        customToolTip={{
          callbacks: {
            label: function (context) {
              const value = context.raw;
              return ` ${value} utilisateurs ${
                context.dataset.label?.toLowerCase() || ""
              }`;
            },
          },
        }}
        showY
      />
    </div>
  );
};

export default StatisticsLog;
