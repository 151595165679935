import React from "react";
import {
  transformInterventionsToStatsMultiBars,
  transformticketsToStatsPie,
} from "../Charts/chartsUtils";
import { useSelector } from "react-redux";
import PieChart from "../Charts/PieChart";
import MultipleBarChart from "../Charts/MultipleBarChart";
import _ from "lodash";

const InterventionsAndClicksLineStats = ({ stats }) => {
  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );
  const click = transformticketsToStatsPie(stats?.clicksStats);
  const interventionsData = transformInterventionsToStatsMultiBars(
    [
      stats?.interventionsStats?.allInterventions,
      stats?.interventionsStats?.statut1Interventions,
      stats?.interventionsStats?.statut2Interventions,
      stats?.interventionsStats?.statut3Interventions,
      stats?.interventionsStats?.statut4Interventions,
      stats?.interventionsStats?.statut5Interventions,
      stats?.interventionsStats?.statut6Interventions,
    ],
    [
      "Créée",
      "En Attente",
      "En cours",
      "En attente de validation",
      "Finalisée",
      "Refusé",
      "En révision",
    ]
  );
  return (
    <div
      className={`statisticsLine alignH w100 ${
        selectedOrganisation.attributes.withClicks && "gap-2"
      }`}
    >
      {selectedOrganisation.attributes.withClicks && (
        <div className="w30 h100 ">
          <PieChart
            title={`Nombre de clic : ${_.sumBy(click, "value")}`}
            infos={click}
            tootltipLabel=""
          />
        </div>
      )}
      <div
        className={`${
          selectedOrganisation.attributes.withClicks ? "w70" : "w-100"
        }`}
      >
        <MultipleBarChart
          dataSets={interventionsData}
          title="Interventions les 12 derniers mois"
          customToolTip={{
            callbacks: {
              label: function (context) {
                const value = context.raw;
                return `${value} interventions ${
                  context.dataset.label?.toLowerCase() || ""
                }`;
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default InterventionsAndClicksLineStats;
