import React, { useEffect, useState } from "react";
import TitleImageBlock from "../TicketCommonComponents/TitleImageBlock";
import DateText from "../TicketCommonComponents/DateText";
import TooltipActions from "../../Common/Tooltip/TooltipActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  downloadTicketAttachements,
  toggleArchive,
  updateTicket,
} from "../../../REDUX/actions/tickets";
import { closeModal, openModal } from "../../../REDUX/actions/modal";
import {
  DeleteIcon,
  Download,
  EditIcon,
  NotificationNew,
  ReopenIcon,
  ResolveIcon,
} from "../../Common/LightDarkSvg";
import TicketForm from "../TicketForm/TicketForm";
import { openConfirmationModal } from "../../../REDUX/actions/confirmationModal";
import { handleError } from "@apollo/client/link/http/parseAndCheckHttpResponse";
import { markTicketAsNotRead } from "../../../REDUX/actions/notifications";

const TicketRightSectionHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { canArchiveTickets, isSupervisor, canResolveTickets, canEditTickets } =
    useSelector((state) => state.roleReducer);
  const { ticket } = useSelector((state) => state.ticketsReducer);
  const { ticketRoute } = useSelector((state) => state.organisationsReducer);
  const { user } = useSelector((state) => state.userReducer);
  const { messagesLoading } = useSelector((state) => state.messagesReducer);

  const [showTooltip, setshowTooltip] = useState(false);
  const [canbeResolved, setcanbeResolved] = useState(
    !ticket?.attributes?.interventions?.data?.filter(
      (intervention) =>
        intervention?.attributes?.statut !== 5 &&
        intervention?.attributes?.statut !== 4
    ).length
  );
  useEffect(() => {
    setcanbeResolved(
      !ticket?.attributes?.interventions?.data?.filter(
        (intervention) =>
          intervention?.attributes?.statut !== 5 &&
          intervention?.attributes?.statut !== 4
      ).length
    );
  }, [ticket, dispatch]);

  const resolveTicket = () => {
    setshowTooltip(false);
    dispatch(
      updateTicket(
        {
          id: ticket?.id,
          statut: 2,
        },
        true,
        user?.id,
        true,
        `Ticket résolu </br> <span class="f12"> Le ticket sera archivé 48 heures après le dernier message. </span>`
      )
    );
  };

  const tooltipData = [
    {
      id: 0,
      text: "Réouvrir",
      icon: <ReopenIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        dispatch(
          updateTicket(
            {
              id: ticket?.id,
              statut: 1,
            },
            true,
            user?.id,
            true,
            "Le ticket a été rouvert."
          )
        );
      },
      condition:
        ticket?.attributes?.statut === 2 &&
        isSupervisor &&
        !ticket?.attributes?.archived,
    },
    {
      id: 1,
      text: "Modifier",
      icon: <EditIcon className="me-2" />,
      action: () => {
        dispatch(
          openModal({
            type: "small",
            content: <TicketForm ticket={ticket} />,
          })
        );
        setshowTooltip(false);
      },
      condition:
        ticket?.attributes?.statut !== 2 &&
        (isSupervisor || canEditTickets) &&
        !ticket?.attributes?.archived,
    },
    {
      id: 2,
      text: ticket?.attributes?.archived ? "Désarchiver" : "Archiver",
      icon: <DeleteIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        ticket?.attributes?.archived
          ? dispatch(
              openConfirmationModal({
                callBack: () => {
                  dispatch(
                    toggleArchive(ticket?.id, (newId) =>
                      navigate(`${ticketRoute}/${newId}`)
                    )
                  );
                  dispatch(closeModal());
                },
                message: "Êtes-vous sur de vouloir désarchiver ce ticket?",
                message2: `#${ticket?.id} | ${ticket?.attributes?.name}`,
                confirmButtonText: "Désarchiver le ticket",
              })
            )
          : dispatch(
              openConfirmationModal({
                callBack: () => {
                  dispatch(
                    toggleArchive(ticket?.id, (newId) =>
                      navigate(`${ticketRoute}/${newId}`)
                    )
                  );
                  dispatch(closeModal());
                },
                message: "Êtes-vous sur de vouloir archiver ce ticket?",
                message2: `#${ticket?.id} ${ticket?.attributes?.name}`,
                confirmButtonText: "Archiver le ticket",
              })
            );
      },
      condition: canArchiveTickets,
    },
    {
      id: 3,
      text: "Résoudre",
      icon: <ResolveIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        canbeResolved
          ? resolveTicket()
          : dispatch(
              handleError(
                "Vous ne pouvez pas résoudre le ticket car des interventions sont actuellement en cours de traitement.",
                1
              )
            );
      },
      condition:
        ticket?.attributes?.statut !== 2 &&
        canResolveTickets &&
        !ticket?.attributes?.archived,
    },
    {
      id: 4,
      text: "Non lu",
      icon: (
        <NotificationNew tichetNotification color="#354074" className="me-1" />
      ),
      action: () => {
        setshowTooltip(false);
        dispatch(markTicketAsNotRead(ticket?.id));
      },
      condition:
        // ticket?.attributes?.notifications?.data?.attributes?.count > 0 &&
        !ticket?.attributes?.archived,
    },
    {
      id: 5,
      text: "Télécharger les pièces jointes",
      icon: <Download className="me-1" size={22} />,
      action: () => {
        dispatch(downloadTicketAttachements(ticket.id));
        setshowTooltip(false);
      },
      disabled: false,
      toolTipText: "",
      condition: ticket?.attributes?.fichiers?.data?.length > 0,
    },
  ];
  return (
    <div className="d-flex w-100 align-items-start justify-content-between">
      <TitleImageBlock
        isLoading={messagesLoading}
        avatar={
          ticket?.attributes?.societe?.data?.attributes?.avatar?.data
            ?.attributes?.image?.data?.attributes?.url
        }
        defaultImage="society"
        societyName={ticket?.attributes?.societe?.data?.attributes?.name}
        extraData={<DateText date={ticket?.attributes?.createdAt} />}
      />
      <div className="w10 p-0 d-flex justify-content-end pointer">
        <TooltipActions
          setshowTooltip={setshowTooltip}
          showTooltip={showTooltip}
          tooltipData={tooltipData}
        />
      </div>
    </div>
  );
};

export default TicketRightSectionHeader;
