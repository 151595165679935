import { useSelector } from "react-redux";

import AttachementUploader from "../../Common/AttachementUploader";
import LighDarkMultiChoice from "../../Common/LighDarkMultiChoice";
import LightDarkTextEditor from "../../Common/LightDarkTextEditor";
import LightDarkInput from "../../Common/LightDarkInput";
import CustomRange from "../../Common/CustomRange";
import SwitchItem from "../../Layout/Notification/SwitchItem";
import InfoQuestion from "../../Common/InfoQuestion";
import LightDarkText from "../../Common/LightDarkText";

import { createNewInterventionType } from "../../../REDUX/actions/interventions";
import { handleDuration } from "../../Tickets/ticketUtils";
import { prepareInterventionMentions } from "../interventionUtils";

const InterventionInfosform = ({
  isNew,
  societeId,
  interventionInputs,
  setinterventionInputs,
  societerelatedTickets,
  totalRemainingForfait,
  setMentions = () => {},
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  const { societes, admins } = useSelector((state) => state.sharedDataReducer);
  const { ticket } = useSelector((state) => state.ticketsReducer);

  const { canAddInterventionsType } = useSelector((state) => state.roleReducer);
  const { selectedOrganisation, interventionRoute } = useSelector(
    (state) => state.organisationsReducer
  );
  const currentRoute = useSelector((state) => state.socketReducer.route);

  const { interventionsTypes } = useSelector(
    (state) => state.interventionsReducer
  );

  const maxDuration = 210;
  return (
    <div className="row">
      <div className={`${isNew && !societeId ? "col-8" : "col-12"}`}>
        {interventionsTypes?.length ? (
          <LighDarkMultiChoice
            label={"Nom de l'intervention"}
            data={interventionsTypes}
            totalInputs={interventionInputs}
            champs="multiChoiceTitle"
            multiple={false}
            required
            createNew={canAddInterventionsType}
            createNewFieldCallback={createNewInterventionType}
            setValue={setinterventionInputs}
          />
        ) : (
          <LightDarkInput
            label={"Nom de l'intervention"}
            totalInputs={interventionInputs}
            setValue={setinterventionInputs}
            champs="title"
            required
          />
        )}

        {!societeId && (
          <div className="row mt-4">
            <div className="col-6">
              <LighDarkMultiChoice
                label="Société"
                data={societes}
                totalInputs={interventionInputs}
                champs="societe"
                disabled={societeId ? true : false}
                multiple={false}
                required
                setValue={setinterventionInputs}
                societySelectInput
              />
            </div>
            <div className="col-6">
              <LighDarkMultiChoice
                label="Ticket rattaché"
                data={societerelatedTickets}
                totalInputs={interventionInputs}
                champs="ticket"
                multiple={false}
                required
                setValue={setinterventionInputs}
              />
            </div>
          </div>
        )}
        {selectedOrganisation?.attributes?.withDuration &&
          interventionInputs.statut === 1 && (
            <>
              <div className={isNew && !societeId && "mt-4"}>
                <CustomRange
                  min={0}
                  max={maxDuration}
                  label={"Durée"}
                  totalInputs={interventionInputs}
                  setValue={setinterventionInputs}
                  champs="duration"
                  editbundle={true}
                />
              </div>
              {interventionInputs.duration ? (
                <>
                  {totalRemainingForfait !== 0 ? (
                    <>
                      {totalRemainingForfait >= interventionInputs.duration && (
                        <LightDarkText
                          className="text-start custom-ticket-font-size bold alignH w80"
                          color={
                            mode === "light" ? "text-blue-grey" : "text-white"
                          }
                        >
                          <div className="flex-1">
                            Forfait restant apres acceptation :{" "}
                            <span className="bolder text-light-blue">
                              {handleDuration(
                                parseInt(
                                  +totalRemainingForfait -
                                    +interventionInputs.duration
                                )
                              )}
                            </span>
                          </div>
                        </LightDarkText>
                      )}
                      {totalRemainingForfait < interventionInputs.duration && (
                        <LightDarkText
                          className="text-start custom-ticket-font-size bolder alignH w80"
                          color={"text-red"}
                        >
                          <div className="flex-1">
                            Dépassement :{" "}
                            <span className="bolder text-red">
                              {" "}
                              {handleDuration(
                                parseInt(
                                  +interventionInputs.duration -
                                    +totalRemainingForfait
                                )
                              )}
                            </span>
                          </div>
                        </LightDarkText>
                      )}
                    </>
                  ) : null}
                  <div
                    className={`${
                      !societeId ? "w80" : "w40"
                    } my-3 position-relative alignH gap-4`}
                  >
                    <div className="flex-1">
                      <SwitchItem
                        label="Intervention hors forfait"
                        value={interventionInputs.hors_forfait}
                        onChange={() =>
                          setinterventionInputs({
                            ...interventionInputs,
                            hors_forfait: !interventionInputs.hors_forfait,
                          })
                        }
                      />
                    </div>
                    <InfoQuestion
                      htmlMessage={
                        "L'intervention ne sera pas comptabilisée dans les forfaits"
                      }
                      placement={"bottom"}
                    />
                  </div>

                  <LightDarkText
                    color={"red"}
                    className={"text-start bold f10 text-red"}
                  >
                    {(totalRemainingForfait === 0 ||
                      totalRemainingForfait < interventionInputs.duration) &&
                    !interventionInputs.hors_forfait &&
                    interventionInputs.societe[0] ? (
                      <span>
                        {totalRemainingForfait === 0 ? (
                          <span>
                            Il n'y a pas de forfait pour la société{" "}
                            <span className="xbold">
                              {interventionInputs.societe[0].label}
                            </span>
                            . Cette intervention ne pourra donc pas être
                            acceptée. Nous vous conseillons de l'éditer en hors
                            forfait et contacter un superviseur.
                          </span>
                        ) : totalRemainingForfait <
                          interventionInputs.duration ? (
                          <span>
                            Le forfait de la société{" "}
                            <span className="xbold">
                              {interventionInputs.societe[0].label}
                            </span>{" "}
                            est insuffisant. Cette intervention ne pourra donc
                            pas être acceptée. Nous vous conseillons de l'éditer
                            en hors forfait et contacter un superviseur.
                          </span>
                        ) : null}
                      </span>
                    ) : (
                      ""
                    )}
                  </LightDarkText>
                </>
              ) : null}
            </>
          )}
      </div>
      <div className={`${isNew && !societeId ? "col-4" : "col-12 mt-4"}`}>
        <AttachementUploader
          ticketUploaderHeight={isNew && !societeId}
          key={"intervention"}
          label={"Pièces jointes"}
        />
      </div>
      <div className={`${isNew && !societeId ? "mt-2" : "mt-4"}`}>
        <LightDarkTextEditor
          height={"80"}
          label={"Description"}
          required
          totalInputs={interventionInputs}
          setValue={setinterventionInputs}
          champs="description"
          value={interventionInputs}
          autoHeight
          reformulateText
          canMention={true}
          setMentions={setMentions}
          users={prepareInterventionMentions(
            admins,
            interventionRoute === currentRoute
              ? []
              : ticket?.attributes?.visibleBy || []
          )}
        />
      </div>
    </div>
  );
};

export default InterventionInfosform;
