import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGoogleDrive } from "../../../REDUX/actions/externalServices/gdrive";
import ExternalFolderList from "./ExternalFolderList";
import gDriveLogo from "../../../Images/apiIcons/gDrive.svg";
import ExternalFileList from "./ExternalFileList";
import { checkExternalServiceShares } from "../../../REDUX/actions/externalServices/cloudCommuns";

const GoogleDriveSection = ({ search = "" }) => {
  const dispatch = useDispatch();
  const {
    externalServices,
    googleDriveSynched,
    googleDriveFiles,
    googleDriveFolders,
    googleDriveInitialFetch,
    googleDriveLoading,
  } = useSelector((state) => state.externalServicesReducer);

  useEffect(() => {
    if (googleDriveSynched && !googleDriveInitialFetch) {
      dispatch(fetchGoogleDrive());
    }
  }, [externalServices]);

  return (
    googleDriveSynched && (
      <div className="d-flex flex-column gap-5 scrollable ps-1 pb-5">
        <ExternalFolderList
          search={search}
          title="Dossiers Google Drive"
          type="gDrive"
          logo={gDriveLogo}
          loading={googleDriveLoading}
          folderList={googleDriveFolders}
        />
        <ExternalFileList
          allFiles={googleDriveFiles}
          search={search}
          type="gDrive"
          title="Fichiers Google Drive"
          logo={gDriveLogo}
          loading={googleDriveLoading}
        />

        {/* <OneDriveFileList search={search} oneDriveFiles={oneDriveFiles} /> */}
      </div>
    )
  );
};

export default GoogleDriveSection;
