import React from "react";
import TicketIcon from "../../Common/MenuIcons/TicketIcon";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LightDarkText from "../../Common/LightDarkText";

const ShareTicketNumber = ({
  dossier,
  isMine,
  sharesNumber = 0,
  callBack,
  top = -25,
  right = -25,
  left = null,
}) => {
  return sharesNumber > 0 ? (
    <div
      className="ticketSharedIcon position-absolute"
      style={{ top: top, right: right, left: left }}
      onClick={callBack}
    >
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={`tooltip-${dossier?.id}`}>
            {isMine ? (
              <LightDarkText className="custom-ticket-font-size py-1 text-white text-start">
                Partagé dans {sharesNumber} ticket(s)
              </LightDarkText>
            ) : (
              <LightDarkText className="custom-ticket-font-size py-1 text-white text-start">
                Accès depuis un ticket
              </LightDarkText>
            )}
          </Tooltip>
        }
      >
        <div className="pointer">
          <TicketIcon width={22} height={22} />
        </div>
      </OverlayTrigger>
    </div>
  ) : null;
};

export default ShareTicketNumber;
