import React from "react";
import FolderCardInMessage from "../../../Cloud/FolderCardInMessage";
import MessageSender from "../TicketChatContainer/MessagesList/MessageCard/MessageCardFooter/MessageSender";
import UserThumbnail from "../../../Common/UserThumbnail";
import FolderCardInMessageExternal from "../../../Cloud/FolderCardInMessageExternal";

const CloudShareMessage = ({ message, setMeSending, meSending }) => {
  return message.attributes.cloud_ticket_shares?.data?.length > 0 ? (
    <div
      id={`messageId-${message.id}`}
      className={` w100 p-4 radius05 position-relative
      `}
    >
      <div className="text-start custom-font-size bold d-flex flex-column gap-3 w-100">
        <div
          className={`alignH w-100 gap-3 ${
            meSending ? "flex-row-reverse align-self-end" : ""
          }`}
        >
          {message.attributes.cloud_ticket_shares?.data?.map((share) =>
            share.attributes.type === "gDrive" ||
            share.attributes.type === "oneDrive" ? (
              <FolderCardInMessageExternal share={share} key={share.id} />
            ) : (
              <FolderCardInMessage
                dossier={share.attributes.folder.data}
                key={share.id}
              />
            )
          )}
        </div>
        <div
          className={`alignH gap-3 ${
            meSending ? "flex-row-reverse align-self-end" : ""
          }`}
        >
          <UserThumbnail
            userId={message.attributes.sender?.data?.id}
            connected={message.attributes.sender?.data?.attributes?.connected}
            lastConnected={
              message.attributes.sender?.data?.attributes?.lastConnected
            }
            apiImg={
              message.attributes.sender?.data?.attributes?.avatar?.data
                ?.attributes?.image?.data?.attributes?.url
            }
            img={`user${
              message.attributes.sender?.data?.attributes?.genre || "3"
            }`}
            size={40}
          />
          <MessageSender
            sender={message?.attributes?.sender?.data}
            message={message}
            setMeSending={setMeSending}
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default CloudShareMessage;
