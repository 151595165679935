import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  showMultipleDevices,
  showMultipleSocieties,
  showPoste,
  showPreferredLanguage,
  showRoleNameByUser,
  showmultipleDepartements,
} from "../../../Utils/utils";
import LightDarkContainer from "../../Common/LightDarkContainer";
import LightDarkText from "../../Common/LightDarkText";
import ColoredButton from "../../Common/Buttons/ColoredButton";

import "./DropDownProfileMenu.css";
import UserForm from "../../Clients/UserForm/UserForm";
import { openSideBar } from "../../../REDUX/actions/sideBar";

const DropDownProfileMenu = ({ show }) => {
  const dispatch = useDispatch();
  const elRef = useRef(null);
  const { user } = useSelector((state) => state.userReducer);
  const { mode } = useSelector((state) => state.genearlReducer);
  const { languages } = useSelector((state) => state.sharedDataReducer);
  const { isSupervisor, isOperator } = useSelector(
    (state) => state.roleReducer
  );
  const { ticketHandleLogic } = useSelector(
    (state) => state.organisationsReducer
  );
  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      show(false);
    }
  };
  useEffect(() => {
    elRef?.current?.focus();
  }, [elRef]);

  return (
    <LightDarkContainer
      className={`position-absolute dropDown-menu radius05 ${
        mode === "light" ? "lightDropDown" : "darkDropDown"
      } `}
      colorLight="white"
      colorDark="dark-grey-bg"
    >
      <div
        ref={elRef}
        onBlur={handleBlur}
        tabIndex={-1}
        className="radius05 py-4 px-4"
      >
        <div className="justify-content-between directionC h100">
          <div>
            <div>
              <LightDarkText
                className="text-start bold custom-ticket-font-size mb-1"
                color={mode === "light" ? "text-light-text" : "text-white"}
              >
                Rôle
              </LightDarkText>
              <LightDarkText
                className="text-start custom-ticket-font-size bold"
                color={mode === "light" ? "text-blue-grey" : "text-light-text"}
              >
                {showRoleNameByUser(user.attributes)}
              </LightDarkText>
            </div>
            <div className="mt-3">
              <LightDarkText
                className="text-start bold custom-ticket-font-size mb-1"
                color={mode === "light" ? "text-light-text" : "text-white"}
              >
                Date de naissance
              </LightDarkText>
              <LightDarkText
                className="text-start custom-ticket-font-size bold"
                color={mode === "light" ? "text-blue-grey" : "text-light-text"}
              >
                {user?.attributes?.birthDate
                  ? moment(user.attributes.birthDate).format("DD.MM.YYYY")
                  : "Pas de date de naissance"}
              </LightDarkText>
            </div>
            <div className="mt-3">
              <LightDarkText
                className="text-start custom-ticket-font-size mb-1 bold"
                color={mode === "light" ? "text-light-text" : "text-white"}
              >
                Poste
              </LightDarkText>
              <LightDarkText
                className="text-start custom-ticket-font-size bold"
                color={mode === "light" ? "text-blue-grey" : "text-light-text"}
              >
                {showPoste(user.attributes)}
              </LightDarkText>
            </div>
            {isOperator ? (
              <>
                <div className="mt-3">
                  <LightDarkText
                    className="text-start custom-ticket-font-size mb-1 bold"
                    color={mode === "light" ? "text-light-text" : "text-white"}
                  >
                    {ticketHandleLogic === 2
                      ? "Département(s)"
                      : "Compétence(s)"}
                  </LightDarkText>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip style={{ zIndex: 10 }}>
                        <LightDarkText className="custom-ticket-font-size py-1 text-white">
                          {ticketHandleLogic === 2
                            ? showmultipleDepartements(
                                user?.attributes?.user_org_permissions.data[0]
                                  .attributes.departements?.data
                              )
                            : showMultipleDevices(
                                user?.attributes?.user_org_permissions.data[0]
                                  .attributes.prestations?.data
                              )}
                        </LightDarkText>
                      </Tooltip>
                    }
                  >
                    <div>
                      <LightDarkText
                        className={`text-start custom-ticket-font-size bold`}
                        color={
                          mode === "light"
                            ? "text-blue-grey"
                            : "text-light-text"
                        }
                      >
                        {ticketHandleLogic === 2
                          ? showmultipleDepartements(
                              user?.attributes?.user_org_permissions.data[0]
                                .attributes.departements?.data
                            )
                          : showMultipleDevices(
                              user?.attributes?.user_org_permissions.data[0]
                                .attributes.prestations?.data
                            )}
                      </LightDarkText>
                    </div>
                  </OverlayTrigger>
                </div>

                {ticketHandleLogic !== 2 && (
                  <div className="mt-3">
                    <LightDarkText
                      className="text-start custom-ticket-font-size mb-1 bold"
                      color={
                        mode === "light" ? "text-light-text" : "text-white"
                      }
                    >
                      Spécialité(s)
                    </LightDarkText>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip style={{ zIndex: 10 }}>
                          <LightDarkText className="custom-ticket-font-size py-1 text-white">
                            {showMultipleDevices(
                              user?.attributes?.user_org_permissions.data[0]
                                .attributes.devices?.data
                            )}
                          </LightDarkText>
                        </Tooltip>
                      }
                    >
                      <div>
                        <LightDarkText
                          className={`text-start custom-ticket-font-size bold`}
                          color={
                            mode === "light"
                              ? "text-blue-grey"
                              : "text-light-text"
                          }
                        >
                          {showMultipleDevices(
                            user?.attributes?.user_org_permissions.data[0]
                              .attributes.devices?.data
                          )}
                        </LightDarkText>
                      </div>
                    </OverlayTrigger>
                  </div>
                )}
              </>
            ) : null}
            <div className="mt-3">
              <LightDarkText
                className="text-start custom-ticket-font-size mb-1 bold"
                color={mode === "light" ? "text-light-text" : "text-white"}
              >
                Email
              </LightDarkText>
              <LightDarkText
                className="text-start custom-ticket-font-size bold"
                color={mode === "light" ? "text-blue-grey" : "text-light-text"}
              >
                {user?.attributes?.email}
              </LightDarkText>
            </div>
            {languages.length ? (
              <div className="mt-3">
                <LightDarkText
                  className="text-start custom-ticket-font-size mb-1 bold"
                  color={mode === "light" ? "text-light-text" : "text-white"}
                >
                  Langue préférée
                </LightDarkText>
                <LightDarkText
                  className="text-start custom-ticket-font-size bold"
                  color={
                    mode === "light" ? "text-blue-grey" : "text-light-text"
                  }
                >
                  {showPreferredLanguage(user)}
                </LightDarkText>
              </div>
            ) : null}
            {!isSupervisor && (
              <div className="mt-3">
                <LightDarkText
                  className="text-start custom-ticket-font-size mb-1 bold"
                  color={mode === "light" ? "text-light-text" : "text-white"}
                >
                  Société(s)
                </LightDarkText>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip style={{ zIndex: 10 }}>
                      <LightDarkText className="custom-ticket-font-size py-1 text-white">
                        {!isSupervisor ? showMultipleSocieties(user) : ""}
                      </LightDarkText>
                    </Tooltip>
                  }
                >
                  <div>
                    <LightDarkText
                      className="text-start custom-ticket-font-size bold"
                      color={
                        mode === "light" ? "text-blue-grey" : "text-light-text"
                      }
                    >
                      {!isSupervisor ? showMultipleSocieties(user) : ""}
                    </LightDarkText>
                  </div>
                </OverlayTrigger>
              </div>
            )}
            <div className="mt-3">
              <LightDarkText
                className="text-start custom-ticket-font-size mb-1 bold"
                color={mode === "light" ? "text-light-text" : "text-white"}
              >
                Téléphone
              </LightDarkText>
              <LightDarkText
                className="text-start custom-ticket-font-size bold"
                color={mode === "light" ? "text-blue-grey" : "text-light-text"}
              >
                {user?.attributes?.phoneNumber
                  ? user?.attributes?.phoneNumber
                  : "Pas de téléphone"}
              </LightDarkText>
            </div>
            <div className="mt-3">
              <LightDarkText
                className="text-start custom-ticket-font-size mb-1 bold"
                color={mode === "light" ? "text-light-text" : "text-white"}
              >
                Adresse
              </LightDarkText>
              <LightDarkText
                className="text-start custom-ticket-font-size bold"
                color={mode === "light" ? "text-blue-grey" : "text-light-text"}
              >
                {!user?.attributes?.adress &&
                !user?.attributes?.city &&
                !user?.attributes?.zipCode
                  ? "Pas d'adresse"
                  : `${
                      user?.attributes?.adress ? user?.attributes?.adress : ""
                    } ${user?.attributes?.city ? user?.attributes?.city : ""}
            ${user?.attributes?.zipCode ? user?.attributes?.zipCode : ""}`}
              </LightDarkText>
            </div>
          </div>
          <div className="w-100 mt-5">
            <div>
              <ColoredButton
                color={"light-blue"}
                textButton="Éditer le profil"
                callback={() =>
                  dispatch(
                    openSideBar({
                      content: (
                        <UserForm
                          userId={user.id}
                          isOp={isSupervisor || isOperator}
                        />
                      ),
                      type: "w40",
                    })
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    </LightDarkContainer>
  );
};

export default DropDownProfileMenu;
