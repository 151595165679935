import React from "react";
import MessageContent from "./MessageContent";
import UserThumbnail from "../../../../../../Common/UserThumbnail";
import { useSelector } from "react-redux";
import InternMessageContainer from "../InternMessageContainer";

const MessageCardContainer = ({
  responseTo,
  setIsReplying,
  setInternalResponseTo,
  handleFocusToRespond,
  setResponseTo,
  message,
  ticketId,
  isArchived,
  messageContenttoShow,
  showOriginal,
  setshowOriginal,
  meSending,
  setMeSending,
}) => {
  const { isSupervisor, isOperator, role } = useSelector(
    (state) => state.roleReducer
  );

  return (
    <div className="d-flex flex-column px-2">
      <div
        id={`messageId-${message.id}`}
        className={`d-flex gap-2 w80 mb-3 ${
          meSending ? "flex-row-reverse align-self-end" : ""
        }`}
      >
        <div className="align-self-end mb-4">
          <UserThumbnail
            userId={message.attributes.sender?.data?.id}
            connected={message.attributes.sender?.data?.attributes?.connected}
            lastConnected={
              message.attributes.sender?.data?.attributes?.lastConnected
            }
            apiImg={
              message.attributes.sender?.data?.attributes?.avatar?.data
                ?.attributes?.image?.data?.attributes?.url
            }
            img={`user${
              message.attributes.sender?.data?.attributes?.genre || "3"
            }`}
            size={40}
          />
        </div>
        <MessageContent
          responseTo={responseTo}
          setIsReplying={setIsReplying}
          handleFocusToRespond={handleFocusToRespond}
          setResponseTo={setResponseTo}
          setInternalResponseTo={setInternalResponseTo}
          ticketId={ticketId}
          isArchived={isArchived}
          message={message}
          messageContenttoShow={messageContenttoShow}
          showOriginal={showOriginal}
          setshowOriginal={setshowOriginal}
          meSending={meSending}
          setMeSending={setMeSending}
        />
      </div>

      {(isOperator && role === 2) || (isSupervisor && role === 1)
        ? message?.attributes?.internal_responses?.data.map((el) => (
            <InternMessageContainer
              key={el.id}
              internMessage={el}
              message={message}
              ticketId={ticketId}
              meSending={meSending}
              setMeSending={setMeSending}
            />
          ))
        : null}
    </div>
  );
};

export default MessageCardContainer;
