import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateInterventionDurations,
  transformPlansData,
} from "../Charts/chartsUtils";
import "./Statistics.css";
import MultipleBarChart from "../Charts/MultipleBarChart";
import TicketsGeneralStats from "./TicketsGeneralStats";
import FranceDepartementsMap from "../Charts/FranceDepartementsMap";
import Loading from "../Common/Loading";
import TicketLineStats from "./TicketLineStats";
import InterventionsAndClicksLineStats from "./InterventionsAndClicksLineStats";
import { getConditionalStats } from "../../REDUX/actions/stats";
import SupervisorGeneralStats from "./SupervisorGeneralStats";

const StatisticsTab = ({ values }) => {
  const dispatch = useDispatch();

  const { stats } = useSelector((state) => state.statsReducer);
  const { user } = useSelector((state) => state.userReducer);

  const { isSupervisor, isClientAdmin } = useSelector(
    (state) => state.roleReducer
  );
  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );

  useEffect(() => {
    dispatch(getConditionalStats(values?.societes));
  }, [dispatch, user, values?.societes]);

  const plansData = transformPlansData(stats?.plansStats);

  return !stats ? (
    <Loading showLoader />
  ) : (
    <div className="w-100 scrollable d-flex flex-column gap-4">
      <TicketsGeneralStats
        tickets={stats?.ticketStats.ticketsNumber.length}
        interventions={stats.interventionsStats.interventionsNumber.length}
        hours={calculateInterventionDurations(
          stats.interventionsStats.interventionsNumber
        )}
      />
      {isSupervisor ? (
        <SupervisorGeneralStats supervisorStats={stats.supervisorStats} />
      ) : null}
      {isSupervisor &&
      selectedOrganisation.attributes.ticketHandleLogic === 2 ? (
        <div>
          <FranceDepartementsMap statsData={stats.ticketsUserByDepartements} />
        </div>
      ) : null}

      <TicketLineStats stats={stats} />

      <InterventionsAndClicksLineStats stats={stats} />

      {(isSupervisor || isClientAdmin) &&
      selectedOrganisation.attributes.withForfait ? (
        <div className={`statisticsLine alignH w100`}>
          <div className="w-100">
            <MultipleBarChart
              dataSets={plansData}
              title="Forfait les 12 derniers mois"
              customToolTip={{
                callbacks: {
                  label: function (context) {
                    const value = context.raw;
                    return `${value} heures ${
                      context.dataset.label?.toLowerCase() || ""
                    }`;
                  },
                },
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default StatisticsTab;
