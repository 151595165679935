import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSideBarCard, openSideBarCard } from "../../REDUX/actions/general";
import {
  CheckFillIcon,
  DeleteIcon,
  EditIcon,
  ExportIcon,
  EyeIcon,
  NoteIcon,
} from "../Common/LightDarkSvg";

import DevisSideBar from "./DevisSideBar";
import ProspectItem from "../Prospect/ProspectItem";
import { exportXlsx, handleUpdateStatus } from "../../Utils/utils";
import NoteForm from "../Prospect/NoteForm";
import { openModal } from "../../REDUX/actions/modal";
import { useNavigate } from "react-router-dom";
import { setDevis } from "../../REDUX/actions/devis";

import moment from "moment";
import TooltipActions from "../Common/Tooltip/TooltipActions";

const DevisColumn = ({
  dataItem,
  selectedItem,
  setSelectedItem,
  filteredTableListData,
  selectedDevis,
  setSelectedDevis,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mode, openSideBar } = useSelector((state) => state.genearlReducer);
  const { devis } = useSelector((state) => state.devisReducer);
  const { devisRoute } = useSelector((state) => state.organisationsReducer);
  const [showTooltip, setshowTooltip] = useState(false);
  const { isSupervisor, isOperator } = useSelector(
    (state) => state.roleReducer
  );

  const tooltipData = [
    {
      id: 0,
      text: "Voir",
      icon: <EyeIcon className="me-2" />,
      action: () => {
        setSelectedItem(dataItem?.id);
        dispatch(setDevis(dataItem));

        dispatch(openSideBarCard());
        setshowTooltip(false);
      },
      condition: true,
    },
    {
      id: 1,
      text: "Note interne",
      icon: <NoteIcon className="me-2" />,
      action: () => {
        dispatch(
          openModal({
            type: "small",
            content: (
              <NoteForm
                id={dataItem.id}
                fullName={`${dataItem?.firstname || ""} ${
                  dataItem?.lastname || ""
                }`}
                type={"devis"}
                oldNote={dataItem?.note}
              />
            ),
          })
        );
        setshowTooltip(false);
        dispatch(closeSideBarCard());
      },
      condition: true,
    },
    {
      id: 2,
      text: "Modifier",
      icon: <EditIcon className="me-2" />,
      action: () => navigate(`${devisRoute}/${dataItem?.id}`),
      condition: !["KO_NEW"].includes(dataItem.status),
    },
    {
      id: 3,
      icon: <CheckFillIcon className="me-2" />,
      text: handleUpdateStatus(dataItem?.status)?.text,
      action: () => {
        setSelectedItem(dataItem?.id);
        dispatch(setDevis(dataItem));
        dispatch(
          openSideBarCard(
            <DevisSideBar devisId={dataItem?.id} openDetails={true} />
          )
        );
        setshowTooltip(false);
      },
      condition:
        !["KO_NEW"].includes(dataItem.status) &&
        !(isOperator && ["NEW_SELLER"].includes(dataItem.status)),
    },
    {
      id: 4,
      text: dataItem?.archived ? "Désarchiver" : "Archiver",
      icon: <DeleteIcon className="me-2" />,
      action: () =>
        dispatch(
          openModal({
            type: "small",
            content: (
              <NoteForm
                item={dataItem}
                id={dataItem.id}
                fullName={`${dataItem?.firstname || ""} ${
                  dataItem?.lastname || ""
                }`}
                oldNote={dataItem?.note}
                type={"devis"}
                isArchive={true}
              />
            ),
          })
        ),
      condition: true,
    },
    {
      id: 5,
      text: "Exporter",
      icon: <ExportIcon className="me-2" />,
      action: () => {
        const pros = devis.find((el) => el.id === dataItem.id);
        const time = moment(Date.now()).format("DD/MM/YYYY hh:mm");
        exportXlsx(pros, `Demande-de-tarif${time}`);
        setshowTooltip(false);
      },
      condition: !["KO_NEW"].includes(dataItem.status) && isSupervisor,
    },
  ];

  return (
    <div
      className={` pointer  py-2 pe-3  d-flex  ${
        mode === "light" ? "bg-white" : "bg-dark-grey-bg"
      }
    ${
      dataItem?.id === selectedItem &&
      (mode === "light" ? "lightListCardFocus" : "darkListCardFocus")
    }
    `}
      style={{
        marginLeft: "1px",
        marginRight: "1px",
        borderTop:
          mode === "light"
            ? "2px solid rgb(248,249,255)"
            : "2px solid rgb(37, 44, 69)",
      }}
    >
      <div className="w5 d-flex align-items-center justify-content-center ">
        <input
          type="checkbox"
          id={dataItem.id}
          checked={selectedDevis.includes(dataItem.id)}
          className=""
          onChange={(e) => {
            const devisId = +e.target.id;
            const isSelected = selectedDevis.includes(devisId);

            setSelectedDevis((prevSelected) =>
              isSelected
                ? prevSelected.filter((el) => el !== devisId)
                : [...prevSelected, devisId]
            );
          }}
        />
      </div>
      <div className="w-100 d-flex">
        {filteredTableListData.map(({ id, accessor, width, widthSideBar }) => {
          const columnCol = openSideBar ? widthSideBar : width;

          return (
            <div
              className={`alignH relative w${columnCol} p-0  
            `}
              key={id}
            >
              <div className=" w-100 ">
                <ProspectItem
                  dataItem={dataItem}
                  accessor={accessor}
                  table={true}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="d-flex  align-items-center justify-content-end w5">
        <TooltipActions
          setshowTooltip={setshowTooltip}
          showTooltip={showTooltip}
          tooltipData={tooltipData}
        />
      </div>
    </div>
  );
};

export default DevisColumn;
