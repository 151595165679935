const initialState = {
  isOpen: false,
  isFullScreen: false,
  fileUrl: "",
  fileId: null,
  fileVersions: [],
  fileLoading: false,
  selectedFileVersions: [],
  selectedComments: [],
  selectedVersionId: null,
};

const pdfCommentsReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case "FETCH_FILE_VERSIONS_LOADING":
      return { ...state, fileLoading: true };

    case "OPEN_PDF_COMMENT_MODAL":
      return {
        ...state,
        isOpen: true,
        selectedVersionId: payload.selectedVersionId,
        fileId: payload.fileId,
        fileUrl: payload.fileUrl,
        isFullScreen: payload.isFullScreen,
      };
    case "CLOSE_PDF_COMMENT_MODAL":
      return initialState;
    case "SELECT_VERSION":
      const alreadySelected = state.selectedFileVersions.some(
        (el) => el.id === payload.id
      );

      const newSelectedVersions = alreadySelected
        ? state.selectedFileVersions.filter((el) => el.id !== payload.id)
        : [...state.selectedFileVersions, payload];

      return {
        ...state,
        selectedFileVersions: newSelectedVersions,
        selectedComments: newSelectedVersions.flatMap(
          (el) => el.attributes.comments.data
        ),
      };
    case "FETCH_FILE_VERSIONS":
      let selected = [];
      if (state.selectedVersionId) {
        selected = payload.filter((el) => el.id === state.selectedVersionId);
      }
      return {
        ...state,
        fileVersions: payload,
        selectedFileVersions: selected,
        selectedComments: selected.flatMap((el) => el.attributes.comments.data),
        fileLoading: false,
      };
    default:
      return state;
  }
};

export default pdfCommentsReducer;
