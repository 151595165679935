import _ from "lodash";
import {
  extractLinks,
  formatDateForDB,
  urlifyMessage,
} from "../../Utils/utils";

export const prepareInterventionFields = (intervention) => {
  return {
    ...intervention,
    title:
      intervention.multiChoiceTitle.length > 0
        ? intervention.multiChoiceTitle[0].label
        : intervention.title,
    duration: parseFloat(intervention.duration),
    description: urlifyMessage(intervention.description),
    responsibles: intervention.responsibles ?? null,
    societe:
      intervention?.societe[0]?.value ?? intervention?.societe[0] ?? null,
    ticket: intervention?.ticket[0]?.value ?? null,
    links: extractLinks(intervention.description),
    dateDebut: intervention.dateDebut
      ? formatDateForDB(intervention.dateDebut)
      : null,
  };
};

export const handleInterventionStatut = (statut) => {
  switch (statut) {
    case 1:
      return {
        text: "En attente",
        color: "yellow",
        bg: "#FFBC2040",
        icon: "👋",
      };
    case 2:
      return { text: "En Cours", color: "purple", bg: "#7E25FF40", icon: "👍" };
    case 3:
      return {
        text: "À Valider",
        color: "light-blue",
        bg: "#3365ff61",
        icon: "⌛️",
      };
    case 4:
      return { text: "Finalisée", color: "green", bg: "#0CCE9140", icon: "✅" };
    case 5:
      return { text: "Refusée", color: "red", bg: "#FF2C2C40", icon: "❌" };
    case 6:
      return { text: "Révision", color: "orange", bg: "#e67e2240", icon: "🔁" };
    default:
      return {
        text: "En attente",
        color: "yellow",
        bg: "#FFBC2040",
        icon: "👋",
      };
  }
};

export const interventionSideBarTabs = () => {
  return [
    {
      title: "Intervention",
      toggleValue: "intervention",
      isVisible: true,
    },
    {
      title: "Ticket",
      toggleValue: "ticket",
      isVisible: true,
    },
    {
      title: "Historique",
      toggleValue: "history",
      isVisible: true,
    },
  ];
};

export const checkRequiredInterventionInputs = (
  interventionInputs,
  societerelatedTickets
) => {
  return (
    !interventionInputs.title ||
    interventionInputs.societe.length === 0 ||
    (interventionInputs.ticket.length === 0 && societerelatedTickets !== 1) ||
    interventionInputs.description?.replace(/<(.|\n)*?>/g, "").trim().length ===
      0
  );
};

export const prepareInterventionMentions = (admins, visibleBy) => {
  const visibleByMentions = visibleBy.map((el) => {
    return {
      id: el?.id,
      genre: el?.attributes?.genre,
      value:
        (el?.attributes?.firstName || "") +
        " " +
        (el?.attributes?.lastName || ""),
      avatar:
        el?.attributes?.avatar?.data?.attributes?.image?.data?.attributes?.url,
      role: el?.attributes?.user_org_permissions?.data[0]?.attributes?.role,
    };
  });
  const adminsMentions = admins.map((el) => {
    return {
      id: el?.id,
      genre: el?.genre,
      value: el.label,
      avatar: el?.avatar,
      role: el?.role,
    };
  });
  return _.uniqBy([...adminsMentions, ...visibleByMentions], "id");
};
