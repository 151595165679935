import React, { useState } from "react";
import LightDarkContainer from "../../Common/LightDarkContainer";
import { closeModal } from "../../../REDUX/actions/modal";
import { CloseIcon } from "../../Common/LightDarkSvg";
import LightDarkText from "../../Common/LightDarkText";
import LighDarkMultiChoice from "../../Common/LighDarkMultiChoice";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getParticipantsRemovedMessage,
  getParticipantsAddedMessage,
  handleFirstLastName,
  showMultipleSocieties,
} from "../../../Utils/utils";
import {
  addTicketParticipants,
  afterModifyTicketParticipants,
  getTicketItem,
} from "../../../REDUX/actions/tickets";
import UserSmallCard from "../../Clients/UserSmallCard";
import { useNavigate } from "react-router-dom";

const AddParticipants = ({ ticket, participants }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { canBeAddedToTickets } = useSelector(
    (state) => state.sharedDataReducer
  );
  const { showPrivateMessages } = useSelector((state) => state.messagesReducer);
  const [localLoading, setLocalLoading] = useState(false);

  const clientWorkInOrg =
    ticket?.attributes?.societe?.data?.attributes?.workInOrg?.data || [];

  let ticketParticipants = [
    ...canBeAddedToTickets,
    ...(showPrivateMessages
      ? []
      : clientWorkInOrg.map((el) => {
          return {
            value: el.attributes.user.data.id,
            label: `${
              handleFirstLastName(el.attributes.user.data) +
              " - " +
              ticket.attributes.societe.data.attributes?.name
            }`,
          };
        })),
  ];
  const [totalInputs, setTotalInputs] = useState({
    participants: participants.map((item) => ({
      value: (item?.id).toString(),
      label: `${handleFirstLastName(item)} - ${
        item.attributes.roleId <= 2
          ? item?.attributes?.user_org_permissions?.data[0]?.attributes
              ?.organisation?.data?.attributes?.name
          : showMultipleSocieties(item)
      }`,
    })),
  });

  const handleSubmit = () => {
    const participantsAdded = totalInputs?.participants?.filter(
      (item1) =>
        !participants?.some((item2) => Number(item2.id) === Number(item1.value))
    );

    const participantsRemoved = participants.filter(
      (p) =>
        !totalInputs.participants
          .map((participant) => Number(participant.value))
          .includes(Number(p.id))
    );

    if (participantsAdded.length === 0 && participantsRemoved.length === 0) {
      dispatch(closeModal());
      return;
    }

    if (participantsAdded.length > 0) {
      dispatch(
        addTicketParticipants({
          visibleBy: [...totalInputs?.participants.map((item) => item?.value)],
          listParticipants: getParticipantsAddedMessage(participantsAdded),
          id: ticket?.id,
        })
      ).then(() => {
        dispatch(getTicketItem(ticket?.id, navigate));
        dispatch(closeModal());
      });
    }

    if (participantsRemoved.length > 0) {
      dispatch(
        addTicketParticipants({
          visibleBy: [...totalInputs?.participants.map((item) => item?.value)],
          listParticipants: getParticipantsRemovedMessage(participantsRemoved),
          id: ticket?.id,
        })
      ).then(() => {
        dispatch(getTicketItem(ticket?.id, navigate));
        dispatch(closeModal());
        setLocalLoading(false);
      });
    }
    dispatch(afterModifyTicketParticipants(ticket?.id));
  };

  return (
    <LightDarkContainer
      className="p-4 radius05 directionC"
      colorLight="white"
      colorDark="dark-mode"
    >
      <div
        className="d-flex justify-content-end mb-2"
        onClick={() => dispatch(closeModal())}
      >
        <CloseIcon />
      </div>
      <LightDarkText className="text-center custom-title-font-size bold mb-4">
        Participants
      </LightDarkText>
      <div style={{ zIndex: 4 }}>
        <LighDarkMultiChoice
          label="Ajouter un ou plusieurs participant(s)"
          data={ticketParticipants}
          totalInputs={totalInputs}
          setValue={setTotalInputs}
          champs="participants"
          placeholder="Ajouter un participant"
          ticketParticipantsInput
        />
      </div>
      {ticket?.attributes?.visibleBy?.filter((el) => el.attributes.roleId !== 1)
        .length !== 0 && (
        <div
          className={`my-3 radius05 px-3 hideScrollbar overflow-y-auto ${
            mode === "light" ? "bg-light-mode" : "bg-dark-message"
          }`}
          style={{
            height: `${
              ticket?.attributes?.visibleBy?.filter(
                (el) => el.attributes.roleId !== 1
              ).length <= 2
                ? "100%"
                : ticket?.attributes?.visibleBy?.filter(
                    (el) => el.attributes.roleId !== 1
                  ).length == 3
                ? "190px"
                : "200px"
            }`,
          }}
        >
          {ticket?.attributes?.visibleBy &&
            (showPrivateMessages
              ? ticket.attributes.visibleBy.filter(
                  (el) => el.attributes.roleId <= 2
                )
              : ticket.attributes.visibleBy.filter(
                  (el) => el.attributes.roleId !== 1
                )
            ).map((el, index) => (
              <UserSmallCard key={index} responsible={el} />
            ))}
        </div>
      )}
      <div className="col-6 align-self-center mb-3 mt-5">
        <ColoredButton
          loading={localLoading}
          disabled={localLoading}
          color={"light-blue"}
          textButton="Enregistrer les modifications"
          callback={() => {
            setLocalLoading(true);
            handleSubmit();
          }}
        />
      </div>
    </LightDarkContainer>
  );
};

export default AddParticipants;
