import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../Common/Loading";
import InterventionDetails from "./InterventionDetails";
import InterventionTicketDetails from "./InterventionTicketDetails";
import InterventionHistoriqueDetails from "./InterventionHistoriqueDetails";
import InterventionActions from "../InterventionActions/InterventionActions";

import { getInterventionById } from "../../../REDUX/actions/interventions";
import MenuTabs from "../../Common/MenuTabs";
import { interventionSideBarTabs } from "../interventionUtils";
import MultiTabsMenu from "../../Common/MultiTabsMenu";

const InterventionSideBar = ({ selectedIntervention }) => {
  const dispatch = useDispatch();

  const { intervention } = useSelector((state) => state.interventionsReducer);
  const [isToggle, setIsToggle] = useState("intervention");
  useEffect(() => {
    dispatch(getInterventionById(selectedIntervention));
  }, [selectedIntervention]);

  return intervention ? (
    <div className=" h100 border-3 notScrollable d-flex flex-column">
      <MultiTabsMenu
        isToggle={isToggle}
        setIsToggle={setIsToggle}
        allTabs={interventionSideBarTabs()}
        align="center"
        gap="gap-5"
      />

      <div className="scrollable bg-white flex-1 p-3">
        {isToggle === "intervention" && (
          <InterventionDetails intervention={intervention} />
        )}
        {isToggle === "ticket" && (
          <InterventionTicketDetails intervention={intervention} />
        )}
        {isToggle === "history" && (
          <InterventionHistoriqueDetails intervention={intervention} />
        )}
      </div>
      {intervention?.attributes?.statut !== 4 &&
        intervention?.attributes?.statut !== 5 && (
          <div className="p-3">
            <InterventionActions
              intervention={intervention}
              ticket={intervention?.attributes?.ticket?.data}
            />
          </div>
        )}
    </div>
  ) : (
    <Loading />
  );
};

export default InterventionSideBar;
