import axios from "axios";
import { endPoint } from "../Api/endPoint";

// agilix://tickets/:orgId
// agilix://tickets/:id/:orgId
// agilix://tickets/:ticketId/:orgId/info/ticketEvents
// agilix://tickets/:ticketId/:orgId/info/ticketFavorites
// agilix://tickets/:ticketId/:orgId/info/ticketLinks
// agilix://tickets/:ticketId/:orgId/info/ticketMembers
// agilix://tickets/:ticketId/:orgId/info/ticketFiles
// agilix://tickets/:ticketId/:orgId/info/ticketGeneralInfos
// agilix://interventions/:orgId
// agilix://interventions/:id/:orgId
// agilix://login
// agilix://forgot-password
// agilix://reset-password/code

const checkScreenType = (screenType) => {
  switch (screenType) {
    case "Tickets":
      return "tickets";
    case "Interventions":
      return "interventions";

    default:
      return "tickets";
  }
};

const handleMobileLink = async (window) => {
  try {
    // KEEP THE LOGS TO INTERNAL TEST
    const externalUrl = window.location.href;
    const compareUrl = externalUrl.includes("localhost")
      ? "localhost:3000/"
      : externalUrl.includes("192")
      ? "192.168.1.33:3000/"
      : "app.agilix.io/";
    const arrayPath = externalUrl.split(compareUrl)[1].split("/");

    if (arrayPath[0].includes("reset-password")) {
      const code = window.location.search.split("=")[1];
      return `agilix://reset-password/${code}`;
    }

    const paramOrganisationName = arrayPath[0];
    const paramScreenType = arrayPath[1];
    const paramScreenId = arrayPath[2];

    const organisation = await axios.get(
      `https://api-xnt2upk1xwzg-server.agilix.io/api/getOrgIdByName?name=/${
        paramOrganisationName || ""
      }&screenType=/${paramScreenType || ""}`
    );

    const orgId = organisation.data.orgId;
    const screenType = checkScreenType(organisation.data.screenType);
    const screenId = paramScreenId ? `/${paramScreenId}` : "";
    if (orgId === 0) {
      return `agilix://`;
    } else {
      const mobileLink = `agilix://${screenType}${screenId}/${orgId}`;
      console.log(mobileLink);
      return mobileLink;
    }
  } catch (error) {
    return `agilix://`;
  }
};

export default handleMobileLink;
