import React, { useEffect, useState } from "react";
import LightDarkContainer from "../Common/LightDarkContainer";
import { CloseIcon, CopyAgilix } from "../Common/LightDarkSvg";
import TransparentButton from "../Common/Buttons/TransparentButton";
import { useDispatch } from "react-redux";
import {
  copyFileInAgilix,
  downloadMultipleFiles,
  copyFolderInAgilix,
  downloadMultipleOneDriveFolders,
} from "../../REDUX/actions/externalServices";
import { deleteMultipleFiles } from "../../REDUX/actions/files";
import { openConfirmationModal } from "../../REDUX/actions/confirmationModal";
import {
  copyGoogleFoldersInAgilix,
  downloadMultipleGoogleDriveFolders,
} from "../../REDUX/actions/externalServices/gdrive";

const SelectCloudBox = ({
  selected,
  setSelected,
  allItems,
  type,
  siteId = null,
  hideDelete = false,
  isFolder = false,
}) => {
  const dispatch = useDispatch();
  const [allSelected, setAllSelected] = useState(
    selected.length === allItems.length
  );

  useEffect(() => {
    setAllSelected(selected.length === allItems.length);
  }, [selected, allItems]);

  const handleDeleteMultiple = () => {
    dispatch(
      openConfirmationModal({
        callBack: () =>
          dispatch(deleteMultipleFiles(selected)).then(() => setSelected([])),
        icon: "archive",
        message: "Voulez vous vraiment Supprimer les fichiers séléctionnés",
        message2: `${selected.length} fichiers`,
        confirmButtonText: "Supprimer le fichier",
      })
    );
  };

  const handleSelectAll = () => {
    if (allItems.length === selected.length) {
      setSelected([]);
      setAllSelected(false);
    } else {
      setSelected(allItems);
      setAllSelected(true);
    }
  };

  const buttons = [
    {
      id: 1,
      callBack: () => {
        dispatch(downloadMultipleFiles(selected, type));
        setSelected([]);
      },
      texte: "Télécharger",
      visibleOn: ["agilix", "oneDrive", "gDrive"],
      icon: null,
      remove: false,
      show: !isFolder,
    },
    {
      id: 6,
      callBack: () => {
        dispatch(downloadMultipleOneDriveFolders(selected, siteId));
        setSelected([]);
      },
      texte: "Télécharger",
      visibleOn: ["oneDrive"],
      icon: null,
      remove: false,
      show: isFolder,
    },
    {
      id: 6,
      callBack: () => {
        dispatch(downloadMultipleGoogleDriveFolders(selected));
        setSelected([]);
      },
      texte: "Télécharger",
      visibleOn: ["gDrive"],
      icon: null,
      remove: false,
      show: isFolder,
    },
    {
      id: 2,
      callBack: () => {
        dispatch(copyFileInAgilix(selected, type));
        setSelected([]);
      },
      texte: "Copier dans Agilix", //files
      visibleOn: ["oneDrive", "gDrive"],
      icon: <CopyAgilix className="me-2" />,
      remove: false,
      show: !isFolder,
    },
    {
      id: 3,
      callBack: handleDeleteMultiple,
      texte: "Supprimer",
      visibleOn: ["agilix"],
      icon: null,
      remove: true,
      hide: hideDelete,
      show: !isFolder,
    },
    {
      id: 4,
      callBack: () => {
        dispatch(copyFolderInAgilix(selected));
        setSelected([]);
      },
      texte: "Copier dans Agilix", //folders ,
      visibleOn: ["oneDrive"],
      icon: <CopyAgilix className="me-2" />,
      remove: false,
      show: isFolder,
    },
    {
      id: 4,
      callBack: () => {
        dispatch(copyGoogleFoldersInAgilix(selected));
        setSelected([]);
      },
      texte: "Copier dans Agilix", //folders ,
      visibleOn: ["gDrive"],
      icon: <CopyAgilix className="me-2" />,
      remove: false,
      show: isFolder,
    },
    {
      id: 5,
      callBack: () => {
        handleSelectAll();
      },
      texte: allSelected ? "Désélectionner tous" : "Sélectionner tous",
      visibleOn: ["all"],
      icon: null,
      remove: false,
      show: true,
    },
  ];

  return selected.length ? (
    <div className="cloudActionsMenu w-100">
      <LightDarkContainer
        colorLight="white"
        className={"cloudActionsMenuInner alignH  p-4 w65 radius05"}
      >
        <div className="alignH flex-1">
          {buttons
            .filter((el) => !el.hide && el.show)
            .map((button) =>
              button.visibleOn.includes(type) ||
              button.visibleOn.includes("all") ? (
                <TransparentButton
                  key={button.id}
                  remove={button.remove}
                  callback={button.callBack}
                >
                  {button.icon} <div>{button.texte}</div>{" "}
                </TransparentButton>
              ) : null
            )}
        </div>
        <div className="alignH gap-3">
          <div className="f12 bold ">
            {selected.length} éléments sélectionnés{" "}
          </div>
          <div
            className="d-flex justify-content-end "
            onClick={() => setSelected([])}
          >
            <CloseIcon fill="#354074" />
          </div>
        </div>
      </LightDarkContainer>
    </div>
  ) : null;
};

export default SelectCloudBox;
