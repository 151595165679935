import React from "react";
import UserThumbnail from "../../Common/UserThumbnail";
import LightDarkContainer from "../../Common/LightDarkContainer";
import LightDarkText from "../../Common/LightDarkText";
import { useSelector } from "react-redux";
import { formatDate } from "@fullcalendar/core/index.js";

const RightFavorites = ({ ticket }) => {
  const handleScroll = (id) => {
    document.querySelector(`#messageId-${id}`).scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };
  const { mode } = useSelector((state) => state.genearlReducer);

  const { showPrivateMessages } = useSelector((state) => state.messagesReducer);

  const favorites = showPrivateMessages
    ? ticket?.attributes?.message_favorites?.data?.filter(
        (el) => el?.attributes?.message?.data?.attributes?.is_internal
      )
    : ticket?.attributes?.message_favorites?.data?.filter(
        (el) => !el?.attributes?.message?.data?.attributes?.is_internal
      );
  return (
    <div className="d-flex flex-column gap-2 scrollable">
      {favorites?.map((fav) => (
        <LightDarkContainer
          className=" text-start f12 radius05 p-2 pointer opacityHover09"
          key={fav?.id}
          callBack={() => handleScroll(fav?.attributes?.message?.data?.id)}
        >
          <LightDarkText className="d-flex align-items-start gap-2">
            <UserThumbnail
              userId={
                fav?.attributes?.message?.data?.attributes?.sender?.data?.id
              }
              connected={
                fav?.attributes?.message?.data?.attributes?.sender?.data
                  ?.attributes?.connected
              }
              lastConnected={
                fav?.attributes?.message?.data?.attributes?.sender?.data
                  ?.attributes?.lastConnected
              }
              apiImg={
                fav?.attributes?.message?.data?.attributes?.sender?.data
                  ?.attributes?.avatar?.data?.attributes?.image?.data
                  ?.attributes?.url
              }
              img={`user${
                fav?.attributes?.message?.data?.attributes?.sender?.data
                  ?.attributes?.genre || "3"
              }`}
              size={40}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: `${fav?.attributes?.message?.data?.attributes?.content
                  .replaceAll("<br>", "")
                  .slice(0, 350)} ${
                  fav?.attributes?.message?.data?.attributes?.content?.length >
                  350
                    ? "<span>...</span>"
                    : ""
                }`,
              }}
            ></div>
          </LightDarkText>
          <div className="alignH justify-content-between mt-2">
            <LightDarkText
              color={mode === "light" ? "text-light-text" : "text-white"}
              className="text-start bold f10 pointer"
              onclickCallback={() => {
                handleScroll(fav?.attributes?.message?.data?.id);
              }}
            >
              {formatDate(fav?.attributes?.createdAt)}
            </LightDarkText>
            <LightDarkText
              className="text-end xbold f10 pointer textBlueHover"
              onclickCallback={() => {
                handleScroll(fav?.attributes?.message?.data?.id);
              }}
            >
              voir le message
            </LightDarkText>
          </div>
        </LightDarkContainer>
      ))}
    </div>
  );
};

export default RightFavorites;
