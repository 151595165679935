import React from 'react'

function AddCommentIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.21 13.5117C4.12 13.4417 3 13.8417 2.17 14.6717C0.61 16.2317 0.61 18.7617 2.17 20.3317C2.7 20.8617 3.35 21.2117 4.04 21.3817C4.42 21.4817 4.82 21.5117 5.22 21.4917C6.17 21.4517 7.1 21.0717 7.83 20.3417C8.86 19.3117 9.21 17.8617 8.88 16.5517C8.72 15.8617 8.36 15.2117 7.83 14.6817C7.11 13.9517 6.17 13.5617 5.21 13.5117ZM7.24 17.4817C7.24 17.6917 7.16 17.8717 7.02 18.0117C6.88 18.1517 6.7 18.2317 6.49 18.2317H5.75V19.0117C5.75 19.2217 5.67 19.4017 5.53 19.5417C5.39 19.6817 5.21 19.7617 5 19.7617C4.59 19.7617 4.25 19.4217 4.25 19.0117V18.2317H3.5C3.09 18.2317 2.75 17.8917 2.75 17.4817C2.75 17.0717 3.09 16.7317 3.5 16.7317H4.25V16.0217C4.25 15.6117 4.59 15.2717 5 15.2717C5.41 15.2717 5.75 15.6117 5.75 16.0217V16.7317H6.49C6.91 16.7317 7.24 17.0717 7.24 17.4817Z" fill="white" />
            <path d="M17.25 2.42969H7.75C4.9 2.42969 3 4.32969 3 7.17969V11.6397C3 11.9897 3.36 12.2397 3.7 12.1497C4.12 12.0497 4.55 11.9997 5 11.9997C7.86 11.9997 10.22 14.3197 10.48 17.1297C10.5 17.4097 10.73 17.6297 11 17.6297H11.55L15.78 20.4497C16.4 20.8697 17.25 20.4097 17.25 19.6497V17.6297C18.67 17.6297 19.86 17.1497 20.69 16.3297C21.52 15.4897 22 14.2997 22 12.8797V7.17969C22 4.32969 20.1 2.42969 17.25 2.42969ZM15.83 10.8097H9.17C8.78 10.8097 8.46 10.4897 8.46 10.0997C8.46 9.69969 8.78 9.37969 9.17 9.37969H15.83C16.22 9.37969 16.54 9.69969 16.54 10.0997C16.54 10.4897 16.22 10.8097 15.83 10.8097Z" fill="white" />
        </svg>

    )
}

export default AddCommentIcon