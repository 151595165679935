import React, { useEffect, useState } from "react";
import LightDarkContainer from "../Common/LightDarkContainer";
import { CloseIcon } from "../Common/LightDarkSvg";
import TransparentButton from "../Common/Buttons/TransparentButton";
import MultipleArchiveButton from "../Commande/MultipleArchiveButton";
import { archiveMultipleProspect } from "../../REDUX/actions/prospect";
import { useDispatch, useSelector } from "react-redux";
import { openConfirmationModal } from "../../REDUX/actions/confirmationModal";

const SelectBoxProspect = ({
  setSelected,
  selected,
  allItems,
  type = "",
  archived,
}) => {
  const dispatch = useDispatch();
  const { canCreateCommande, isSupervisor } = useSelector(
    (state) => state.roleReducer
  );
  const [allSelected, setAllSelected] = useState(
    selected.length === allItems.length
  );
  useEffect(() => {
    setAllSelected(selected.length === allItems.length);
  }, [selected.length, allItems.length]);

  const handleSelectAll = () => {
    if (allItems.length === selected.length) {
      setSelected([]);
      setAllSelected(false);
    } else {
      setSelected(allItems.map((item) => item.id));
      setAllSelected(true);
    }
  };
  const buttons = [
    {
      id: 1,
      callBack: () => {
        const singleName =
          type === "prospects"
            ? "prospect"
            : type === "devis"
            ? "devis"
            : "commande";
        const isFeminine = type === "commandes";
        dispatch(
          openConfirmationModal({
            callBack: () => {
              dispatch(archiveMultipleProspect(selected, archived, type));
              setSelected([]);
            },
            message: `Êtes-vous sur de vouloir ${
              archived ? "désarchiver" : "archiver"
            } ${
              selected.length === 0
                ? `${isFeminine ? "toutes" : "tous"} les ${type}`
                : `${selected.length} ${
                    selected.length === 1 ? singleName : type
                  }`
            }`,
            message2: ``,
            confirmButtonText: "Archiver",
          })
        );
      },
      texte: `${
        archived ? "Désarchiver la sélection" : "Archiver la sélection"
      }`,
      visibleOn: ["all"],
      icon: null,
      remove: true,
      show: true,
    },
    {
      id: 5,
      callBack: () => {
        handleSelectAll();
      },
      texte: allSelected ? "Désélectionner tous" : "Sélectionner tous",
      visibleOn: ["all"],
      icon: null,
      remove: false,
      show: true,
    },
  ];

  return selected.length ? (
    <div className="cloudActionsMenu w-100">
      <LightDarkContainer
        colorLight="white"
        className={"cloudActionsMenuInner alignH  p-4 w65 radius05"}
      >
        <div className="alignH flex-1">
          {buttons
            .filter((el) => !el.hide && el.show)
            .map((button) =>
              button.visibleOn.includes(type) ||
              button.visibleOn.includes("all") ? (
                <TransparentButton
                  key={button.id}
                  remove={button.remove}
                  callback={button.callBack}
                >
                  {button.icon} <div>{button.texte}</div>{" "}
                </TransparentButton>
              ) : null
            )}
        </div>
        <div className="alignH gap-3">
          <div className="f12 bold ">
            {selected.length} éléments sélectionnés{" "}
          </div>
          <div
            className="d-flex justify-content-end "
            onClick={() => setSelected([])}
          >
            <CloseIcon fill="#354074" />
          </div>
        </div>
      </LightDarkContainer>
    </div>
  ) : null;
};

export default SelectBoxProspect;
