import React from "react";
import { useSelector } from "react-redux";
import useWindowWidth from "../../Hooks/useWindowWidth";
import LightDarkText from "../Common/LightDarkText";
import ElementName from "./CloudPartials/ElementName";
import FolderCardInMessageIcon from "./FolderCardInMessageIcon";

const FolderCardInMessage = ({ dossier }) => {
  const screenWidth = useWindowWidth();

  const { organisationUrl } = useSelector(
    (state) => state.organisationsReducer
  );
  const handleNavigate = () => {
    window.open(`${organisationUrl}/dossier/${dossier.id}`, "_blank");
  };
  return (
    <div
      className="folderCard d-flex flex-column justify-content-between p-3 radius05 t-02 border"
      style={{
        backgroundColor: `white`,
      }}
    >
      <div className="d-flex align-items-start justify-content-between">
        <FolderCardInMessageIcon
          handleNavigate={handleNavigate}
          share={dossier}
          avatar={
            dossier.attributes.avatar?.data?.attributes?.image?.data?.attributes
              ?.url
          }
        />
      </div>

      <div className="d-flex mt-3 mb-2 justify-content-between">
        <div className="text-start pointer  w-100" onClick={handleNavigate}>
          <ElementName
            name={dossier.attributes.name}
            callBack={handleNavigate}
          />
          {dossier.attributes?.shared_to_societes?.data.length === 1 ? (
            <LightDarkText className="f12 bold mt-1" color="text-light-text">
              {
                dossier.attributes?.shared_to_societes?.data[0]?.attributes
                  ?.name
              }
            </LightDarkText>
          ) : null}
          <div className="d-flex gap-2">
            <LightDarkText className="f10 bold mt-1" color="text-light-text">
              {dossier.attributes?.fichiers?.data?.attributes?.count || 0}
              {dossier.attributes?.fichiers?.data?.attributes?.count === 1
                ? " fichier "
                : " fichiers "}{" "}
              |
            </LightDarkText>
            <LightDarkText className="f10 bold mt-1" color="text-light-text">
              {dossier.attributes?.subFolders?.data?.attributes?.count || 0}
              {dossier.attributes?.subFolders?.data?.attributes?.count === 1
                ? " dossier "
                : " dossiers "}
            </LightDarkText>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FolderCardInMessage;
