import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SocietyPlanOverViewPlaceHolder from "../../../PlaceHolders/Society/SocietyPlanOverViewPlaceHolder";
import LightDarkText from "../../../Common/LightDarkText";
import SocietyBundleBar from "../../SocietyBundleBar";
import { handleDuration } from "../../../Tickets/ticketUtils";
import { sumList } from "../../../Charts/chartsUtils";
import moment from "moment";
import ColoredButton from "../../../Common/Buttons/ColoredButton";
import SocietyBundleForm from "./SocietyBundleForm";
import { ClockIcon } from "../../../Common/LightDarkSvg";

const SocietyPlanOverview = ({ plansData }) => {
  const { societe, societyItemLoading } = useSelector(
    (state) => state.societesReducer
  );
  const { mode } = useSelector((state) => state.genearlReducer);

  const { canCreateSociety } = useSelector((state) => state.roleReducer);

  const [planInputs, setPlanInputs] = useState({
    monthlyPlan: societe?.attributes?.monthlyPlan || 0,
    monthlyQuota: societe?.attributes?.monthlyQuota || 0,
  });

  const [planFields, setPlanFields] = useState(
    societe
      ? {
          generalPlan: societe?.attributes?.monthlyPlan || 0,
          generalQuota: societe?.attributes?.monthlyQuota || 0,
          actualMonthPlan:
            societe.attributes.plans.data[0].attributes.monthlyPlan || 0,
          actualMonthQuota:
            societe.attributes.plans.data[0].attributes.monthlyQuota || 0,
          actualMonthConsumption:
            societe.attributes.plans.data[0].attributes.consumption_0 || 0,
        }
      : {}
  );

  useEffect(() => {
    setPlanInputs({
      monthlyPlan: societe?.attributes?.monthlyPlan || 0,
      monthlyQuota: societe?.attributes?.monthlyQuota || 0,
    });
    setPlanFields(
      societe
        ? {
            generalPlan: societe?.attributes?.monthlyPlan || 0,
            generalQuota: societe?.attributes?.monthlyQuota || 0,
            actualMonthPlan:
              societe.attributes.plans.data[0].attributes.monthlyPlan || 0,
            actualMonthQuota:
              societe.attributes.plans.data[0].attributes.monthlyQuota || 0,
            actualMonthConsumption:
              societe.attributes.plans.data[0].attributes.consumption_0 || 0,
          }
        : {}
    );
  }, [societe]);

  const [editingPlan, setEditingPlan] = useState(false);

  return !societe || societyItemLoading ? (
    <SocietyPlanOverViewPlaceHolder />
  ) : (
    <div
      style={{ height: "280px" }}
      className={`radius05 alignC f14 p-3 ${
        mode === "light" ? "bg-white" : "bg-dark-grey-bg"
      }`}
    >
      {!societe.attributes.monthlyPlan &&
      !societe.attributes.monthlyQuota &&
      !editingPlan ? (
        <div className="d-flex flex-column justify-content-between w-100 h100">
          <LightDarkText className="f18 alignH gap-2">
            <ClockIcon darkColor={"#616a90"} size={24} />
            Vous n’avez pas de forfait
          </LightDarkText>
          <LightDarkText className={"text-start f14"}>
            Pour exploiter au maximum le savoir-faire de notre équipe grâce à
            notre logiciel, nous mettons à votre disposition des forfaits
            d'intervention d'une journée ou plus. N'hésitez pas à nous contacter
            pour profiter des remises disponibles.
          </LightDarkText>
          <LightDarkText
            className={"text-end f14 bold text-light-blue"}
            color={"light-blue"}
          >
            Profitez d’une remise de -10% sur votre premier mois
          </LightDarkText>
          {canCreateSociety && (
            <div className="mt-4 alignH justify-content-end">
              <div className="w20">
                <ColoredButton
                  textButton="Ajouter un forfait"
                  outlined
                  color={"dark-blue"}
                  callback={() => setEditingPlan(true)}
                />
              </div>
            </div>
          )}
        </div>
      ) : !editingPlan ? (
        <div className="d-flex flex-column gap-3 w-100">
          <div className="alignH justify-content-between bold">
            <LightDarkText>
              {" "}
              Forfait Total restant :{" "}
              {/* <span className="f12">
                ({handleDuration(planFields.actualMonthPlan)} dont{" "}
                {handleDuration(planFields.actualMonthQuota)} de quota)
              </span> */}
            </LightDarkText>
            <div className="d-flex align-items-center">
              {/* Forfait total restant :{" "} */}
              <ClockIcon darkColor={"#354074"} size={24} className="mx-2" />
              <span className="" style={{ color: "#59638B" }}>
                {handleDuration(sumList(plansData[3]?.data || [])) +
                  " au total"}
              </span>{" "}
            </div>
          </div>
          <div className="mt-4">
            <SocietyBundleBar
              consumption_0={planFields.actualMonthConsumption}
              monthlyPlan={planFields.generalPlan}
              monthlyQuota={planFields.generalQuota}
            />
          </div>
          <div className="d-flex mt-4 bold justify-content-evenly px-3">
            <div>
              <LightDarkText className={"f12"}>
                Forfait actuel en heure
              </LightDarkText>
              <div className="f18">
                <span className="text-green">{planFields.generalQuota}H</span>
                <span style={{ color: "#59638B" }}> + </span>
                <span className="text-light-blue">
                  {" "}
                  {planFields.generalPlan - planFields.generalQuota}H
                </span>
              </div>
            </div>
            <div className="flex-1">
              <LightDarkText className={"f12"}>
                Forfait restant (mois en cours)
              </LightDarkText>
              <div className="f18 text-light-blue">
                {handleDuration(
                  planFields.actualMonthPlan -
                    planFields.actualMonthQuota -
                    planFields.actualMonthConsumption
                )}
              </div>
            </div>
            <div>
              <LightDarkText className={"f12"}>Renouvellement</LightDarkText>
              <div className="f18" style={{ color: "#59638B" }}>
                {moment(societe.attributes.plans.data[0].attributes.date)
                  .add(1, "month")
                  .format("DD/MM/YYYY")}
              </div>
            </div>
          </div>
          {canCreateSociety && (
            <div className="mt-4 alignH justify-content-end">
              <div className="w20">
                <ColoredButton
                  textButton="Modifier le forfait"
                  outlined
                  color={"dark-blue"}
                  callback={() => setEditingPlan(true)}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <SocietyBundleForm
          societyId={societe.id}
          planId={societe.attributes.plans.data[0].id}
          planInputs={planInputs}
          setPlanInputs={setPlanInputs}
          setEditingPlan={setEditingPlan}
        />
      )}
    </div>
  );
};

export default SocietyPlanOverview;
{
  /* <div>
          <LightDarkText className={"f12"}>Total dépensé</LightDarkText>
          <div className="f18 text-orange">540€</div>
        </div> */
}
