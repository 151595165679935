import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import TableList from "../Common/TableComponents/TableList";
import InterventionsCard from "./InterventionsCard";

import { interventionTableData } from "../../Utils/tableStaticData";
import { sortFunction } from "../../Utils/utils";
import EmptyList from "../Common/EmptyList";

const InterventionsList = ({
  searchInterventions,
  selectedItem,
  setSelectedItem,
}) => {
  const { interventions, interventionLoading } = useSelector(
    (state) => state.interventionsReducer
  );

  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );
  const {
    selectedInterventionStatus,
    selectedSociety,
    selectedOperators,
    selectedInterventionPrestations,
  } = useSelector((state) => state.filtersReducer);

  const [sortInterventions, setsortInterventions] = useState({
    champs: "id",
    asc: false,
  });

  const [filteredInterventions, setFilteredInterventions] =
    useState(interventions);
  const [filtering, setfiltering] = useState(false);

  useEffect(() => {
    setFilteredInterventions(
      sortFunction(
        interventions,
        sortInterventions.champs,
        sortInterventions.asc
      ).filter(
        (el) =>
          (selectedSociety?.length
            ? selectedSociety?.includes(
                Number(el.attributes?.societe?.data?.id)
              )
            : el) &&
          (selectedInterventionPrestations?.length
            ? selectedInterventionPrestations.some(
                (pres) =>
                  pres ===
                  el?.attributes?.ticket?.data?.attributes?.prestation?.data?.id
              )
            : el) &&
          (selectedOperators?.length
            ? el.attributes?.ticket?.data?.attributes?.visibleBy?.data?.some(
                (operator) => selectedOperators.includes(operator.id)
              )
            : el) &&
          (selectedInterventionStatus?.length
            ? selectedInterventionStatus?.includes(el.attributes?.statut)
            : el) &&
          (searchInterventions
            ? el?.attributes.stringPrestation
                ?.toLowerCase()
                .includes(searchInterventions.toLowerCase()) ||
              el?.attributes.stringTicketName
                ?.toLowerCase()
                .includes(searchInterventions.toLowerCase()) ||
              el?.attributes.stringTicketId
                ?.toLowerCase()
                .includes(searchInterventions.toLowerCase()) ||
              el?.attributes?.title
                ?.toLowerCase()
                .includes(searchInterventions.toLowerCase()) ||
              el.id.toString()?.includes(searchInterventions) ||
              el.attributes?.stringSociety
                ?.toLowerCase()
                .includes(searchInterventions?.toLowerCase())
            : el)
      )
    );
    if (
      searchInterventions?.length ||
      selectedInterventionStatus?.length ||
      selectedSociety?.length
    ) {
      setfiltering(true);
    }
  }, [
    searchInterventions,
    interventions,
    selectedInterventionStatus,
    selectedSociety,
    sortInterventions.champs,
    sortInterventions.asc,
  ]);

  return (
    <TableList
      headerItems={interventionTableData(
        selectedOrganisation?.attributes?.withDuration
      )}
      setSort={setsortInterventions}
      sort={sortInterventions}
      loading={interventionLoading}
      hasTooltip
    >
      {(interventions.length === 0 && !interventionLoading) ||
      (filteredInterventions.length == 0 && filtering) ? (
        <EmptyList type="intervention" />
      ) : (
        filteredInterventions?.map((intervention, index) => (
          <InterventionsCard
            intervention={intervention}
            tableList={interventionTableData(
              selectedOrganisation?.attributes?.withDuration
            )}
            key={intervention?.id}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            index={index}
          />
        ))
      )}
    </TableList>
  );
};

export default InterventionsList;
