import React from "react";
import { useSelector } from "react-redux";
import AttachmentPlaceHolder from "../../PlaceHolders/AttachmentPlaceHolder";
import AttachementUploader from "../../Common/AttachementUploader";
import AttachmentsList from "../../Common/AttachmentsList";
import CloudAttachedFolders from "./CloudAttachedFolders";

const RightAttachedFiles = ({ ticket }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  const { canSendMessages } = useSelector((state) => state.roleReducer);
  const { messagesLoading, showPrivateMessages } = useSelector(
    (state) => state.messagesReducer
  );
  const { open } = useSelector((state) => state.modalReducer);

  return (
    <div className="d-flex flex-column justify-content-between h100 gap-2 notScrollable">
      <div className="mb-3 scrollable">
        <div className="d-flex flex-column gap-1">
          {messagesLoading ? (
            <AttachmentPlaceHolder />
          ) : (
            <>
              <CloudAttachedFolders />
              <AttachmentsList
                files={ticket.attributes?.fichiers.data}
                ticketAttachedFiles
                ticketNotArchived={!ticket.attributes.archived}
              />
            </>
          )}
        </div>
      </div>
      {open || ticket?.attributes?.archived || !canSendMessages ? null : (
        <div className="">
          <AttachementUploader
            showCloud
            ticketUploader
            label={"Joindre un fichier"}
            ticket={ticket}
            textColor={showPrivateMessages ? "text-light-red" : null}
            borderColor={showPrivateMessages ? "#ff7f7f" : null}
            bgColor={showPrivateMessages ? "#fee2e2" : null}
          />
        </div>
      )}
    </div>
  );
};

export default RightAttachedFiles;
