import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOneDrive } from "../../../REDUX/actions/externalServices";
import OneDriveFodlerList from "./OneDriveFodlerList";
import OneDriveFileList from "./OneDriveFileList";
import "./OneDriveSection.css";
import SharePointSiteList from "./SharePointSiteList";
import ExternalFolderList from "../GoogleDrive/ExternalFolderList";
import oneDriveLogo from "../../../Images/apiIcons/oneDrive.svg";
import ExternalFileList from "../GoogleDrive/ExternalFileList";

const OneDriveSection = ({ search }) => {
  const dispatch = useDispatch();
  const {
    externalServices,
    oneDriveSynched,
    oneDriveFiles,
    oneDriveFolders,
    oneDriveInitialFetch,
    oneDriveLoading,
    sharePointSites,
  } = useSelector((state) => state.externalServicesReducer);
  useEffect(() => {
    if (oneDriveSynched && !oneDriveInitialFetch) {
      dispatch(fetchOneDrive());
    }
  }, [externalServices]);

  return (
    oneDriveSynched && (
      <div className="d-flex flex-column gap-5 scrollable ps-1 pb-5 h100">
        <ExternalFolderList
          search={search}
          title="Dossiers OneDrive"
          type="oneDrive"
          logo={oneDriveLogo}
          loading={oneDriveLoading}
          folderList={oneDriveFolders}
        />

        <ExternalFileList
          title={"Fichiers OneDrive"}
          allFiles={oneDriveFiles}
          type="oneDrive"
          logo={oneDriveLogo}
          loading={oneDriveLoading}
          search={search}
          oneDriveFiles={oneDriveFiles}
        />
        {sharePointSites.length ? (
          <SharePointSiteList
            search={search}
            sharePointSites={sharePointSites}
          />
        ) : null}
      </div>
    )
  );
};

export default OneDriveSection;
