import React from "react";
import { useSelector } from "react-redux";
import SocietyAvatar from "./SocietyAvatar";
import LightDarkContainer from "../../Common/LightDarkContainer";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LightDarkText from "../../Common/LightDarkText";
import { SocietyViewIconBack } from "../../Common/LightDarkSvg";

const SocietyVerticalList = () => {
  const navigate = useNavigate();
  const { societes, societe } = useSelector((state) => state.societesReducer);
  const { societeRoute } = useSelector((state) => state.organisationsReducer);

  return (
    <div className="scrollable d-flex flex-column gap-2 ">
      <div className="searchSocietyIconContainer">
        <LightDarkContainer
          colorLight="white"
          className={`verticalSocietyElt pointer radius05 border border-2 alignC flex-1 border-white position-fixed`}
        >
          <SocietyViewIconBack
            size={30}
            className={"societyViewIconBack"}
            callBack={() => navigate(`${societeRoute}`)}
          />
        </LightDarkContainer>
      </div>

      {societes.length === 0 ? (
        <div>
          <LightDarkContainer
            colorLight=""
            className={`verticalSocietyElt`}
          ></LightDarkContainer>
        </div>
      ) : (
        societes.map((society) => (
          <OverlayTrigger
            key={society.id}
            placement="right"
            overlay={
              <Tooltip style={{ zIndex: 5000 }}>
                <LightDarkText className="custom-font-size py-1 text-white">
                  {society.attributes.name}
                </LightDarkText>
              </Tooltip>
            }
          >
            <div>
              <LightDarkContainer
                colorLight="white"
                callBack={() => navigate(`${societeRoute}/${society.id}`)}
                className={`verticalSocietyElt pointer radius05 border border-2 alignC flex-1 ${
                  societe?.id === society.id
                    ? "border-light-blue"
                    : "border-white"
                }`}
              >
                <SocietyAvatar
                  width="40px"
                  height="40px"
                  imageUrl={
                    society.attributes.avatar.data?.attributes?.image?.data
                      ?.attributes?.url
                  }
                />
              </LightDarkContainer>
            </div>
          </OverlayTrigger>
        ))
      )}
    </div>
  );
};

export default SocietyVerticalList;
