import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import LightDarkText from "./LightDarkText";

import useWindowWidth from "../../Hooks/useWindowWidth";

import { handleFirstLastName } from "../../Utils/utils";

import { closeModal, openModal } from "../../REDUX/actions/modal";
import { CloseIcon } from "./LightDarkSvg";
import UserSmallCard from "../Clients/UserSmallCard";
import UserThumbnail from "./UserThumbnail";

const UserGroupThumbnail = ({
  participants,
  participantsRole = "",
  modalTitle,
  showNumber = 3,
}) => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);
  // const [search, setSearch] = useState("");
  return (
    <div
      onClick={(e) => (
        e.stopPropagation(),
        dispatch(
          openModal({
            type: "",
            content: (
              <div
                className={`${
                  mode === "light" ? "bg-white" : "bg-dark-mode"
                } p-4 radius05 d-flex flex-column justify-content-between notScrollable`}
                style={{
                  width: `${
                    screenWidth > 600 ? "600px" : "calc(100vw - 50px)"
                  }`,
                }}
              >
                <div className="d-flex justify-content-between mb-3">
                  <LightDarkText
                    className="custom-title-font-size bold w-100"
                    color={mode === "light" ? "text-dark-blue" : "text-white"}
                  >
                    {modalTitle}
                  </LightDarkText>

                  <div
                    className="d-flex justify-content-end"
                    onClick={() => dispatch(closeModal())}
                  >
                    <CloseIcon />
                  </div>
                </div>
                <div
                  className="hideScrollbar scrollable"
                  style={{ height: "200px" }}
                >
                  {participants?.map((el, index) => (
                    <UserSmallCard key={index} responsible={el} />
                  ))}
                </div>
              </div>
            ),
          })
        )
      )}
    >
      {participants?.length ? (
        <div className={`d-flex align-items-center`}>
          <div className="d-flex" style={{ marginLeft: "15px" }}>
            {participants
              ?.slice(0, showNumber)
              ?.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i)
              .map((participant, index) => (
                <OverlayTrigger
                  key={index}
                  placement="bottom"
                  overlay={
                    <Tooltip style={{ zIndex: 10000 }}>
                      <LightDarkText className="custom-ticket-font-size py-1 text-white">
                        {handleFirstLastName(participant)}
                      </LightDarkText>
                    </Tooltip>
                  }
                >
                  <div
                    className="avatars__item pointer"
                    style={{
                      zIndex: participants?.slice(0, showNumber).length - index,
                    }}
                  >
                    <UserThumbnail
                      key={index}
                      img={`user${participant.attributes.genre || "3"}`}
                      size={32}
                      apiImg={
                        participant?.attributes?.avatar?.data?.attributes?.image
                          ?.data?.attributes?.url
                      }
                    />
                  </div>
                </OverlayTrigger>
              ))}
          </div>
          {participants?.length > showNumber && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip className="mt-1" style={{ zIndex: 10000 }}>
                  <LightDarkText className="custom-ticket-font-size py-1 text-white">
                    Consulter tous les membres
                  </LightDarkText>
                </Tooltip>
              }
            >
              <div>
                <LightDarkText
                  className={`ms-2 text-start bold pointer clickable-text t-02 ${
                    screenWidth > 1200 ? "f14" : "f12"
                  }`}
                  color={mode === "light" ? "text-blue-grey" : "text-white"}
                >
                  {participants?.length > showNumber
                    ? `+${
                        participants?.length - showNumber
                      } ${participantsRole}`
                    : `${participants?.length} ${participantsRole}`}
                </LightDarkText>
              </div>
            </OverlayTrigger>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default UserGroupThumbnail;
