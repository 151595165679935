import React, { useEffect, useState } from "react";
import {
  Routes as Switch,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";

import ClientsView from "../Views/ClientsView/ClientsView";
import CloudView from "../Views/CloudView/CloudView";
import DashBoardView from "../Views/DashBoardView/DashBoardView";
import FacturesView from "../Views/FacturesView/FacturesView";
import InterventionsView from "../Views/InterventionsView/InterventionsView";
import OperateursView from "../Views/OperateursView/OperateursView";
import TicketsView from "../Views/TicketsView/TicketsView";
import TicketItemView from "../Views/TicketItemView/TicketItemView";
import FaqView from "../Views/FaqView/FaqView";

import CloudFolderView from "../Views/CloudFolderView/CloudFolderView";
import CalendarView from "../Views/CalendarView/CalendarView";
import SimulateurView from "../Views/SimulateurView/SimulateurView";
import ProspectView from "../Views/ProspectView/ProspectView";
import DevisView from "../Views/DevisView/DevisView";
import CommandeView from "../Views/CommandeView/CommandeView";
import ProspectForm from "../Views/ProspectView/ProspectForm";
import ProspectDetailView from "../Views/ProspectdetailView/ProspectDetailView";
import DevisDetailView from "../Views/DevisdetailView/DevisDetailView";
import DevisForm from "../Views/DevisView/DevisForm";
import CommandeForm from "../Views/CommandeView/CommandeForm";
import CommandeDetail from "../Views/CommandedetailView/CommandeDetail";
import DiffusionView from "../Views/DiffusionView/DiffusionView";
// import TestView from "../Views/TestView/TestView";
import ExternalSyncView from "../Views/ExternalSyncView/ExternalSyncView";
import OneDriveFolderView from "../Views/OneDriveFolderView/OneDriveFolderView";
import SharePointSiteView from "../Views/SharePointSiteView/SharePointSiteView";

import ArtefactoView from "../Views/ArtefactoView/ArtefactoView";
import { useDispatch, useSelector } from "react-redux";
import { selectorganisation } from "../REDUX/actions/organisations";
import NewSocietyView from "../Views/SocietesView/NewSocietyView";
import SocietyItemView from "../Views/SocietyItemView/SocietyItemView";
import GoogleDriveFolderView from "../Views/GoogleDriveFolderView/GoogleDriveFolderView";

const AuthenticatedRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { tabs, organisationUrl, selectedOrganisation, organisations } =
    useSelector((state) => state.organisationsReducer);
  const [isToggle, setIsToggle] = useState("overview");
  const [activeTab, setActiveTab] = useState("folders");
  const [clientVue, setClientVue] = useState(false);
  const [goto, setgoto] = useState(null);

  const NoRedirectUrl = ["external-sync"];

  useEffect(() => {
    if (!NoRedirectUrl.includes(location.pathname.split("/")[1])) {
      const inUrlOrganisation = organisations.filter(
        (org) => org.attributes.url === `/${location.pathname.split("/")[1]}`
      )[0];

      if (!inUrlOrganisation) {
        setgoto(selectedOrganisation.attributes.url);
      } else {
        if (inUrlOrganisation.id !== selectedOrganisation.id) {
          dispatch(selectorganisation(inUrlOrganisation.id));
        }
      }
    }
  }, [location.pathname]);

  return (
    <Switch>
      {tabs.map((tab, index) => {
        let type = tab.attributes.type.name;

        if (type === "Dashboard") {
          return (
            <Route
              key={index}
              path={`${organisationUrl}${tab.attributes.url}`}
              exact
              element={<DashBoardView />}
            ></Route>
          );
        } else if (type === "Tickets") {
          return (
            <React.Fragment key={index}>
              {/* <Route path="test" exact element={<TestView />}></Route> */}
              <Route
                path="external-sync"
                exact
                element={<ExternalSyncView />}
              ></Route>
              <Route
                path={`${organisationUrl}${tab.attributes.url}`}
                exact
                element={<TicketsView />}
              ></Route>
              <Route
                path={`${organisationUrl}${tab.attributes.url}/:id`}
                exact
                element={<TicketItemView />}
              ></Route>
            </React.Fragment>
          );
        } else if (type === "FAQ") {
          return (
            <React.Fragment key={index}>
              <Route
                path={`${organisationUrl}${tab.attributes.url}`}
                exact
                element={<FaqView />}
              ></Route>
            </React.Fragment>
          );
        } else if (type === "Interventions") {
          return (
            <Route
              key={index}
              path={`${organisationUrl}${tab.attributes.url}`}
              exact
              element={<InterventionsView />}
            ></Route>
          );
        } else if (type === "Sociétés") {
          return (
            <React.Fragment key={index}>
              <Route
                path={`${organisationUrl}${tab.attributes.url}`}
                exact
                element={
                  clientVue ? (
                    <ClientsView
                      clientVue={clientVue}
                      setClientVue={setClientVue}
                    />
                  ) : (
                    <NewSocietyView
                      setIsToggle={setIsToggle}
                      clientVue={clientVue}
                      setClientVue={setClientVue}
                    />
                  )
                }
              ></Route>
              <Route
                path={`${organisationUrl}${tab.attributes.url}/:societyId`}
                exact
                element={
                  <SocietyItemView
                    isToggle={isToggle}
                    setIsToggle={setIsToggle}
                  />
                }
              ></Route>
            </React.Fragment>
          );
        } else if (type === "Documents") {
          return (
            <React.Fragment key={index}>
              <Route
                path={`${organisationUrl}${tab.attributes.url}`}
                exact
                element={
                  <CloudView
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                }
              ></Route>
              <Route
                path={`${organisationUrl}/dossier/:id`}
                exact
                element={
                  <CloudFolderView
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                }
              ></Route>
              <Route
                path={`${organisationUrl}/oneDrive/:id`}
                exact
                element={
                  <OneDriveFolderView
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                }
              ></Route>
              <Route
                path={`${organisationUrl}/onedrivefolder/:id`}
                exact
                element={
                  <OneDriveFolderView
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                }
              ></Route>
              <Route
                path={`${organisationUrl}/gDrive/:id`}
                exact
                element={
                  <GoogleDriveFolderView
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                }
              ></Route>
              <Route
                path={`${organisationUrl}/sharepointsite/:id`}
                exact
                element={
                  <SharePointSiteView
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                }
              ></Route>
            </React.Fragment>
          );
        } else if (type === "Opérateurs") {
          return (
            <Route
              key={index}
              path={`${organisationUrl}${tab.attributes.url}`}
              exact
              element={<OperateursView />}
            ></Route>
          );
        } else if (type === "Factures") {
          return (
            <Route
              key={index}
              path={`${organisationUrl}${tab.attributes.url}`}
              exact
              element={<FacturesView />}
            ></Route>
          );
        } else if (type === "Calendriers") {
          return (
            <Route
              key={index}
              path={`${organisationUrl}${tab.attributes.url}`}
              exact
              element={<CalendarView />}
            ></Route>
          );
        } else if (type === "Alertes") {
          return (
            <Route
              key={index}
              path={`${organisationUrl}${tab.attributes.url}`}
              exact
              element={<DiffusionView />}
            ></Route>
          );
        } else if (type === "Prospets") {
          return (
            <React.Fragment key={index}>
              <Route
                path={`${organisationUrl}${tab.attributes.url}`}
                exact
                element={<ProspectView />}
              ></Route>
              <Route
                path={`${organisationUrl}${tab.attributes.url}/new`}
                exact
                element={<ProspectForm />}
                key={index}
              ></Route>
              <Route
                path={`${organisationUrl}${tab.attributes.url}/:id`}
                exact
                element={<ProspectDetailView />}
                key={index}
              ></Route>
              <Route
                path={`${organisationUrl}/configrateur-3d`}
                exact
                element={<ArtefactoView />}
                key={index}
              ></Route>
            </React.Fragment>
          );
        } else if (type === "Devis") {
          return (
            <React.Fragment key={index}>
              <Route
                path={`${organisationUrl}${tab.attributes.url}`}
                exact
                element={<DevisView />}
              ></Route>
              <Route
                path={`${organisationUrl}${tab.attributes.url}/new`}
                exact
                element={<DevisForm />}
                key={index}
              ></Route>
              <Route
                path={`${organisationUrl}${tab.attributes.url}/:id`}
                exact
                element={<DevisDetailView />}
                key={index}
              ></Route>
            </React.Fragment>
          );
        } else if (type === "Commandes") {
          return (
            <React.Fragment key={index}>
              <Route
                path={`${organisationUrl}${tab.attributes.url}`}
                exact
                element={<CommandeView />}
              ></Route>
              <Route
                path={`${organisationUrl}${tab.attributes.url}/new`}
                exact
                element={<CommandeForm />}
                key={index}
              ></Route>
              <Route
                path={`${organisationUrl}${tab.attributes.url}/:id`}
                exact
                element={<CommandeDetail />}
                key={index}
              ></Route>
            </React.Fragment>
          );
        } else if (type === "Configurateur") {
          return (
            <Route
              key={index}
              path={`${organisationUrl}${tab.attributes.url}`}
              exact
              element={<SimulateurView />}
            ></Route>
          );
        }
      })}
      {goto && <Route path="*" element={<Navigate to={goto} replace />} />}
    </Switch>
  );
};

export default AuthenticatedRoutes;
