import React from "react";
import { useDispatch } from "react-redux";
import useWindowWidth from "../../Hooks/useWindowWidth";
import { openModal } from "../../REDUX/actions/modal";
import LightDarkText from "../Common/LightDarkText";
import { AddCloudFolder } from "../Common/LightDarkSvg";
import CreateFolderForm from "./CreateFolderForm";
import FolderCard from "./FolderCard";

import "./FolderList.css";

const FolderList = ({
  dossiers,
  search,
  subFolder = false,
  parentFolder = null,
  isMine = false,
  cloudElementTitle = "Dossiers",
}) => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();

  return (
    <div>
      <LightDarkText className="custom-title-font-size mt-4 mb-3 bold text-start">
        {cloudElementTitle}
      </LightDarkText>

      <div className={`d-flex flex-wrap gap-4 ${subFolder && "mt-4"}`}>
        {isMine && (
          <div
            className="addFolder directionC align-items-center justify-content-center folderCard radius05 pointer"
            style={{ border: "1px solid #A9BCD3" }}
            onClick={() =>
              dispatch(
                openModal({
                  type: "small",
                  content: (
                    <CreateFolderForm
                      subfolder={subFolder}
                      parentFolderId={parentFolder}
                    />
                  ),
                })
              )
            }
          >
            <AddCloudFolder size={screenWidth < 1200 ? 30 : 40} />
            <LightDarkText className="custom-ticket-font-size mt-3 bold text-center">
              Créer un nouveau dossier
            </LightDarkText>
          </div>
        )}

        {dossiers
          ?.filter((el) =>
            el.attributes.name?.toLowerCase().includes(search.toLowerCase())
          )
          .map((dossier) => (
            <FolderCard
              isMine={isMine}
              key={dossier.id}
              dossier={dossier}
              shared={
                dossier?.attributes?.sharedUsers?.data.length ||
                dossier?.attributes?.shared_to_societes?.data.length ||
                dossier?.attributes?.shared_to_roles?.data.length
              }
            />
          ))}
      </div>
    </div>
  );
};

export default FolderList;
