import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetpassword } from "../../REDUX/actions/user";
import useWindowWidth from "../../Hooks/useWindowWidth";
import { getRandomPic } from "../../Utils/utils";
import logoIconColor from "../../Images/agilix-logo-nom.png";
import LightDarkText from "../../Components/Common/LightDarkText";
import ColoredButton from "../../Components/Common/Buttons/ColoredButton";
import "./ResetPasswordView.css";
import LoadingButton from "../../Components/Common/LoadingButton";
import LoginCopyRights from "../../Components/Common/LoginCopyRights";
import { MobileView } from "react-device-detect";

const ResetPasswordView = () => {
  const { resetSuccess, resetError, loadingReset } = useSelector(
    (state) => state.userReducer
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const urlCode = searchParams.get("code");
  const initPassword = searchParams.get("init") === "true";

  const screenWidth = useWindowWidth();

  const [password, setpassword] = useState("");
  const [repassword, setrepassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [rePasswordShown, setRePasswordShown] = useState(false);
  const [infodata, setinfodata] = useState({
    state: null,
    msg: null,
  });
  const [imageBackground, setImageBackground] = useState("");

  useEffect(() => {
    setImageBackground(`url(${getRandomPic()})`);
  }, []);
  useEffect(() => {
    if (resetSuccess) {
      setinfodata({
        state: "success",
        msg: "Mot de passe mis à jour avec succès !",
      });
      setpassword("");
      setrepassword("");
    }
    if (resetError) {
      setinfodata({
        state: "danger",
        msg: "Un probléme est survenue, veuillez réessayer !!",
      });
    }
  }, [resetError, resetSuccess]);
  const CheckSyncPassword = () => {
    setinfodata({
      state: null,
      msg: null,
    });
    if (password !== repassword) {
      setinfodata({
        state: "danger",
        msg: "Les mots de passe sont différents",
      });
    } else {
      dispatch(resetpassword(password, repassword, urlCode, initPassword));
    }
  };
  return (
    <div
      className="resetContainer position-relative bg-light-mode"
      style={{
        backgroundImage: imageBackground,
        backgroundSize: `${screenWidth > 1000 ? "100% 100%" : "cover"}`,
      }}
    >
      {screenWidth > 1000 ? (
        <div className="h100 leftSideForm px-3 bg-white">
          <div className="resetInner w-100 h100">
            <div className="directionC resetForm">
              <Link to={"/"}>
                <div className="mx-auto loginImage-container">
                  <img
                    className="align-self-center loginImage"
                    src={logoIconColor}
                    alt="logo-icon"
                  />
                </div>
              </Link>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  // dispatch(resetpassword(password, repassword, UrlCode));
                }}
              >
                <div className="my-4">
                  <LightDarkText className="text-blue-grey bold custom-font-size mb-4">
                    Réinitialiser le mot de passe
                  </LightDarkText>
                  <div className="custom-ticket-font-size pb-2 bold text-dark-blue text-start">
                    Mot de passe
                  </div>
                  <div className="input-content">
                    <div
                      className="inputIcons pointer"
                      onClick={() => setPasswordShown(!passwordShown)}
                    >
                      {passwordShown ? (
                        <i className="bi f24 text-light-message bi-eye-slash-fill"></i>
                      ) : (
                        <i className="bi f24 text-light-message bi-eye-fill"></i>
                      )}
                    </div>
                    <input
                      className="resetInput text-dark-blue px-3 custom-font-size bold"
                      type={passwordShown ? "text" : "password"}
                      onChange={(e) => setpassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="my-4 directionC">
                  <div className="custom-ticket-font-size pb-2 bold text-dark-blue text-start">
                    Confirmer le mot de passe
                  </div>
                  <div className="input-content">
                    <div
                      className="inputIcons pointer"
                      onClick={() => setRePasswordShown(!rePasswordShown)}
                    >
                      {rePasswordShown ? (
                        <i className="bi f24 text-light-message bi-eye-slash-fill"></i>
                      ) : (
                        <i className="bi f24 text-light-message bi-eye-fill"></i>
                      )}
                    </div>
                    <input
                      className="resetInput text-dark-blue px-3 custom-font-size bold"
                      type={rePasswordShown ? "text" : "password"}
                      onChange={(e) => setrepassword(e.target.value)}
                    />
                  </div>
                </div>
                {infodata.state === "success" ? null : (
                  <div className="mt-4">
                    <ColoredButton
                      textButton={
                        loadingReset ? (
                          <LoadingButton />
                        ) : (
                          "Réinitialiser le mot de passe"
                        )
                      }
                      color="light-blue"
                      loginBtn
                      callback={() => CheckSyncPassword()}
                      disabled={
                        !password ||
                        !repassword ||
                        password !== repassword ||
                        resetSuccess
                      }
                    />
                  </div>
                )}
                {resetError || resetSuccess ? (
                  <div className="mt-2">
                    <div
                      className={`alert mb-2 alert-${infodata.state} custom-ticket-font-size text-light-message regular`}
                      role="alert"
                    >
                      {infodata.msg}
                    </div>
                  </div>
                ) : null}
                {infodata.state === "success" && (
                  <ColoredButton
                    textButton={"Se Connecter"}
                    color="light-blue"
                    outlined
                    loginBtn
                    callback={() => navigate("/")}
                  />
                )}
              </form>
            </div>
            <LoginCopyRights />
          </div>
        </div>
      ) : (
        <MobileView />
      )}

      {/* <p
        className="p-0 m-0 f12 text-end text-white imageText"
        style={{ position: "absolute", bottom: 10, right: 10, zIndex: 10 }}
      >
        Image générée par l’IA prochainement disponible sur Agilix.io
      </p> */}
    </div>
  );
};

export default ResetPasswordView;
