import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotpassword } from "../../REDUX/actions/user";
import useWindowWidth from "../../Hooks/useWindowWidth";
import { validMail, getRandomPic } from "../../Utils/utils";
import logoIconColor from "../../Images/agilix-logo-nom.png";
import ColoredButton from "../../Components/Common/Buttons/ColoredButton";
import LightDarkText from "../../Components/Common/LightDarkText";
import { LetterIcon } from "../../Components/Common/LightDarkSvg";

import "./ForgotPasswordView.css";
import LoginCopyRights from "../../Components/Common/LoginCopyRights";
import { MobileView } from "react-device-detect";

const ForgotPasswordView = () => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();

  const [email, setemail] = useState("");
  const [sendConfirmation, setsendConfirmation] = useState(false);
  const [imageBackground, setImageBackground] = useState("");

  useEffect(() => {
    setImageBackground(`url(${getRandomPic()})`);
  }, []);

  const onFormSubmit = (e) => {
    e.preventDefault();
    // dispatch(forgotpassword(email)) && setsendConfirmation(true);
  };

  return (
    <div
      className="forgotContainer position-relative bg-light-mode"
      style={{
        backgroundImage: imageBackground,
        backgroundSize: `${screenWidth > 1000 ? "100% 100%" : "cover"}`,
      }}
    >
      {screenWidth > 1000 ? (
        <div className="h100 leftSideForm px-3 bg-white">
          <div className="forgotInner w-100 h100">
            <div className="directionC w-100 forgotForm">
              <Link to={"/"}>
                <div className="mx-auto loginImage-container">
                  <img
                    className="align-self-center loginImage"
                    src={logoIconColor}
                    alt="logo-icon"
                  />
                </div>
              </Link>
              <form onSubmit={onFormSubmit}>
                <div className="my-4">
                  <LightDarkText
                    className="bold custom-font-size mb-4"
                    color="text-blue-grey"
                  >
                    Mot de passe oublié ?
                  </LightDarkText>
                  <div className="custom-ticket-font-size pb-2 bold text-dark-blue text-start">
                    E-mail
                  </div>
                  <div className="input-content">
                    <div className="inputIcons">
                      <LetterIcon darkColor="#414A6E" />
                    </div>
                    <input
                      className="loginInput text-dark-blue px-3 custom-font-size bold"
                      type={"text"}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <ColoredButton
                    textButton={"Réinitialiser le mot de passe"}
                    color="light-blue"
                    loginBtn
                    callback={() =>
                      dispatch(forgotpassword(email)) &&
                      setsendConfirmation(true)
                    }
                    disabled={!validMail(email)}
                  />
                  <a
                    className={
                      "d-flex align-items-center text-light-blue bold custom-ticket-font-size forgottenPassword mt-2"
                    }
                    href={"/"}
                  >
                    <i className="bi bi-arrow-left me-2 f14"></i>
                    Revenir en arrière
                  </a>
                  {sendConfirmation && (
                    <div className="mt-3 w-100">
                      <div
                        className="alert alert-success custom-ticket-font-size text-light-message regular"
                        role="alert"
                      >
                        Si vous avez un compte, un e-mail a été envoyé à
                        l'adresse indiquée. Suivez les instructions pour créer
                        un nouveau mot de passe.
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </div>
            <LoginCopyRights />
          </div>
        </div>
      ) : (
        <MobileView />
      )}

      {/* <p
        className="p-0 m-0 f12 text-end text-white imageText"
        style={{ position: "absolute", bottom: 10, right: 10, zIndex: 10 }}
      >
        Image générée par l’IA prochainement disponible sur Agilix.io
      </p> */}
    </div>
  );
};

export default ForgotPasswordView;
