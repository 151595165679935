import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { handleFirstLastName } from "../../../Utils/utils";
import { SharedFolder } from "../../Common/LightDarkSvg";
import { useDispatch, useSelector } from "react-redux";
import LightDarkText from "../../Common/LightDarkText";
import ShareFolder from "../ShareFolder";
import { openModal } from "../../../REDUX/actions/modal";

const FileSharedSection = ({ file, isMine }) => {
  const dispatch = useDispatch();
  const rolesNames = [
    "Les superviseurs",
    "Les Opérateurs",
    "Les Administrateurs Clients",
    "Les Collaborateurs",
    "Les Invités",
  ];

  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );

  const openShares = () => {
    if (isMine) {
      dispatch(
        openModal({
          type: "small",
          content: (
            <ShareFolder
              folder={file}
              folderSharedUsers={file?.attributes?.sharedUsers}
              parentFolder={file?.attributes?.parentFolder?.data}
            />
          ),
        })
      );
    }
  };

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id={`tooltip-${file?.id}`}>
          {isMine ? (
            <LightDarkText className="custom-ticket-font-size py-1 text-white text-start">
              Partagé avec{" "}
              {file.attributes.sharedUsers.data.length > 0 && (
                <div>
                  {`${file.attributes.sharedUsers.data.length} utilisateur(s)`}
                  <br />
                </div>
              )}
              {file.attributes.shared_to_societes.data.length > 0 && (
                <div>
                  {`${file.attributes.shared_to_societes.data.length} société(s)`}
                  <br />
                </div>
              )}
              {file.attributes.shared_to_roles.data.length > 0 &&
                file.attributes.shared_to_roles.data.map((el, index) => (
                  <div key={index}>
                    {`${rolesNames[el.id - 1]} de ${
                      selectedOrganisation.attributes.name
                    }`}
                    <br />
                  </div>
                ))}
            </LightDarkText>
          ) : (
            `Partagé par 
                  ${handleFirstLastName(
                    file?.attributes?.cloud?.data?.attributes
                      .user_org_permission?.data?.attributes?.user?.data
                  )}`
          )}
        </Tooltip>
      }
    >
      <button
        className="pe-0 bg-transparent border-0 outline-none"
        onClick={openShares}
      >
        <SharedFolder color={isMine ? "darkBlue" : ""} />
      </button>
    </OverlayTrigger>
  );
};

export default FileSharedSection;
