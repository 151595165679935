import React, { useEffect } from "react";
import LightDarkText from "../Common/LightDarkText";
import LoginCopyRights from "../Common/LoginCopyRights";
import logoIconColor from "../../Images/agilix-logo-nom.png";
import appStoreLogo from "../../Images/mobile/downloadOnAppStore.svg";
import playStoreLogo from "../../Images/mobile/downloadOnPlayStore.png";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Common/Loading";
import { fetchLoginViewData } from "../../REDUX/actions/loginData";
import { ANDROID_APP_STORE_URL, IOS_APP_STORE_URL } from "../../Api/endPoint";

const MobileView = ({ first }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { loginDataLoading, loginImageSelected } = useSelector(
    (state) => state.loginViewDataReducer
  );

  useEffect(() => {
    dispatch(fetchLoginViewData(params["*"]));
  }, []);
  return loginDataLoading ? (
    <Loading />
  ) : (
    <div
      className="loginContainer position-relative bg-light-mode"
      style={{
        backgroundImage: `url(${loginImageSelected})`,
      }}
    >
      <div className="directionC p-1 justify-content-center align-items-center h100 w100 border">
        <div
          className={`bg-white radius05 p-4 text-center w-100`}
          style={{ opacity: "90%" }}
        >
          <img
            className="align-self-center"
            src={logoIconColor}
            alt="logo-icon"
            width={100}
          />
          <LightDarkText
            className="f16 bold text-justify my-4"
            color="text-dark-blue"
          >
            T&eacute;l&eacute;chargez d&egrave;s maintenant notre logiciel
            conversationnel sur votre mobile et transformez votre mani&egrave;re
            d'&eacute;changer avec vos clients et collaborateurs.
          </LightDarkText>

          <div className="alignC gap-2">
            {/* {isIOS ? (
              <a href={IOS_APP_STORE_URL}>
                <img src={appStoreLogo} height={40} width={"auto"} />
              </a>
            ) : isAndroid ? (
              <a href={ANDROID_APP_STORE_URL} target="_blank">
                <img src={playStoreLogo} height={40} width={"auto"} />
              </a>
            ) : (
              <> */}
            <a href={IOS_APP_STORE_URL}>
              <img src={appStoreLogo} height={40} width={"auto"} />
            </a>
            <a href={ANDROID_APP_STORE_URL}>
              <img src={playStoreLogo} height={40} width={"auto"} />
            </a>
            {/* </>
            )} */}
          </div>
          {/* <img src={playStoreLogo} width={100} /> */}
        </div>
        <div className="mt-2">
          <LoginCopyRights color="text-white" />
        </div>
      </div>
    </div>
  );
};

export default MobileView;
