import React from "react";
import { useSelector } from "react-redux";

import LightDarkText from "../../../../Common/LightDarkText";
import AttachementFiles from "../../../../Common/AttachementFiles";

import {
  handleOldInterventionMessages,
  messageType,
} from "../../../ticketUtils";
import MessageInnerHtmlText from "../../TicketChatContainer/MessagesList/MessageCard/MessageCardContainer/MessageInnerHtmlText";

const InterventionActionMessage = ({ message, measureRef }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );
  const handdleScroll = (id) => {
    document.querySelector(`#inter${id}`).scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div
      className={`radius05 pointer m-auto d-flex my-2 gap-4 align-items-center p-2 example-${
        messageType(message?.attributes?.type)?.color
      }`}
      onClick={() =>
        handdleScroll(message?.attributes?.relatedIntervention.data.id)
      }
      ref={measureRef}
    >
      <LightDarkText
        className="flex-1"
        color={mode === "light" ? "text-blue-grey" : "text-white"}
      >
        <div className="text-center bold custom-ticket-font-size">
          {message.attributes.oldId
            ? handleOldInterventionMessages(message)
            : messageType(
                message.attributes.type,
                message?.attributes?.relatedIntervention.data.id,
                message?.attributes?.createdAt,
                message?.attributes?.sender.data,
                selectedOrganisation.attributes.singleOperator
              ).longText}
        </div>
        {message.attributes.content && (
          <div className="mt-2">
            <MessageInnerHtmlText
              htmlContent={message.attributes.content}
              message={message}
            />
          </div>
        )}
        <AttachementFiles message={message} />
      </LightDarkText>
    </div>
  );
};

export default InterventionActionMessage;
