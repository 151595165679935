import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../../REDUX/actions/modal";
import {
  actionIntervention,
  updateInterventionDuration,
} from "../../../../REDUX/actions/interventions";
import { CloseIcon } from "../../../Common/LightDarkSvg";
import ColoredButton from "../../../Common/Buttons/ColoredButton";
import InMessageInput from "../InMessageInput";
import { getMessages } from "../../../../REDUX/actions/messages";
import { handleDuration } from "../../../Tickets/ticketUtils";
import LightDarkTextEditor from "../../../Common/LightDarkTextEditor";
import { prepareInterventionMentions } from "../../interventionUtils";

const InterventionConfirmModal = ({
  intervention,
  title,
  statut,
  text,
  color,
  background,
  icon,
  outlined,
  ticket,
  addDuration = false,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const { temporarySelectedIntervention } = useSelector(
    (state) => state.filesReducer
  );
  const { interventionRoute } = useSelector(
    (state) => state.organisationsReducer
  );
  const currentRoute = useSelector((state) => state.socketReducer.route);
  const { admins } = useSelector((state) => state.sharedDataReducer);
  const visibleBy =
    useSelector(
      (state) => state.ticketsReducer.ticket?.attributes?.visibleBy
    ) || [];
  const { interventionActionLoading } = useSelector(
    (state) => state.interventionsReducer
  );
  const [raison, setraison] = useState({ raison: "" });
  const [duration, setduration] = useState({
    duration: intervention.attributes.duration,
  });
  const [mentions, setMentions] = useState([]);
  const handleInterventionActions = (action, title) => {
    let messageData = {
      ticket: ticket.id,
      relatedIntervention: intervention.id,
      sender: user.id,
      type: action,
      content: `${raison.raison ? `${raison.raison}` : ""}${
        intervention.attributes.duration !== duration.duration
          ? `<strong> Durée </strong>: ${handleDuration(duration.duration)}`
          : ""
      }`,
      is_internal: false,
      mentions: mentions.map((mention) => mention.insert.mention.id),
    };

    let actionLogData = {
      title: title,
      intervention: intervention.id,
      by: user.id,
    };
    if (statut === 3) {
      dispatch(updateInterventionDuration(intervention.id, duration.duration));
      dispatch(getMessages(ticket.id, 0, false));
    }

    dispatch(
      actionIntervention(
        action,
        {
          messageData: messageData,
          attachedFiles: { files: temporarySelectedIntervention },
        },
        actionLogData
      )
    );
  };
  return (
    <div className="p-3 directionC bg-white radius05">
      <div
        className="align-self-end mb-3"
        onClick={() => {
          dispatch(closeModal());
          dispatch({ type: "CLEAR_UPLOAD" });
        }}
      >
        <CloseIcon />
      </div>

      <div>
        <LightDarkTextEditor
          height={"80"}
          totalInputs={raison}
          setValue={setraison}
          champs="raison"
          value={raison}
          autoHeight
          reformulateText
          canMention={true}
          setMentions={setMentions}
          placeholder={title}
          users={prepareInterventionMentions(
            admins,
            interventionRoute === currentRoute ? [] : visibleBy
          )}
        />
        <InMessageInput
          duration={duration}
          setduration={setduration}
          raison={raison}
          setValue={setraison}
          placeholder={title}
          statut={statut}
          addDuration={addDuration}
          isInterventionRevision
        />
      </div>
      <div className="col-4 align-self-center mt-5">
        <ColoredButton
          color={color}
          background={background}
          icon={icon}
          outlined={outlined}
          loading={interventionActionLoading}
          disabled={interventionActionLoading}
          square
          textButton={"Valider"}
          callback={() => {
            handleInterventionActions(statut, text);
            dispatch(closeModal());
          }}
        />
      </div>
    </div>
  );
};

export default InterventionConfirmModal;
