import moment from "moment";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InterventionTitle from "./InterventionTitle";
import InterventionStatus from "./InterventionStatus";
import InterventionDuration from "./InterventionDuration";
import InterventionDescription from "./InterventionDescription";
import InterventionCreationDate from "./InterventionCreationDate";
import AttachementFiles from "../../../../Common/AttachementFiles";
import InterventionActions from "../../../../Interventions/InterventionActions/InterventionActions";
import { messageType } from "../../../ticketUtils";
import { getCurrentPlan } from "../../../../../REDUX/actions/plan";
import InterventionFree from "./InterventionFree";
import MessageInnerHtmlText from "../../TicketChatContainer/MessagesList/MessageCard/MessageCardContainer/MessageInnerHtmlText";

const InterventionMessage = ({ message }) => {
  const dispatch = useDispatch();
  const [interventionData, setinterventionData] = useState(
    message?.attributes?.relatedIntervention.data.attributes
  );

  const { mode } = useSelector((state) => state.genearlReducer);
  const { ticket } = useSelector((state) => state.ticketsReducer);
  const { user } = useSelector((state) => state.userReducer);

  useEffect(() => {
    setinterventionData(
      message?.attributes?.relatedIntervention.data.attributes
    );
  }, [
    message,
    message?.attributes?.relatedIntervention?.data?.attributes?.statut,
  ]);

  useEffect(() => {
    dispatch(
      getCurrentPlan(
        message?.attributes?.relatedIntervention.data?.attributes?.societe?.data
          ?.id,
        moment().format("YYYY-MM-01")
      )
    );
  }, [
    dispatch,
    message?.attributes?.relatedIntervention.data?.attributes?.societe?.data
      ?.id,
  ]);

  return (
    <div
      id={`inter${message.attributes.relatedIntervention.data.id}`}
      className={`w-100 d-flex flex-column gap-3 align-items-start mt-4 mb-2 messageCardMessage p-4 radius05 position-relative
      ${
        mode === "light" && interventionData?.statut === 1
          ? "example-lightMode"
          : `example-${messageType(interventionData.statut).color}`
      }`}
    >
      <InterventionStatus
        interventionStatus={interventionData?.statut}
        message={message}
      />
      <InterventionTitle
        id={message?.attributes?.relatedIntervention.data.id}
        title={interventionData?.title}
      />
      {message.attributes.type && (
        <>
          <MessageInnerHtmlText
            htmlContent={interventionData?.description}
            message={message}
          />
          {/* <InterventionDescription
            description={interventionData?.description}
          /> */}
          <div className="d-flex align-items-center justify-content-start gap-4">
            <InterventionCreationDate createdAt={interventionData?.createdAt} />
            <svg
              width="4"
              height="4"
              viewBox="0 0 4 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="2" cy="2" r="2" fill="#59638B" />
            </svg>
            <InterventionDuration duration={interventionData?.duration} />
            {interventionData.hors_forfait && (
              <>
                {" "}
                <svg
                  width="4"
                  height="4"
                  viewBox="0 0 4 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="2" cy="2" r="2" fill="#59638B" />
                </svg>
                <InterventionFree />
              </>
            )}
          </div>
          <AttachementFiles message={message} />
        </>
      )}
      <div className="w-100 m-auto">
        <InterventionActions
          intervention={message?.attributes?.relatedIntervention.data}
          ticket={ticket}
        />
      </div>
    </div>
  );
};

export default InterventionMessage;
