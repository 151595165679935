import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../REDUX/actions/modal";
import { shareFile } from "../../REDUX/actions/cloud";
import LightDarkContainer from "../Common/LightDarkContainer";
import LightDarkText from "../Common/LightDarkText";
import ColoredButton from "../Common/Buttons/ColoredButton";
import LighDarkMultiChoice from "../Common/LighDarkMultiChoice";
import { CloseIcon } from "../Common/LightDarkSvg";

const ShareFile = ({ file }) => {
  const dispatch = useDispatch();

  const { societes } = useSelector((state) => state.sharedDataReducer);
  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );
  const visibleToData = [
    {
      label: `Superviseurs de ${selectedOrganisation.attributes.name}`,
      value: 1,
      field: "share_to_supervisors",
    },
    {
      label: `Opérateurs de ${selectedOrganisation.attributes.name}`,
      value: 2,
      field: "share_to_operators",
    },
    {
      label: `Administrateurs clients de ${selectedOrganisation.attributes.name}`,
      value: 3,
      field: "share_to_admins",
    },
    {
      label: `Clients de ${selectedOrganisation.attributes.name}`,
      value: 4,
      field: "share_to_clients",
    },
    {
      label: `Invités de ${selectedOrganisation.attributes.name}`,
      value: 5,
      field: "share_to_guests",
    },
  ];
  const { user } = useSelector((state) => state.userReducer);
  const { canShareTo } = useSelector((state) => state.sharedDataReducer);
  const [sharedUsers, setSharedUsers] = useState({
    sharedUsers: canShareTo.filter((el) =>
      file.attributes.sharedUsers.data.map((s) => +s.id).includes(+el.value)
    ),
  });
  const [shareToRoles, setShareToRoles] = useState({
    visibleTo: visibleToData.filter((el) =>
      file.attributes.shared_to_roles.data.map((s) => +s.id).includes(+el.value)
    ),
  });
  const [sharedSocieties, setSharedSocieties] = useState({
    shared_to_societes: societes.filter((el) =>
      file.attributes.shared_to_societes.data
        .map((s) => +s.id)
        .includes(+el.value)
    ),
  });
  const handleSubmit = () => {
    dispatch(
      shareFile(
        file.id,
        sharedUsers.sharedUsers,
        shareToRoles.visibleTo,
        sharedSocieties.shared_to_societes
      )
    );
    dispatch(closeModal());
  };

  return (
    <LightDarkContainer
      className="p-4 radius05 directionC"
      colorLight="white"
      colorDark="dark-mode"
    >
      <div
        className="d-flex justify-content-end mb-2"
        onClick={() => dispatch(closeModal())}
      >
        <CloseIcon />
      </div>
      <LightDarkText className="text-center custom-title-font-size bold mb-4">
        Partager le fichier
      </LightDarkText>
      <div className="d-flex flex-column gap-3">
        <div className="text-start">
          <div onSubmit={(e) => e.preventDefault()}>
            <LighDarkMultiChoice
              label="Partage par utilisateur"
              data={canShareTo.filter((el) => el.value !== user.id)}
              totalInputs={sharedUsers}
              setValue={setSharedUsers}
              champs="sharedUsers"
              placeholder="Sélectionner un ou plusieurs utilisateur(s)"
            />
          </div>
        </div>
        <div className="text-start">
          <LighDarkMultiChoice
            label="Partage par role"
            totalInputs={shareToRoles}
            setValue={setShareToRoles}
            data={visibleToData}
            champs={"visibleTo"}
          />
        </div>
        <div className="text-start">
          <LighDarkMultiChoice
            label="Partage par société"
            data={societes}
            totalInputs={sharedSocieties}
            setValue={setSharedSocieties}
            champs="shared_to_societes"
            multiple={true}
            societySelectInput
          />
        </div>
      </div>
      <div className="col-6 align-self-center mb-3 mt-5">
        <ColoredButton
          textButton="Enregistrer les modifications"
          // disabled={!sharedUsers.sharedUsers?.length > 0}
          color="light-blue"
          callback={handleSubmit}
        />
      </div>
    </LightDarkContainer>
  );
};

export default ShareFile;
