import React, { useEffect } from "react";
import Slider from "react-rangeslider";
import { useSelector } from "react-redux";

import { handleDuration } from "../Tickets/ticketUtils";
import LightDarkText from "./LightDarkText";
import MoreInfo from "./MoreInfo";

import "react-rangeslider/lib/index.css";
import "./CustomRange.css";

const CustomRange = ({
  min,
  max,
  label,
  totalInputs,
  setValue,
  champs,
  editbundle,
  disabled = false,
  packageMessage = false,
  unit = "duration",
  bold = false,
  isString = false,
  step = 0.5,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  const handleSlide = (value) => {
    if (!disabled) {
      setValue({
        ...totalInputs,
        [champs]: isString ? value.toString() : parseFloat(value),
      });
    }
  };
  const handleAdd = () => {
    const actualValue = parseFloat(totalInputs[champs]) || 0;

    if (actualValue < max) {
      setValue({
        ...totalInputs,
        [champs]: actualValue + step,
      });
    }
  };
  const handleMinus = () => {
    const actualValue = parseFloat(totalInputs[champs]) || 0;
    if (actualValue > min) {
      setValue({
        ...totalInputs,
        [champs]: actualValue - step,
      });
    }
  };
  useEffect(() => {
    if (max === 0) {
      setValue({
        ...totalInputs,
        [champs]: 0,
      });
    }
  }, [max]);

  return (
    <div className="mt-3">
      <div className="d-flex justify-content-between">
        <LightDarkText
          className={`alignH gap-3 text-start bold custom-ticket-font-size ${
            bold ? "sub_standard" : ""
          }`}
          color={mode === "light" ? "text-blue-grey" : "text-white"}
        >
          {label}
        </LightDarkText>
        {!packageMessage && (
          <LightDarkText
            className="text-start custom-ticket-font-size bold alignH"
            color={mode === "light" ? "text-dark-blue" : "text-light-text"}
          >
            {unit === "duration"
              ? handleDuration(totalInputs[champs])
              : `${totalInputs[champs] || ""} ${unit}`}
          </LightDarkText>
        )}
        {packageMessage && <MoreInfo text={"?"} right />}
      </div>
      <div className="alignH gap-2">
        <i
          className="bi bi-arrow-bar-left f14 text-light-text pointer t-02 clickable-text"
          onClick={handleMinus}
        ></i>
        <div className="flex-1">
          <Slider
            className={`customRange mt-3 customRange${
              disabled ? "disabled" : ""
            } ${mode === "light" ? "lightRange" : "darkRange"}`}
            min={min}
            value={totalInputs[champs]}
            onChange={(e) => (editbundle ? handleSlide(e) : null)}
            max={max}
            tooltip={true}
            step={step}
            disabled={disabled}
          />
        </div>

        <i
          className="bi bi-arrow-bar-right f14 text-light-text pointer t-02 clickable-text"
          onClick={handleAdd}
        ></i>
      </div>
      {packageMessage && (
        <div className="d-flex justify-content-between">
          <LightDarkText className="text-start custom-font-size bold">
            + {handleDuration(totalInputs[champs])}
          </LightDarkText>
          <div className="d-flex justify-content-between">
            <LightDarkText className="text-start custom-font-size bold text-decoration-line-through me-3">
              1200€
            </LightDarkText>
            <LightDarkText className="text-start custom-font-size bold">
              1140€
            </LightDarkText>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomRange;
