import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import TicketChat from "./TicketMessages/TicketChat";
import TicketsList from "./TicketsList/TicketsList";

import { scrollTo } from "../../Utils/utils";
import TicketRightSection from "./RightPanel/TicketRightSection";

const TicketItemContainer = () => {
  const { ticket, expandedChatList } = useSelector(
    (state) => state.ticketsReducer
  );

  const [responseTo, setResponseTo] = useState(null);
  const [internalResponseTo, setInternalResponseTo] = useState(null);
  const [isReplying, setIsReplying] = useState(null);

  useEffect(() => {
    scrollTo(`chat${ticket.id}`);
  }, [ticket?.id]);

  const resetReplying = () => {
    setResponseTo(null);
    setInternalResponseTo(null);
    setIsReplying(null);
  };

  return (
    (ticket?.attributes ||
      !ticket?.attributes?.notifications?.data?.length) && (
      <div
        className="container-fluid d-flex"
        style={{
          height: "calc(100vh - 170px)",
        }}
      >
        <TicketsList resetReplying={resetReplying} />
        <div
          className={`${expandedChatList ? "col-6 px-4" : "col-8 px-4"} t-02`}
        >
          <TicketChat
            resetReplying={resetReplying}
            isReplying={isReplying}
            setIsReplying={setIsReplying}
            responseTo={responseTo}
            setResponseTo={setResponseTo}
            internalResponseTo={internalResponseTo}
            setInternalResponseTo={setInternalResponseTo}
          />
        </div>
        <TicketRightSection />
      </div>
    )
  );
};

export default TicketItemContainer;
