import React, { useEffect, useRef, useState } from "react";
import LightDarkText from "../Common/LightDarkText";
import "./FilesList.css";
import { fileUrl } from "../../Utils/utils";
import CloudFileCard from "./OneDrive/CloudFileCard";
import SelectCloudBox from "./SelectCloudBox";

const FilesList = ({
  fichiers,
  search,
  isMine,
  cloudElementTitle = "Fichiers",
}) => {
  const scrollRef = useRef(null);
  const [selected, setSelected] = useState([]);

  const [filteredFiles, setFilteredFiles] = useState(fichiers);

  useEffect(() => {
    setFilteredFiles(
      fichiers.filter(
        (el) =>
          el.attributes?.file?.data?.attributes?.name
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          el.attributes?.name?.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, fichiers]);
  return (
    <div className="position-relative mt-5 w-100">
      <LightDarkText className="custom-title-font-size bold text-start mb-3">
        {cloudElementTitle}
      </LightDarkText>

      <SelectCloudBox
        isFolder={false}
        hideDelete={!isMine}
        key={1}
        selected={selected}
        setSelected={setSelected}
        allItems={filteredFiles.map((el) => {
          return {
            name: el?.attributes?.file?.data?.attributes?.name,
            id: el?.id,
            mimeType: el?.attributes?.file?.data?.attributes?.mime,
            url: fileUrl(el?.attributes?.file?.data?.attributes?.url),
          };
        })}
        type="agilix"
      />
      <div
        className="cloudFileList justify-content-start flex-wrap d-flex gap-4 mt-2 ps-2 pt-4"
        ref={scrollRef}
      >
        {filteredFiles?.map((fichier) => (
          <CloudFileCard
            isMine={isMine}
            file={fichier}
            name={
              fichier?.attributes?.name ||
              fichier?.attributes?.file?.data?.attributes?.name
            }
            id={fichier?.id}
            mimeType={fichier?.attributes?.file?.data?.attributes?.mime}
            size={""}
            creationDate={fichier?.attributes.file.data?.attributes.createdAt}
            url={fileUrl(fichier?.attributes?.file?.data?.attributes?.url)}
            thumbnailUrl={fileUrl(
              fichier?.attributes?.file?.data?.attributes?.url
            )}
            downloadUrl={fileUrl(
              fichier?.attributes?.file?.data?.attributes?.url
            )}
            setSelected={setSelected}
            selected={selected}
            key={fichier.id}
            shared={
              fichier?.attributes?.sharedUsers?.data.length ||
              fichier?.attributes?.shared_to_societes?.data.length ||
              fichier?.attributes?.shared_to_roles?.data.length
            }
            type="agilix"
          />
        ))}
      </div>
    </div>
  );
};

export default FilesList;
