import Api from "../../Api/Api";
import { CREATE_FILE, RENAME_FILE } from "../Graphql/Mutations/cloud";
import { UPLOAD } from "../Graphql/Mutations/files";
import store from "../store/store";
import client from "./Client";
import { getCloud, getCloudFiles, getFolderItem } from "./cloud";
import { fetchOneDrive, getOneDriveFolder } from "./externalServices";
import {
  fetchGoogleDrive,
  getGoogleDriveFolder,
} from "./externalServices/gdrive";
import { closeModal } from "./modal";
import { handleError, handleInfo } from "./toastMessage";

export const selectFiles = (files) => async (dispatch) => {
  dispatch({ type: "SELECT_TEMPORARY_FILE", payload: [...files] });
};
export const unSelectFiles = (file) => async (dispatch) => {
  dispatch({ type: "UNSELECT_TEMPORARY_FILE", payload: file });
};
export const selectFilesIntervention = (files) => async (dispatch) => {
  dispatch({ type: "SELECT_TEMPORARY_FILE_INTERVENTION", payload: [...files] });
};

export const changeConfidentiality = (isConfidentiel) => async (dispatch) => {
  dispatch({ type: "CHANGE_CONFIDENTIALITY", payload: isConfidentiel });
};

export const clearTempUpload = () => async (dispatch) => {
  dispatch({ type: "CLEAR_UPLOAD" });
};

export const uploadFiles =
  (links, attachementFiles, showPercentage = true, hanleModal = false) =>
  async (dispatch) => {
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    const user = store.getState().userReducer.user;

    if (!orgId) {
      return;
    }
    const userOrg = await user.attributes?.user_org_permissions?.data?.filter(
      (userOrgElt) => +userOrgElt?.attributes?.organisation?.data?.id === +orgId
    )[0];
    dispatch({ type: "LOADING_UPLOAD" });
    let fileIds = [];

    try {
      if (attachementFiles?.length) {
        let uploadedFiles = [];
        for (let i = 0; i < attachementFiles.length; i++) {
          const file = attachementFiles[i];
          const formData = new FormData();
          formData.append("files", file);
          const fileUploaded = await Api.post(`/api/upload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          uploadedFiles = [...uploadedFiles, fileUploaded.data[0]];
          if (showPercentage) {
            dispatch({
              type: "ONGOING_UPLOADING",
              payload: {
                percentage: (i * 100) / attachementFiles.length,
                statut: "En cours d'upload des fichiers Joints",
              },
            });
          }
        }
        for (let i = 0; i < uploadedFiles.length; i++) {
          const uploadedFile = uploadedFiles[i];

          const createdFichier = await client.mutate({
            mutation: CREATE_FILE,
            variables: {
              file: uploadedFile.id,
              ticket: links.ticket,
              name: uploadedFile.name,
              cloud: userOrg?.attributes?.cloud?.data?.id,
              message: links.message,
              folder: links.folder,
              intervention: links.intervention,
              confidentiel: attachementFiles.isConfidentiel,
              sharedUsers: links.sharedUsers,
            },
          });
          fileIds = [...fileIds, createdFichier.data.createFichier.data.id];
        }
        if (showPercentage) {
          dispatch({ type: "UPLOAD_DONE" });
        }
        if (hanleModal) {
          dispatch(getCloud());
          dispatch(closeModal());
          dispatch(handleInfo("Fichiers enregistrés", 1, "light-blue"));
        }
        dispatch({ type: "CLEAR_UPLOAD" });
        return fileIds;
      } else {
        dispatch({ type: "CLEAR_UPLOAD" });
      }
    } catch (error) {
      dispatch(
        handleError(
          "Erreur lors de l'upload des fichiers joints, réessayer plus tard",
          1
        )
      );
      dispatch({ type: "CLEAR_UPLOAD" });
      throw dispatch(closeModal());
    }
  };

export const handleRefetchCloud =
  (type = "agilix", isFiles = false) =>
  async (dispatch, getState) => {
    const { documentsRoute } = getState().organisationsReducer;
    const location = getState().socketReducer.route;
    if (type === "agilix") {
      if (location.toLowerCase() === documentsRoute.toLowerCase()) {
        if (isFiles) {
          dispatch(getCloudFiles(false));
        } else {
          dispatch(getCloud(false));
        }
      } else if (location.includes("/dossier/")) {
        const folderId = location.split("/dossier/")[1];
        dispatch(getFolderItem(folderId, false));
      }
    } else if (type === "oneDrive") {
      if (location.toLowerCase() === documentsRoute.toLowerCase()) {
        dispatch(fetchOneDrive(false));
      } else if (location.includes(type)) {
        const folderId = location.split(`${type}/`)[1];
        dispatch(getOneDriveFolder(folderId, false));
      }
    } else if (type === "gDrive") {
      if (location.toLowerCase() === documentsRoute.toLowerCase()) {
        dispatch(fetchGoogleDrive(false));
      } else if (location.includes(type)) {
        const folderId = location.split(`${type}/`)[1];

        dispatch(getGoogleDriveFolder(folderId, false));
      }
    }
  };

export const renameFile = (newName, fileId) => async (dispatch) => {
  try {
    await client.mutate({
      mutation: RENAME_FILE,
      variables: {
        id: fileId,
        name: newName,
      },
    });

    dispatch(handleRefetchCloud());

    dispatch(closeModal());
  } catch (error) {
    dispatch(handleError("Erreur réseau", 1));
  }
};

export const deleteMultipleFiles =
  (filesArray) => async (dispatch, getState) => {
    const orgId = getState().organisationsReducer.selectedOrganisation.id;
    try {
      await Api.post(`/api/deleteMultipleFiles?organisation=${orgId}`, {
        data: filesArray,
      });

      dispatch(handleRefetchCloud("agilix", true));
      dispatch(closeModal());
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
    }
  };
