import React, { useEffect, useState } from "react";
import UserSmallCard from "../../Clients/UserSmallCard";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../REDUX/actions/modal";
import AddParticipants from "./AddParticipants";
import ParticipantsPlaceHolder from "../../PlaceHolders/ParticipantsPlaceHolder";
import { handleTicketParticipants } from "../ticketUtils";

const RightParticipants = ({ ticket }) => {
  const dispatch = useDispatch();

  const { isSupervisor, canManageParticipants } = useSelector(
    (state) => state.roleReducer
  );
  const { messagesLoading, messages } = useSelector(
    (state) => state.messagesReducer
  );
  const { admins } = useSelector((state) => state.sharedDataReducer);

  const { showPrivateMessages } = useSelector((state) => state.messagesReducer);

  const privateVisibleBy = ticket?.attributes?.visibleBy?.filter(
    (el) => el?.attributes?.user_org_permissions?.data[0]?.attributes?.role <= 2
  );
  const [clientVisibleBy, setclientVisibleBy] = useState(
    ticket?.attributes?.visibleBy
  );
  useEffect(() => {
    setclientVisibleBy(ticket?.attributes?.visibleBy);
  }, [ticket?.attributes?.visibleBy.map((el) => el.id)]);

  return (
    <div className="d-flex flex-column justify-content-between h100 gap-2 notScrollable">
      <div className="mb-3 scrollable">
        {messagesLoading ? (
          <ParticipantsPlaceHolder />
        ) : (
          handleTicketParticipants(
            ticket,
            admins,
            messages,
            showPrivateMessages
          ).map((el, index) => (
            <UserSmallCard
              key={index}
              responsible={el}
              withActions={true}
              showSociety={
                ticket?.attributes?.societes_copies?.data?.length > 0
              }
            />
          ))
        )}
      </div>

      {(isSupervisor || canManageParticipants) &&
      !ticket?.attributes?.archived &&
      !showPrivateMessages ? (
        <div className="pt-2">
          <ColoredButton
            color={"light-blue"}
            textButton="Ajout/suppression des participants"
            callback={() =>
              dispatch(
                openModal({
                  type: "small",
                  content: (
                    <AddParticipants
                      ticket={ticket}
                      participants={
                        showPrivateMessages ? privateVisibleBy : clientVisibleBy
                      }
                    />
                  ),
                })
              )
            }
          />
        </div>
      ) : null}
    </div>
  );
};

export default RightParticipants;
